import {Button, ButtonProps, makeStyles, Theme} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {calcColorStyle} from './uiUtils'

interface Props {
  color?: string
  colorizeWhenDisabled?: boolean
}

type ColorButtonProps = Props & Omit<ButtonProps, keyof Props>

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: ({color, colorizeWhenDisabled}: Props) =>
    color ? calcColorStyle(color, theme, colorizeWhenDisabled) : {}
}))

export const ColorButton: React.FC<ColorButtonProps> = ({
  color,
  className,
  colorizeWhenDisabled = false,
  ...other
}) => {
  const classes = useStyles({color, colorizeWhenDisabled})

  return <Button className={classNames(classes.buttonStyle, className)} {...other} />
}
