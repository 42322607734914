import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'

import {Client} from '../../declarations'

const createClient = (): Client => {
  const dfBackendSelector = new BrowserBackendSelector(getDefaultDFBackends())
  const dfSelectedBackend = dfBackendSelector.getSelectedBackend()

  const dfLoginStorage = new BrowserLoginStorage(
    `HC-${process.env.REACT_APP_CLIENT_NAME}-${dfSelectedBackend.NAME}`
  )

  const dfAuthRequestProvider = new EnvAuthRequestProvider(dfBackendSelector)
  const dfLoginFlow = new BrowserLoginFlow(dfLoginStorage, dfAuthRequestProvider, dfBackendSelector)

  const dfApi = createHttpClient({
    backendSelector: dfBackendSelector,
    authRequestProvider: dfAuthRequestProvider,
    loginStorage: dfLoginStorage,
    loginFlow: dfLoginFlow
  })

  return {dfLoginStorage, dfApi, dfLoginFlow}
}

export const client = createClient()
