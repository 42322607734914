import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import {LayoutConstants} from '../constants'

import {useShellContext} from './Context'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.palette.primary.contrastText,
    minHeight: 112,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    '@media print': {
      display: 'none'
    }
  },
  content: {
    display: 'flex',
    minHeight: '100%'
  },
  boxed: {
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

interface FooterProps {
  boxed?: boolean
  children: React.ReactNode
}

const Footer = React.forwardRef<HTMLDivElement, FooterProps>(({children, boxed, ...props}, ref) => {
  const {boxed: defaultBoxed} = useShellContext()
  const classes = useStyles()
  const useBoxedLayout = boxed !== undefined ? boxed : defaultBoxed

  return (
    <div ref={ref} className={classes.container} {...props}>
      <div>
        <div className={classNames({[classes.boxed]: useBoxedLayout})}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  )
})

export default Footer
