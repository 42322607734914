import {useMemo} from 'react'

import {CsSampleType} from '../declarations'

import {usePlantSampleSources} from './usePlantSampleSources'

export const usePlantSampleTypes: () => CsSampleType[] = () => {
  const sampleSources = usePlantSampleSources()
  return useMemo(
    () =>
      sampleSources.filter((source) => source.names.length > 0).map((source) => source.sampleType),
    [sampleSources]
  )
}
