import {makeStyles} from '@material-ui/core'
import React from 'react'

import {LinearScaleFn} from './types'

interface YAxisProps {
  width: number
  ticks: number[]
  y: LinearScaleFn
  color: string
  textColor: string
}

const useStyles = makeStyles((theme) => ({
  axisLabel: {
    ...theme.typography.caption
  }
}))

export const YAxis: React.FC<YAxisProps> = ({width, ticks, y, color, textColor}) => {
  const classes = useStyles()

  return (
    <g>
      {ticks.map((yTick) => {
        const yPos = y(yTick)
        return (
          <g key={yTick}>
            {width !== 0 && (
              <line
                x1={0}
                x2={width}
                y1={yPos}
                y2={yPos}
                strokeWidth={1}
                stroke={color}
                fill="none"
              />
            )}
            <text
              transform={`translate(-6, ${yPos + 4})`}
              textAnchor="end"
              className={classes.axisLabel}
              fill={textColor}
            >
              {yTick}
            </text>
          </g>
        )
      })}
    </g>
  )
}
