import {useMemo} from 'react'

import {extractStatsRecords} from '../common/cementStrength'
import {CementStrengthSample, StatsRecord, TimeRange} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'

export const useCementStrengthStatsRecords = (
  samples: CementStrengthSample[],
  timeRange: TimeRange
): StatsRecord[] => {
  const {strength: strengthLevel} = useCsParamsWithDefaults()
  return useMemo(
    () => extractStatsRecords(samples, strengthLevel, timeRange),
    [samples, strengthLevel, timeRange]
  )
}
