import {Breadcrumbs, makeStyles, Typography} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import React from 'react'

import {replaceNonWordCharacters} from '../common/helpers'

import {InAppLink} from './InAppLink'

interface BreadcrumbEntry {
  title: string
  url: string
}

interface PageBreadcrumbsProps {
  current: string
  pathEntries?: BreadcrumbEntry[]
}

const useStyles = makeStyles((theme: Theme) => ({
  crumbs: {
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(1)
  },
  linkEntry: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.text.secondary,
      textDecoration: 'none'
    }
  },
  linkCurrent: {
    color: theme.palette.text.secondary
  }
}))

export const PageBreadcrumbs: React.FC<PageBreadcrumbsProps> = ({current, pathEntries = []}) => {
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.crumbs}>
      {pathEntries.map((entry) => (
        <InAppLink
          key={entry.url}
          to={entry.url}
          className={classes.linkEntry}
          data-test-id={`breadcrumbs-link-${replaceNonWordCharacters(entry.title)}`}
        >
          {entry.title}
        </InAppLink>
      ))}
      <Typography className={classes.linkCurrent}>{current}</Typography>
    </Breadcrumbs>
  )
}
