import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {AggregatedMaterialData, Material, TimeRange} from '../../declarations'
import {useCementStrengthStats} from '../../hooks/useCementStrengthStats'
import {usePlantNavigation} from '../../hooks/usePlantNavigation'
import {InAppLink} from '../InAppLink'

import {InfoPopOverButton} from './InfoPopOverButton'
import {StatsItem} from './StatsItem'

interface AccuracyStatsProps {
  material: Material
  timeRange: TimeRange
  data: AggregatedMaterialData
}

const R2InfoBox: React.FC = () => {
  const {t} = useTranslation()
  const {getPlantUrl} = usePlantNavigation()

  return (
    <>
      {t('accuracyStats.r2')}
      <InfoPopOverButton>
        <Box m={3} width={256}>
          <Typography>
            <Trans
              i18nKey="accuracyStats.r2Info"
              components={{
                inAppLink: (
                  <InAppLink
                    to={{
                      hash: t('userManual.anchors.metrics'),
                      pathname: getPlantUrl({
                        plantPage: 'userManual',
                        resetQueryParams: true
                      })
                    }}
                    data-test-id="accuracy-stats-link-to-metrics"
                  />
                )
              }}
            />
          </Typography>
        </Box>
      </InfoPopOverButton>
    </>
  )
}

export const AccuracyStats: React.FC<AccuracyStatsProps> = ({material, timeRange, data}) => {
  const {t} = useTranslation()
  const stats = useCementStrengthStats(data.samples, timeRange, material.materialId)

  return (
    <Box display="flex" data-test-id={`accuracy-stats-${material.materialId}`}>
      <StatsItem
        headerLabel={t('accuracyStats.meanAbsoluteError')}
        value={stats.meanAbsoluteError}
        valueUnit={t('unit.MPa')}
        data-test-id={`accuracy-stats-${material.materialId}-mae`}
      />
      <Box mr={3} />
      <StatsItem
        headerLabel={<R2InfoBox />}
        value={stats.r2}
        data-test-id={`accuracy-stats-${material.materialId}-r2`}
        valueDigits={2}
      />
    </Box>
  )
}
