import {Project, setProcessors, setMiddleware, LogTransaction, createLogger} from '../lib'
import {apiClientNetworkErrorMiddleware, messageFormatMiddleware} from '../middleware'
import {AzureProcessor, ConsoleProcessor, SentryProcessor} from '../processors'

export type LogTarget = 'azure' | 'console' | 'sentry'
/**
 *
 * @param project One of the projects existing in the HDigital Catalog
 * @param target Optional parameter if one wants to override the default logger type
 * @returns Log
 */
export const createDefaultLogger = (project: Project, target?: LogTarget): LogTransaction => {
  const getEnvDependentTarget = (): LogTarget =>
    process.env.NODE_ENV === 'development' ? 'console' : 'azure'
  const logTarget = target ?? getEnvDependentTarget()
  const setupLogger: {[key in LogTarget]: () => void} = {
    console: () => {
      setMiddleware([messageFormatMiddleware])
      setProcessors([ConsoleProcessor])
    },
    azure: () => {
      setProcessors([AzureProcessor])
    },
    sentry: () => {
      setMiddleware([apiClientNetworkErrorMiddleware])
      setProcessors([SentryProcessor])
    }
  }
  setupLogger[logTarget]()
  return createLogger({project})
}
