import React from 'react'
import {useTranslation} from 'react-i18next'

import {FreeLimeActionBar} from '../components/actionBar/FreeLimeActionBar'
import {KilnChartsContainer} from '../container/KilnChartsContainer'
import {usePlantFullName} from '../hooks/usePlantFullName'

import {PlantShellPage} from './PlantShellPage'

export const FreeLimePage: React.FC = () => {
  const {t} = useTranslation()
  const plantName = usePlantFullName()
  return (
    <PlantShellPage>
      <FreeLimeActionBar title={t('freeLimePredictionPage.title', {plant: plantName})} />
      <KilnChartsContainer />
    </PlantShellPage>
  )
}
