import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {NOW_PARAM} from '../common/constants'
import {dateTimeParamsFromHorizon} from '../common/dateUtils'
import {searchStringToUrlQueryParams} from '../common/navigation'
import {UrlFreeLimeParamsWithDefaults} from '../declarations'

import {usePlantConfig} from './usePlantConfig'
import {usePlantKilnIds} from './usePlantKilnIds'

export const useFlParamsWithDefaults = (): UrlFreeLimeParamsWithDefaults => {
  const {defaultTimeDurationFreeLime} = usePlantConfig()
  const kilnIds = usePlantKilnIds()
  const {search} = useLocation()

  return useMemo(() => {
    const params = searchStringToUrlQueryParams(search)
    return {
      selectedKilnId: params.selectedKilnId ?? kilnIds[0],
      flStart: params.flStart ?? dateTimeParamsFromHorizon(defaultTimeDurationFreeLime).start,
      flEnd: params.flEnd ?? NOW_PARAM,
      flSelectedTagIds: params.flSelectedTagIds ?? []
    }
  }, [search, kilnIds, defaultTimeDurationFreeLime])
}
