import {makeStyles} from '@material-ui/core'
import React from 'react'

const cellPaddingAdjustStyle: React.CSSProperties | Record<string, React.CSSProperties> = {
  paddingRight: 8,
  paddingLeft: 8,
  '&:last-child': {
    paddingRight: 8
  }
}

export const useTableStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.grey[50]
  },
  tableHeaderCell: {
    ...theme.typography.caption,
    ...cellPaddingAdjustStyle,
    color: theme.palette.grey[700]
  },
  tableRow: {
    '&:last-child > td': {
      borderBottom: 0
    }
  },
  tableRowCell: {
    ...cellPaddingAdjustStyle,
    borderBottomColor: theme.palette.grey[100]
  },
  cellBorder: {
    borderRightColor: theme.palette.grey[100],
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    '&:last-child': {
      borderRight: 0
    }
  },
  alwaysCellBorder: {
    borderRightColor: theme.palette.grey[100],
    borderRightStyle: 'solid',
    borderRightWidth: 1
  },
  cell: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:last-child': {
      paddingRight: theme.spacing(2)
    }
  },
  headerCell: {
    cursor: 'pointer',
    position: 'relative'
  },
  paginationSpacer: {
    flex: 0.5
  }
}))
