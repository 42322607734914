import {AxiosRequestConfig} from 'axios'
import queryString from 'query-string'

import {getURLEncodedContentTypeHeader} from '../../tools'
import {ClientConfig} from '../../types'

export const createClientCredentialsInterceptor =
  (clientConfig: Pick<ClientConfig, 'authRequestProvider'>) =>
  async (config: AxiosRequestConfig) => {
    const headers = config.headers || {}
    const urlEncodedHeader = getURLEncodedContentTypeHeader()
    const urlEncodeBody = headers['Content-Type'] === urlEncodedHeader['Content-Type']

    const body: Record<string, unknown> = {
      ...config.data,
      client_id: clientConfig.authRequestProvider.getClientId()
    }

    const data = urlEncodeBody ? queryString.stringify(body) : body

    const modifiedConfig = {
      ...config,
      headers,
      data
    }

    return modifiedConfig
  }
