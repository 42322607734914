import type {TFunction} from 'i18next'
import {clamp, isNumber, toString} from 'lodash'

import type {
  CsTableData,
  CsTableSample,
  CsTableSampleFilter,
  CsTableSampleFilterData,
  CsTableSampleKey,
  CsTableSampleSort,
  Pagination,
  RawLabData,
  StrengthLevel,
  TableFilter,
  TableFilterData,
  TableSort,
  TableValueMapperFn
} from '../declarations'

import {getActualValue, getPredictedValue} from './cementStrength'
import {AGGREGATED_SILOS_NAME} from './constants'
import {formatTimeZoneDate, parseDate} from './dateUtils'
import {filterAndSortCsTableSamples, filterAndSortTableData} from './filter'
import {formatFloatOrNa} from './format'

export const calcPageFromTo = (
  page: number,
  rowsPerPage: number,
  count: number
): {from: number; to: number} =>
  count < 1
    ? {from: 0, to: 0}
    : {
        from: Math.min(page * rowsPerPage + 1, count),
        to: Math.min((page + 1) * rowsPerPage, count)
      }

export const pageToString = (page: number): string => `${page + 1}`

export const lastPage = (count: number, rowsPerPage: number) =>
  Math.max(0, Math.ceil(count / rowsPerPage) - 1)

export const convertToTableSamples = (
  rawData: RawLabData[],
  strength: StrengthLevel
): CsTableSample[] =>
  rawData.map((record) => ({
    id: record.sampleIdPlant,
    datetime: parseDate(record.sampleDate),
    strength: getActualValue(record, strength),
    predictedStrength: getPredictedValue(record, strength),
    location: record.millName || (isNumber(record.siloNumber) ? AGGREGATED_SILOS_NAME : undefined),
    materialName: record.unifiedMaterialName,
    materialId: record.unifiedMaterialId,
    alite: record.alite,
    belite: record.belite,
    blaine: record.blaine,
    sO3: record.sO3
  }))

export const applySelectedMatAndSourceToTableData = (
  tableSamples: CsTableSample[],
  selectedSources: string[],
  selectedMaterialId?: number
): CsTableSample[] => {
  return tableSamples
    .filter(({materialId}) => !isNumber(selectedMaterialId) || selectedMaterialId === materialId)
    .filter(({location}) => !location || selectedSources.includes(location))
}

const defaultToString: TableValueMapperFn = (value) => toString(value).trim().toLowerCase()

export const getMapperFn = (
  field: CsTableSampleKey,
  t: TFunction,
  timeZone: string
): TableValueMapperFn => {
  const datetimeToString = (datetime: unknown) =>
    formatTimeZoneDate(datetime as number, timeZone, t('cementStrengthSamplesTable.datetime'))

  const numberToString = (value: unknown) =>
    formatFloatOrNa(value as number | undefined, t('common.na'))

  const toStringMap: Partial<Record<CsTableSampleKey, TableValueMapperFn>> = {
    datetime: datetimeToString,
    strength: numberToString,
    predictedStrength: numberToString,
    blaine: numberToString,
    sO3: numberToString,
    alite: numberToString,
    belite: numberToString
  }

  return toStringMap[field] ?? defaultToString
}

export const addMapperFns = (
  filters: CsTableSampleFilterData[],
  t: TFunction,
  timeZone: string
): CsTableSampleFilter[] => {
  return filters.map((filter) => ({
    ...filter,
    valueToString: getMapperFn(filter.field, t, timeZone)
  }))
}

export const calcFilterArray = <T>(
  filterData: TableFilterData<T>[],
  field: T,
  expression: string
): TableFilterData<T>[] => {
  const newFilters = filterData.filter((filter) => filter.field !== field)
  const trimmedExpression = expression.trim().toLowerCase()
  if (trimmedExpression) {
    return [...newFilters, {field, filterExpression: trimmedExpression}]
  }

  return newFilters
}

export const calcCsTableData = ({
  rawData,
  strengthLevel,
  selectedSources,
  filters,
  sortCriterion,
  materialId
}: {
  rawData: RawLabData[]
  strengthLevel: StrengthLevel
  selectedSources: string[]
  sortCriterion?: CsTableSampleSort
  filters: CsTableSampleFilter[]
  materialId?: number
}): CsTableData => {
  const tableData = applySelectedMatAndSourceToTableData(
    convertToTableSamples(rawData, strengthLevel),
    selectedSources,
    materialId
  )
  return {
    strengthLevel,
    samples: filterAndSortCsTableSamples(tableData, filters, sortCriterion ? [sortCriterion] : [])
  }
}

export const addValueToStringFnToFilterData = <T>(
  filterData: TableFilterData<T>,
  valueToString: TableValueMapperFn = defaultToString
): TableFilter<T> => ({
  ...filterData,
  valueToString
})

export const getTablePage = <T>(tableData: T[], pageNumber: number, rowsPerPage: number) =>
  tableData.slice(pageNumber * rowsPerPage, (pageNumber + 1) * rowsPerPage)

export const calcSimpleTableData = <T, K extends keyof T = keyof T>(
  tableRawData: T[],
  filterData: TableFilterData<K>[],
  sortCriterion?: TableSort<K>
): T[] => {
  const sortCriteria = sortCriterion ? [sortCriterion] : []
  const filters: TableFilter<K>[] = filterData.map((filter) =>
    addValueToStringFnToFilterData(filter)
  )
  return filterAndSortTableData(tableRawData, filters, sortCriteria)
}

export const emptyLabelDisplayedRows = () => ''

interface PaginationArgs {
  rowCount: number
  page: number
  rowsPerPage: number
  setPage: (pageNumber: number) => void
  setRowsPerPage: (rows: number) => void
}

export const calcPagination = ({
  rowCount,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage
}: PaginationArgs): Pagination => {
  const lastPageNumber = lastPage(rowCount, rowsPerPage)
  const pageNo = clamp(page, 0, lastPageNumber)

  return {
    rowCount,
    pageNumber: pageNo,
    lastPageNumber,
    rowsPerPage,
    onPageChange: (event, pageNumber) => {
      setPage(clamp(pageNumber, 0, lastPageNumber))
    },
    onRowsPerPageChange: (event) => {
      setRowsPerPage(+event.target.value)
    }
  }
}
