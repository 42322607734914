import {makeStyles, TextField, TextFieldProps} from '@material-ui/core'
import {isNumber, round} from 'lodash'
import React, {useEffect, useState} from 'react'
import {usePrevious} from 'react-use'

import {formatNumber, parseFormattedFloat} from '../common/format'

type NumberFieldProps = Omit<TextFieldProps, 'value' | 'defaultValue' | 'onBlur'> & {
  defaultValue?: number
  value?: number
  formatter?: (v?: number) => string
  onNumberChange?: (v?: number) => void
  precision?: number
}

const useStyles = makeStyles(() => ({
  numberFieldInput: {
    textAlign: 'right'
  }
}))

const DEFAULT_PRECISION = 8

const defaultFormatter = (precision: number) => (v?: number) =>
  isNumber(v) ? formatNumber(v, {minimumFractionDigits: 2, maximumFractionDigits: precision}) : ''

const roundValue = (val: number | undefined, precision: number) =>
  isNumber(val) ? round(val, precision) : val

export const NumberField: React.FC<NumberFieldProps> = (props) => {
  const {
    value: unrounded,
    precision = DEFAULT_PRECISION,
    formatter = defaultFormatter(precision),
    onNumberChange,
    inputProps,
    ...remainingProps
  } = props
  const value = roundValue(unrounded, precision)
  const previousValue = usePrevious(value)
  const classes = useStyles()
  const [numValue, setNumValue] = useState(formatter(value))

  useEffect(() => {
    if (previousValue !== value) {
      setNumValue(formatter(value))
    }
  }, [formatter, previousValue, value])

  return (
    <TextField
      value={numValue}
      inputProps={{...inputProps, className: classes.numberFieldInput}}
      onChange={(event) => {
        setNumValue(event.target.value)
      }}
      onBlur={(event) => {
        const selectedNumber = roundValue(parseFormattedFloat(event.target.value), precision)
        setNumValue(formatter(selectedNumber))
        onNumberChange?.(selectedNumber)
      }}
      {...remainingProps}
    />
  )
}
