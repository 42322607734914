import {Dialog, DialogContent, DialogTitle, makeStyles, TextField} from '@material-ui/core'
import {isEqual} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {updateProcessMetaData} from '../../common/backend'
import {QueryKeyName} from '../../common/constants'
import {
  isProcessMetaDataPlantSpecificTagValid,
  isProcessMetaDataValid,
  trimProcessMetaDataFields
} from '../../common/metaData'
import {ProcessMetaData} from '../../declarations'
import {useDialogStyles} from '../../hooks/useDialogStyles'
import {useMutationWithSnackbar} from '../../hooks/useMutationWithSnackbar'
import {usePlantId} from '../../hooks/usePlantId'
import {DataBox} from '../DataBox'
import {DialogCancelSaveActions} from '../DialogCancelSaveActions'
import {Spacing} from '../Spacing'

const useStyles = makeStyles(() => ({
  dialog: {
    width: 500
  },
  dataBox: {
    padding: 0
  }
}))

interface ProcessMetaDataDialogProps {
  open?: boolean
  item: ProcessMetaData
  title?: string
  onClose?: () => void
}

const showError = (
  currentValue: string,
  originalValue: string,
  isValid: (value: string) => boolean
): boolean => currentValue !== originalValue && !isValid(currentValue)

const useUpdateProcessMetaDataMutation = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  return useMutationWithSnackbar(
    t('sensorMetaDataDialog.updateSuccessful'),
    t('sensorMetaDataDialog.updateFailed'),
    {
      mutationFn: async (metaData: ProcessMetaData) => {
        const dataToSave = trimProcessMetaDataFields(metaData)
        await updateProcessMetaData(plantId, dataToSave)
      },
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetProcessMetaData)
        void client.invalidateQueries(QueryKeyName.GetSensorData)
        void client.invalidateQueries(QueryKeyName.GetPlantSpecificSensorMeta)
      }
    }
  )
}

export const ProcessMetaDataDialog: React.FC<ProcessMetaDataDialogProps> = ({
  item,
  open = false,
  title = '',
  onClose
}) => {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const {t} = useTranslation()
  const {mutate, isLoading} = useUpdateProcessMetaDataMutation()
  const [plantTag, setPlantTag] = useState(item.plantSpecificTag)
  const itemToSave: ProcessMetaData = {
    ...item,
    plantSpecificTag: plantTag
  }
  const isPristine = isEqual(item, itemToSave)

  const onSave = () => {
    mutate(itemToSave, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  const onEnter = () => {
    setPlantTag(item.plantSpecificTag)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-test-id={`process-meta-data-dialog-${item.uniformTag}`}
      PaperProps={{className: classes.dialog}}
      TransitionProps={{onEnter}}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={dialogClasses.contentBlock}>
        <TextField
          fullWidth
          data-test-id="process-meta-data-dialog-plant-tag-field"
          placeholder={t('processMetaData.plantSpecificTag')}
          label={t('processMetaData.plantSpecificTag')}
          value={plantTag}
          onChange={(event) => {
            setPlantTag(event.target.value)
          }}
          required
          error={showError(plantTag, item.plantSpecificTag, isProcessMetaDataPlantSpecificTagValid)}
          helperText={
            showError(plantTag, item.plantSpecificTag, isProcessMetaDataPlantSpecificTagValid)
              ? t('form.required')
              : undefined
          }
        />
        <Spacing height={3} />
        <DataBox
          data={[
            {
              key: item.uniformTag,
              value: item.uniformTag,
              label: t('processMetaData.uniformTag')
            },
            {
              key: item.unit,
              value: item.unit,
              label: t('processMetaData.unit')
            },
            {
              key: item.description,
              value: item.description,
              label: t('processMetaData.description')
            }
          ]}
          className={classes.dataBox}
        />
      </DialogContent>
      <DialogCancelSaveActions
        onCancel={onCancel}
        onSave={onSave}
        saveBtnLabel={t('button.save')}
        saveDisabled={!isProcessMetaDataValid(itemToSave) || isPristine}
        saving={isLoading}
      />
    </Dialog>
  )
}
