import {SimpleCurve} from '@hconnect/uikit'
import React, {useMemo} from 'react'

import {getActualValues, getPredictedValues} from '../../common/cementStrength'
import {
  MAX_GRAPH_DATA_POINTS_OVERVIEW,
  PREDICTION_DASHARRAY_SMALL
} from '../../common/charts/chartConfigurations'
import {reduceDataPoints} from '../../common/filter'
import type {AggregatedMaterialData, LinearScaleFn, StrengthLevel} from '../../declarations'
import {useCsColorFn} from '../../hooks/useColorFn'

interface MaterialDataCurvesProps {
  materialData: AggregatedMaterialData
  x: LinearScaleFn
  y: LinearScaleFn
  level: StrengthLevel
}

export const MaterialDataCurves: React.FC<MaterialDataCurvesProps> = ({
  materialData,
  x,
  y,
  level
}) => {
  const {samples} = materialData
  const getColor = useCsColorFn()
  const actualValues = useMemo(
    () => reduceDataPoints(getActualValues(samples, level), MAX_GRAPH_DATA_POINTS_OVERVIEW),
    [level, samples]
  )
  const predictedValues = useMemo(
    () => reduceDataPoints(getPredictedValues(samples, level), MAX_GRAPH_DATA_POINTS_OVERVIEW),
    [level, samples]
  )

  return (
    <g>
      <SimpleCurve
        color={getColor(level)}
        records={predictedValues}
        x={x}
        y={y}
        strokeDasharray={PREDICTION_DASHARRAY_SMALL}
      />
      <SimpleCurve color={getColor(level)} records={actualValues} x={x} y={y} />
    </g>
  )
}
