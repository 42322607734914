import type {Event} from '@hconnect/common/logging'
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {usePromise} from 'react-use'

import {initUser, isUserLoggedIn, startLoginProcess, startLogoutProcess} from './common/backend'
import {logger} from './common/logger'
import {AppContext, PredictUser} from './declarations'

const Context = createContext<AppContext | undefined>(undefined)

export const useAuthContext = (): AppContext => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useAppContext used outside of AuthProvider')
  }
  return context
}

export const AuthProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<PredictUser | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const promise = usePromise()

  useEffect(() => {
    if (isUserLoggedIn(user)) {
      setLoading(false)
      return undefined
    }

    setLoading(true)
    let subscribe = true
    initUser()
      .then((user) => {
        if (subscribe) {
          setUser(user)
          setLoading(false)
        }
      })
      .catch((error: unknown) => {
        logger.error(error as Event)
        if (subscribe) {
          setLoading(false)
        }
      })

    return () => {
      subscribe = false
    }
  }, [user])

  const value = useMemo(() => {
    const logout = async () => {
      await promise(startLogoutProcess())
      setUser(undefined)
    }

    return {
      user,
      login: startLoginProcess,
      logout,
      isLoading: loading,
      isLoggedIn: isUserLoggedIn(user)
    }
  }, [loading, promise, user])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
