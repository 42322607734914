import {isEmpty} from 'lodash'

import type {
  BackendCsSampleType,
  CsSampleSource,
  CsSampleType,
  FreeLimeConfig,
  FreelimeConfigurationDto,
  Plant,
  PlantConfig,
  PlantConfigurationDto,
  SampleSourceDto,
  StrengthLevel,
  TimeHorizonId
} from '../../declarations'
import {
  compareStrengthLevels,
  getStrengthLevelFromString,
  isStrengthLevel,
  SAMPLE_TYPES
} from '../cementStrength'
import {
  AGGREGATED_SILOS_NAME,
  DEFAULT_FREE_LIME_CONFIG,
  DEFAULT_PLANT_CONFIG,
  DEFAULT_TIME_ZONE
} from '../constants'
import {logger} from '../logger'
import {timeHorizonFromDuration} from '../timeHorizon'

import {client} from './client'

const convertFreeLimeConfig = (freeLimeConfigDto?: FreelimeConfigurationDto): FreeLimeConfig => ({
  ...DEFAULT_FREE_LIME_CONFIG,
  ...freeLimeConfigDto
})

const sampleTypesMap: Record<CsSampleType, BackendCsSampleType> = {
  mill: 'mill',
  silo: 'silo',
  hourlyProduction: 'Hourly production',
  dailyAverage: 'Daily average'
}

const convertSampleSources = (sources: SampleSourceDto): CsSampleSource[] =>
  SAMPLE_TYPES.map((sampleType) => {
    const sourceNames = sources[sampleTypesMap[sampleType]] ?? []
    if (sampleType === 'silo') {
      // The individual silo names are not interesting for the app,
      // the silo samples are aggregated to a virtual Silo named AGGREGATED_SILOS_NAME
      return {sampleType, names: isEmpty(sourceNames) ? [] : [AGGREGATED_SILOS_NAME]}
    }
    return {sampleType, names: sourceNames}
  })

const convertToSampleType = (type: string): CsSampleType | undefined =>
  SAMPLE_TYPES.find((sampleType) => sampleType === type)

const calcDefaultStrengthLevel = (
  levels: StrengthLevel[],
  defaultTimeHorizon?: string
): StrengthLevel => {
  const log = logger.context(
    'calculate default strength level from plant config',
    'calcDefaultStrengthLevel'
  )

  let defaultStrengthLevel = getStrengthLevelFromString(defaultTimeHorizon)
  if (!defaultStrengthLevel) {
    log.info(
      `The default strength level is undefined, the last supported strength level ${
        levels[levels.length - 1]
      } is used`
    )
    defaultStrengthLevel = levels[levels.length - 1]
  } else if (!levels.includes(defaultStrengthLevel)) {
    log.info(
      `The default strength level ${defaultStrengthLevel} is not supported. The last supported strength level ${
        levels[levels.length - 1]
      } is used`
    )
    defaultStrengthLevel = levels[levels.length - 1]
  }

  return defaultStrengthLevel
}

const timeHorizonIdFromDurationString = (
  horizonString: string | undefined | null,
  defaultHorizonId: TimeHorizonId
): TimeHorizonId => (horizonString ? timeHorizonFromDuration(horizonString) : defaultHorizonId)

export const extractPlantConfig = (plant: PlantConfigurationDto): PlantConfig => {
  const log = logger.context('load plants', 'extractPlantConfig')
  const {defaultTimeHorizon, supportedTimeHorizons} = plant
  let levels: StrengthLevel[] =
    supportedTimeHorizons?.map((sth) => getStrengthLevelFromString(sth)).filter(isStrengthLevel) ??
    []

  if (levels.length === 0) {
    log
      .tags({plantDto: plant.plantFullName ?? ''})
      .info(`No valid strength horizons are provided for ${plant.plantFullName}, using default...`)
    levels = DEFAULT_PLANT_CONFIG.supportedStrengthLevels
  }
  levels.sort(compareStrengthLevels)

  return {
    defaultStrengthLevel: calcDefaultStrengthLevel(levels, defaultTimeHorizon),
    supportedStrengthLevels: levels,
    defaultSampleTypeSources: (plant.defaultSampleTypeSources
      ?.map(convertToSampleType)
      .filter(Boolean) ?? []) as CsSampleType[],
    defaultTimeDurationCementStrength: timeHorizonIdFromDurationString(
      plant.defaultTimeDurationCementStrength,
      DEFAULT_PLANT_CONFIG.defaultTimeDurationCementStrength
    ),
    defaultTimeDurationFreeLime: timeHorizonIdFromDurationString(
      plant.defaultTimeDurationFreelime,
      DEFAULT_PLANT_CONFIG.defaultTimeDurationFreeLime
    )
  }
}

export const convertPlants = (plants: PlantConfigurationDto[]): Plant[] =>
  plants
    .map((p) => ({
      timeZone: p.timezone ?? DEFAULT_TIME_ZONE,
      id: p.plantId ?? '',
      name: p.plantName ?? '',
      fullName: p.plantFullName ?? '',
      country: p.country ?? '',
      config: extractPlantConfig(p),
      sampleSources: convertSampleSources(p.supportedSampleTypeSources ?? {}),
      freeLimeConfig: convertFreeLimeConfig(p.freelimeConfig),
      kilnIds: p.kilns?.map((k) => k.toString()) ?? []
    }))
    .filter((p) => Boolean(p.id))

export const getPlants = async (): Promise<Plant[]> => {
  const response = await client.dfApi.get<PlantConfigurationDto[]>('predict/plants')
  return convertPlants(response.data)
}
