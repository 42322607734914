import {makeStyles, SelectProps, TextField} from '@material-ui/core'
import classNames from 'classnames'
import React, {useCallback} from 'react'

const useStyles = makeStyles(() => ({
  selectFieldWrapper: {
    marginTop: 0,
    marginBottom: 8,
    width: 160,
    maxWidth: '100%'
  }
}))

export interface ActionBarSelectRenderProps<T> {
  itemValue: T
}

interface ActionBarSelectProps<T> {
  onSelected: (value: T) => void
  value: T
  values: T[]
  render: (props: ActionBarSelectRenderProps<T>) => React.ReactNode
  fieldId: string
  label?: string
  textFieldClassName?: string
  'data-test-id'?: string
  disabled?: boolean
}

const selectProps: SelectProps = {MenuProps: {getContentAnchorEl: null}}

export const ActionBarSelect = <T,>({
  onSelected,
  value,
  values,
  render,
  fieldId,
  label,
  textFieldClassName,
  'data-test-id': dataTestId,
  disabled
}: ActionBarSelectProps<T>) => {
  const classes = useStyles()
  const onChange = useCallback(
    (event) => {
      onSelected(event.target.value as T)
    },
    [onSelected]
  )

  return (
    <TextField
      className={classNames(classes.selectFieldWrapper, textFieldClassName)}
      id={fieldId}
      data-test-id={dataTestId}
      select
      label={label}
      value={value}
      onChange={onChange}
      margin="normal"
      SelectProps={selectProps}
      disabled={disabled}
    >
      {values.map((itemValue) => render({itemValue}))}
    </TextField>
  )
}
