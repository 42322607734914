import {clamp, maxBy, minBy, orderBy} from 'lodash'

import {
  CsTableSample,
  CsTableSampleFilter,
  CsTableSampleSort,
  DateTimeRecord,
  SortOrder,
  TableFilter,
  TableFilterData,
  TableSort,
  TimeRange,
  WithDateTime
} from '../declarations'

import {DEFAULT_TIME_MARGIN_FACTOR} from './charts/chartConfigurations'
import {inTimeRange, padTimeRange} from './timeRange'

export const filterSamplesByTimeRange = <T extends WithDateTime>(
  samples: T[],
  timeRange: TimeRange
): T[] => {
  return samples.filter((d) => inTimeRange(timeRange, d.datetime))
}

export const filterSamplesByTimeRangeWithMargin = <T extends WithDateTime>(
  samples: T[],
  timeRange: TimeRange,
  timeMarginFactor = DEFAULT_TIME_MARGIN_FACTOR
): T[] => filterSamplesByTimeRange(samples, padTimeRange(timeRange, timeMarginFactor))

export const createFilterTerms = (search: string): string[] =>
  search
    .split(/\s+/)
    .map((str) => str.toLowerCase())
    .filter((str) => str.length > 1)

export const matchesSome = (text: string, filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const preProcessedText = text.toLowerCase()
  return filter.some((filterTerm) => preProcessedText.includes(filterTerm))
}

export const matchesAll = (text: string, filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const preProcessedText = text.toLowerCase()
  return filter.every((filterTerm) => preProcessedText.includes(filterTerm))
}

export const someMatchAll = (texts: string[], filter: string[]) => {
  if (filter.length === 0) {
    return true
  }
  const textsLower = texts.map((text) => text.toLowerCase())
  return filter.every((filterTerm) => textsLower.some((text) => text.includes(filterTerm)))
}

const findMinValRecord = (records: DateTimeRecord[]): DateTimeRecord | undefined =>
  minBy(records, (r) => r.value)

const findMaxValRecord = (records: DateTimeRecord[]): DateTimeRecord | undefined =>
  maxBy(records, (r) => r.value)

export const mapTimeToIntervalIndex = (
  time: number,
  start: number,
  end: number,
  intervalCount: number
): number => {
  if (intervalCount < 1) {
    throw new Error('Illegal argument interval < 1')
  }
  if (start >= end) {
    throw new Error('Illegal argument start >= end')
  }
  const timePos = (time - start) / (end - start)
  return clamp(Math.floor(timePos * intervalCount), 0, intervalCount - 1)
}

export const reduceDataPoints = (records: DateTimeRecord[], maxCount: number): DateTimeRecord[] => {
  if (maxCount < 2) {
    throw new Error('Illegal argument maxCount < 2')
  }

  if (records.length <= maxCount) {
    return records
  }

  const min = records[0].datetime
  const max = records[records.length - 1].datetime
  if (min === max) {
    throw new Error('Illegal argument records must have disjunct timestamps')
  }
  const intervalCount = Math.floor(0.5 * maxCount)
  const splitRecords: DateTimeRecord[][] = []
  records.forEach((record) => {
    const idx = mapTimeToIntervalIndex(record.datetime, min, max, intervalCount)
    splitRecords[idx] = [...(splitRecords[idx] ?? []), record]
  })
  return splitRecords.filter(Boolean).flatMap((splitted) =>
    splitted.length === 1
      ? splitted
      : [findMinValRecord(splitted), findMaxValRecord(splitted)].sort((a, b) => {
          return (a as DateTimeRecord).datetime - (b as DateTimeRecord).datetime
        })
  ) as DateTimeRecord[]
}

export const sortTableData = <T>(data: T[], sortCriteria: TableSort<keyof T>[]): T[] =>
  orderBy(
    data,
    sortCriteria.map(({field}) => field),
    sortCriteria.map(({order}) => order)
  )

export const sortCsTableSamples = (
  samples: CsTableSample[],
  sortCriteria: CsTableSampleSort[]
): CsTableSample[] => {
  const isWithDatetime = sortCriteria.find((criterion) => criterion.field === 'datetime')
  const sortWithDateTime: CsTableSampleSort[] = isWithDatetime
    ? sortCriteria
    : [...sortCriteria, {field: 'datetime', order: 'desc'}]
  return sortTableData(samples, sortWithDateTime)
}

export const sortedBy = <T>(
  sortCriterion: TableSort<T> | undefined,
  field: T
): SortOrder | undefined => (sortCriterion?.field === field ? sortCriterion?.order : undefined)

export const filterTableData = <T>(data: T[], filters: TableFilter<keyof T>[]): T[] => {
  const lowerCaseFilter: TableFilter<keyof T>[] = filters.map((filter) => ({
    ...filter,
    filterExpression: filter.filterExpression.trim().toLowerCase()
  }))

  return data.filter((record) =>
    lowerCaseFilter.every(({field, filterExpression, valueToString}) =>
      valueToString(record[field]).toLowerCase().includes(filterExpression)
    )
  )
}

export const filterCsTableSamples = (
  samples: CsTableSample[],
  filters: CsTableSampleFilter[]
): CsTableSample[] => filterTableData(samples, filters)

export const isFilteredBy = <T>(filters: TableFilterData<T>[], field: T): boolean =>
  filters.findIndex((filter) => filter.field === field) > -1

export const filterAndSortCsTableSamples = (
  samples: CsTableSample[],
  filters: CsTableSampleFilter[],
  sortCriteria: CsTableSampleSort[]
): CsTableSample[] => sortCsTableSamples(filterCsTableSamples(samples, filters), sortCriteria)

export const filterAndSortTableData = <T>(
  tableData: T[],
  filters: TableFilter<keyof T>[],
  sortCriteria: TableSort<keyof T>[]
): T[] => sortTableData(filterTableData(tableData, filters), sortCriteria)

export const getFilterExpression = <T>(
  filters: TableFilterData<T>[],
  field: T
): string | undefined => filters.find((filter) => filter.field === field)?.filterExpression
