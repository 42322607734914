import {useQuery, UseQueryResult} from 'react-query'

import {getProcessSensorData} from '../common/backend'
import {
  DEFAULT_TIME_MARGIN_FACTOR,
  FL_FETCH_INTERVAL_MS
} from '../common/charts/chartConfigurations'
import {QueryKeyName} from '../common/constants'
import {isRelativeDateTimeParam} from '../common/dateUtils'
import {padTimeRange, timeRangeFromStartEnd} from '../common/timeRange'
import {DateTimeParam, ProcessSensorData} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'
import {usePlantId} from './usePlantId'
import {usePlantTimeZone} from './usePlantTimeZone'
import {useProcessMetaData} from './useProcessMetaData'
import {useSelectedMetaIds} from './useSelectedMetaIds'
import {useStorageProviderParams} from './useStorageProviderParams'

const useProcessSensorData = (
  selectedTagIds: string[],
  start: DateTimeParam,
  end: DateTimeParam,
  refetchInterval?: number
): UseQueryResult<ProcessSensorData> => {
  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const metaData = useProcessMetaData()
  const {storageProvider, warmStorageProvider, coldStorageProvider} = useStorageProviderParams()
  const ids = useSelectedMetaIds(selectedTagIds, metaData, 'uniformTag')

  return useQuery(
    [QueryKeyName.GetSensorData, plantId, ids, timeZone, start, end],
    () =>
      getProcessSensorData(
        plantId,
        ids,
        padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR),
        storageProvider,
        warmStorageProvider,
        coldStorageProvider
      ),
    {
      refetchInterval: isRelativeDateTimeParam(end) && refetchInterval,
      staleTime: 0
    }
  )
}

export const useCsSensorData = (): UseQueryResult<ProcessSensorData> => {
  const {csSelectedTagIds, csStart, csEnd} = useCsParamsWithDefaults()
  return useProcessSensorData(csSelectedTagIds, csStart, csEnd)
}

export const useFlSensorData = (): UseQueryResult<ProcessSensorData> => {
  const {flSelectedTagIds, flStart, flEnd} = useFlParamsWithDefaults()
  return useProcessSensorData(flSelectedTagIds, flStart, flEnd, FL_FETCH_INTERVAL_MS)
}
