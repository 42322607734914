export interface Dates {
  startDate: Date | null
  endDate: Date | null
}

type QuickSelectionDates = [startDate: Date, endDate?: Date]
export interface QuickSelectionItem {
  id?: string
  title: string
  dates: QuickSelectionDates
}

export const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const
