import {TableCell, TableCellProps} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {useTableStyles} from '../../hooks/useTableStyles'

export const TableBodyCell: React.FC<TableCellProps> = (props) => {
  const {className, ...rest} = props
  const tableClasses = useTableStyles()
  return (
    <TableCell
      className={classNames(
        tableClasses.tableRowCell,
        tableClasses.cell,
        tableClasses.cellBorder,
        className
      )}
      {...rest}
    />
  )
}
