import {makeStyles, Theme} from '@material-ui/core'

export const useDialogStyles = makeStyles((theme: Theme) => ({
  contentBlock: {
    paddingBottom: theme.spacing(3)
  },
  actionBlock: {
    padding: theme.spacing(3),
    boxShadow: '0px -4px 8px 0px #00000014'
  }
}))
