import {makeStyles, Popover} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'

import {usePopoverAnchor} from '../../hooks/usePopoverAnchor'
import {usePopoverStyles} from '../../hooks/usePopoverStyles'

const useStyles = makeStyles(() => ({
  statsInfoBox: {
    position: 'relative'
  },
  statsInfoIcon: {
    position: 'absolute',
    top: -4,
    left: 8,
    cursor: 'pointer'
  }
}))

export const InfoPopOverButton: React.FC = ({children}) => {
  const classes = useStyles()
  const popoverClasses = usePopoverStyles({})
  const {anchorEl, openPopover, closePopover, isOpen, id} = usePopoverAnchor('info-popover')

  return (
    <span className={classes.statsInfoBox}>
      <InfoOutlinedIcon
        aria-describedby={id}
        fontSize="small"
        color="primary"
        onClick={openPopover}
        className={classes.statsInfoIcon}
        data-test-id="info-pop-over-icon"
      />
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          className: popoverClasses.popoverPaper
        }}
      >
        {children}
      </Popover>
    </span>
  )
}
