import {useMemo} from 'react'

import {UrlQueryParamsWithDefaults} from '../declarations'

import {useAdminParamsWithDefaults} from './useAdminParamsWithDefaults'
import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'
import {useSettingsParamsWithDefaults} from './useSettingsParamsWithDefaults'
import {useStorageProviderParams} from './useStorageProviderParams'

export const useQueryParamsWithDefaults = (): UrlQueryParamsWithDefaults => {
  const cementStrengthParamsWithDefaults = useCsParamsWithDefaults()
  const freeLimeParamsWithDefaults = useFlParamsWithDefaults()
  const settingsParamsWithDefaults = useSettingsParamsWithDefaults()
  const adminParamsWithDefaults = useAdminParamsWithDefaults()
  const storageProviderParams = useStorageProviderParams()

  return useMemo(
    () => ({
      ...cementStrengthParamsWithDefaults,
      ...freeLimeParamsWithDefaults,
      ...settingsParamsWithDefaults,
      ...adminParamsWithDefaults,
      ...storageProviderParams
    }),
    [
      cementStrengthParamsWithDefaults,
      freeLimeParamsWithDefaults,
      settingsParamsWithDefaults,
      adminParamsWithDefaults,
      storageProviderParams
    ]
  )
}
