import {isNumber} from 'lodash'

import type {
  CementQualityDetailDto,
  CementStrengthPredictionDataDto,
  CementStrengthSampleDetails,
  DatedCementStrengthPredictionDto,
  MaterialData,
  SampleSourceData,
  TimeRange
} from '../../declarations'
import {CementStrengthSample, RawLabData, RawLabdataDto} from '../../declarations'
import {AGGREGATED_SILOS_NAME} from '../constants'
import {parseDate} from '../dateUtils'

import {client} from './client'

export const dtoToSample = (dto: DatedCementStrengthPredictionDto): CementStrengthSample => ({
  id: dto.sampleIdPlant,
  datetime: parseDate(dto.datetime),
  predictedStrength1d: dto.predictedStrength1d,
  predictedStrength2d: dto.predictedStrength2d,
  predictedStrength3d: dto.predictedStrength3d,
  predictedStrength7d: dto.predictedStrength7d,
  predictedStrength28d: dto.predictedStrength28d,
  strength1d: dto.strength1d,
  strength2d: dto.strength2d,
  strength3d: dto.strength3d,
  strength7d: dto.strength7d,
  strength28d: dto.strength28d,
  millName: dto.millName,
  siloNumber: dto.siloNumber,
  alite: dto.alite,
  belite: dto.belite,
  blaine: dto.blaine,
  sO3: dto.sO3,
  sieve45um: dto.sieve45um,
  psdRrLocation: dto.psdRrLocation
})

export const compareSample = (a: CementStrengthSample, b: CementStrengthSample) =>
  a.datetime - b.datetime

const convertCementStrengthDataDto = (
  dto: CementStrengthPredictionDataDto,
  timeRange: TimeRange
): MaterialData => {
  if (!isNumber(dto.materialId)) {
    throw new Error('materialId must be a number')
  }

  const millSources: SampleSourceData[] =
    dto.mills
      ?.map((millDto) => {
        const {millName, predictions} = millDto
        const records: DatedCementStrengthPredictionDto[] = predictions ?? []
        const samples = records.map((r) => dtoToSample(r))
        samples.sort(compareSample)
        return {name: millName ?? '', samples}
      })
      .filter(({name, samples}) => samples.length > 0 && name) ?? []
  const siloSamples = dto.silos?.map((r) => dtoToSample(r)).sort(compareSample) ?? []
  const siloSources: SampleSourceData[] =
    siloSamples.length > 0 ? [{name: AGGREGATED_SILOS_NAME, samples: siloSamples}] : []

  const sampleSources = [...siloSources, ...millSources]

  return {
    materialId: dto.materialId,
    materialName: dto.materialName ?? '',
    sampleSources,
    timeRange
  }
}

export const getCementStrengthByMaterial = async (
  plantId: string,
  materialId: number,
  range: TimeRange
): Promise<MaterialData | undefined> => {
  const response = await client.dfApi.get<CementStrengthPredictionDataDto[]>(
    `/predict/plants/${plantId}/cementStrengths/predictions?startTime=${range.start.toJSON()}&endTime=${range.end.toJSON()}&unifiedMaterialId=${materialId}`
  )
  if (response.data.length === 0) {
    return
  }
  const [dto] = response.data
  return convertCementStrengthDataDto(dto, range)
}

const convertCementStrengthDetailsDto = (
  dto: CementQualityDetailDto
): CementStrengthSampleDetails => {
  const {sampleIdPlant, sampleDate, ...rest} = dto
  return {...rest, id: sampleIdPlant, datetime: parseDate(sampleDate)}
}

export const getSampleDetails = async (
  plantId: string,
  materialId: number,
  sampleId: number
): Promise<CementStrengthSampleDetails | undefined> => {
  const response = await client.dfApi.get<CementQualityDetailDto[]>(
    `/predict/plants/${plantId}/cementQualities/details?unifiedMaterialId=${materialId}&sampleIdPlant=${sampleId}`
  )
  if (response.data.length === 0) {
    return
  }
  const [dto] = response.data
  return convertCementStrengthDetailsDto(dto)
}

export const getUnfilteredCementStrengthData = async (
  plantId: string,
  range: TimeRange
): Promise<RawLabData[]> => {
  const {data} = await client.dfApi.get<RawLabdataDto[]>(
    `/predict/plants/${plantId}/cementStrengths/all?startTime=${range.start.toJSON()}&endTime=${range.end.toJSON()}`
  )

  return data
}
