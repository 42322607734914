import {isEmpty, omitBy} from 'lodash'

import {MiddlewareFn} from '../../lib'

import {
  getNetworkErrorFingerprint,
  getNetworkErrorUser,
  getNetworkErrorTags
} from './NetworkSelectors'

export const apiClientNetworkErrorMiddleware: MiddlewareFn = (event, metadata) => {
  if (!(event instanceof Error)) {
    return [event, metadata]
  }
  const omitByEmpty = (param: Record<string, unknown>) => omitBy(param, isEmpty)
  const {project, context, extras, tags} = metadata
  const fingerprint = getNetworkErrorFingerprint(event)
  const user = omitByEmpty(getNetworkErrorUser(event))
  return [
    event,
    {
      tags: {...tags, ...getNetworkErrorTags(event)},
      project,
      context,
      extras: {
        ...extras,
        ...omitByEmpty({fingerprint, user})
      }
    }
  ]
}
