import {useMemo} from 'react'

import {hasActualAndPredicted} from '../common/cementStrength'
import {filterSamplesByTimeRange} from '../common/filter'
import {timeRangeFromDateTimeParams} from '../common/timeRange'
import {CementStrengthSample} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useCsTimeRange} from './useCsTimeRange'

export const useSamplesInTimeRangeAndView = (
  samples: CementStrengthSample[]
): CementStrengthSample[] => {
  const {predictionView, strength} = useCsParamsWithDefaults()
  const csTimeRange = useCsTimeRange()
  return useMemo(() => {
    const filtered = filterSamplesByTimeRange(samples, timeRangeFromDateTimeParams(csTimeRange))
    if (predictionView === 'time') {
      return filtered
    }
    return filtered.filter((s) => hasActualAndPredicted(s, strength))
  }, [predictionView, samples, strength, csTimeRange])
}
