export const isDev = () => process.env.NODE_ENV === 'development'

export const projects = [
  'adminconsole',
  'apiclient',
  'authenticator',
  'automate',
  'cli',
  'common',
  'hcem',
  'hproduce',
  'hub',
  'insight',
  'metricsapi',
  'metricsui',
  'onsite',
  'predict',
  'truckmonitor',
  'uikit',
  'legal',
  'datalink',
  'rcfa'
] as const
export type Project = typeof projects[number]

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical'
}
