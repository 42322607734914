import {Box, Theme, Typography, useTheme} from '@material-ui/core'
import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getMaterialLevel, getStrengthLevelInDays} from '../../common/cementStrength'
import {formatTimeZoneDate} from '../../common/dateUtils'
import {formatFloat} from '../../common/format'
import {AggregatedMaterialData, MaterialLevel, StrengthLevel, TimeRange} from '../../declarations'
import {useCementStrengthStats} from '../../hooks/useCementStrengthStats'
import {useMaterial} from '../../hooks/useMaterial'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'

import {InfoPopOverButton} from './InfoPopOverButton'
import {StatsItem} from './StatsItem'

interface MillStatsProps {
  data: AggregatedMaterialData
  materialId: number
  strengthLevel: StrengthLevel
  timeRange: TimeRange
}

const labelColor = (theme: Theme, deviation?: number, materialLevel?: MaterialLevel): string => {
  if (!materialLevel || !deviation) {
    return theme.palette.grey[700]
  }

  const absDeviation = Math.abs(deviation)
  const {target, min, max} = materialLevel
  const allowedDeviation = (Math.abs(target - min) + Math.abs(max - target)) / 2
  if (absDeviation < allowedDeviation / 2) {
    return theme.palette.success.main
  }
  if (absDeviation < allowedDeviation) {
    return theme.palette.warning.main
  }
  return theme.palette.error.main
}

export const MillStats: React.FC<MillStatsProps> = ({
  data,
  materialId,
  strengthLevel,
  timeRange
}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const timeZone = usePlantTimeZone()
  const material = useMaterial(materialId)
  const materialLevel = getMaterialLevel(material, strengthLevel)
  const {samples} = data
  const stats = useCementStrengthStats(samples, timeRange, materialId)

  const averageDeviation = stats.deviationFromTarget
  const deviationString = isNumber(averageDeviation)
    ? t('millStats.strengthMPa', {
        strength: formatFloat(averageDeviation ?? 0, {locale: i18n.language, displayPlus: true})
      })
    : t('common.na')

  return (
    <Box display="flex" data-test-id={`mill-stats-container-${materialId}`}>
      <StatsItem
        headerLabel={t('millStats.averagePredictedStrength')}
        value={stats.average}
        valueUnit={t('unit.MPa')}
        data-test-id={`mill-stats-container-${materialId}-predicted-strength`}
      />
      <Box mr={3} />
      <StatsItem
        headerLabel={
          <>
            {t('millStats.fromTarget')}
            <InfoPopOverButton>
              <Box m={3} width={256}>
                <Typography>
                  {t('millStats.fromTargetInfo', {
                    start: formatTimeZoneDate(timeRange.start, timeZone, t('millStats.datetime')),
                    end: formatTimeZoneDate(timeRange.end, timeZone, t('millStats.datetime')),
                    strength: t('strengthLevel.strength', {
                      count: getStrengthLevelInDays(strengthLevel)
                    }),
                    materialName: material?.name,
                    value: deviationString
                  })}
                </Typography>
              </Box>
            </InfoPopOverButton>
          </>
        }
        value={stats.deviationFromTarget}
        valueColor={labelColor(theme, averageDeviation, materialLevel)}
        valueUnit={t('unit.MPa')}
        valueSign
        data-test-id={`mill-stats-container-${materialId}-deviation`}
      />
    </Box>
  )
}
