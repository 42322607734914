import {Box, Grid, Typography} from '@material-ui/core'
import {isNumber} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FL_TIME_HORIZON_IDS} from '../../common/timeHorizon'
import {TimeHorizonId} from '../../declarations'
import {useFreeLimeConfig} from '../../hooks/useFreeLimeConfig'
import {useFreeLimeSettingsMutation} from '../../hooks/useFreeLimeSettingsMutation'
import {usePlantConfig} from '../../hooks/usePlantConfig'
import {TimeHorizonSelect} from '../actionBar/TimeHorizonSelect'
import {NumberField} from '../NumberField'

import {FormBox} from './FormBox'
import type {HasDisabled} from './types'

const isAxisRangeValid = (min: number | undefined, max: number | undefined) =>
  isNumber(min) && isNumber(max) && min < max

export const FreeLimeViewSettings: React.FC<HasDisabled> = ({disabled}) => {
  const {t} = useTranslation()
  const {defaultTimeDurationFreeLime: defaultTimeDuration} = usePlantConfig()
  const {axisMin, axisMax} = useFreeLimeConfig()
  const [axisMinimum, setAxisMinimum] = useState<number | undefined>(axisMin)
  const [axisMaximum, setAxisMaximum] = useState<number | undefined>(axisMax)
  const isValidAxisRange = isAxisRangeValid(axisMinimum, axisMaximum)

  const [selectedTimeHorizon, setSelectedTimeHorizon] = useState<TimeHorizonId>(defaultTimeDuration)

  const {isLoading, mutate} = useFreeLimeSettingsMutation()

  useEffect(() => {
    setSelectedTimeHorizon(defaultTimeDuration)
  }, [defaultTimeDuration])

  const onRevert = () => {
    setSelectedTimeHorizon(defaultTimeDuration)
    setAxisMinimum(axisMin)
    setAxisMaximum(axisMax)
  }

  const onSave = () => {
    if (isAxisRangeValid(axisMinimum, axisMaximum)) {
      mutate({
        axisRange: {min: axisMinimum as number, max: axisMaximum as number},
        defaultTimeDurationFreeLime: selectedTimeHorizon
      })
    }
  }

  const isPristine =
    selectedTimeHorizon === defaultTimeDuration &&
    axisMinimum === axisMin &&
    axisMaximum === axisMax

  return (
    <FormBox
      title={t('freeLimeViewSettings.title')}
      onSave={onSave}
      onRevert={onRevert}
      disabled={isPristine}
      isFormInvalid={!isValidAxisRange}
      data-test-id="free-lime-view-settings-form-box"
      isSaving={isLoading}
    >
      <div>
        <Box mb={1}>
          <TimeHorizonSelect
            selectable={FL_TIME_HORIZON_IDS}
            value={selectedTimeHorizon}
            onSelected={setSelectedTimeHorizon}
            disabled={disabled}
          />
        </Box>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={12} sm="auto">
            <Typography>{t('freeLimeViewSettings.axisRangeLabel')}</Typography>
          </Grid>
          <Grid item>
            <NumberField
              value={axisMinimum}
              error={!isValidAxisRange}
              disabled={disabled}
              onNumberChange={(val) => setAxisMinimum(val)}
              inputProps={{
                'data-test-id': 'free-lime-view-settings-axis-min-input'
              }}
            />
          </Grid>
          <Grid item>
            <Typography>-</Typography>
          </Grid>
          <Grid item>
            <NumberField
              value={axisMaximum}
              error={!isValidAxisRange}
              disabled={disabled}
              onNumberChange={(val) => setAxisMaximum(val)}
              inputProps={{
                'data-test-id': 'free-lime-view-settings-axis-max-input'
              }}
            />
          </Grid>
        </Grid>
      </div>
    </FormBox>
  )
}
