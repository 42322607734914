import {isNumber} from 'lodash'

import {getMaterialById} from '../common/cementStrength'
import {Material} from '../declarations'
import {useMaterialContext} from '../providers/MaterialProvider'

export const useMaterial = (materialId: number | undefined): Material | undefined => {
  const {materials} = useMaterialContext()
  return isNumber(materialId) ? getMaterialById(materials, materialId) : undefined
}
