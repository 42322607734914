import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@material-ui/core'
import {NavigateBefore, NavigateNext} from '@material-ui/icons'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

import {formatTimeZoneDate} from '../../common/dateUtils'
import {formatFloat} from '../../common/format'
import {CementStrengthSample, Material} from '../../declarations'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useKeyEventUpOrHoldTriggered} from '../../hooks/useKeyEvent'
import {usePlantNavigation} from '../../hooks/usePlantNavigation'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'
import {CementStrengthTable} from '../CementStrengthTable'
import {QualityParameterRow} from '../QualityParameterRow'

interface CementStrengthSampleDetailsProps {
  sample: CementStrengthSample
  material: Material
  onPrev: (currentSampleId: number) => void
  onNext: (currentSampleId: number) => void
  isFirstSample: boolean
  isLastSample: boolean
}

const useStyles = makeStyles((theme) => {
  const margin = theme.spacing(3) / 2
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: -margin,
      height: `calc(100% + ${theme.spacing(3)}px)`
    },
    item: {
      padding: margin
    },
    spacing: {
      flexGrow: 1
    },
    highlighted: {
      display: 'inline-block',
      paddingBottom: 4,
      borderBottomColor: 'black',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid'
    },
    detailsTime: {
      fontWeight: theme.typography.fontWeightBold
    },
    detailsId: {
      color: theme.palette.grey[700]
    },
    sectionHeader: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: 16
    },
    navigationButton: {
      minWidth: 'auto',
      padding: 0,
      width: 40,
      height: 40,
      color: theme.palette.primary.main
    },
    viewDetailsButton: {
      color: theme.palette.primary.main
    }
  }
})

interface QualityParameter {
  key: string
  value?: number
}

const getQualityParameters = (sample: CementStrengthSample): QualityParameter[] => [
  {key: 'alite', value: sample.alite},
  {key: 'belite', value: sample.belite},
  {key: 'blaine', value: sample.blaine}
]

export const CementStrengthSampleDetails: React.FC<CementStrengthSampleDetailsProps> = ({
  sample,
  material,
  onPrev,
  onNext,
  isFirstSample,
  isLastSample
}) => {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const queryParams = useCsParamsWithDefaults()
  const classes = useStyles()
  const {levels} = material
  const onPrevSample = useCallback(() => onPrev(sample.id), [onPrev, sample.id])
  const onNextSample = useCallback(() => onNext(sample.id), [onNext, sample.id])

  useKeyEventUpOrHoldTriggered(onPrevSample, 'ArrowLeft')
  useKeyEventUpOrHoldTriggered(onNextSample, 'ArrowRight')

  const qualityParameters = useMemo(() => getQualityParameters(sample), [sample])
  const navigation = usePlantNavigation()

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="outlined"
            className={classes.navigationButton}
            color="secondary"
            onClick={onPrevSample}
            data-test-id="sample-details-prev-button"
            disabled={isFirstSample}
          >
            <NavigateBefore />
          </Button>
          <Box data-test-id="cement-strength-sample-details-caption-box">
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.detailsTime}
              gutterBottom
            >
              {formatTimeZoneDate(sample.datetime, timeZone, t('sampleDetails.dateFormat'))}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              align="center"
              className={classes.detailsId}
            >
              {t('sampleDetails.sampleId', {id: sample.id})}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            className={classes.navigationButton}
            color="secondary"
            onClick={onNextSample}
            data-test-id="sample-details-next-button"
            disabled={isLastSample}
          >
            <NavigateNext />
          </Button>
        </Box>
      </div>
      <div className={classes.item}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <QualityParameterRow
                name={t('sampleData.millName.label')}
                value={sample.millName}
                data-test-id="sample-details-millName"
              />
              <QualityParameterRow
                name={t('sampleData.siloNumber.label')}
                value={isNumber(sample.siloNumber) ? String(sample.siloNumber) : undefined}
                data-test-id="sample-details-siloNumber"
              />
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.item}>
        <Typography
          variant="subtitle1"
          className={classes.sectionHeader}
          data-test-id="sample-details-strength-section-header"
        >
          <span className={classes.highlighted}>{t('sampleDetails.cementStrengthTitle')}</span>
        </Typography>
        <CementStrengthTable levels={levels} sample={sample} />
      </div>
      {qualityParameters.length > 0 && (
        <div className={classes.item}>
          <Typography
            variant="subtitle1"
            className={classes.sectionHeader}
            data-test-id="sample-details-quality-parameters-section-header"
          >
            <span className={classes.highlighted}>{t('sampleDetails.qualityParameters')}</span>
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {qualityParameters.map((qp) => (
                  <QualityParameterRow
                    key={qp.key}
                    name={t(`sampleData.${qp.key}.label`)}
                    value={
                      isNumber(qp.value)
                        ? t(`sampleData.${qp.key}.value`, {value: formatFloat(qp.value)})
                        : undefined
                    }
                    data-test-id={`sample-details-${qp.key}`}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className={classes.spacing} />
      <div className={classes.item}>
        <Button
          variant="outlined"
          className={classes.viewDetailsButton}
          color="secondary"
          data-test-id="sample-details-view-details-button"
          fullWidth
          component={RouterLink}
          to={navigation.getPlantUrl({
            plantPage: 'cementStrengthSample',
            pathParams: {
              materialId: material.materialId,
              sampleId: sample.id
            },
            queryParams
          })}
        >
          {t('sampleDetails.viewDetailsBtnCaption')}
        </Button>
      </div>
    </div>
  )
}
