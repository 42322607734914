import {Localization} from '@hconnect/uikit'

import {logger} from '../common/logger'

export const supportedLanguages = ['de-DE', 'en-US']

const loader = (filename: string) => {
  const log = logger.context('localization', 'loader')
  log.tags({filename}).info('Loading Translation')
  return import(`./${filename}.json`).catch((err) => {
    log.tags({filename, message: err?.message}).error('Could not load translation')
    throw err
  })
}

export const initLocalization = () => {
  void Localization({
    fallbackLng: 'en-US',
    compatibilityJSON: undefined,
    supportedLngs: [...supportedLanguages],
    backend: {
      loader
    }
  })
}
