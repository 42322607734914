import * as Sentry from '@sentry/browser'

import {isDev} from '../../lib'
import {sanitizeUrl} from '../../utilities/DataSanitizer'

// https://docs.sentry.io/error-reporting/configuration/filtering/?platform=browser
const beforeSend = (event) => {
  if (event.request && event.request.url) {
    event.request.url = sanitizeUrl(event.request.url)
  }
  return event
}

const beforeBreadcrumb = (breadcrumb) => {
  if (breadcrumb.data && breadcrumb.data.url) {
    breadcrumb.data.url = sanitizeUrl(breadcrumb.data.url)
  }
  return breadcrumb
}

/**
 * Singleton to configure a sentry instance. Can be used to send custom exceptions but for basic event and error capturing simply run `SentriInitializer.initialize()` at the start of your app.
 */
export class SentryInitializer {
  private static isInitialized = false
  public static initialize(): typeof Sentry | undefined {
    if (this.isInitialized) return Sentry
    if (isDev())
      console.warn(
        '🚨 You are using sentry in a development context.  Please ensure that this is desired.'
      )

    if (!process.env.REACT_APP_SENTRY_DSN || !process.env.REACT_APP_STAGE) {
      console.error(
        'Sentry could not be set up because one of the following environment variables was not present: REACT_APP_SENTRY_DSN , REACT_APP_STAGE'
      )
      return
    } else {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_STAGE,
        beforeSend,
        beforeBreadcrumb
      })
      this.isInitialized = true
      return Sentry
    }
  }
}
