import plannerContactPersonImageSrc from '../assets/ContactPersonImage/planner.jpeg'
import plants from '../plants.json'
import {AppConfig, PLANT_SHORT_NAME, PlantId} from '../types'

const whiteListedPlants: PlantId[] = []

for (const plantId in plants) {
  if (Object.prototype.hasOwnProperty.call(plants, plantId) && 'shortName' in plants[plantId]) {
    // add all plants that have a short name, as this is the identifier planner uses for the plant
    whiteListedPlants.push(plantId)
  }
}

export const planner: AppConfig = {
  contact: {
    name: 'Tim Radmacher',
    mail: 'tim.radmacher@heidelbergcement.com',
    profileImage: plannerContactPersonImageSrc
  },
  name: 'Planner',
  url: {
    qa: `https://hcem-qa.heidelbergcement.com/plants/${PLANT_SHORT_NAME}/`,
    uat: `https://hcem-uat.heidelbergcement.com/plants/${PLANT_SHORT_NAME}`,
    prod: `https://hcem.heidelbergcement.com/plants/${PLANT_SHORT_NAME}`
  },
  whiteListedPermissionTypes: ['VIEW_ELECTRICITY_PRICE'],
  plant: {
    whiteList: whiteListedPlants
  }
}
