import {makeStyles, Theme} from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyle = makeStyles((theme: Theme) => ({
  monthSelector: {
    fontSize: 18,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  monthNavButton: {
    backgroundColor: 'transparent',
    border: '1px solid #E6ECF0',
    borderRadius: 4,
    height: 44,
    width: 44,
    cursor: 'pointer'
  }
}))

interface Props {
  monthInView: number
  showNextMonth: () => void
  showPreviousMonth: () => void
  yearInView: number
}

const MonthSelector: React.FC<Props> = ({
  monthInView,
  showNextMonth,
  showPreviousMonth,
  yearInView
}) => {
  const classes = useStyle()
  const {t} = useTranslation()
  const text = `${t('datePicker.months', {returnObjects: true})[monthInView]} ${yearInView}`

  return (
    <div className={classes.monthSelector}>
      <button className={classes.monthNavButton} onClick={showPreviousMonth}>
        <ChevronLeft color="primary" />
      </button>
      {text}
      <button className={classes.monthNavButton} onClick={showNextMonth}>
        <ChevronRight color="primary" />
      </button>
    </div>
  )
}

export {MonthSelector}
