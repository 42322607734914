import React from 'react'

import {NOW_PARAM} from '../../common/constants'
import {arrayToParameter} from '../../common/navigation'
import {CS_TIME_HORIZON_IDS} from '../../common/timeHorizon'
import {DateTimeParam, StrengthLevel} from '../../declarations'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useSetQueryParam, useSetQueryParamWithConverter} from '../../hooks/useSetQueryParam'
import {useSetCsTimeRangeParams} from '../../hooks/useSetTimeRangeParams'
import {PageTitle} from '../PageTitle'

import {ActionBar} from './ActionBar'
import {CsSampleSourceSelect} from './CsSampleSourceSelect'
import {ExcelExportButton} from './ExcelExportButton'
import {PredictionStrengthSelect} from './PredictionStrengthSelect'
import {PredictionViewButtonGroup} from './PredictionViewButtonGroup'
import {TimeRangePickerButton} from './TimeRangePickerButton'
import {ZoomButtonGroup} from './ZoomButtonGroup'

export const CementStrengthActionBar: React.FC<{title: string; showZoomOptions?: boolean}> = ({
  title,
  showZoomOptions = false
}) => {
  const {csStart, csEnd, selectedSources, strength: strengthLevel} = useCsParamsWithDefaults()
  const onTimeRangeChanged = useSetCsTimeRangeParams()
  const onSourceSelected = useSetQueryParamWithConverter<string[]>(
    'selectedSources',
    arrayToParameter
  )
  const onStrengthChanged = useSetQueryParam<StrengthLevel>('strength')

  return (
    <ActionBar title={<PageTitle>{title}</PageTitle>}>
      <CsSampleSourceSelect selectedSources={selectedSources} onSourceSelected={onSourceSelected} />
      <PredictionStrengthSelect
        strengthLevel={strengthLevel}
        onStrengthLevelChanged={onStrengthChanged}
      />
      <TimeRangePickerButton
        start={csStart}
        end={csEnd}
        selectableTimeHorizons={CS_TIME_HORIZON_IDS}
        onTimeRangeChanged={(start: DateTimeParam, end: DateTimeParam) =>
          onTimeRangeChanged(start, end)
        }
      />
      {showZoomOptions && (
        <ZoomButtonGroup
          timeRange={{
            start: csStart,
            end: csEnd
          }}
          onTimeRangeChanged={onTimeRangeChanged}
          maxEnd={csEnd === NOW_PARAM ? NOW_PARAM : undefined}
        />
      )}
      <PredictionViewButtonGroup />
      <ExcelExportButton />
    </ActionBar>
  )
}
