import {useMemo} from 'react'

import {DateTimeParam, TimeRange} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'

export const useCsTimeRange = (): TimeRange<DateTimeParam> => {
  const {csStart: start, csEnd: end} = useCsParamsWithDefaults()
  return useMemo(() => ({start, end}), [end, start])
}
