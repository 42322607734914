import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

interface XAxisLabelProps {
  height: number
  width: number
  label: string
  labelColor: string
  yOffset?: number
  textClassName?: string
}

const useStyles = makeStyles((theme) => ({
  axisLabel: {
    ...theme.typography.caption
  }
}))

export const XAxisLabel: React.FC<XAxisLabelProps> = ({
  height,
  width,
  label,
  labelColor,
  yOffset = 48,
  textClassName
}) => {
  const classes = useStyles()

  return (
    <g>
      <text
        fill={labelColor}
        textAnchor="middle"
        transform={`translate(${0.5 * width}, ${height + yOffset})`}
        className={classNames(classes.axisLabel, textClassName)}
      >
        {label}
      </text>
    </g>
  )
}
