import React from 'react'

import {calcInterval} from './chartUtils'
import {LinearScaleFn} from './types'

const inNumberRange = (min: number, value: number, max: number): boolean =>
  min <= value && value <= max

interface TouchAreasProps {
  timestamps: number[]
  x: LinearScaleFn
  graphHeight: number
  graphWidth: number
  onSelect?: (datetime: number) => void
  onHover?: (datetime?: number) => void
}

export const TouchAreas: React.FC<TouchAreasProps> = ({
  timestamps,
  x,
  graphHeight,
  graphWidth,
  onSelect,
  onHover
}) => {
  const filteredTimestamps = timestamps.filter((timestamp) =>
    inNumberRange(0, x(timestamp), graphWidth)
  )
  const positions = filteredTimestamps.map((timestamp) => x(timestamp))

  return (
    <g>
      {filteredTimestamps.map((dt, idx) => {
        const [xPos, xNextPos] = calcInterval(positions, idx, graphWidth)

        return (
          <rect
            key={dt}
            x={xPos}
            y={0}
            height={graphHeight}
            width={xNextPos - xPos}
            fill="transparent"
            onMouseOver={() => onHover?.(dt)}
            onMouseLeave={() => onHover?.()}
            onClick={() => onSelect?.(dt)}
          />
        )
      })}
    </g>
  )
}
