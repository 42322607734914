import {isNumber} from 'lodash'

import type {Material, MaterialLevel, StrengthLevel} from '../../declarations'
import {inNumberRange} from '../math/numberRange'

export const getMaterialById = (materials: Material[], materialId: number): Material | undefined =>
  materials.find((m) => m.materialId === materialId)

export const getMaterialLevel = (
  material: Material | undefined,
  level: StrengthLevel
): MaterialLevel | undefined => {
  return material?.levels.find((l) => l.name === level)
}

export const isValueOutOfMaterialRange = (
  materials: Material[],
  materialId: number | undefined,
  strengthLevel: StrengthLevel,
  value?: number
): boolean => {
  if (!isNumber(materialId) || !isNumber(value)) {
    return false
  }
  const materialLevel = getMaterialLevel(getMaterialById(materials, materialId), strengthLevel)
  return materialLevel ? !inNumberRange(materialLevel, value) : false
}
