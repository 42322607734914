import {Box, makeStyles, Typography} from '@material-ui/core'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {findNextSample, findPrevSample} from '../../common/cementStrength'
import {intToParam} from '../../common/helpers'
import {setQueryParam} from '../../common/navigation'
import {timeRangeFromDateTimeParams} from '../../common/timeRange'
import {AsyncContainer} from '../../container/AsyncContainer'
import {CementStrengthSample, Material} from '../../declarations'
import {useAggregatedCemStrengthData} from '../../hooks/useAggregatedCemStrengthData'
import {useChartContainerStyles} from '../../hooks/useChartContainerStyles'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useCsTimeRange} from '../../hooks/useCsTimeRange'
import {useMaterialLevel} from '../../hooks/useMaterialLevel'
import {useSamplesInTimeRangeAndView} from '../../hooks/useSamplesInTimeRangeAndView'
import {CementStrengthSamplesTable} from '../CementStrengthSamplesTable'
import {DefaultBox} from '../DefaultBox'
import {ErrorMessage} from '../ErrorMessage'

import {CementStrengthSampleDetails} from './CementStrengthSampleDetails'
import {MaterialDetailsChartContent} from './MaterialDetailsChartContent'

interface MaterialDetailsChartProps {
  material: Material
}

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.grey[500]
  },
  tableContainer: {
    flexGrow: 1,
    margin: `${theme.spacing(3) / 2}px 0`
  }
}))

const isFirstSample = (samples: CementStrengthSample[], sampleId?: number) =>
  isNumber(sampleId) && samples[0]?.id === sampleId

const isLastSample = (samples: CementStrengthSample[], sampleId?: number) =>
  isNumber(sampleId) && samples[samples.length - 1]?.id === sampleId

const MIN_GRAPH_CONTAINER_HEIGHT = 620
const DETAILS_BOX_WIDTH = 320
const GRAPH_HEIGHT = 540

export const MaterialDetailsChart: React.FC<MaterialDetailsChartProps> = ({material}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const chartClasses = useChartContainerStyles()
  const {materialId} = material

  const {sampleId, strength: level, predictionView} = useCsParamsWithDefaults()
  const timeRange = useCsTimeRange()
  const {data, isLoading, isError} = useAggregatedCemStrengthData(materialId)
  const materialLevel = useMaterialLevel(materialId, level)
  const onSelectSample = useCallback(
    (selectedId?: number) => {
      setQueryParam(history, 'sampleId', intToParam(selectedId))
    },
    [history]
  )

  const visibleSamples = useSamplesInTimeRangeAndView(data?.samples ?? [])

  const isFirst = isFirstSample(visibleSamples, sampleId)
  const isLast = isLastSample(visibleSamples, sampleId)

  const selectedSample: CementStrengthSample | undefined = useMemo(() => {
    if (isNumber(sampleId) && data) {
      return visibleSamples.find((s) => s.id === sampleId)
    }
  }, [sampleId, data, visibleSamples])

  const onPrevSample = useCallback(
    (currentSampleId: number) => {
      const prevSample = findPrevSample(visibleSamples, currentSampleId)
      if (prevSample) {
        onSelectSample(prevSample.id)
      }
    },
    [visibleSamples, onSelectSample]
  )

  const onNextSample = useCallback(
    (currentSampleId: number) => {
      const nextSample = findNextSample(visibleSamples, currentSampleId)
      if (nextSample) {
        onSelectSample(nextSample.id)
      }
    },
    [visibleSamples, onSelectSample]
  )

  return (
    <div className={chartClasses.container}>
      {predictionView === 'table' ? (
        <DefaultBox width="100%" className={classes.tableContainer}>
          <CementStrengthSamplesTable materialId={materialId} />
        </DefaultBox>
      ) : (
        <>
          <div className={chartClasses.itemGraph}>
            <DefaultBox minHeight={MIN_GRAPH_CONTAINER_HEIGHT}>
              {materialLevel ? (
                <AsyncContainer
                  isLoading={isLoading}
                  isError={isError || !data}
                  isSuccess={Boolean(data)}
                  errorMessage={
                    <ErrorMessage height="100%">{t('errors.noDataAvailable')}</ErrorMessage>
                  }
                >
                  {data && (
                    <MaterialDetailsChartContent
                      materialData={data}
                      materialLevel={materialLevel}
                      timeRange={timeRangeFromDateTimeParams(timeRange)}
                      selectedSample={selectedSample}
                      onSelectSample={onSelectSample}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </AsyncContainer>
              ) : (
                <ErrorMessage height="100%">{t('errors.noMaterialLevel')}</ErrorMessage>
              )}
            </DefaultBox>
          </div>

          <div className={chartClasses.itemDetails}>
            <DefaultBox
              width={DETAILS_BOX_WIDTH}
              className={chartClasses.itemDetailBox}
              height="100%"
            >
              {selectedSample ? (
                <CementStrengthSampleDetails
                  material={material}
                  sample={selectedSample}
                  isFirstSample={isFirst}
                  isLastSample={isLast}
                  onPrev={onPrevSample}
                  onNext={onNextSample}
                />
              ) : (
                <Box display="flex" height="60%" justifyContent="center" flexDirection="column">
                  <Typography variant="body1" align="center" className={classes.placeholder}>
                    {t('sampleDetails.placeholder')}
                  </Typography>
                </Box>
              )}
            </DefaultBox>
          </div>
        </>
      )}
    </div>
  )
}
