import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

interface YAxisLabelProps {
  height: number
  label: string
  labelColor: string
  xOffset?: number
  textClassName?: string
  'data-test-id'?: string
}

const useStyles = makeStyles((theme) => ({
  axisLabel: {
    ...theme.typography.caption
  }
}))

export const YAxisLabel: React.FC<YAxisLabelProps> = ({
  height,
  label,
  labelColor,
  xOffset = -32,
  textClassName,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()

  return (
    <g>
      <text
        data-test-id={dataTestId}
        transform={`translate(${xOffset}, ${0.5 * height}) rotate(-90)`}
        fill={labelColor}
        textAnchor="middle"
        className={classNames(classes.axisLabel, textClassName)}
      >
        {label}
      </text>
    </g>
  )
}
