import React from 'react'

interface GraphBorderProps {
  graphHeight: number
  graphWidth: number
  color: string
}

export const GraphBorder: React.FC<GraphBorderProps> = ({graphWidth, graphHeight, color}) => (
  <rect x={0} y={0} height={graphHeight} width={graphWidth} fill="none" stroke={color} />
)
