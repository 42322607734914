import {makeStyles, Theme} from '@material-ui/core'
import React from 'react'

import {AxisFormatFn, LinearScaleFn} from './types'

interface XAxisProps {
  height: number
  scale: LinearScaleFn
  color: string
  textColor: string
  textPadding?: number
  format?: AxisFormatFn
  tickValues: readonly number[]
}

const useStyles = makeStyles((theme: Theme) => ({
  textLabel: {
    ...theme.typography.caption
  }
}))

export const XAxis: React.FC<XAxisProps> = ({
  scale,
  height,
  color,
  textColor,
  textPadding = 16,
  format = (value) => value,
  tickValues
}) => {
  const classes = useStyles()

  return (
    <g>
      {tickValues.map((value, idx) => {
        const pos = scale(value)
        return (
          <g key={value}>
            <line x1={pos} x2={pos} y1={0} y2={height} strokeWidth={1} stroke={color} fill="none" />
            <g transform={`translate(${pos}, ${height + textPadding})`}>
              <text textAnchor="middle" fill={textColor} className={classes.textLabel}>
                {format(value, idx)}
              </text>
            </g>
          </g>
        )
      })}
    </g>
  )
}
