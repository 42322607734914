import {isNumber} from 'lodash'

import type {PathParams, PredictPageId, UrlQueryParams} from '../../declarations'

import {queryParamsToString} from './urlQueryParams'

export const FRONT_PAGE_URL = '/'
export const PLANTS_URL = '/plants'
export const ADMIN_URL = '/admin'

const PLANT_PARAM = 'plant'
const MATERIAL_PARAM = 'materialId'
const SAMPLE_PARAM = 'sampleId'

export const plantNameFromParameter = (plantParam: string) => decodeURIComponent(plantParam)

const buildPath = (...args: Array<string | number | null | undefined>) =>
  [...args].filter((s) => isNumber(s) || Boolean(s)).join('/')

interface PredictPage {
  urlPattern: string
  getPageUrl: (pathParams?: PathParams) => string
}

const simplePage = (path: string): PredictPage => ({
  urlPattern: path,
  getPageUrl: () => path
})

const plantPage = (subPath = ''): PredictPage => ({
  urlPattern: buildPath(PLANTS_URL, `:${PLANT_PARAM}`, subPath),
  getPageUrl: (pathParams) => buildPath(PLANTS_URL, pathParams?.plant, subPath)
})

const materialPage = (subPath = ''): PredictPage => {
  const {urlPattern, getPageUrl} = plantPage('cement-strength')
  return {
    urlPattern: buildPath(urlPattern, `:${MATERIAL_PARAM}`, subPath),
    getPageUrl: (pathParams) => buildPath(getPageUrl(pathParams), pathParams?.materialId)
  }
}

const samplePage = (subPath = ''): PredictPage => {
  const {urlPattern, getPageUrl} = materialPage()
  return {
    urlPattern: buildPath(urlPattern, `:${SAMPLE_PARAM}`, subPath),
    getPageUrl: (pathParams) => buildPath(getPageUrl(pathParams), pathParams?.sampleId)
  }
}

const pages: Record<PredictPageId, PredictPage> = {
  root: simplePage(FRONT_PAGE_URL),
  plants: simplePage(PLANTS_URL),
  plantsHome: plantPage(),
  freeLime: plantPage('free-lime'),
  cementStrength: plantPage('cement-strength'),
  cementStrengthTrends: materialPage(),
  cementStrengthSample: samplePage(),
  userManual: plantPage('user-manual'),
  settings: plantPage('settings'),
  admin: simplePage(ADMIN_URL)
}

export const getPageUrlPattern = (pageId: PredictPageId): string => pages[pageId].urlPattern

const addSearchParam = (path: string, searchParams?: UrlQueryParams): string => {
  const queryString = queryParamsToString(searchParams)
  return queryString ? `${path}?${queryString}` : path
}

export const getPageUrl = (
  pageId: PredictPageId,
  pathParams?: PathParams,
  searchParams?: UrlQueryParams
): string => addSearchParam(pages[pageId].getPageUrl(pathParams), searchParams)
