import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme
} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import classNames from 'classnames'
import {isNumber} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getStrengthLevelInDays, isValueOutOfMaterialRange} from '../common/cementStrength'
import {getFilterExpression, sortedBy} from '../common/filter'
import {logger} from '../common/logger'
import {emptyLabelDisplayedRows, getMapperFn} from '../common/tablePageUtils'
import type {CsTableSampleKey, RawLabData} from '../declarations'
import {useAllCementStrengthSamples} from '../hooks/useAllCementStrengthSamples'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {usePaginatedCsTableData} from '../hooks/usePaginatedCsTableData'
import {usePlantNavigation} from '../hooks/usePlantNavigation'
import {usePlantTimeZone} from '../hooks/usePlantTimeZone'
import {usePopoverAnchor} from '../hooks/usePopoverAnchor'
import {useSetQueryParam} from '../hooks/useSetQueryParam'
import {useTableFilters} from '../hooks/useTableFilters'
import {useTableSort} from '../hooks/useTableSort'
import {useTableStyles} from '../hooks/useTableStyles'
import {useMaterialContext} from '../providers/MaterialProvider'

import {DefaultBox} from './DefaultBox'
import {ErrorMessage} from './ErrorMessage'
import {PaginationActions} from './table/PaginationActions'
import {PopoverContent} from './table/PopoverContent'
import {TableBodyCell} from './table/TableBodyCell'
import {TableHeaderCell} from './table/TableHeaderCell'
import {TableHeaderPopover} from './table/TableHeaderPopover'

interface CementStrengthSamplesTableProps {
  materialId?: number
}

const POPOVER_WIDTH = 308

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: theme.spacing(1),
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  bodyRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#018ae60a'
    }
  },
  numberOutsideRange: {
    color: theme.palette.error.main
  },
  selectedRow: {
    animation: `$rowAnim 1500ms ${theme.transitions.easing.sharp}`
  },
  '@keyframes rowAnim': {
    '0%': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

const COLUMN_KEYS: CsTableSampleKey[] = [
  'datetime',
  'id',
  'location',
  'materialName',
  'strength',
  'predictedStrength',
  'blaine',
  'sO3',
  'alite',
  'belite'
]

const SamplesTable: React.FC<{materialId?: number; data: RawLabData[]}> = ({materialId, data}) => {
  const {t} = useTranslation()
  const {materials} = useMaterialContext()
  const timeZone = usePlantTimeZone()
  const {navigate} = usePlantNavigation()
  const {anchorEl, openPopover, closePopover, isOpen, id} = usePopoverAnchor(
    'table-header-cell-popover'
  )
  const [popoverField, setPopoverField] = useState<CsTableSampleKey>('datetime')
  const classes = useStyles()
  const tableClasses = useTableStyles()
  const {sampleId: selectedSampleId} = useCsParamsWithDefaults()
  const setSampleId = useSetQueryParam<number | undefined>('sampleId')
  const {
    tableData: {strengthLevel},
    pageNumber,
    rowCount,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    pageData
  } = usePaginatedCsTableData(data, materialId)

  const {sortCriterion, setSortCriterion: onSort} = useTableSort<CsTableSampleKey>('csTableSort')

  const {filters, setFilters: onFilter} = useTableFilters<CsTableSampleKey>('csTableFilters')

  const onHeaderClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLTableHeaderCellElement>
        | React.MouseEvent<HTMLTableDataCellElement>,
      field: CsTableSampleKey
    ) => {
      setPopoverField(field)
      openPopover(event)
    },
    [openPopover]
  )

  return (
    <TableContainer className={classes.container} data-test-id="cement-strength-samples-table">
      <Table size="small">
        <TableHead className={tableClasses.tableHeader}>
          <TableRow>
            {COLUMN_KEYS.slice(0, 4).map((key) => (
              <TableHeaderCell
                key={key}
                data-test-id={`cement-strength-samples-table-header-row-${key}`}
                field={key}
                sortCriterion={sortCriterion}
                filters={filters}
                onHeaderClick={onHeaderClick}
                rowSpan={2}
              >
                {t(`cementStrengthSamplesTable.header.${key}`)}
              </TableHeaderCell>
            ))}
            <TableCell
              className={classNames(
                tableClasses.tableHeaderCell,
                tableClasses.tableRowCell,
                tableClasses.cell,
                tableClasses.cellBorder
              )}
              colSpan={2}
            >
              {t('cementStrengthSamplesTable.header.cementStrength', {
                count: getStrengthLevelInDays(strengthLevel)
              })}
            </TableCell>
            {COLUMN_KEYS.slice(-4).map((key) => (
              <TableHeaderCell
                key={key}
                field={key}
                sortCriterion={sortCriterion}
                filters={filters}
                onHeaderClick={onHeaderClick}
                rowSpan={2}
                align="right"
              >
                {t(`cementStrengthSamplesTable.header.${key}`)}
              </TableHeaderCell>
            ))}
          </TableRow>
          <TableRow>
            <TableHeaderCell
              field={'strength'}
              sortCriterion={sortCriterion}
              filters={filters}
              onHeaderClick={onHeaderClick}
              align="right"
            >
              {t('cementStrengthSamplesTable.header.strength')}
            </TableHeaderCell>
            <TableHeaderCell
              field={'predictedStrength'}
              sortCriterion={sortCriterion}
              filters={filters}
              onHeaderClick={onHeaderClick}
              alwaysCellBorder
              align="right"
            >
              {t('cementStrengthSamplesTable.header.predictedStrength')}
            </TableHeaderCell>
          </TableRow>
          <TableHeaderPopover
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            width={POPOVER_WIDTH}
            onClose={closePopover}
          >
            <PopoverContent
              width={POPOVER_WIDTH}
              field={popoverField}
              onFilter={onFilter}
              onSort={onSort}
              onClose={closePopover}
              sortOrder={sortedBy(sortCriterion, popoverField)}
              sortButtonLabel={t('popoverContent.sortBy', {
                field: t(`cementStrengthSamplesTable.popoverHeader.${popoverField}`)
              })}
              filter={getFilterExpression(filters, popoverField)}
              filterPlaceholder={t('popoverContent.filterBy', {
                field: t(`cementStrengthSamplesTable.popoverHeader.${popoverField}`)
              })}
            />
          </TableHeaderPopover>
        </TableHead>
        <TableBody>
          {pageData.map((sample) => (
            <TableRow
              key={sample.id}
              data-test-id={`cement-strength-samples-table-body-row-${sample.id}`}
              className={classNames(classes.bodyRow, {
                [classes.selectedRow]: sample.id === selectedSampleId
              })}
              onClick={() => {
                setSampleId(sample.id)
                if (isNumber(sample.materialId)) {
                  navigate({
                    plantPage: 'cementStrengthSample',
                    pathParams: {
                      materialId: sample.materialId,
                      sampleId: sample.id
                    },
                    queryParams: {
                      sampleId: sample.id
                    }
                  })
                } else {
                  logger.error('Cannot link a sample that has no material ID.')
                }
              }}
            >
              <TableBodyCell>{getMapperFn('datetime', t, timeZone)(sample.datetime)}</TableBodyCell>
              <TableBodyCell>{sample.id}</TableBodyCell>
              <TableBodyCell>{sample.location}</TableBodyCell>
              <TableBodyCell>{sample.materialName}</TableBodyCell>
              <TableBodyCell
                align="right"
                className={classNames({
                  [classes.numberOutsideRange]: isValueOutOfMaterialRange(
                    materials,
                    sample.materialId,
                    strengthLevel,
                    sample.strength
                  )
                })}
              >
                {getMapperFn('strength', t, timeZone)(sample.strength)}
              </TableBodyCell>
              <TableBodyCell
                align="right"
                className={classNames({
                  [classes.numberOutsideRange]: isValueOutOfMaterialRange(
                    materials,
                    sample.materialId,
                    strengthLevel,
                    sample.predictedStrength
                  )
                })}
              >
                {getMapperFn('predictedStrength', t, timeZone)(sample.predictedStrength)}
              </TableBodyCell>
              <TableBodyCell align="right">
                {getMapperFn('blaine', t, timeZone)(sample.blaine)}
              </TableBodyCell>
              <TableBodyCell align="right">
                {getMapperFn('sO3', t, timeZone)(sample.sO3)}
              </TableBodyCell>
              <TableBodyCell align="right">
                {getMapperFn('alite', t, timeZone)(sample.alite)}
              </TableBodyCell>
              <TableBodyCell align="right">
                {getMapperFn('belite', t, timeZone)(sample.belite)}
              </TableBodyCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{spacer: tableClasses.paginationSpacer}}
              count={rowCount}
              page={pageNumber}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              labelDisplayedRows={emptyLabelDisplayedRows}
              labelRowsPerPage={t('pagination.labelRowsPerPage')}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export const CementStrengthSamplesTable: React.FC<CementStrengthSamplesTableProps> = ({
  materialId
}) => {
  const {data, isLoading} = useAllCementStrengthSamples()
  const {t} = useTranslation()

  if (data) {
    return <SamplesTable data={data} materialId={materialId} />
  }

  return (
    <DefaultBox width="100%" height={560}>
      {isLoading ? (
        <Skeleton variant="rect" height="100%" />
      ) : (
        <ErrorMessage height="65%">{t('errors.noDataAvailable')}</ErrorMessage>
      )}
    </DefaultBox>
  )
}
