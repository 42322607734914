import {useTheme} from '@material-ui/core'
import React from 'react'

import {COLOR_ACCURACY_DIAGONAL, COLOR_TARGET} from '../../common/charts/chartConfigurations'

interface AccuracyGraphBackgroundProps {
  height: number
  width: number
  targetPos: {x: number; y: number}
  rangeOffset: number
}

export const AccuracyTargetLines: React.FC<AccuracyGraphBackgroundProps> = ({
  height,
  width,
  targetPos,
  rangeOffset
}) => {
  const theme = useTheme()
  return (
    <>
      <circle cx={targetPos.x} cy={targetPos.y} r={16} fill={COLOR_TARGET} />
      <line
        x1={0}
        x2={width}
        y1={height + rangeOffset}
        y2={rangeOffset}
        strokeWidth={1}
        stroke={theme.palette.error.main}
        fill="none"
      />
      <line
        x1={0}
        x2={width}
        y1={height - rangeOffset}
        y2={-rangeOffset}
        strokeWidth={1}
        stroke={theme.palette.error.main}
        fill="none"
      />
      <line
        x1={0}
        x2={width}
        y1={height}
        y2={0}
        strokeWidth={2}
        stroke={COLOR_ACCURACY_DIAGONAL}
        fill="none"
      />
    </>
  )
}
