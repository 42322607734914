import {PlantId} from '@hconnect/common/hproduce/types'
import {makeStyles, MenuItem, FormControl, InputLabel, Select} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

const usePlantSelectorStyles = makeStyles((theme) => ({
  plantSelectBox: {
    padding: theme.spacing(2)
  },
  dropdown: {
    width: '100%',
    padding: theme.spacing(1),
    background: '#FBFBFC',
    border: '1.5px solid rgba(31, 73, 94, 0.15)',
    boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
    borderRadius: '4px',
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        borderBottom: 'none'
      }
    }
  },
  label: {
    color: '#5E7A8C',
    top: '5px',
    left: theme.spacing(1),
    '&.MuiFormLabel-root.MuiFormLabel-focused': {
      color: '#5E7A8C'
    }
  },
  selectBox: {
    padding: 0,
    fontWeight: 500
  }
}))

export type Plant = {
  plantId: PlantId
  plantName: string
}

type PlantSelectProps = {
  activePlantId: PlantId
  'data-test-id': string
  plants: Plant[]
  onChange: (next: PlantId) => void
}

export const PlantSelect: React.FC<PlantSelectProps> = ({
  activePlantId,
  'data-test-id': dataTestId,
  plants,
  onChange
}) => {
  const {t} = useTranslation()
  const classes = usePlantSelectorStyles()

  return (
    <div className={classes.plantSelectBox} data-test-id={dataTestId}>
      <FormControl variant="standard" className={classes.dropdown}>
        <InputLabel className={classes.label}>{t('plantSelection.selectPlant')}</InputLabel>
        <Select
          classes={{
            root: classes.selectBox
          }}
          onChange={(event) => {
            onChange(event.target.value as PlantId)
          }}
          value={activePlantId}
          disableUnderline
        >
          {plants?.map((detail) => (
            <MenuItem key={detail.plantId} value={detail.plantId}>
              {detail.plantName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
