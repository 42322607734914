import React from 'react'

import {ErrorMessage} from '../components/ErrorMessage'
import {Loader} from '../components/Loader'

interface AsyncContainerProps {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  errorMessage?: React.ReactNode
}

export const AsyncContainer: React.FC<AsyncContainerProps> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  children
}) => {
  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return typeof errorMessage === 'string' ? (
      <ErrorMessage>{errorMessage ?? ''}</ErrorMessage>
    ) : (
      <>{errorMessage}</>
    )
  }

  if (isSuccess) {
    return <>{children}</>
  }

  return null
}
