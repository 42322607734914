import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {getSensorMetaData} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {SensorMetaData} from '../declarations'

export const useSensorMetaData = (): SensorMetaData[] => {
  const {data} = useQuery(QueryKeyName.GetSensorMetaData, () => getSensorMetaData())
  return useMemo(() => data ?? [], [data])
}
