import * as d3 from 'd3'
import React, {useLayoutEffect, useRef} from 'react'

import {AxisFormatFn, LinearScaleFn} from './types'

type AxisPosition = 'bottom' | 'left' | 'top' | 'right'

interface AxisProps {
  posX?: number
  posY?: number
  scale: LinearScaleFn
  color?: string
  textColor?: string
  tickSize?: number
  tickSizeInnerValue?: number
  tickSizeOuterValue?: number
  padding?: number
  format?: AxisFormatFn
  extraFormat?: AxisFormatFn
  position: AxisPosition
  tickValues?: readonly number[]
  'data-test-id'?: string
}

const axisFunction = (position: AxisPosition) => {
  return {bottom: d3.axisBottom, left: d3.axisLeft, top: d3.axisTop, right: d3.axisRight}[position]
}

export const Axis: React.FC<AxisProps> = ({
  position,
  scale,
  posX = 0,
  posY = 0,
  color = 'black',
  textColor = color,
  tickSize = 6,
  tickSizeInnerValue = tickSize,
  tickSizeOuterValue = tickSize,
  padding = 3,
  format,
  extraFormat,
  tickValues,
  'data-test-id': dataTestId
}) => {
  const ref = useRef(null)

  useLayoutEffect(() => {
    const root = d3
      .select(ref.current)
      .attr('transform', `translate(${posX}, ${posY})`)
      .attr('color', color)

    root.selectAll('*').remove()

    root
      .call(
        axisFunction(position)(scale)
          .tickFormat(format)
          .tickSize(tickSize)
          .tickPadding(padding)
          .tickValues(tickValues)
          .tickSizeInner(tickSizeInnerValue)
          .tickSizeOuter(tickSizeOuterValue)
      )
      .call((g) => g.selectAll('.tick text').attr('color', textColor).attr('font-size', '12px'))

    if (extraFormat) {
      root.call((g) => g.selectAll('.tick text').clone().attr('dy', '1.8em').text(extraFormat))
    }
  })

  return <g data-test-id={dataTestId} ref={ref} />
}
