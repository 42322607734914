import {ButtonProps, makeStyles, Popover, Theme} from '@material-ui/core'
import classnames from 'classnames'
import React, {useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Calendar} from './Calendar'
import {DatePickerButton} from './DatePickerButton'
import {DatePickerTextField} from './DatePickerTextField'
import {PickerForm} from './PickerForm'
import {QuickSelection} from './QuickSelection'
import {TabBar} from './TabBar'
import {Dates, QuickSelectionItem} from './types'

const useStyle = makeStyles((theme: Theme) => ({
  calendarContainer: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 356
  },
  datePickerContainer: {
    padding: 0,
    display: 'inline-block',
    position: 'relative',
    zIndex: 999
  },
  popover: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.contrastText
  }
}))

type DateRange = [Date | null, Date | null]

interface Props {
  autoFocus?: boolean
  calendarWrapper?: (innerPicker: React.ReactNode) => React.ReactNode
  className?: string
  date?: Date | null
  dateRange?: DateRange
  dateExceptions?: Date[]
  focusEndDate?: boolean
  handleDateChange?: (arg: Date) => void
  handleRangeChange?: (args: Dates, item?: QuickSelectionItem) => void
  isOutsideRange?: (arg: Date) => boolean
  label?: React.ReactNode
  noQuickSelection?: boolean
  noAutoClose?: boolean
  showDateChange?: boolean
  showDatePickerButton?: boolean
  showDateChangeLabel?: string
  textFormatter?: (d: Date) => string
  textClasses?: string
  withForm?: boolean
  selectedQuickSelectItem?: QuickSelectionItem
  quickSelectionItems?: QuickSelectionItem[]
  quickSelectOptionDateTemplate?: (option: QuickSelectionItem) => string
  datePickerButtonWithSimpleLayout?: boolean
  datePickerButtonProps?: ButtonProps
}

const formatDate = (date: Date, language: string, textFormatter?: (d: Date) => string): string =>
  textFormatter ? textFormatter(date) : date?.toLocaleDateString(language)

const SimpleDatePicker: React.FC<Props> = ({
  autoFocus,
  className,
  calendarWrapper,
  date,
  dateExceptions,
  dateRange = [],
  focusEndDate,
  handleDateChange,
  handleRangeChange,
  isOutsideRange,
  label,
  noQuickSelection = true,
  noAutoClose,
  showDateChange,
  showDatePickerButton,
  showDateChangeLabel,
  textClasses,
  textFormatter,
  withForm,
  selectedQuickSelectItem,
  quickSelectionItems,
  quickSelectOptionDateTemplate,
  datePickerButtonWithSimpleLayout,
  datePickerButtonProps
}) => {
  const classes = useStyle()
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState(date || startDate || null)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showCalendar, setShowCalendar] = useState(!selectedQuickSelectItem)
  const noDateText = t('datePicker.chooseDate')
  const startText = !selectedStartDate
    ? noDateText
    : formatDate(selectedStartDate, language, textFormatter)

  const endText = !selectedEndDate
    ? noDateText
    : formatDate(selectedEndDate, language, textFormatter)

  const selectionText = isSingleDate ? startText : `${startText} - ${endText}`

  const close = useCallback(() => setOpen(false), [setOpen])

  const calendarWrapperFn = calendarWrapper || ((node: React.ReactNode) => node)

  const handleDateRangeChange = (dates: Dates, item?: QuickSelectionItem) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      handleRangeChange?.({startDate: dates.startDate, endDate: dates.endDate}, item)
      !noAutoClose && close()
    }
  }

  const handleSingleDateChange = (date: Date | null) => {
    if (!date) return
    setSelectedStartDate(date)
    setSelectedEndDate(date)

    handleDateChange?.(date)
    !noAutoClose && close()
  }

  const calendarProps = {
    autoFocus,
    focusEndDate,
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isSingleDate
      ? {onDateChange: handleSingleDateChange, dateExceptions}
      : {endDate: selectedEndDate, onRangeChange: handleDateRangeChange})
  }

  return (
    <div className={classnames(classes.datePickerContainer, className)}>
      {showDatePickerButton ? (
        <DatePickerButton
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          label={selectionText}
          isDateChange={showDateChange}
          isDateChangeLabel={showDateChangeLabel}
          simpleLayout={datePickerButtonWithSimpleLayout}
          customButtonProps={datePickerButtonProps}
        />
      ) : (
        <DatePickerTextField
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          text={selectionText}
          label={label}
          textClasses={textClasses}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{paper: classes.popover}}
        PaperProps={{
          id: 'simple-date-picker-popover-paper'
        }}
        data-test-id="simple-date-picker-popover"
      >
        <div className={classes.calendarContainer}>
          {!noQuickSelection && (
            <TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
          )}

          {showCalendar ? (
            calendarWrapperFn(
              <>
                <Calendar {...calendarProps} />
                {withForm && (
                  <PickerForm
                    applyDateRange={handleDateRangeChange}
                    dateExceptions={dateExceptions}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={isSingleDate}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                  />
                )}
              </>
            )
          ) : (
            <QuickSelection
              handleItemClick={handleDateRangeChange}
              selectedItem={selectedQuickSelectItem}
              dateCustomTemplate={quickSelectOptionDateTemplate}
              items={quickSelectionItems}
            />
          )}
        </div>
      </Popover>
    </div>
  )
}

export {SimpleDatePicker}
export type {Props as SimpleDatePickerProps}
