import {Plant} from '../declarations'
import {usePlantsContext} from '../providers/PlantsProvider'

import {usePlantName} from './usePlantName'

export const usePlant: () => Plant | undefined = () => {
  const plantName = usePlantName()
  const plantContext = usePlantsContext()
  return plantContext.plants.find((p) => p.name === plantName)
}
