import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme
} from '@material-ui/core'
import {Check, Delete} from '@material-ui/icons'
import type {Location} from 'history'
import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {v4 as uuid} from 'uuid'

import {addHandle, enableHandle, isHandleEnabled, removeHandle} from '../common/promptUtils'
import {ColorButton} from '../components/ColorButton'
import type {HandleBooleanMap} from '../declarations'
import {useDialogStyles} from '../hooks/useDialogStyles'

export interface PromptOptions {
  title?: string
  message?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
}

type ProvidersEnablePromptFunc = (handle: string, enable: boolean, options?: PromptOptions) => void
export type EnablePromptFunc = (enable: boolean, options?: PromptOptions) => void

export interface PromptContext {
  enablePrompt: ProvidersEnablePromptFunc
  addHandle: () => string
  removeHandle: (handle: string) => void
}

const PromptProviderContext = createContext<PromptContext | undefined>(undefined)

export const usePromptContext = () => {
  const context = useContext(PromptProviderContext)
  if (!context) {
    throw new Error('usePromptContext used outside of PromptProvider')
  }
  return context
}

interface CustomPromptProps {
  message: string
  show?: boolean
  title?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
}

const CustomPrompt: React.FC<CustomPromptProps> = ({
  message,
  show = false,
  title = '',
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  onConfirm,
  onCancel
}) => {
  const theme = useTheme()
  const dialogClasses = useDialogStyles()

  return (
    <Dialog open={show} data-test-id="custom-prompt-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={dialogClasses.contentBlock}>{message}</DialogContent>
      <DialogActions className={dialogClasses.actionBlock}>
        <ColorButton
          startIcon={<Delete />}
          color={theme.palette.error.main}
          onClick={onConfirm}
          data-test-id="custom-prompt-confirm-button"
        >
          {confirmButtonLabel}
        </ColorButton>
        <Button
          startIcon={<Check />}
          color="primary"
          onClick={onCancel}
          data-test-id="custom-prompt-cancel-button"
        >
          {cancelButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const PromptProvider: React.FC = ({children}) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [location, setLocation] = useState<Location>()
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>()
  const [confirmButtonLabel, setConfirmButtonLabel] = useState<string>()
  const [cancelButtonLabel, setCancelButtonLabel] = useState<string>()
  const unblockRef = useRef<() => void>()
  const [handleMap, setHandleMap] = useState<HandleBooleanMap>({})

  useEffect(() => {
    if (isHandleEnabled(handleMap)) {
      unblockRef.current?.()
      unblockRef.current = history.block((location) => {
        setLocation(location)
        setShowModal(true)
        return false
      })
    } else {
      // unblock navigation
      unblockRef.current?.()
    }
  }, [history, handleMap])

  const onCancel = () => {
    setShowModal(false)
    setLocation(undefined)
  }

  const onConfirm = () => {
    setShowModal(false)
    if (location) {
      unblockRef.current?.()
      history.push(location)
    }
  }

  const value = useMemo(
    () => ({
      enablePrompt: (handle: string, enable: boolean, options?: PromptOptions) => {
        setHandleMap(enableHandle(handle, enable))
        setMessage(options?.message ?? '')
        setTitle(options?.title)
        setConfirmButtonLabel(options?.confirmButtonLabel)
        setCancelButtonLabel(options?.cancelButtonLabel)
      },
      addHandle: () => {
        const handle = uuid()
        setHandleMap(addHandle(handle))
        return handle
      },
      removeHandle: (handle: string) => {
        setHandleMap(removeHandle(handle))
      }
    }),
    []
  )

  return (
    <PromptProviderContext.Provider value={value}>
      {children}
      <CustomPrompt
        show={showModal}
        message={message}
        title={title}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </PromptProviderContext.Provider>
  )
}
