import {mean} from 'lodash'

export const calcInterval = (
  positions: number[],
  index: number,
  maxPos: number
): [number, number] => {
  if (positions.length < 2) {
    return [0, maxPos]
  }

  const pos = positions[index]
  return [
    index === 0 ? 0 : mean([positions[index - 1], pos]),
    index === positions.length - 1 ? maxPos : mean([pos, positions[index + 1]])
  ]
}
