/* eslint-disable @typescript-eslint/no-non-null-assertion */
import qs from 'query-string'

import {BackendSelector, LoginStorage, SignInRequest, SignOutRequest} from '../types'

import {deriveChallenge, generateRandomString} from './crypto.utils'

export class EnvAuthRequestProvider {
  constructor(backendSelector: BackendSelector) {
    this.backendSelector = backendSelector
  }

  private backendSelector: BackendSelector

  /**
   * Returns the API scope the application should ask for.
   */
  private getScope() {
    return process.env.REACT_APP_API_SCOPE!
  }

  /**
   * Returns base url of the authenticator based on backendSelector
   */
  private getAuthenticatorBaseUrl() {
    return this.backendSelector.getSelectedBackend().AUTH_URL
  }

  /**
   * Returns client ID of the current product
   */
  getClientId() {
    return process.env.REACT_APP_CLIENT_ID!
  }

  /**
   * Upon successful login Identity server will redirect to this url
   */
  getAuthSuccessRedirectUrl() {
    return `${window.location.origin}/auth`
  }

  /**
   * Returns the URL of the Authenticator as well as the flow that must be stored in loginStorage
   */
  async createSignInRequest(
    extraAuthenticatorQueryParams: {[key: string]: string} = {}
  ): Promise<SignInRequest> {
    const clientId = this.getClientId()
    const authenticatorUrl = new URL(this.getAuthenticatorBaseUrl())

    const state = generateRandomString(21)

    const codeVerifier = generateRandomString(96)
    const codeChallenge = await deriveChallenge(codeVerifier).catch((error) => {
      console.log('Browser does not support crypto, not using PKCE', error)
      return undefined
    })

    const queryParams = {
      redirect_uri: `${window.location.origin}/auth`,
      client_id: clientId,
      response_type: 'code',
      scope: this.getScope(),
      state,
      response_mode: 'query',
      ...extraAuthenticatorQueryParams,
      ...(codeChallenge
        ? {
            code_challenge: codeChallenge,
            code_challenge_method: 'S256'
          }
        : {})
    }

    const stringifiedQueryParams =
      qs.stringify(queryParams) + authenticatorUrl.search.replace('?', '&')

    const isSignInOrSignOutUrl = window.location.href.indexOf(`${window.origin}/auth`) === 0
    const href = !isSignInOrSignOutUrl ? window.location.href : window.origin

    return {
      url: `${authenticatorUrl.origin}/api/identity/connect/authorize?${stringifiedQueryParams}`,
      flow: {
        state,
        href,
        codeVerifier
      }
    }
  }

  /**
   * Returns the URL of the Authenticator as well as the flow that must be stored in loginStorage
   */
  createRegistrationRequest(
    registrationType: 'create-account' | 'request-access',
    countryId = ''
  ): Promise<SignInRequest> {
    const extraAuthenticatorQueryParams = {
      registrationType,
      countryId
    }
    return this.createSignInRequest(extraAuthenticatorQueryParams)
  }

  async createSignOutRequest(storage: LoginStorage): Promise<SignOutRequest> {
    const authenticatorUrl = new URL(this.getAuthenticatorBaseUrl())
    const token = await storage.getToken()
    const queryParams = {
      id_token_hint: token.idToken,
      post_logout_redirect_uri: `${window.location.origin}/auth/callback/logout`
    }

    const stringifiedQueryParams =
      qs.stringify(queryParams) + authenticatorUrl.search.replace('?', '&')

    return {
      url: `${authenticatorUrl.origin}/api/identity/connect/endsession?${stringifiedQueryParams}`
    }
  }
}
