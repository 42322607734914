import * as d3 from 'd3'
import React from 'react'

import type {DateTimeRecord, LinearScaleFn} from './types'

interface SimpleCurveProps {
  color: string
  records: DateTimeRecord[]
  x: LinearScaleFn
  y: LinearScaleFn
  strokeDasharray?: string
  'data-test-id'?: string
}

export const SimpleCurve: React.FC<SimpleCurveProps> = ({
  color,
  records,
  x,
  y,
  strokeDasharray,
  'data-test-id': dataTestId
}) => {
  const line = d3
    .line()
    .x((d: DateTimeRecord) => x(d.datetime))
    .y((d: DateTimeRecord) => y(d.value))

  return (
    <path
      data-test-id={dataTestId}
      d={line(records)}
      fill="none"
      stroke={color}
      strokeMiterlimit={1}
      strokeWidth={2}
      strokeDasharray={strokeDasharray}
    />
  )
}
