import {Typography} from '@material-ui/core'
import React from 'react'

import {DefaultBox} from './DefaultBox'

interface DetailsBoxProps {
  caption?: string
  'data-test-id'?: string
  className?: string
}

export const DetailsBox: React.FC<DetailsBoxProps> = ({
  caption,
  children,
  className,
  'data-test-id': dataTestId
}) => (
  <DefaultBox data-test-id={dataTestId} className={className}>
    {caption && (
      <Typography variant="h6" gutterBottom>
        {caption}
      </Typography>
    )}
    {children}
  </DefaultBox>
)
