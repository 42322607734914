import {Theme} from '@material-ui/core'

export const MAX_GRAPH_DATA_POINTS = 400
export const MAX_GRAPH_DATA_POINTS_OVERVIEW = 200

export const CEMENT_STRENGTH_DOMAIN_MARGIN = 4
export const CEMENT_STRENGTH_OVERVIEW_STEP_SIZE = 2

export const SUGGESTED_TRENDS_TICK_COUNT = 10

export const CEMENT_STRENGTH_ACCURACY_OFFSET = 3

export const FL_FETCH_INTERVAL_MS = 60 * 1000

export const PREDICTION_DASHARRAY = '8 4'
export const PREDICTION_DASHARRAY_SMALL = '4 2'
export const PREDICTION_OPACITY = 0.5

export const COLOR_FL_ACTUAL_VALUES = '#10007a'
export const COLOR_CS_SELECTED_STRENGTH = '#10007a'
export const COLOR_TARGET = '#7fd493'
export const COLOR_TREND_AXIS = '#002e4d'
export const COLOR_TREND_BORDER = '#d4dbe0'
export const COLOR_OVERVIEW_BORDER = '#5e7a8c'
export const COLOR_ACCURACY_DIAGONAL = '#5e7a8c'
export const COLOR_GRID_LINES = '#f5f6f8'

// We fetch more than requested time range so the curve is connected to the points preceding and succeeding the timeline.
// Therefore, we use a factor, that extends the given time range.
// A factor of 0.5 means the time margin is 50% of the initial time range,
// so the resulting duration between start and end would be 1 + 0.5 (start) + 0.5 (end) = 2 times as long
export const DEFAULT_TIME_MARGIN_FACTOR = 0.25

export const hoveredColor = (theme: Theme) => theme.palette.grey[500]
