import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFlParamsWithDefaults} from '../../hooks/useFlParamsWithDefaults'
import {usePlantKilnIds} from '../../hooks/usePlantKilnIds'
import {useSetQueryParam} from '../../hooks/useSetQueryParam'

import {StringValueSelect} from './StringValueSelect'

export const KilnSelect: React.FC = () => {
  const {selectedKilnId} = useFlParamsWithDefaults()
  const selectableKilns = usePlantKilnIds()
  const onKilnIdChanged = useSetQueryParam('selectedKilnId')
  const {t} = useTranslation()

  return (
    <StringValueSelect
      onSelected={onKilnIdChanged}
      value={selectedKilnId ?? ''}
      values={selectableKilns}
      fieldId="kiln-select"
      data-test-id="kiln-select"
      label={t('kilnSelect.label')}
      valueLabel={(kilnId: string) => t('kilnSelect.kiln', {id: kilnId})}
    />
  )
}
