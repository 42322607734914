import {isNumber} from 'lodash'
import {useQuery, useQueryClient} from 'react-query'

import {getSampleDetails} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {CementStrengthSampleDetails} from '../declarations'

import {useAggregatedCemStrengthData} from './useAggregatedCemStrengthData'
import {useMaterialId} from './useMaterialId'
import {useNextSample} from './useNextSample'
import {usePlantId} from './usePlantId'
import {usePreviousSample} from './usePreviousSample'

const queryFunction =
  (plantId: string, materialId?: number, sampleId?: number) =>
  (): Promise<CementStrengthSampleDetails | undefined> => {
    if (isNumber(materialId) && isNumber(sampleId)) {
      return getSampleDetails(plantId, materialId, sampleId)
    }
    return Promise.resolve(undefined)
  }

const usePrefetch = (plantId: string, materialId?: number, sampleId?: number) => {
  const {data} = useAggregatedCemStrengthData(materialId)
  const queryClient = useQueryClient()
  const prev = usePreviousSample(data?.samples ?? [], sampleId)?.id
  const next = useNextSample(data?.samples ?? [], sampleId)?.id
  void queryClient.prefetchQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, prev],
    queryFunction(plantId, materialId, prev)
  )
  void queryClient.prefetchQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, next],
    queryFunction(plantId, materialId, next)
  )
}

const useSampleDetailsSingle = (plantId: string, materialId?: number, sampleId?: number) => {
  return useQuery(
    [QueryKeyName.GetSampleDetails, plantId, materialId, sampleId],
    queryFunction(plantId, materialId, sampleId)
  )
}

export const useCementStrengthSampleDetails = (sampleId?: number) => {
  const plantId = usePlantId()
  const materialId = useMaterialId()
  const query = useSampleDetailsSingle(plantId, materialId, sampleId)

  // pre-fetch predecessor and successor
  usePrefetch(plantId, materialId, sampleId)

  return query
}
