import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'

import {updateFreelimeSettings} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {FreeLimeUpdateSettings} from '../declarations'

import {useFreeLimeConfig} from './useFreeLimeConfig'
import {usePlantConfig} from './usePlantConfig'
import {usePlantId} from './usePlantId'
import {useSnackbar} from './useSnackbar'

type MutationArgs = Partial<FreeLimeUpdateSettings>

const useFreeLimeCurrentSettings = (): FreeLimeUpdateSettings => {
  const {axisMin, axisMax, min, max, target} = useFreeLimeConfig()
  const {defaultTimeDurationFreeLime} = usePlantConfig()
  return useMemo(
    () => ({
      axisRange: {min: axisMin, max: axisMax},
      targetRange: {min, max, target},
      defaultTimeDurationFreeLime
    }),
    [axisMax, axisMin, defaultTimeDurationFreeLime, max, min, target]
  )
}

export const useFreeLimeSettingsMutation = () => {
  const plantId = usePlantId()
  const {t} = useTranslation()
  const {
    axisRange: currentAxisRange,
    defaultTimeDurationFreeLime: currentTimeDuration,
    targetRange: currentTargetRange
  } = useFreeLimeCurrentSettings()
  const {showSuccess, showError} = useSnackbar()
  const client = useQueryClient()
  return useMutation(
    ({axisRange, targetRange, defaultTimeDurationFreeLime}: MutationArgs) =>
      updateFreelimeSettings(plantId, {
        axisRange: axisRange ?? currentAxisRange,
        defaultTimeDurationFreeLime: defaultTimeDurationFreeLime ?? currentTimeDuration,
        targetRange: targetRange ?? currentTargetRange
      }),
    {
      onSuccess: () => {
        showSuccess(t('settings.settingsSaved'))
        void client.invalidateQueries(QueryKeyName.GetPlants)
      },
      onError: () => {
        showError(t('settings.settingsNotSavedError'))
      }
    }
  )
}
