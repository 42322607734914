import DateRangeIcon from '@material-ui/icons/DateRange'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {formatDateTimeParamRange} from '../../common/dateUtils'
import {DateTimeParam, TimeHorizonId, TimeRange} from '../../declarations'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'

import {ActionBarButton} from './ActionBarButton'
import {TimeRangePickerDialog} from './TimeRangePickerDialog'

interface TimeRangePickerButtonProps {
  start: DateTimeParam
  end: DateTimeParam
  selectableTimeHorizons: TimeHorizonId[]
  onTimeRangeChanged: (string: DateTimeParam, end: DateTimeParam) => void
}

export const TimeRangePickerButton: React.FC<TimeRangePickerButtonProps> = ({
  start,
  end,
  selectableTimeHorizons,
  onTimeRangeChanged
}) => {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const [open, setOpen] = useState(false)
  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(
    (selectedRange?: TimeRange<DateTimeParam>) => {
      if (selectedRange) {
        onTimeRangeChanged(selectedRange.start, selectedRange.end)
      }
      setOpen(false)
    },
    [onTimeRangeChanged]
  )

  return (
    <>
      <ActionBarButton
        onClick={handleClick}
        data-test-id="time-range-picker-button"
        startIcon={<DateRangeIcon />}
        width={320}
      >
        {formatDateTimeParamRange({start, end}, timeZone, t('dateFormat.datetime'), t)}
      </ActionBarButton>
      <TimeRangePickerDialog
        open={open}
        onClose={handleClose}
        start={start}
        end={end}
        selectableTimeHorizons={selectableTimeHorizons}
      />
    </>
  )
}
