import {useMemo} from 'react'

import {calcPagination, getTablePage} from '../common/tablePageUtils'
import {CsTableData, CsTableSample, Pagination, RawLabData} from '../declarations'

import {useCementStrengthTableData} from './useCementStrengthTableData'
import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useSetQueryParam} from './useSetQueryParam'

interface CsPaginatedTableData extends Pagination {
  tableData: CsTableData
  pageData: CsTableSample[]
}

export const usePaginatedCsTableData = (
  rawData: RawLabData[],
  materialId?: number
): CsPaginatedTableData => {
  const {csTableRowsPerPage: rowsPerPage, csTablePageNo: page} = useCsParamsWithDefaults()
  const setPage = useSetQueryParam<number>('csTablePageNo')
  const setRowsPerPage = useSetQueryParam<number>('csTableRowsPerPage')
  const tableData = useCementStrengthTableData(rawData, materialId)

  return useMemo(() => {
    const rowCount = tableData.samples.length
    const {pageNumber, onPageChange, onRowsPerPageChange, lastPageNumber} = calcPagination({
      rowCount,
      rowsPerPage,
      page,
      setPage,
      setRowsPerPage
    })

    return {
      tableData,
      pageData: getTablePage(tableData.samples, pageNumber, rowsPerPage),
      rowCount,
      rowsPerPage,
      pageNumber,
      lastPageNumber,
      onPageChange,
      onRowsPerPageChange
    }
  }, [tableData, rowsPerPage, page, setPage, setRowsPerPage])
}
