import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import {Delete, Edit} from '@material-ui/icons'
import classNames from 'classnames'
import {concat, difference, intersection, uniq} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getFilterExpression, sortedBy} from '../../common/filter'
import {addOrRemove, includesAll, replaceNonWordCharacters} from '../../common/helpers'
import {emptyLabelDisplayedRows} from '../../common/tablePageUtils'
import type {
  PaginatedTableData,
  SensorMetaData,
  SortOrder,
  TableFilterData,
  TableSort
} from '../../declarations'
import {usePopoverAnchor} from '../../hooks/usePopoverAnchor'
import {useTableStyles} from '../../hooks/useTableStyles'
import {PaginationActions} from '../table/PaginationActions'
import {PopoverContent} from '../table/PopoverContent'
import {TableBodyCell} from '../table/TableBodyCell'
import {TableHeaderCell} from '../table/TableHeaderCell'
import {TableHeaderPopover} from '../table/TableHeaderPopover'
import {TableIconButton} from '../table/TableIconButton'

import type {HasDisabled} from './types'

const POPOVER_WIDTH = 308

const useStyles = makeStyles(() => ({
  cell: {
    borderRight: 0
  }
}))

interface SensorSettingsTableProps<T extends SensorMetaData> extends HasDisabled {
  selectedItemIds?: string[]
  onIdsSelected?: (selectedIds: string[]) => void
  deleteDisabled?: boolean
  multiSelectDisabled?: boolean
  paginatedTableData: PaginatedTableData<T>
  onEditItemClicked?: (item: T) => void
  onDeleteItemClicked?: (item: T) => void
  sortCriterion?: TableSort<keyof T>
  onSort?: (field: keyof T, order: SortOrder) => void
  filters?: TableFilterData<keyof T>[]
  onFilter?: (field: keyof T, expression: string) => void
  columnKeys: (keyof T & string)[]
}

const isSelected = (selectedItems: string[], id: string): boolean => selectedItems.includes(id)

export const SensorSettingsTable = <T extends SensorMetaData>({
  selectedItemIds = [],
  onIdsSelected,
  disabled,
  deleteDisabled = disabled,
  multiSelectDisabled = disabled,
  paginatedTableData,
  onEditItemClicked,
  onDeleteItemClicked,
  sortCriterion,
  onSort,
  filters = [],
  onFilter,
  columnKeys
}: SensorSettingsTableProps<T>) => {
  const {t} = useTranslation()
  const tableClasses = useTableStyles()
  const classes = useStyles()
  const {pageData, pageNumber, rowsPerPage, rowCount, onRowsPerPageChange, onPageChange} =
    paginatedTableData
  const {anchorEl, openPopover, closePopover, isOpen, id} = usePopoverAnchor(
    'table-header-sensor-settings-popover'
  )
  const [popoverField, setPopoverField] = useState<keyof T>('uniformTag')
  const visibleIds = pageData.map(({uniformTag}) => uniformTag)
  const visibleSelectedIds = intersection(selectedItemIds, visibleIds)
  const numSelected = visibleSelectedIds.length
  const visibleRowCount = pageData.length

  const onHeaderClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLTableHeaderCellElement>
        | React.MouseEvent<HTMLTableDataCellElement>,
      field: keyof T
    ) => {
      setPopoverField(field)
      openPopover(event)
    },
    [openPopover]
  )

  const onSelect = (id: string, selected: boolean) => {
    onIdsSelected?.(addOrRemove(selectedItemIds, id, selected))
  }

  const onSelectAll = () => {
    onIdsSelected?.(
      includesAll(selectedItemIds, visibleIds)
        ? difference(selectedItemIds, visibleIds)
        : uniq(concat(selectedItemIds, visibleIds))
    )
  }

  const buttonCellClassNames = classNames(tableClasses.tableRowCell, tableClasses.cell)

  return (
    <TableContainer>
      <Table size="small">
        <TableHead className={tableClasses.tableHeader}>
          <TableRow>
            {multiSelectDisabled ? null : (
              <TableCell className={buttonCellClassNames} padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < visibleRowCount}
                  checked={visibleRowCount > 0 && numSelected === visibleRowCount}
                  onChange={onSelectAll}
                  inputProps={{'aria-label': t('sensorSettingsTable.selectAll')}}
                  data-test-id="sensor-settings-table-select-all-checkbox"
                />
              </TableCell>
            )}
            {columnKeys.map((key) => (
              <TableHeaderCell
                data-test-id={`sensor-settings-table-header-row-${key}`}
                key={key}
                field={key}
                sortCriterion={sortCriterion}
                filters={filters}
                className={classes.cell}
                onHeaderClick={onHeaderClick}
              >
                {t(`processMetaData.${key}`)}
              </TableHeaderCell>
            ))}
            {disabled ? null : <TableCell className={buttonCellClassNames} />}
          </TableRow>
          <TableHeaderPopover
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            width={POPOVER_WIDTH}
            onClose={closePopover}
          >
            <PopoverContent
              width={POPOVER_WIDTH}
              field={popoverField}
              onFilter={onFilter}
              onSort={onSort}
              onClose={closePopover}
              sortOrder={sortedBy(sortCriterion, popoverField)}
              sortButtonLabel={t('popoverContent.sortBy', {
                field: t(`processMetaData.${popoverField}`)
              })}
              filter={getFilterExpression(filters, popoverField)}
              filterPlaceholder={t('popoverContent.filterBy', {
                field: t(`processMetaData.${popoverField}`)
              })}
            />
          </TableHeaderPopover>
        </TableHead>
        <TableBody>
          {pageData.map((meta) => (
            <TableRow
              key={meta.uniformTag}
              data-test-id={`sensor-settings-table-row-${replaceNonWordCharacters(
                meta.uniformTag
              )}`}
            >
              {multiSelectDisabled ? null : (
                <TableCell className={buttonCellClassNames} padding="checkbox">
                  <Checkbox
                    onChange={(event) => onSelect(meta.uniformTag, event.target.checked)}
                    checked={isSelected(selectedItemIds, meta.uniformTag)}
                    inputProps={{
                      'aria-label': t('sensorSettingsTable.selectRow', {
                        description: meta.description
                      })
                    }}
                    data-test-id={`sensor-settings-table-select-row-${replaceNonWordCharacters(
                      meta.uniformTag
                    )}-checkbox`}
                  />
                </TableCell>
              )}
              {columnKeys.map((columnKey) => (
                <TableBodyCell
                  key={columnKey}
                  className={classes.cell}
                  data-test-id={`sensor-settings-table-cell-${replaceNonWordCharacters(
                    meta.uniformTag
                  )}-${columnKey}`}
                >
                  {meta[columnKey]}
                </TableBodyCell>
              ))}
              {disabled ? null : (
                <TableBodyCell className={buttonCellClassNames} align="right">
                  <TableIconButton
                    toolTipTitle={t('button.edit')}
                    onClick={() => onEditItemClicked?.(meta)}
                    data-test-id={`sensor-settings-table-edit-btn-${replaceNonWordCharacters(
                      meta.uniformTag
                    )}`}
                  >
                    <Edit />
                  </TableIconButton>
                  {deleteDisabled ? null : (
                    <TableIconButton
                      toolTipTitle={t('button.delete')}
                      onClick={() => onDeleteItemClicked?.(meta)}
                      data-test-id={`sensor-settings-table-delete-btn-${replaceNonWordCharacters(
                        meta.uniformTag
                      )}`}
                    >
                      <Delete />
                    </TableIconButton>
                  )}
                </TableBodyCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{spacer: tableClasses.paginationSpacer}}
              count={rowCount}
              page={pageNumber}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              labelDisplayedRows={emptyLabelDisplayedRows}
              labelRowsPerPage={t('pagination.labelRowsPerPage')}
              ActionsComponent={PaginationActions}
              SelectProps={{
                SelectDisplayProps: {
                  id: 'sensor-settings-table-rows-per-page-select'
                },
                MenuProps: {
                  MenuListProps: {
                    id: 'sensor-settings-table-rows-per-page-select-list'
                  }
                }
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
