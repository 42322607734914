import {useTheme} from '@material-ui/core'
import React from 'react'

import {COLOR_TARGET} from '../../common/charts/chartConfigurations'
import {TargetRange} from '../../declarations'

import {HorizontalLine} from './HorizontalLine'

interface TargetLinesProps {
  width: number
  targetRange: TargetRange
  strokeDasharray?: string
}

export const TargetLines: React.FC<TargetLinesProps> = ({width, targetRange, strokeDasharray}) => {
  const {min, max, target} = targetRange
  const theme = useTheme()

  return (
    <>
      <HorizontalLine
        width={width}
        yPos={target}
        stroke={COLOR_TARGET}
        strokeDasharray={strokeDasharray}
      />
      <HorizontalLine
        width={width}
        yPos={min}
        stroke={theme.palette.error.main}
        strokeDasharray={strokeDasharray}
      />
      <HorizontalLine
        width={width}
        yPos={max}
        stroke={theme.palette.error.main}
        strokeDasharray={strokeDasharray}
      />
    </>
  )
}
