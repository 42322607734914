import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  getFormattedActualValue,
  getFormattedPredictedValue,
  getStrengthLevelInDays
} from '../common/cementStrength'
import {formatFloat} from '../common/format'
import {CementStrengthSample, MaterialLevel} from '../declarations'
import {useTableStyles} from '../hooks/useTableStyles'

interface CementStrengthTableProps {
  levels: MaterialLevel[]
  sample?: CementStrengthSample
  rowCellClassName?: string
  valueCellClassName?: string
  containerClassName?: string
  headClassName?: string
}

export const CementStrengthTable: React.FC<CementStrengthTableProps> = ({
  levels,
  sample,
  rowCellClassName,
  valueCellClassName,
  containerClassName,
  headClassName
}) => {
  const {t} = useTranslation()
  const classes = useTableStyles()

  return (
    <TableContainer className={containerClassName}>
      <Table size="small">
        <TableHead className={classNames(classes.tableHeader, headClassName)}>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>
              {t('sampleDetails.strengthColumnTitle')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              {t('sampleDetails.targetColumnTitle')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              {t('sampleDetails.predictionColumnTitle')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              {t('sampleDetails.actualValueColumnTitle')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {levels.map((l) => (
            <TableRow key={l.name} className={classes.tableRow}>
              <TableCell className={classNames(classes.tableRowCell, rowCellClassName)}>
                {t('strengthLevel.strength', {count: getStrengthLevelInDays(l.name)})}
              </TableCell>
              <TableCell
                data-test-id={`sample-details-${l.name}-target`}
                className={classNames(classes.tableRowCell, rowCellClassName, valueCellClassName)}
                align="right"
              >
                {formatFloat(l.target)}
              </TableCell>
              <TableCell
                data-test-id={`sample-details-${l.name}-prediction`}
                className={classNames(classes.tableRowCell, rowCellClassName, valueCellClassName)}
                align="right"
              >
                {getFormattedPredictedValue(sample, l.name, t('common.na'))}
              </TableCell>
              <TableCell
                data-test-id={`sample-details-${l.name}-actual-value`}
                className={classNames(classes.tableRowCell, rowCellClassName, valueCellClassName)}
                align="right"
              >
                {getFormattedActualValue(sample, l.name, t('common.na'))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
