import React from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'

import {useAuthContext} from './AuthProvider'
import {isUserAdmin} from './common/backend'
import {ADMIN_URL, FRONT_PAGE_URL, getPageUrlPattern, PLANTS_URL} from './common/navigation/router'
import {HelmetTitle} from './components/helmet/HelmetTitle'
import {MainLoader} from './components/MainLoader'
import {DefaultShell} from './container/DefaultShell'
import {useUser} from './hooks/useUser'
import {Login} from './Login'
import {AdminPage} from './pages/AdminPage'
import {CementStrengthPage} from './pages/CementStrengthPage'
import {CementStrengthSamplePage} from './pages/CementStrengthSamplePage'
import {CementStrengthTrendsPage} from './pages/CementStrengthTrendsPage'
import {ErrorPage} from './pages/ErrorPage'
import {FreeLimePage} from './pages/FreeLimePage'
import {PlantHomePage} from './pages/PlantHomePage'
import {PlantsPage} from './pages/PlantsPage'
import {SettingsPage} from './pages/SettingsPage'
import {UserManualPage} from './pages/UserManualPage'
import {ColorProvider} from './providers/ColorProvider'
import {MaterialProvider} from './providers/MaterialProvider'
import {PlantsProvider, usePlantsContext} from './providers/PlantsProvider'

const ErrorRoute: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Route>
      <HelmetTitle title={t('errors.error')} />
      <ErrorPage />
    </Route>
  )
}

const LoggedInMain: React.FC = () => {
  const {t} = useTranslation()
  const {isLoading} = usePlantsContext()
  const user = useUser()
  const isAdmin = isUserAdmin(user)

  if (isLoading) {
    return <MainLoader />
  }

  return (
    <DefaultShell>
      <Switch>
        <Route path={getPageUrlPattern('cementStrength')}>
          <HelmetTitle title={t('navigation.cementStrength')} />
          <Switch>
            <Route path={getPageUrlPattern('cementStrengthSample')} exact>
              <CementStrengthSamplePage />
            </Route>
            <Route path={getPageUrlPattern('cementStrengthTrends')} exact>
              <CementStrengthTrendsPage />
            </Route>
            <Route path={getPageUrlPattern('cementStrength')} exact>
              <CementStrengthPage />
            </Route>
            <ErrorRoute />
          </Switch>
        </Route>

        <Route path={getPageUrlPattern('freeLime')} exact>
          <HelmetTitle title={t('navigation.freeLime')} />
          <FreeLimePage />
        </Route>
        <Route path={getPageUrlPattern('userManual')} exact>
          <HelmetTitle title={t('navigation.userManual')} />
          <UserManualPage />
        </Route>
        <Route path={getPageUrlPattern('settings')} exact>
          <HelmetTitle title={t('navigation.settings')} />
          <SettingsPage />
        </Route>
        <Route path={getPageUrlPattern('plantsHome')} exact>
          <HelmetTitle />
          <PlantHomePage />
        </Route>
        {isAdmin ? (
          <Route path={ADMIN_URL} exact>
            <HelmetTitle title={t('navigation.admin')} />
            <AdminPage />
          </Route>
        ) : null}
        <Route path={PLANTS_URL} exact>
          <PlantsPage />
        </Route>
        <Redirect from={FRONT_PAGE_URL} to={PLANTS_URL} exact />
        <ErrorRoute />
      </Switch>
    </DefaultShell>
  )
}

export const Main: React.FC = () => {
  const {isLoggedIn, isLoading} = useAuthContext()

  if (isLoading) {
    return <MainLoader />
  }

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <PlantsProvider>
      <ColorProvider>
        <MaterialProvider>
          <LoggedInMain />
        </MaterialProvider>
      </ColorProvider>
    </PlantsProvider>
  )
}
