import {useQuery} from 'react-query'

import {getFreeLimeData} from '../common/backend'
import {
  DEFAULT_TIME_MARGIN_FACTOR,
  FL_FETCH_INTERVAL_MS
} from '../common/charts/chartConfigurations'
import {QueryKeyName} from '../common/constants'
import {isRelativeDateTimeParam} from '../common/dateUtils'
import {padTimeRange, timeRangeFromStartEnd} from '../common/timeRange'

import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'
import {usePlantId} from './usePlantId'
import {usePlantTimeZone} from './usePlantTimeZone'

export const useFreeLimeData = () => {
  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const {flStart, flEnd} = useFlParamsWithDefaults()
  return useQuery(
    [QueryKeyName.GetFreeLime, plantId, timeZone, flStart, flEnd],
    () =>
      getFreeLimeData(
        plantId,
        padTimeRange(timeRangeFromStartEnd(flStart, flEnd), DEFAULT_TIME_MARGIN_FACTOR)
      ),
    {
      refetchInterval: isRelativeDateTimeParam(flEnd) && FL_FETCH_INTERVAL_MS,
      staleTime: 0
    }
  )
}
