import {makeStyles, StyleRulesCallback, Theme} from '@material-ui/core'
import React from 'react'

import {inNumberRange} from '../../common/math/numberRange'
import {VerticalMarkerPoint} from '../../declarations'

import {CircleMarker} from './CircleMarker'

export interface VerticalMarkerProps {
  height: number
  posX: number
  color: string
  opacity?: string
  label?: string
  'data-test-id'?: string
  points?: VerticalMarkerPoint[]
}

const labelPadding = 48

const styleRulesCb: StyleRulesCallback<Theme, VerticalMarkerProps, string> = (theme) => ({
  latestLabel: (props) => ({
    ...theme.typography.caption,
    fill: props.color,
    fontWeight: theme.typography.fontWeightBold
  })
})

const useStyles = makeStyles(styleRulesCb)

export const VerticalMarker: React.FC<VerticalMarkerProps> = (props) => {
  const {height, posX, color, label, 'data-test-id': dataTestId, points = []} = props
  const classes = useStyles(props)

  return (
    <g data-test-id={dataTestId}>
      <line x1={posX} x2={posX} y1={0} y2={height} strokeWidth={2} stroke={color} fill="none" />
      {label && (
        <text
          className={classes.latestLabel}
          textAnchor="middle"
          transform={`translate(${posX}, ${height + labelPadding})`}
        >
          {label}
        </text>
      )}
      <circle r={6} cx={posX} cy={height} fill={color} />
      {points
        .filter(({posY}) => inNumberRange({min: 0, max: height}, posY))
        .map((point) => (
          <CircleMarker key={point.id} xPos={posX} yPos={point.posY} color={point.color ?? color} />
        ))}
    </g>
  )
}
