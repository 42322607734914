import {isEqual} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {usePrevious} from 'react-use'

import {isValidTargetRange} from '../../common/math/numberRange'
import {TargetRange} from '../../declarations'
import {useFreeLimeConfig} from '../../hooks/useFreeLimeConfig'
import {useFreeLimeSettingsMutation} from '../../hooks/useFreeLimeSettingsMutation'

import {FormBox} from './FormBox'
import {TargetRangeTableForm} from './TargetRangeTableForm'
import type {HasDisabled, TargetRangeEntry} from './types'

export const FreeLimeTargetSettings: React.FC<HasDisabled> = ({disabled}) => {
  const {t} = useTranslation()
  const {min, max, target} = useFreeLimeConfig()
  const backendEntry: TargetRangeEntry = useMemo(
    () => ({
      id: 'freeLimeTargetRange',
      label: t('freeLimeTargetSettings.freeLimeLabel'),
      targetRange: {min, max, target}
    }),
    [max, min, t, target]
  )
  const prevBeEntry = usePrevious(backendEntry)
  const [targetRangeEntry, setTargetRangeEntry] = useState<TargetRangeEntry>(backendEntry)
  const isValid = isValidTargetRange(targetRangeEntry.targetRange)
  const {isLoading, mutate} = useFreeLimeSettingsMutation()

  useEffect(() => {
    if (!isEqual(prevBeEntry, backendEntry)) {
      setTargetRangeEntry(backendEntry)
    }
  }, [backendEntry, prevBeEntry])

  return (
    <FormBox
      title={t('freeLimeTargetSettings.title')}
      onRevert={() => {
        setTargetRangeEntry(backendEntry)
      }}
      onSave={() => {
        if (isValid) {
          mutate({targetRange: targetRangeEntry.targetRange as TargetRange})
        }
      }}
      disabled={isEqual(backendEntry, targetRangeEntry)}
      isFormInvalid={!isValid}
      isSaving={isLoading}
      data-test-id="free-lime-target-settings-form-box"
    >
      <TargetRangeTableForm
        headerLabel={t('freeLimeTargetSettings.tableHeaderLabel')}
        unit={t('freeLimeTargetSettings.tableHeaderUnit')}
        targetRangeEntries={[targetRangeEntry]}
        onChange={(entries) => {
          setTargetRangeEntry(entries[0])
        }}
        disabled={disabled}
      />
    </FormBox>
  )
}
