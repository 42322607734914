import {isNil, toString} from 'lodash'
import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'

import {setQueryParam} from '../common/navigation'
import {UrlQueryKey} from '../declarations'

const toStringOrUndefined = <T>(value: T): string | undefined =>
  isNil(value) ? undefined : toString(value)

export const useSetQueryParamWithConverter = <T>(
  key: UrlQueryKey,
  convertToString: (v: T) => string | undefined
): ((v: T) => void) => {
  const history = useHistory()
  return useCallback(
    (value) => {
      setQueryParam(history, key, convertToString(value))
    },
    [history, key, convertToString]
  )
}

export const useSetQueryParam = <T = string | undefined>(key: UrlQueryKey) =>
  useSetQueryParamWithConverter<T>(key, toStringOrUndefined)
