import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import {Close, Delete} from '@material-ui/icons'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {deleteSensorMetaDataList} from '../../common/backend'
import {QueryKeyName} from '../../common/constants'
import {SensorMetaData} from '../../declarations'
import {useDialogStyles} from '../../hooks/useDialogStyles'
import {useMutationWithSnackbar} from '../../hooks/useMutationWithSnackbar'
import {LoadingButton} from '../LoadingButton'
import {calcColorStyle} from '../uiUtils'

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: 500
  },
  deleteButton: {
    ...calcColorStyle(theme.palette.error.main, theme)
  }
}))

interface SensorMetaDataDeleteDialogProps {
  open?: boolean
  items: SensorMetaData[]
  title?: string
  onClose?: () => void
}

const SensorEntries: React.FC<{entries: string[]}> = ({entries}) => (
  <>
    {entries.map((entry) => (
      <React.Fragment key={entry}>
        <br />
        <b>{entry}</b>
      </React.Fragment>
    ))}
  </>
)

const useDeleteSensorMetaDataMutation = () => {
  const {t} = useTranslation()
  const client = useQueryClient()
  return useMutationWithSnackbar(
    t('sensorMetaDataDeleteDialog.deletionSucceeded'),
    t('sensorMetaDataDeleteDialog.deletionFailed'),
    {
      mutationFn: (metaData: SensorMetaData[]) => {
        return deleteSensorMetaDataList(metaData.map(({uniformTag}) => uniformTag))
      },
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetProcessMetaData)
        void client.invalidateQueries(QueryKeyName.GetSensorMetaData)
        void client.invalidateQueries(QueryKeyName.GetSensorData)
        void client.invalidateQueries(QueryKeyName.GetPlantSpecificSensorMeta)
      }
    }
  )
}

export const SensorMetaDataDeleteDialog: React.FC<SensorMetaDataDeleteDialogProps> = ({
  items,
  open = false,
  title = '',
  onClose
}) => {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const {t} = useTranslation()
  const tagsToDelete = items.map(({uniformTag}) => uniformTag).sort()
  const {mutate, isLoading} = useDeleteSensorMetaDataMutation()

  const onDelete = () => {
    mutate(items, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-test-id="process-meta-data-delete-dialog"
      PaperProps={{className: classes.dialog}}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={dialogClasses.contentBlock}>
        <Typography>
          <Trans
            i18nKey="sensorMetaDataDeleteDialog.deleteQuestion"
            values={{
              count: items.length
            }}
            components={{
              scomp: <SensorEntries entries={tagsToDelete} />
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions className={dialogClasses.actionBlock}>
        <Button onClick={onCancel} startIcon={<Close />} data-test-id="dialog-cancel-button">
          {t('button.cancel')}
        </Button>
        <LoadingButton
          startIcon={<Delete />}
          btnClassName={classes.deleteButton}
          onClick={onDelete}
          data-test-id="dialog-delete-button"
          loading={isLoading}
        >
          {t('button.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
