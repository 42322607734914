import {Dialog, DialogContent, DialogTitle, Grid, makeStyles, TextField} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {isEqual} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {addSensorMetaData, updateSensorMetaData} from '../../common/backend'
import {QueryKeyName} from '../../common/constants'
import {
  isMetaDataDescriptionValid,
  isMetaDataUniformTagValid,
  isSensorMetaDataValid,
  trimSensorMetaDataFields
} from '../../common/metaData'
import {SensorMetaData} from '../../declarations'
import {useDialogStyles} from '../../hooks/useDialogStyles'
import {useMutationWithSnackbar} from '../../hooks/useMutationWithSnackbar'
import {DialogCancelSaveActions} from '../DialogCancelSaveActions'

const useStyles = makeStyles(() => ({
  dialog: {
    width: 500
  },
  infoIcon: {
    marginBottom: '-0.35rem',
    marginRight: '0.5rem'
  }
}))

interface SensorMetaDataDialogProps {
  open?: boolean
  item?: SensorMetaData
  title?: string
  onClose?: () => void
}

const EMPTY_PROCESS_META_DATA: SensorMetaData = {
  uniformTag: '',
  unit: '',
  displayName: '',
  category: '',
  description: ''
} as const

const showError = (
  currentValue: string,
  originalValue: string,
  isValid: (value: string) => boolean
): boolean => currentValue !== originalValue && !isValid(currentValue)

const useOrAddUpdateProcessMetaDataMutation = (add: boolean) => {
  const {t} = useTranslation()
  const client = useQueryClient()
  return useMutationWithSnackbar(
    t(add ? 'sensorMetaDataDialog.addSuccessful' : 'sensorMetaDataDialog.updateSuccessful'),
    t(add ? 'sensorMetaDataDialog.addFailed' : 'sensorMetaDataDialog.updateFailed'),
    {
      mutationFn: async (metaData: SensorMetaData) => {
        const dataToSave = trimSensorMetaDataFields(metaData)
        if (add) {
          await addSensorMetaData(dataToSave)
        } else {
          await updateSensorMetaData(dataToSave)
        }
      },
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetProcessMetaData)
        void client.invalidateQueries(QueryKeyName.GetSensorMetaData)
        void client.invalidateQueries(QueryKeyName.GetSensorData)
        void client.invalidateQueries(QueryKeyName.GetPlantSpecificSensorMeta)
      }
    }
  )
}

export const SensorMetaDataDialog: React.FC<SensorMetaDataDialogProps> = ({
  item: optionalItem,
  open = false,
  title = '',
  onClose
}) => {
  const shouldAdd = !optionalItem
  const item: SensorMetaData = optionalItem ?? EMPTY_PROCESS_META_DATA
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const {t} = useTranslation()
  const {unit, uniformTag, description} = item
  const [unitValue, setUnitValue] = useState(unit)
  const [uniformTagValue, setUniformTagValue] = useState(uniformTag)
  const [descriptionValue, setDescriptionValue] = useState(description)
  const {mutate, isLoading} = useOrAddUpdateProcessMetaDataMutation(shouldAdd)
  const itemToSave: SensorMetaData = {
    ...item,
    unit: unitValue,
    uniformTag: uniformTagValue,
    description: descriptionValue
  }
  const isPristine = isEqual(item, itemToSave)

  const onSave = () => {
    mutate(itemToSave, {
      onSuccess: onClose
    })
  }

  const onCancel = () => {
    onClose?.()
  }

  const onEnter = () => {
    setUnitValue(unit)
    setUniformTagValue(uniformTag)
    setDescriptionValue(description)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-test-id={`sensor-meta-data-dialog-${uniformTag}`}
      PaperProps={{className: classes.dialog}}
      TransitionProps={{onEnter}}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={dialogClasses.contentBlock}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              data-test-id="sensor-meta-data-dialog-uniform-tag-field"
              placeholder={t('processMetaData.uniformTag')}
              label={t('processMetaData.uniformTag')}
              value={uniformTagValue}
              onChange={(event) => {
                setUniformTagValue(event.target.value)
              }}
              helperText={
                <span>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  {t('sensorMetaDataDialog.uniformTagHelper')}
                </span>
              }
              required
              error={showError(uniformTagValue, uniformTag, isMetaDataUniformTagValid)}
              disabled={!shouldAdd}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              data-test-id="sensor-meta-data-dialog-unit-field"
              placeholder={t('processMetaData.unit')}
              label={t('processMetaData.unit')}
              value={unitValue}
              onChange={(event) => {
                setUnitValue(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              data-test-id="sensor-meta-data-dialog-description-field"
              placeholder={t('processMetaData.description')}
              label={t('processMetaData.description')}
              value={descriptionValue}
              onChange={(event) => {
                setDescriptionValue(event.target.value)
              }}
              error={showError(descriptionValue, description, isMetaDataDescriptionValid)}
              helperText={
                showError(descriptionValue, description, isMetaDataDescriptionValid)
                  ? t('form.required')
                  : undefined
              }
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogCancelSaveActions
        onCancel={onCancel}
        onSave={onSave}
        saveBtnLabel={t('button.save')}
        saveDisabled={!isSensorMetaDataValid(itemToSave) || isPristine}
        saving={isLoading}
      />
    </Dialog>
  )
}
