import {intersection, sortBy} from 'lodash'

import type {
  AggregatedMaterialData,
  CementStrengthSample,
  MaterialData,
  StrengthLevel
} from '../../declarations'

import {hasAnyValue} from './cementStrengthSample'

const strengthValueFilter = (
  strengthLevel?: StrengthLevel
): ((CementStrengthSample) => boolean) => {
  if (strengthLevel) {
    return (sample: CementStrengthSample) => hasAnyValue(sample, strengthLevel)
  }
  return () => true
}

const aggregateSamples = (
  materialData: MaterialData,
  sourcesToAggregate: string[] = []
): AggregatedMaterialData => {
  const {materialId, materialName, sampleSources} = materialData

  const sourceNames = sampleSources.map((source) => source.name)
  const aggregatedSourceNames = intersection(sourceNames, sourcesToAggregate)
  const merged = sampleSources
    .filter((sourceData) => aggregatedSourceNames.includes(sourceData.name))
    .flatMap((md) => md.samples)

  const samples = sortBy(merged, 'datetime')

  return {
    materialId,
    materialName,
    samples
  }
}

export const aggregateMaterialData: (
  materialData: MaterialData,
  options: {sourcesToAggregate?: string[]; strengthLevel?: StrengthLevel}
) => AggregatedMaterialData = (materialData, {sourcesToAggregate = [], strengthLevel}) => {
  const aggregated = aggregateSamples(materialData, sourcesToAggregate)
  return {...aggregated, samples: aggregated.samples.filter(strengthValueFilter(strengthLevel))}
}
