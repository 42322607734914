import {get} from 'lodash'
import qs from 'query-string'

// removes ?key=value&key2=value2 from localhost:3000?key=value&key2=value2
export const removeQueryString = (url: string) => {
  const parsedURL = qs.parseUrl(url)
  const {url: urlWithoutQueryString} = parsedURL
  return urlWithoutQueryString
}

// returns ?key=value&key2=value2 from localhost:3000?key=value&key2=value2
export const getQueryString = (url: string) => get(url.split('?'), 1, '')

// removes sensitive information from url (e.g. tokenInfo)
export const sanitizeUrl = (url: string): string => {
  const parsedURL = qs.parseUrl(url)

  const {...params} = parsedURL.query
  if (params.tokenInfo) {
    delete params.tokenInfo
  }
  if (params.state) {
    delete params.state
  } // CSRF token from loginFlow

  const stringifiedParams = qs.stringify(params)
  if (stringifiedParams) {
    return `${parsedURL.url}?${stringifiedParams}`
  }
  return parsedURL.url
}
