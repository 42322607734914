import {useEffect, useState} from 'react'

function getScrollPosition() {
  return {
    x: window.pageXOffset,
    y: window.pageYOffset
  }
}

export function useScroll() {
  const [position, setPosition] = useState(getScrollPosition())
  useEffect(() => {
    let request: number | null = null

    function handleScroll() {
      if (request === null) {
        request = window.requestAnimationFrame(() => {
          setPosition(getScrollPosition())
          request = null
        })
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return position
}

export function useXScroll() {
  const {x} = useScroll()
  return x
}

export function useYScroll() {
  const {y} = useScroll()
  return y
}
