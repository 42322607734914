import type {AxiosError} from 'axios'

import type {
  CementStrengthSettings,
  CementStrengthViewUpdateSettings,
  CsSampleType,
  FreelimeSettings,
  FreeLimeUpdateSettings,
  SampleTypeSource,
  StrengthLevel,
  TargetRange
} from '../../declarations'
import {logger} from '../logger'
import {getTimeHorizonIdAsDurationIsoString} from '../timeHorizon'

import {client} from './client'

export const updateCementStrengthTargetLevel = async (
  plantId: string,
  materialId: number,
  strengthLevel: StrengthLevel,
  targetRange: TargetRange
): Promise<void> => {
  const log = logger.context(
    'updateCementStrengthTargetLevel',
    plantId,
    materialId.toString(),
    strengthLevel,
    JSON.stringify(targetRange)
  )
  try {
    await client.dfApi.put<void>(
      `/predict/plants/${plantId}/settings/cementStrength/materialTargetLevels/${materialId}/${strengthLevel}`,
      {
        plantId,
        unifiedMaterialId: materialId,
        name: strengthLevel as string,
        targetValue: targetRange.target,
        lowerBound: targetRange.min,
        upperBound: targetRange.max
      }
    )
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const updateFreelimeSettings = async (
  plantId: string,
  settings: FreeLimeUpdateSettings
): Promise<void> => {
  const log = logger.context('updateFreelimeSettings', plantId, JSON.stringify(settings))
  try {
    const {axisRange, targetRange, defaultTimeDurationFreeLime} = settings
    const backendSettings: FreelimeSettings = {
      axisRange: {lowerBound: axisRange.min, upperBound: axisRange.max},
      targetRange: {
        lowerBound: targetRange.min,
        targetValue: targetRange.target,
        upperBound: targetRange.max
      },
      defaultTimeDurationFreelime: getTimeHorizonIdAsDurationIsoString(defaultTimeDurationFreeLime)
    }
    await client.dfApi.put(`/predict/plants/${plantId}/settings/freelime`, backendSettings)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

const CS_SAMPLE_TYPE_TO_SAMPLE_TYPE_SOURCE_MAP: Record<CsSampleType, SampleTypeSource> = {
  mill: 'Mill',
  silo: 'Silo',
  dailyAverage: 'DailyAverage',
  hourlyProduction: 'HourlyProduction'
}

export const updateCementStrengthSettings = async (
  plantId: string,
  settings: CementStrengthViewUpdateSettings
): Promise<void> => {
  const log = logger.context('updateCementStrengthSettings', plantId, JSON.stringify(settings))
  try {
    const backendSettings: CementStrengthSettings = {
      defaultView: 'TimeSelection',
      defaultTimeHorizon: settings.defaultTimeHorizon,
      defaultTimeDurationCementStrength: getTimeHorizonIdAsDurationIsoString(
        settings.defaultTimeDurationCementStrength
      ),
      defaultSampleTypeSources: settings.defaultSampleTypeSources.map(
        (csSampleType) => CS_SAMPLE_TYPE_TO_SAMPLE_TYPE_SOURCE_MAP[csSampleType]
      )
    }
    await client.dfApi.put(`/predict/plants/${plantId}/settings/cementStrength`, backendSettings)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}
