import AU from './Assets/flags/AU.png'
import BA from './Assets/flags/BA.png'
import CN from './Assets/flags/CN.png'
import DE from './Assets/flags/DE.png'
import ES from './Assets/flags/ES.png'
import FR from './Assets/flags/FR.png'
import GB from './Assets/flags/GB.png'
import GE from './Assets/flags/GE.png'
import HK from './Assets/flags/HK.png'
import HR from './Assets/flags/HR.png'
import KZ from './Assets/flags/KZ.png'
import NL from './Assets/flags/NL.png'
import NO from './Assets/flags/NO.png'
import PL from './Assets/flags/PL.png'
import RS from './Assets/flags/RS.png'
import RU from './Assets/flags/RU.png'
import TH from './Assets/flags/TH.png'
import US from './Assets/flags/US.png'

const flags: {[key: string]: any} = {
  'bs-BA': BA,
  'de-DE': DE,
  'de-BE': DE,
  'en-AU': AU,
  'en-CA': GB,
  'en-GB': GB,
  'en-HK': GB,
  'en-US': US,
  'es-ES': ES,
  'es-US': ES,
  'fr-CA': FR,
  'fr-BE': FR,
  'fr-FR': FR,
  'hr-BS': HR,
  'ka-GE': GE,
  'kk-KZ': KZ,
  'nl-NL': NL,
  'nl-BE': NL,
  'nn-NO': NO,
  'pl-PL': PL,
  'rs-BS': RS,
  'ru-RU': RU,
  'ru-KK': RU,
  'th-TH': TH,
  'zh-CN': CN,
  'zh-HK': HK
}

export default flags
