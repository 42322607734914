import {makeStyles, Typography} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  chartInfo: {
    fontSize: 12,
    color: theme.palette.grey[700]
  }
}))

interface ChartInfoTextProps {
  'data-test-id'?: string
}

export const ChartInfoText: React.FC<ChartInfoTextProps> = ({
  children,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()

  return (
    <Typography align="right" className={classes.chartInfo} data-test-id={dataTestId}>
      {children}
    </Typography>
  )
}
