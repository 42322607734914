import moment from 'moment-timezone'

import {TimeHorizonId} from '../declarations'

type TimeHorizons = Record<
  TimeHorizonId,
  {
    amount: number
    unit: moment.unitOfTime.Base
    roundTo: moment.unitOfTime.Base
    duration: string
  }
>

export const TIME_HORIZONS: TimeHorizons = {
  last3Hours: {amount: 3, unit: 'hour', roundTo: 'hour', duration: 'PT3H'},
  last6Hours: {amount: 6, unit: 'hour', roundTo: 'hour', duration: 'PT6H'},
  last12Hours: {amount: 12, unit: 'hour', roundTo: 'hour', duration: 'PT12H'},
  last24Hours: {amount: 1, unit: 'day', roundTo: 'hour', duration: 'P1D'},
  last3Days: {amount: 3, unit: 'day', roundTo: 'day', duration: 'P3D'},
  lastWeek: {amount: 1, unit: 'week', roundTo: 'day', duration: 'P7D'},
  lastMonth: {amount: 1, unit: 'month', roundTo: 'day', duration: 'P1M'},
  last3Months: {amount: 3, unit: 'month', roundTo: 'day', duration: 'P3M'},
  last6Months: {amount: 6, unit: 'month', roundTo: 'month', duration: 'P6M'},
  lastYear: {amount: 1, unit: 'year', roundTo: 'month', duration: 'P1Y'},
  last2Years: {amount: 2, unit: 'year', roundTo: 'month', duration: 'P2Y'},
  last5Years: {amount: 5, unit: 'year', roundTo: 'year', duration: 'P5Y'},
  last10Years: {amount: 10, unit: 'year', roundTo: 'year', duration: 'P10Y'}
} as const

export const TIME_HORIZON_IDS: TimeHorizonId[] = Object.keys(TIME_HORIZONS) as TimeHorizonId[]
export const CS_TIME_HORIZON_IDS: TimeHorizonId[] = [
  'lastWeek',
  'lastMonth',
  'last3Months',
  'last6Months',
  'lastYear',
  'last2Years',
  'last5Years',
  'last10Years'
] as TimeHorizonId[]
export const CS_TIME_HORIZON_MAX: TimeHorizonId = 'last10Years'

export const FL_TIME_HORIZON_IDS: TimeHorizonId[] = [
  'last3Hours',
  'last6Hours',
  'last12Hours',
  'last24Hours',
  'last3Days',
  'lastWeek',
  'lastMonth'
] as TimeHorizonId[]

export const timeHorizonFromDuration = (duration: string): TimeHorizonId => {
  const durationMs = moment.duration(duration).valueOf()
  return (
    TIME_HORIZON_IDS.find(
      (th) =>
        durationMs <= moment.duration(TIME_HORIZONS[th].amount, TIME_HORIZONS[th].unit).valueOf()
    ) ?? TIME_HORIZON_IDS[TIME_HORIZON_IDS.length - 1]
  )
}

export const getTimeHorizonFromString = (param?: string): TimeHorizonId | undefined => {
  if (param) {
    return TIME_HORIZON_IDS.find((th) => th.toLowerCase() === param.toLowerCase())
  }
}

export const getTimeHorizonIdAsDurationIsoString = (timeHorizonId: TimeHorizonId) =>
  TIME_HORIZONS[timeHorizonId].duration
