import {makeStyles, TableCell, TableRow} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface QualityParameterRowProps {
  name: string
  value?: string
  'data-test-id'?: string
  rowClassName?: string
  cellClassName?: string
}

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:last-child > td': {
      borderBottom: 0
    }
  },
  tableRowCell: {
    borderBottomColor: theme.palette.grey[100]
  },
  tableCellQualityParameter: {
    fontWeight: theme.typography.fontWeightBold
  }
}))

export const QualityParameterRow: React.FC<QualityParameterRowProps> = ({
  name,
  value,
  'data-test-id': dataTestId,
  rowClassName,
  cellClassName
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <TableRow className={classNames(classes.tableRow, rowClassName)}>
      <TableCell className={classNames(classes.tableRowCell, cellClassName)}>{name}</TableCell>
      <TableCell
        data-test-id={dataTestId}
        align="right"
        className={classNames(
          classes.tableCellQualityParameter,
          classes.tableRowCell,
          cellClassName
        )}
      >
        {value || t('common.na')}
      </TableCell>
    </TableRow>
  )
}
