import i18next from 'i18next'
import {isNumber} from 'lodash'

import type {
  CementStrengthSample,
  CementStrengthSampleFieldRecord,
  DataRecord,
  SampleFinder,
  StrengthField,
  StrengthLevel,
  StrengthPredictionField,
  TimeRange,
  WithStrengthPredictions,
  WithStrengthValues
} from '../../declarations'
import {formatFloat} from '../format'

import {isStrengthLevel} from './strengthLevel'

const levelStrengthMap: Record<StrengthLevel, StrengthField> = {
  strength1: 'strength1d',
  strength2: 'strength2d',
  strength3: 'strength3d',
  strength7: 'strength7d',
  strength28: 'strength28d'
} as const

const levelPredictionMap: Record<StrengthLevel, StrengthPredictionField> = {
  strength1: 'predictedStrength1d',
  strength2: 'predictedStrength2d',
  strength3: 'predictedStrength3d',
  strength7: 'predictedStrength7d',
  strength28: 'predictedStrength28d'
} as const

type ValueGetter = (sample: CementStrengthSample, level: StrengthLevel) => number | undefined

export const getActualValue = (
  sample: WithStrengthValues,
  level: StrengthLevel
): number | undefined => sample[levelStrengthMap[level]]

export const getPredictedValue = (
  sample: WithStrengthPredictions,
  level: StrengthLevel
): number | undefined => sample[levelPredictionMap[level]]

export const getQualityValue = (sample: CementStrengthSample, field: string): number | undefined =>
  sample[field]

export const getFieldValues = (
  sample: CementStrengthSample,
  currentStrengthLevel: StrengthLevel,
  selectedFields: string[]
): CementStrengthSampleFieldRecord[] => {
  const selected: string[] = [currentStrengthLevel, ...selectedFields]

  const strengthFields: CementStrengthSampleFieldRecord[] = selected
    .filter(isStrengthLevel)
    .flatMap((field) => [
      {field, value: getActualValue(sample, field)},
      {field, value: getPredictedValue(sample, field), isPrediction: true}
    ])
    .filter((item) => isNumber(item.value)) as CementStrengthSampleFieldRecord[]

  const qualityFields: CementStrengthSampleFieldRecord[] = selected
    .filter((field) => !isStrengthLevel(field))
    .map((field) => ({field, value: getQualityValue(sample, field)}))
    .filter((item) => isNumber(item.value)) as CementStrengthSampleFieldRecord[]
  return [...strengthFields, ...qualityFields]
}

export const hasAnyValue = (sample: CementStrengthSample, strengthLevel: StrengthLevel): boolean =>
  isNumber(getPredictedValue(sample, strengthLevel)) ||
  isNumber(getActualValue(sample, strengthLevel))

export const hasActualAndPredicted = (
  sample: CementStrengthSample,
  strengthLevel: StrengthLevel
): boolean =>
  isNumber(getPredictedValue(sample, strengthLevel)) &&
  isNumber(getActualValue(sample, strengthLevel))

const getValues = (
  samples: CementStrengthSample[],
  level: StrengthLevel,
  getter: ValueGetter
): DataRecord[] => {
  return samples
    .map((s) => ({
      sampleId: s.id,
      datetime: s.datetime,
      value: getter(s, level)
    }))
    .filter((s) => isNumber(s.value)) as DataRecord[]
}

export const getActualValues = (
  samples: CementStrengthSample[],
  level: StrengthLevel
): DataRecord[] => getValues(samples, level, getActualValue)

export const getPredictedValues = (
  samples: CementStrengthSample[],
  level: StrengthLevel
): DataRecord[] => getValues(samples, level, getPredictedValue)

export const getQualityValues = (samples: CementStrengthSample[], field: string): DataRecord[] =>
  samples
    .map((sample) => ({
      sampleId: sample.id,
      datetime: sample.datetime,
      value: getQualityValue(sample, field)
    }))
    .filter((record) => isNumber(record.value)) as DataRecord[]

export const getValuesByLevel = (
  samples: CementStrengthSample[],
  level: StrengthLevel
): number[] => {
  return samples
    .flatMap((s) => [getActualValue(s, level), getPredictedValue(s, level)])
    .filter(isNumber)
}

export const findPrevSample: SampleFinder = (samples, sampleId) => {
  if (samples.length === 0) {
    return undefined
  }
  const idx = samples.findIndex((s) => s.id === sampleId)
  return idx === -1 ? undefined : samples[idx - 1]
}

export const findNextSample: SampleFinder = (samples, sampleId) => {
  if (samples.length === 0) {
    return undefined
  }
  const idx = samples.findIndex((s) => s.id === sampleId)
  return idx === -1 ? undefined : samples[idx + 1]
}

const getFormattedValue = (
  sample: CementStrengthSample | undefined,
  level: StrengthLevel,
  fallback: string,
  getter: ValueGetter,
  locale: string = i18next.language
): string => {
  if (!sample) {
    return fallback
  }
  const value = getter(sample, level)
  return isNumber(value) ? formatFloat(value, {locale}) : fallback
}

export const getFormattedPredictedValue = (
  sample: CementStrengthSample | undefined,
  level: StrengthLevel,
  fallback: string,
  locale: string = i18next.language
): string => getFormattedValue(sample, level, fallback, getPredictedValue, locale)

export const getFormattedActualValue = (
  sample: CementStrengthSample | undefined,
  level: StrengthLevel,
  fallback: string,
  locale: string = i18next.language
): string => getFormattedValue(sample, level, fallback, getActualValue, locale)

export const isSampleInTimeRange = (sample: CementStrengthSample, timeRange: TimeRange): boolean =>
  timeRange.start.getTime() <= sample.datetime && sample.datetime <= timeRange.end.getTime()

export const latestSample = (samples: CementStrengthSample[]): CementStrengthSample | undefined =>
  samples[samples.length - 1]
