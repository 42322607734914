import {allApps, WhiteList, Permission} from '@hconnect/common/hproduce'
import {AppConfig, Env, AppName} from '@hconnect/common/hproduce/types'
import {addToWhiteList, whiteListByPermission} from '@hconnect/common/hproduce/whiteListCheck'
import {Box, makeStyles} from '@material-ui/core'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, NavLink} from 'react-router-dom'

import {AppLogo} from '../Assets/HProduceApps'
import {useMobileBreakPoint} from '../Hooks/useBreakPoints'
import Typography from '../Molecules/Typography'

import {AppSelectPopUp} from './AppSelect'
import {BurgerMenu, useLinkStyles} from './BurgerMenu'
import HProduceAccountDropdown from './HProduceAccountDropdown'
import {Plant} from './PlantSelect'
import {NavItem, User} from './types'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    border: 'none',
    marginRight: theme.spacing(2)
  },

  linkWrapper: {
    marginLeft: 20,
    marginRight: 20
  },
  icon: {
    marginRight: 8
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      marginRight: 30
    }
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  plant: {
    fontSize: 12,
    textTransform: 'capitalize'
  },
  spacerBox: {
    [theme.breakpoints.up('lg')]: {
      width: '65px'
    },
    [theme.breakpoints.only('md')]: {
      width: '48px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '32px'
    },
    [theme.breakpoints.only('xs')]: {
      width: '24px'
    }
  },
  appContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      justifyContent: 'flex-start'
    }
  },
  desktopLinksContainer: {
    display: 'flex'
  }
}))

export type CommonHeaderProps = {
  appName: AppName | 'HProduce'
  plant?: Plant
  navItems?: NavItem[]
  supportedLanguages: string[]
  user?: User
} & (
  | {
      disableAppSelect: true
    }
  | {
      permissions: Permission[]
      env: Env
    }
  | {
      whiteList: WhiteList
      env: Env
    }
) & {
    // TODO tell typescript that you need booth or none, currently a mix is acceptet but not use full
    onLogout?: () => void
    user?: User
  }

function getWhiteListFromProps(props: CommonHeaderProps) {
  let whiteList: WhiteList | undefined = undefined
  if ('disableAppSelect' in props) return

  if ('whiteList' in props && props.whiteList) {
    whiteList = props.whiteList
  } else if ('permissions' in props && props.permissions) {
    whiteList = whiteListByPermission(props.env, props.permissions)
  }

  // this is a temporary safety net until the permission check is bullet-proved,
  // it will make sure that at least app the user is currently in, is available in the list

  const {plant, appName, env} = props
  const plantId = plant?.plantId
  const appConfig: AppConfig = allApps[appName]

  if (plantId && whiteList && !whiteList.optionsMap.has(appName + plantId) && appConfig) {
    addToWhiteList(env, whiteList, appConfig, plantId)
  }

  return whiteList
}

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const {appName, navItems, plant} = props
  const {t, i18n} = useTranslation()
  const {activeLink, link} = useLinkStyles()
  const classes = useStyles()
  const language = i18n.language
  const localesList = props.supportedLanguages.map((code) => ({
    code,
    name: t(`locales.${code}`)
  }))
  const isMobile = useMobileBreakPoint()

  const whiteList = useMemo(
    () => getWhiteListFromProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props['disableAppSelect'], props['whiteList'], props['permissions']]
  )

  // in case the user has access to only one app on one plant, AppSelect should be disabled
  const disableAppSelect = !whiteList || whiteList.optionsMap.size <= 1

  return (
    <>
      <Box className={classes.spacerBox} />
      <Box display="flex" width="100%" data-test-id="common-header">
        <nav className={classes.nav}>
          {isMobile ? (
            <BurgerMenu
              iconButtonClassName={classes.menuButton}
              disableAppSelect={disableAppSelect}
              navItems={navItems}
              activeAppName={appName}
              activePlantId={plant?.plantId}
              whiteList={whiteList}
            />
          ) : (
            !disableAppSelect && (
              <AppSelectPopUp
                iconButtonClassName={classes.menuButton}
                activeAppName={appName}
                activePlantId={plant?.plantId}
                whiteList={whiteList}
              />
            )
          )}

          <Box className={classes.appContainer}>
            <Link className={classes.logo} to="/" data-test-id="common-header-logo">
              {appName !== 'HProduce' && <AppLogo name={appName} className={classes.icon} />}
              <div>
                <Typography variant="h4" color="textPrimary">
                  {appName}
                </Typography>
                {plant?.plantName && (
                  <Typography variant="h4" color="secondary" className={classes.plant}>
                    {plant.plantName}
                  </Typography>
                )}
              </div>
            </Link>
          </Box>
          {!isMobile && (
            <Box className={classes.desktopLinksContainer}>
              {navItems?.map(({label, url, dataTestId}) => (
                <Box key={label} className={classes.linkWrapper}>
                  <NavLink
                    to={url}
                    activeClassName={activeLink}
                    className={link}
                    data-test-id={dataTestId}
                  >
                    {label}
                  </NavLink>
                </Box>
              ))}
            </Box>
          )}
        </nav>
      </Box>
      {props.user && props.onLogout && (
        <HProduceAccountDropdown
          actions={{
            logout: props.onLogout,
            selectLanguage: (lng: string) => i18n.changeLanguage(lng)
          }}
          logoutButtonText={t('logout')}
          locales={{
            defaultLocale: language,
            locales: localesList,
            localeListLabel: t(`locales.${language}`)
          }}
          profile={{
            name: props.user.name ?? '',
            email: props.user.email ?? ''
          }}
        />
      )}
      <Box className={classes.spacerBox} />
    </>
  )
}

// eslint-disable-next-line import/no-default-export
export default CommonHeader
