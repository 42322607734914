import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getMaterialById} from '../common/cementStrength'
import {CementStrengthActionBar} from '../components/actionBar/CementStrengthActionBar'
import {MaterialDetailsChart} from '../components/materialTrends/MaterialDetailsChart'
import {PageBreadcrumbs} from '../components/PageBreadcrumbs'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {useMaterialId} from '../hooks/useMaterialId'
import {usePlantFullName} from '../hooks/usePlantFullName'
import {usePlantNavigation} from '../hooks/usePlantNavigation'
import {useMaterialContext} from '../providers/MaterialProvider'

import {PlantShellPage} from './PlantShellPage'

export const CementStrengthTrendsPage: React.FC = () => {
  const {t} = useTranslation()
  const plantName = usePlantFullName()
  const materialId = useMaterialId()
  const navigation = usePlantNavigation()
  const {materials} = useMaterialContext()
  const {predictionView} = useCsParamsWithDefaults()
  const material = isNumber(materialId) ? getMaterialById(materials, materialId) : undefined

  return (
    <PlantShellPage>
      <PageBreadcrumbs
        current={material?.name || t('navigation.cementStrengthTrends')}
        pathEntries={[
          {
            title: t('navigation.cementStrength'),
            url: navigation.getPlantUrl({
              plantPage: 'cementStrength'
            })
          }
        ]}
      />
      {material && (
        <>
          <CementStrengthActionBar
            title={`${plantName} - ${material.name}`}
            showZoomOptions={predictionView === 'time'}
          />
          <MaterialDetailsChart material={material} />
        </>
      )}
    </PlantShellPage>
  )
}
