import React, {createContext, useContext, useMemo} from 'react'
import {useQuery} from 'react-query'

import {getPlants} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {PlantsContext} from '../declarations'

const PContext = createContext<PlantsContext | undefined>(undefined)

export const usePlantsContext = (): PlantsContext => {
  const context = useContext(PContext)
  if (!context) {
    throw new Error('usePlantsContext used outside of PlantsProvider')
  }

  return context
}

export const PlantsProvider: React.FC = ({children}) => {
  const {isLoading, error, data} = useQuery([QueryKeyName.GetPlants], () => getPlants())

  const value = useMemo(
    () => ({
      plants: data ?? [],
      isLoading,
      error
    }),
    [data, isLoading, error]
  )

  return <PContext.Provider value={value}>{children}</PContext.Provider>
}
