import React from 'react'
import {useTranslation} from 'react-i18next'

import {PageContainer} from '../components/PageContainer'
import {PageTitle} from '../components/PageTitle'
import {SensorSettings} from '../components/settings/SensorSettings'

export const AdminPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <PageContainer>
      <PageTitle data-test-id="plant-shell-page-title">{t('adminPage.title')}</PageTitle>
      <SensorSettings />
    </PageContainer>
  )
}
