import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {getDefaultSampleSources} from '../common/cementStrength'
import {DEFAULT_PREDICTION_VIEW, DEFAULT_ROWS_PER_PAGE, NOW_PARAM} from '../common/constants'
import {dateTimeParamsFromHorizon} from '../common/dateUtils'
import {searchStringToUrlQueryParams} from '../common/navigation'
import {UrlCementStrengthParamsWithDefaults} from '../declarations'

import {usePlantConfig} from './usePlantConfig'
import {usePlantSampleSources} from './usePlantSampleSources'

export const useCsParamsWithDefaults = (): UrlCementStrengthParamsWithDefaults => {
  const {defaultSampleTypeSources, defaultStrengthLevel, defaultTimeDurationCementStrength} =
    usePlantConfig()
  const sources = usePlantSampleSources()
  const {search} = useLocation()

  // eslint-disable-next-line complexity
  return useMemo(() => {
    const params = searchStringToUrlQueryParams(search)
    const strengthLevel = params.strength ?? defaultStrengthLevel
    return {
      strength: strengthLevel,
      sampleId: params.sampleId,
      selectedSources:
        params.selectedSources ?? getDefaultSampleSources(sources, defaultSampleTypeSources),
      predictionView: params.predictionView ?? DEFAULT_PREDICTION_VIEW,
      csStart: params.csStart ?? dateTimeParamsFromHorizon(defaultTimeDurationCementStrength).start,
      csEnd: params.csEnd ?? NOW_PARAM,
      csSelectedTagIds: (params.csSelectedTagIds ?? []).filter((tagId) => tagId !== strengthLevel),
      csTableRowsPerPage: params.csTableRowsPerPage ?? DEFAULT_ROWS_PER_PAGE,
      csTablePageNo: params.csTablePageNo ?? 0,
      csTableSort: params.csTableSort,
      csTableFilters: params.csTableFilters ?? []
    }
  }, [
    search,
    defaultStrengthLevel,
    sources,
    defaultSampleTypeSources,
    defaultTimeDurationCementStrength
  ])
}
