import React from 'react'

interface HorizontalLineProps {
  width: number
  yPos: number
  stroke: string
  strokeDasharray?: string
}

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
  width,
  yPos,
  stroke,
  strokeDasharray
}) => {
  return (
    <line
      x1={0}
      x2={width}
      y1={yPos}
      y2={yPos}
      strokeWidth={1}
      stroke={stroke}
      strokeDasharray={strokeDasharray}
      fill="none"
    />
  )
}
