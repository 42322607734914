import {useCallback, useEffect, useRef} from 'react'

import {EnablePromptFunc, PromptOptions, usePromptContext} from '../providers/PromptProvider'

export const usePrompt = (): EnablePromptFunc => {
  const {enablePrompt, addHandle, removeHandle} = usePromptContext()
  const handleRef = useRef<string>()

  useEffect(() => {
    const handle = addHandle()
    handleRef.current = handle

    return () => removeHandle(handle)
  }, [addHandle, removeHandle])

  return useCallback(
    (enable: boolean, options?: PromptOptions) => {
      if (handleRef.current) {
        enablePrompt(handleRef.current, enable, options)
      }
    },
    [enablePrompt]
  )
}
