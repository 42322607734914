import {Typography, TypographyProps} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: 'none',
    marginBottom: theme.spacing(3)
  }
}))

export const PageTitle: React.FC<TypographyProps> = (props) => {
  const classes = useStyles()
  const {className, ...rest} = props

  return <Typography variant="h2" className={classNames(classes.title, className)} {...rest} />
}
