import React, {createContext, useCallback, useContext, useMemo, useRef} from 'react'
import {usePrevious} from 'react-use'

import {
  COLOR_CS_SELECTED_STRENGTH,
  COLOR_FL_ACTUAL_VALUES
} from '../common/charts/chartConfigurations'
import {calcUpdatedColorMap} from '../common/charts/colorUtils'
import {ColorContext} from '../declarations'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {useFlParamsWithDefaults} from '../hooks/useFlParamsWithDefaults'

const ColContext = createContext<ColorContext | undefined>(undefined)

export const useColorContext = () => {
  const context = useContext(ColContext)
  if (!context) {
    throw new Error('useColorContext used outside of ColorProvider')
  }
  return context
}

const useColorMapFn = (selectedIds: string[], defaultColor: string) => {
  const prevIds = usePrevious(selectedIds)
  const colorMap = useRef({})
  colorMap.current = calcUpdatedColorMap(colorMap.current, prevIds ?? [], selectedIds)

  return useCallback((id) => colorMap.current[id] ?? defaultColor, [defaultColor])
}

const useFlColorMapFn = () => {
  const {flSelectedTagIds} = useFlParamsWithDefaults()
  return useColorMapFn(flSelectedTagIds, COLOR_FL_ACTUAL_VALUES)
}

const useCsColorMapFn = () => {
  const {csSelectedTagIds} = useCsParamsWithDefaults()
  return useColorMapFn(csSelectedTagIds, COLOR_CS_SELECTED_STRENGTH)
}

export const ColorProvider: React.FC = ({children}) => {
  const flColorFn = useFlColorMapFn()
  const csColorFn = useCsColorMapFn()

  const value: ColorContext = useMemo(
    () => ({
      getCsColor: csColorFn,
      getFlColor: flColorFn
    }),
    [csColorFn, flColorFn]
  )

  return <ColContext.Provider value={value}>{children}</ColContext.Provider>
}
