import {Snackbar} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import React, {createContext, useCallback, useMemo, useState} from 'react'

import {SnackbarConfig, SnackbarContext} from '../declarations'

export const SbContext = createContext<SnackbarContext | undefined>(undefined)

const DEFAULT_CONFIG: SnackbarConfig = {
  autoHideDuration: 5000,
  severity: 'error'
}

export const SnackbarProvider: React.FC = ({children}) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<React.ReactNode>('')
  const [config, setConfig] = useState<SnackbarConfig>(DEFAULT_CONFIG)

  const onClose = useCallback(() => {
    config.onClose?.()
    setOpen(false)
  }, [config])

  const value: SnackbarContext = useMemo(() => {
    const showSnackbar = (message: React.ReactNode, config?: SnackbarConfig): void => {
      setMessage(message)
      setConfig(config ?? DEFAULT_CONFIG)
      setOpen(true)
    }
    const showSuccess = (message: React.ReactNode): void =>
      showSnackbar(message, {
        autoHideDuration: 2000,
        severity: 'success'
      })

    const showError = (message: React.ReactNode): void => showSnackbar(message)
    return {
      showSnackbar,
      showSuccess,
      showError
    }
  }, [])

  return (
    <SbContext.Provider value={value}>
      {children}
      <Snackbar autoHideDuration={config.autoHideDuration} open={open} onClose={onClose}>
        <Alert severity={config.severity} onClose={onClose}>
          {message}
        </Alert>
      </Snackbar>
    </SbContext.Provider>
  )
}
