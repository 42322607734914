import {makeStyles, Theme, Typography} from '@material-ui/core'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ProcessMetaData, ProcessMetaDataKey} from '../../declarations'
import {usePlantSpecificSensorMetaData} from '../../hooks/usePlantSpecificSensorMetaData'
import {useSensorSettingsTableData} from '../../hooks/useSensorSettingsTableData'
import {useSettingsParamsWithDefaults} from '../../hooks/useSettingsParamsWithDefaults'
import {useTableFilters} from '../../hooks/useTableFilters'
import {useTableSort} from '../../hooks/useTableSort'
import {DefaultBox} from '../DefaultBox'
import {Spacing} from '../Spacing'

import {ProcessMetaDataDialog} from './ProcessMetaDataDialog'
import {SensorSettingsTable} from './SensorSettingsTable'
import {HasDisabled} from './types'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold
  }
}))

const COLUMN_KEYS: ProcessMetaDataKey[] = ['plantSpecificTag', 'uniformTag', 'unit', 'description']

export const ProcessMetaDataSettings: React.FC<HasDisabled> = ({disabled}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const metaData = usePlantSpecificSensorMetaData()
  const {
    settingsSensorsTableSort,
    settingsSensorsTableFilters,
    settingsSensorsTablePageNo,
    settingsSensorsTableRowsPerPage
  } = useSettingsParamsWithDefaults()
  const paginatedTableData = useSensorSettingsTableData(
    metaData,
    settingsSensorsTablePageNo,
    settingsSensorsTableRowsPerPage,
    settingsSensorsTableFilters,
    settingsSensorsTableSort,
    'settingsSensorsTableRowsPerPage',
    'settingsSensorsTablePageNo'
  )
  const {sortCriterion, setSortCriterion: onSort} = useTableSort<ProcessMetaDataKey>(
    'settingsSensorsTableSort'
  )
  const {filters, setFilters: onFilter} = useTableFilters<ProcessMetaDataKey>(
    'settingsSensorsTableFilters'
  )
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [itemToEdit, setItemToEdit] = useState<ProcessMetaData>()

  return (
    <DefaultBox width="100%" data-test-id="process-meta-data-settings">
      <Typography variant="h6" className={classes.title}>
        {t('sensorSettings.title')}
      </Typography>
      <Spacing height={3} />
      <SensorSettingsTable
        columnKeys={COLUMN_KEYS}
        paginatedTableData={paginatedTableData}
        disabled={disabled}
        deleteDisabled={true}
        multiSelectDisabled={true}
        onEditItemClicked={(item) => {
          setItemToEdit(item)
          setOpenEditDialog(true)
        }}
        sortCriterion={sortCriterion}
        onSort={onSort}
        filters={filters}
        onFilter={onFilter}
      />
      {itemToEdit ? (
        <ProcessMetaDataDialog
          open={openEditDialog}
          onClose={() => {
            setItemToEdit(undefined)
            setOpenEditDialog(false)
          }}
          item={itemToEdit}
          title={t('sensorTableEditButton.title')}
        />
      ) : null}
    </DefaultBox>
  )
}
