import {intersection} from 'lodash'
import {useMemo} from 'react'

type WithStringKey<Type> = {
  [Property in keyof Type]: string
}

export const useSelectedMetaIds = <T extends object>(
  selectedTagIds: string[],
  metaData: WithStringKey<T>[],
  id: keyof T
): string[] => {
  return useMemo(
    () =>
      intersection(
        metaData.map((data) => data[id]),
        selectedTagIds
      ),
    [id, metaData, selectedTagIds]
  )
}
