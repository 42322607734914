import {CommonHeader} from '@hconnect/uikit'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useAuthContext} from '../AuthProvider'
import {isUserAdmin} from '../common/backend'
import {ADMIN_URL, getPageUrl} from '../common/navigation/router'
import type {Plant, PredictPageId} from '../declarations'
import {usePlantParameter} from '../hooks/usePlantParameter'
import {useUser} from '../hooks/useUser'
import {supportedLanguages} from '../localization'
import {usePlantsContext} from '../providers/PlantsProvider'

interface NavItem {
  label: string
  url: string
  dataTestId: string
}

const PAGE_KEYS: PredictPageId[] = ['cementStrength', 'freeLime', 'userManual', 'settings']

const usePlantNavItems = (): {plant: Plant | undefined; navItems: NavItem[]} => {
  const {plants} = usePlantsContext()
  const {t} = useTranslation()
  const plantParam = usePlantParameter()
  const user = useUser()
  return useMemo(() => {
    const adminNavItems = isUserAdmin(user)
      ? [
          {
            label: t('navigation.admin'),
            url: ADMIN_URL,
            dataTestId: 'nav-link-admin'
          }
        ]
      : []
    const plantItems = plantParam
      ? PAGE_KEYS.map((pageKey) => ({
          label: t(`navigation.${pageKey}`),
          url: getPageUrl(pageKey, {plant: plantParam}),
          dataTestId: `nav-link-${pageKey}`
        }))
      : []
    const navItems = [...plantItems, ...adminNavItems]
    return {plant: plants.find((p) => p.name === plantParam), navItems}
  }, [plantParam, plants, t, user])
}

export const ShellHeader: React.FC = () => {
  const {plant, navItems} = usePlantNavItems()
  const {user, logout} = useAuthContext()

  return (
    <CommonHeader
      appName="Predict"
      onLogout={logout}
      plant={plant ? {plantName: plant.fullName, plantId: plant.id} : undefined}
      supportedLanguages={supportedLanguages}
      user={user}
      navItems={navItems}
      disableAppSelect
    />
  )
}
