import {Grid} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {timeRangeFromDateTimeParams} from '../common/timeRange'
import {CementStrengthSamplesTable} from '../components/CementStrengthSamplesTable'
import {DefaultBox} from '../components/DefaultBox'
import {ErrorMessage} from '../components/ErrorMessage'
import {Loader} from '../components/Loader'
import {MaterialChart} from '../components/materialOverview/MaterialChart'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {useCsTimeRange} from '../hooks/useCsTimeRange'
import {useMaterialContext} from '../providers/MaterialProvider'

export const MaterialChartsContainer: React.FC = () => {
  const {t} = useTranslation()
  const csTimeRange = useCsTimeRange()
  const timeRange = timeRangeFromDateTimeParams(csTimeRange)
  const materialContext = useMaterialContext()
  const {predictionView} = useCsParamsWithDefaults()
  const {materials} = materialContext

  if (materialContext.isLoading) {
    return <Loader />
  }

  if (materialContext.error) {
    return <ErrorMessage>{t('errors.cementStrengthData')}</ErrorMessage>
  }

  if (materialContext.materials.length === 0) {
    return <ErrorMessage>{t('errors.noMaterials')}</ErrorMessage>
  }

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {predictionView === 'table' ? (
        <DefaultBox width="100%" m={1} flex={1}>
          <CementStrengthSamplesTable />
        </DefaultBox>
      ) : (
        materials.map((mat) => (
          <Grid item key={mat.materialId} xs={12} sm={6} lg={4} xl={3}>
            <MaterialChart material={mat} timeRange={timeRange} />
          </Grid>
        ))
      )}
    </Grid>
  )
}
