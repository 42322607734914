import {makeStyles} from '@material-ui/core'
import React, {useCallback, useLayoutEffect, useRef, useState} from 'react'

import {useWindowResizeEvent} from '../Hooks'

import {ClippedWrapper} from './ClippedWrapper'

export interface GraphRenderProps {
  graphWidth: number
  graphHeight: number
  Clipped: React.FC
}

export interface GraphProps {
  height?: number
  margin: {
    top: number
    bottom: number
    left: number
    right: number
  }
  render: (renderProps: GraphRenderProps) => React.ReactNode
  graphId: string
  'data-test-id'?: string
}

const calcGraphWidth = (width: number, marginLeft = 0, marginRight = 0) =>
  width - (marginLeft + marginRight)

const calcGraphHeight = (height: number, marginTop = 0, marginBottom = 0) =>
  height - (marginTop + marginBottom)

const useStyles = makeStyles(() => ({
  graphRoot: {
    overflowX: 'hidden'
  }
}))

export const Graph: React.FC<GraphProps> = ({
  height: h,
  margin,
  render,
  graphId,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const onWindowResize = useCallback(() => setWidth(0), [])
  useWindowResizeEvent(onWindowResize)

  // after the resizing setWidth has to be called again with the ref's calculated offsetWidth
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0)
  })

  const height = h ?? width
  const graphWidth = calcGraphWidth(width, margin.left, margin.right)
  const graphHeight = calcGraphHeight(height, margin.top, margin.bottom)
  const clipPathId = `${graphId}-clipPath`

  return (
    <div ref={ref} data-test-id={dataTestId} className={classes.graphRoot}>
      {width > 0 && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <defs>
              <clipPath id={clipPathId}>
                <rect x={0} y={0} height={graphHeight} width={graphWidth} />
              </clipPath>
            </defs>

            {render({
              graphWidth,
              graphHeight,
              Clipped: ({children}) => (
                <ClippedWrapper clipPathId={clipPathId}>{children}</ClippedWrapper>
              )
            })}
          </g>
        </svg>
      )}
    </div>
  )
}
