import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTableStyles} from '../../hooks/useTableStyles'

interface TargetRangeTableFormContainerProps {
  headerLabel: string
  unit: string
  tableRows: React.ReactNode[]
  withButtonColumn?: boolean
}

export const TargetRangeTableFormContainer: React.FC<TargetRangeTableFormContainerProps> = ({
  headerLabel,
  unit,
  tableRows,
  withButtonColumn = false
}) => {
  const tableClasses = useTableStyles()
  const {t} = useTranslation()
  const headerCellClassName = classNames(
    tableClasses.tableHeaderCell,
    tableClasses.tableRowCell,
    tableClasses.cell
  )

  return (
    <TableContainer>
      <Table size="small">
        <TableHead className={tableClasses.tableHeader}>
          <TableRow>
            {withButtonColumn ? <TableCell className={headerCellClassName} /> : null}
            <TableCell className={headerCellClassName}>{headerLabel}</TableCell>
            <TableCell className={headerCellClassName} align="right">
              {t('targetRangeTableForm.minHeader', {unit})}
            </TableCell>
            <TableCell className={headerCellClassName} align="right">
              {t('targetRangeTableForm.targetHeader', {unit})}
            </TableCell>
            <TableCell className={headerCellClassName} align="right">
              {t('targetRangeTableForm.maxHeader', {unit})}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  )
}
