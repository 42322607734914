import {BackendConfig, BackendConfigList} from '../types'

const prod: BackendConfig = {
  AUTH_URL: 'https://auth.hconnect.digital',
  API_URL: 'https://api.hconnect.heidelbergcement.com'
}

const qa: BackendConfig = {
  AUTH_URL: 'https://auth-qa.hconnect.digital',
  API_URL: 'https://api-qa.hconnect.heidelbergcement.com'
}

const uat: BackendConfig = {
  AUTH_URL: 'https://auth-uat.hconnect.digital',
  API_URL: 'https://api-uat.hconnect.heidelbergcement.com'
}

const regression: BackendConfig = {
  AUTH_URL: 'https://auth-regression.hconnect.digital',
  API_URL: 'https://api-regression.hconnect.heidelbergcement.com'
}

const dev: BackendConfig = {
  AUTH_URL: 'http://localhost:4000?backend=dev',
  API_URL: 'https://api-dev.hconnect.heidelbergcement.com'
}

const local: BackendConfig = {
  AUTH_URL: 'http://localhost:4000?backend=dev',
  API_URL: 'http://localhost:9000'
}

const origin: BackendConfig = {
  AUTH_URL: 'http://localhost:4000?backend=dev',
  API_URL: window.location.origin + '/api'
}

const uatr: BackendConfig = {
  AUTH_URL: 'https://auth-uatr.hconnect.digital',
  API_URL: 'https://api-uat-readonly.hconnect.heidelbergcement.com'
}

// That backend is special:
// It's a test environment for multi-cluster deployments
const dpTest: BackendConfig = {
  AUTH_URL: 'https://auth-dptest.hconnect.digital',
  API_URL: 'https://dptest.heidelbergcement.com'
}

const LOCAL_AUTH_ADDR = 'http://localhost:4000'

const prodLocal: BackendConfig = {
  ...prod,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=prod`
}

const qaLocal: BackendConfig = {
  ...qa,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=qa`
}

const uatLocal: BackendConfig = {
  ...uat,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=uat`
}

const uatrLocal: BackendConfig = {
  ...uat,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=uatr`
}

const regressionLocal: BackendConfig = {
  ...regression,
  AUTH_URL: `${LOCAL_AUTH_ADDR}?backend=regression`
}

export function getDefaultDFBackends(): BackendConfigList {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return {prod}
  }
  return {
    local,
    dpTest,
    dev,
    qa,
    qaLocal,
    uat,
    uatLocal,
    uatr,
    uatrLocal,
    regressionLocal,
    regression,
    prod,
    prodLocal,
    origin
  }
}

export const getLocalBackend = (): BackendConfigList => ({local})
export const getQABackend = (): BackendConfigList => ({qa})
export const getUATBackend = (): BackendConfigList => ({uat})
export const getUATReadonlyBackend = (): BackendConfigList => ({uat})
export const getRegressionBackend = (): BackendConfigList => ({regression})
export const getPRODBackend = (): BackendConfigList => ({prod})
