import {makeStyles, Theme} from '@material-ui/core'

export const useChartContainerStyles = makeStyles((theme: Theme) => {
  const margin = theme.spacing(3) / 2
  return {
    container: {
      display: 'flex',
      marginTop: -margin,
      marginLeft: -margin,
      marginRight: -margin,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    itemGraph: {
      flexGrow: 1,
      padding: margin,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    itemDetails: {
      padding: margin,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    itemDetailBox: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
})
