import {isUndefined, trimStart} from 'lodash'
import {useEffect, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {searchStringToUrlQueryParams, setParam} from '../common/navigation'
import {isStorageProvider} from '../common/storageProvider'
import {UrlQueryKey, UrlStorageProviderParams} from '../declarations'

export const useStorageProviderParams = (): UrlStorageProviderParams => {
  // Get location hook for params
  const {search} = useLocation()
  // Get history hook to correct wrong params
  const history = useHistory()

  // Create storage provider params
  const storageProviderParams = useMemo(() => {
    const params = searchStringToUrlQueryParams(search)
    return {
      storageProvider: params.storageProvider,
      warmStorageProvider: params.warmStorageProvider,
      coldStorageProvider: params.warmStorageProvider
    }
  }, [search])

  // Detect and delete wrong param values
  useEffect(() => {
    const search = Object.entries(storageProviderParams).reduce((searchTerm, cur) => {
      const [key, provider] = cur
      if (isUndefined(provider) || !isStorageProvider(provider)) {
        return setParam(searchTerm, key as UrlQueryKey, undefined)
      }
      return searchTerm
    }, history.location.search)
    if (trimStart(search, '?') !== trimStart(history.location.search, '?')) {
      history.push({search})
    }
  }, [history, storageProviderParams])

  // Return storage provider params
  return storageProviderParams
}
