import {isNumber} from 'lodash'
import React from 'react'

import {NOW_PARAM} from '../../common/constants'
import {FL_TIME_HORIZON_IDS} from '../../common/timeHorizon'
import {maxDateTimeParam} from '../../common/timeRange'
import {DateTimeParam} from '../../declarations'
import {useFlParamsWithDefaults} from '../../hooks/useFlParamsWithDefaults'
import {useFreeLimeKilnLastPrediction} from '../../hooks/useFreeLimeKilnLastPrediction'
import {usePlantKilnIds} from '../../hooks/usePlantKilnIds'
import {useSetFlTimeRangeParams} from '../../hooks/useSetTimeRangeParams'
import {PageTitle} from '../PageTitle'

import {ActionBar} from './ActionBar'
import {KilnSelect} from './KilnSelect'
import {TimeRangePickerButton} from './TimeRangePickerButton'
import {ZoomButtonGroup} from './ZoomButtonGroup'

const calcMaxEnd = (rangeEnd: DateTimeParam, lastPrediction?: number) => {
  const shouldEndWithNow = rangeEnd === NOW_PARAM
  if (isNumber(lastPrediction)) {
    return shouldEndWithNow ? maxDateTimeParam(lastPrediction, NOW_PARAM) : lastPrediction
  }
  return shouldEndWithNow ? NOW_PARAM : undefined
}

export const FreeLimeActionBar: React.FC<{title: string}> = ({title}) => {
  const {flStart, flEnd} = useFlParamsWithDefaults()
  const onTimeRangeChanged = useSetFlTimeRangeParams()
  const kilnIds = usePlantKilnIds()
  const lastPrediction = useFreeLimeKilnLastPrediction()

  return (
    <ActionBar title={<PageTitle>{title}</PageTitle>}>
      <TimeRangePickerButton
        start={flStart}
        end={flEnd}
        selectableTimeHorizons={FL_TIME_HORIZON_IDS}
        onTimeRangeChanged={(start: DateTimeParam, end: DateTimeParam) =>
          onTimeRangeChanged(start, end)
        }
      />
      <ZoomButtonGroup
        timeRange={{
          start: flStart,
          end: flEnd
        }}
        onTimeRangeChanged={onTimeRangeChanged}
        maxEnd={calcMaxEnd(flEnd, lastPrediction)}
      />
      {kilnIds.length > 1 ? <KilnSelect /> : null}
    </ActionBar>
  )
}
