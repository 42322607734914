/* eslint-disable jsx-a11y/no-autofocus */
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core'
import {Clear, Sort} from '@material-ui/icons'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SortOrder} from '../../declarations'

interface PopoverContentProps<T> {
  field: T
  onFilter?: (field: T, expression: string) => void
  onSort?: (field: T, order: SortOrder) => void
  onClose: () => void
  sortOrder?: SortOrder
  filter?: string
  width: number
  sortButtonLabel?: string
  filterPlaceholder?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  filterContainer: {
    marginTop: theme.spacing(2)
  },
  clearFilterButton: {
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.grey[700],
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))

export const PopoverContent = <T,>(props: PopoverContentProps<T>) => {
  const {
    field,
    onFilter,
    onSort,
    onClose,
    sortOrder,
    filter = '',
    width,
    sortButtonLabel = '',
    filterPlaceholder = ''
  } = props
  const {t} = useTranslation()
  const classes = useStyles()
  const [filterExpression, setFilterExpression] = useState(filter)

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        onFilter?.(field, filterExpression)
        onClose()
      }
    },
    [field, filterExpression, onClose, onFilter]
  )

  return (
    <Box display="flex" flexDirection="column" width={width} p={3}>
      <Button
        data-test-id="popover-content-sort-button"
        startIcon={<Sort />}
        onClick={() => {
          onSort?.(field, sortOrder === 'asc' ? 'desc' : 'asc')
          onClose()
        }}
      >
        {sortButtonLabel}
      </Button>
      <TextField
        data-test-id="popover-content-filter-text-field"
        className={classes.filterContainer}
        fullWidth
        placeholder={filterPlaceholder}
        value={filterExpression}
        onChange={(event) => {
          setFilterExpression(event.target.value.trim())
        }}
        autoFocus
        onBlur={() => {
          onFilter?.(field, filterExpression)
        }}
        onKeyUp={onKeyUp}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.clearFilterButton}
                aria-label={t('popoverContent.clearFilter')}
                onClick={() => {
                  setFilterExpression('')
                  onFilter?.(field, '')
                }}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}
