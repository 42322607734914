import {
  Typography as AbstractTypography,
  makeStyles,
  TypographyProps as AbstractTypographyProps
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {HCThemeType} from '../HCTheme.types'

const useStyle = makeStyles((theme: HCThemeType) => ({
  gutterAround: {
    padding: theme.spacing(2.5)
  },
  captionBold: theme.typography.captionBold,
  formLabel: theme.typography.formLabel,
  textSecondarySoft: {
    color: theme.palette.text.secondarySoft
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  textPrimarySoft: {
    color: theme.palette.text.primarySoft
  },
  textError: {
    color: theme.palette.error.main
  },
  textSuccessMain: {
    color: theme.palette.success.main
  },
  textDisabled: {
    color: theme.palette.text.disabled
  },
  textErrorContrastText: {
    color: theme.palette.common.white
  },
  breakWords: {
    wordWrap: 'break-word'
  },
  breakLine:{
    wordWrap: 'normal'
  },
  bold: {
    fontWeight: 700
  }
}))

export interface TypographyProps extends AbstractTypographyProps {
  component?: React.ElementType<any>
  gutterAround?: boolean
  breakWords?: boolean
  breakLine?: boolean
  customColor?:
    | 'textSuccessMain'
    | 'textError'
    | 'textDisabled'
    | 'textPrimarySoft'
    | 'textSecondarySoft'
    | 'textSecondary'
    | 'textErrorContrastText'
  customVariant?: 'formLabel' | 'captionBold' | 'bold'
}

const Typography: React.FC<TypographyProps> = ({
  children,
  gutterAround = false,
  breakWords = false,
  breakLine = false,
  customVariant,
  customColor,
  ...props
}) => {
  const classes = useStyle()
  return (
    <AbstractTypography
      className={classNames(
        customVariant ? classes[customVariant] : undefined,
        customColor ? classes[customColor] : undefined,
        {
          [classes.gutterAround]: gutterAround,
          [classes.breakWords]: breakWords,
          [classes.breakLine]: breakLine
        }
      )}
      {...props}
    >
      {children}
    </AbstractTypography>
  )
}

export default Typography
