import React from 'react'

import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {usePlantNavigation} from '../../hooks/usePlantNavigation'
import {InAppLink} from '../InAppLink'

interface TrendsLinkProps {
  materialId?: number
  sampleId?: number
}

export const TrendsLink: React.FC<TrendsLinkProps> = ({materialId, sampleId, children}) => {
  const plantNavigation = usePlantNavigation()
  const params = useCsParamsWithDefaults()

  return (
    <InAppLink
      to={plantNavigation.getPlantUrl({
        plantPage: 'cementStrengthTrends',
        pathParams: {materialId},
        queryParams: {
          ...params,
          sampleId
        }
      })}
      data-test-id={`material-chart-${materialId}`}
    >
      {children}
    </InAppLink>
  )
}
