import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {getLabMetaData} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {LabMetaData} from '../declarations'

import {usePlantId} from './usePlantId'

export const useLabMetaData = (): LabMetaData[] => {
  const plantId = usePlantId()
  const {data} = useQuery([QueryKeyName.GetLabMetaData, plantId], async () => {
    const metaData = await getLabMetaData(plantId)
    return {metaData}
  })
  return useMemo(() => data?.metaData ?? [], [data])
}
