import {makeStyles, Theme} from '@material-ui/core'
import {isNumber} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useKey} from 'react-use'

import {hoveredColor} from '../../common/charts/chartConfigurations'
import {DEFAULT_ZOOM_OUT_FACTOR} from '../../common/constants'
import {zoom} from '../../common/timeRange'
import {DateTimeParam, LinearTimeScaleFn, TimeRange} from '../../declarations'
import {getRelativePosition} from '../uiUtils'

interface TimeZoomWrapperProps {
  x: LinearTimeScaleFn
  graphHeight: number
  graphWidth: number
  onTimeRangeChanged: (timeRange: TimeRange<DateTimeParam>) => void
  zoomOutFactor?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  boundingRect: {
    fill: hoveredColor(theme),
    fillOpacity: 0.25,
    stroke: hoveredColor(theme),
    strokeOpacity: 0.5
  }
}))

const BUTTON_LEFT = 0
const BUTTONS_LEFT = 1

const isLeftButtonDown = (event: React.MouseEvent): boolean => Boolean(event.buttons & BUTTONS_LEFT)

export const TimeZoomWrapper: React.FC<TimeZoomWrapperProps> = ({
  x,
  graphHeight,
  graphWidth,
  onTimeRangeChanged,
  zoomOutFactor = DEFAULT_ZOOM_OUT_FACTOR,
  children
}) => {
  const classes = useStyles()
  const [startX, setStartX] = useState<number>()
  const [endX, setEndX] = useState<number>()
  const onMouseDown = useCallback((event: React.MouseEvent<SVGRectElement>) => {
    if (event.button === BUTTON_LEFT) {
      const pos = getRelativePosition(event)
      setStartX(pos.x)

      event.preventDefault()
    }
  }, [])

  const onMouseLeave = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (isNumber(startX)) {
        const pos = getRelativePosition(event)
        setEndX(pos.x > startX ? graphWidth : 0)

        event.preventDefault()
      }
    },
    [graphWidth, startX]
  )

  const onZoomOutKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        const start = x.invert(0).getTime()
        const end = x.invert(graphWidth).getTime()
        const zoomed = zoom(
          {
            start,
            end
          },
          zoomOutFactor,
          Date.now()
        )
        onTimeRangeChanged(zoomed)
        event.preventDefault()
      }
    },
    [graphWidth, onTimeRangeChanged, x, zoomOutFactor]
  )

  useKey('z', onZoomOutKey, undefined, [onZoomOutKey])
  useKey('Z', onZoomOutKey, undefined, [onZoomOutKey])

  useEffect(() => {
    const onMouseUp = (event) => {
      if (event.button == BUTTON_LEFT) {
        setStartX(undefined)
        setEndX(undefined)

        if (isNumber(startX) && isNumber(endX)) {
          const startTime = x.invert(startX).getTime()
          const endTime = x.invert(endX).getTime()

          if (startTime < endTime) {
            onTimeRangeChanged({start: startTime, end: endTime})
          } else if (startTime > endTime) {
            onTimeRangeChanged({start: endTime, end: startTime})
          }
        }

        event.preventDefault()
      }
    }

    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [endX, onTimeRangeChanged, startX, x])

  const onMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (!isNumber(startX)) {
        return
      }
      if (!isLeftButtonDown(event)) {
        setStartX(undefined)
        setEndX(undefined)
      }
      const pos = getRelativePosition(event)
      setEndX(pos.x)

      event.preventDefault()
    },
    [startX]
  )

  const showSelection = isNumber(startX) && isNumber(endX)

  return (
    <g onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
      {showSelection ? (
        <rect
          x={Math.min(startX, endX)}
          y={0}
          height={graphHeight}
          width={Math.abs(endX - startX)}
          className={classes.boundingRect}
        />
      ) : null}
      <rect fill="transparent" x={0} y={0} height={graphHeight} width={graphWidth} />
      {children}
    </g>
  )
}
