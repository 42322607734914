import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core'
import classNames from 'classnames'
import {TFunction} from 'i18next'
import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getLast5Records} from '../../common/charts/chartUtils'
import {formatTimeZoneDate} from '../../common/dateUtils'
import {inNumberRange} from '../../common/math/numberRange'
import {FreeLimeSample, TargetRange} from '../../declarations'
import {useFreeLimeConfig} from '../../hooks/useFreeLimeConfig'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'
import {useTableStyles} from '../../hooks/useTableStyles'
import {DefaultBox} from '../DefaultBox'

import {formatFreeLimeValue} from './helpers'

interface LatestSamplesInfoBoxProps {
  title: string
  freeLimeSamples: FreeLimeSample[]
  showQualityParams?: boolean
  className?: string
  'data-test-id'?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  valueCell: {
    fontWeight: theme.typography.fontWeightBold
  },
  rangeExceeded: {
    color: theme.palette.error.main
  }
}))

const formatTime = (datetime: number | undefined, t: TFunction, timeZone: string): string =>
  isNumber(datetime) ? formatTimeZoneDate(datetime, timeZone, 'HH:mm') : t('common.na')

const inTargetRange = (targetRange: TargetRange, value?: number): boolean =>
  !isNumber(value) || inNumberRange(targetRange, value)

export const LatestSamplesInfoBox: React.FC<LatestSamplesInfoBoxProps> = ({
  title,
  freeLimeSamples,
  showQualityParams = false,
  className,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const targetRange = useFreeLimeConfig()
  const classes = useStyles()
  const tableClasses = useTableStyles()
  const lastSamples = getLast5Records(freeLimeSamples)

  return (
    <DefaultBox width={360} className={className} data-test-id={dataTestId}>
      <Typography
        variant="subtitle1"
        className={classes.title}
        data-test-id="latest-samples-info-box-title"
      >
        {title}
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead className={tableClasses.tableHeader}>
            <TableRow>
              <TableCell className={tableClasses.tableHeaderCell}>
                {t('latestSamplesInfoBox.table.time')}
              </TableCell>
              <TableCell align="right" className={tableClasses.tableHeaderCell}>
                {t('latestSamplesInfoBox.table.freeLime')}
              </TableCell>
              {showQualityParams && (
                <>
                  <TableCell align="right" className={tableClasses.tableHeaderCell}>
                    {t('latestSamplesInfoBox.table.alite')}
                  </TableCell>
                  <TableCell align="right" className={tableClasses.tableHeaderCell}>
                    {t('latestSamplesInfoBox.table.belite')}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {lastSamples.map((sample, idx) => (
              <TableRow key={sample?.datetime ?? idx} className={tableClasses.tableRow}>
                <TableCell
                  className={tableClasses.tableRowCell}
                  data-test-id={`latest-samples-info-box-time-cell-${idx}`}
                >
                  {formatTime(sample?.datetime, t, timeZone)}
                </TableCell>
                <TableCell
                  align="right"
                  className={classNames(
                    tableClasses.tableRowCell,
                    classes.valueCell,
                    inTargetRange(targetRange, sample?.value) ? undefined : classes.rangeExceeded
                  )}
                  data-test-id={`latest-samples-info-box-free-lime-cell-${idx}`}
                >
                  {formatFreeLimeValue(sample?.value, t)}
                </TableCell>
                {showQualityParams && (
                  <>
                    <TableCell
                      align="right"
                      className={classNames(tableClasses.tableRowCell, classes.valueCell)}
                      data-test-id={`latest-samples-info-box-alite-cell-${idx}`}
                    >
                      {formatFreeLimeValue(sample?.alite, t, 1)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(tableClasses.tableRowCell, classes.valueCell)}
                      data-test-id={`latest-samples-info-box-belite-cell-${idx}`}
                    >
                      {formatFreeLimeValue(sample?.belite, t, 1)}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DefaultBox>
  )
}
