import {
  Axis,
  Graph,
  GraphRenderProps,
  HorizontalGrid,
  LinearScaleFn,
  VerticalGrid,
  XAxisLabel,
  YAxisLabel
} from '@hconnect/uikit'
import {makeStyles, Theme} from '@material-ui/core'
import * as d3 from 'd3'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {getStrengthLevelInDays, toStatsRecord} from '../../common/cementStrength'
import {
  CEMENT_STRENGTH_ACCURACY_OFFSET,
  CEMENT_STRENGTH_DOMAIN_MARGIN,
  COLOR_GRID_LINES,
  COLOR_TREND_AXIS,
  COLOR_TREND_BORDER,
  SUGGESTED_TRENDS_TICK_COUNT
} from '../../common/charts/chartConfigurations'
import {getStrengthScaleDomain} from '../../common/charts/chartUtils'
import {CementStrengthSample, StrengthLevel} from '../../declarations'
import {useCementStrengthStatsRecords} from '../../hooks/useCementStrengthStatsRecords'
import {useCsColorFn} from '../../hooks/useColorFn'
import {AccuracyTargetLines} from '../charts/AccuracyTargetLines'
import {ClampedPoint} from '../charts/ClampedPoint'
import {GraphBorder} from '../charts/GraphBorder'

import {AccuracyPoint} from './AccuracyPoint'
import {MaterialDetailsChartContentProps} from './types'

interface CementStrengthAccuracyDetailsGraphContentProps extends MaterialDetailsChartContentProps {
  graphWidth: number
  graphHeight: number
  Clipped: React.FC
}

const useStyles = makeStyles((theme: Theme) => ({
  selectedSample: {
    fill: theme.palette.primary.main,
    fillOpacity: 0.75
  }
}))

const SelectedSample: React.FC<{
  selectedSample: CementStrengthSample
  strengthLevel: StrengthLevel
  x: LinearScaleFn
  y: LinearScaleFn
  graphWidth: number
  graphHeight: number
}> = ({selectedSample, strengthLevel, x, y, graphHeight, graphWidth}) => {
  const classes = useStyles()
  const getColor = useCsColorFn()
  const statsRecord = toStatsRecord(selectedSample, strengthLevel)
  return (
    <>
      {statsRecord && (
        <ClampedPoint
          data-test-id={`cs-acc-clamped-point-${statsRecord.id}`}
          posX={x(statsRecord.actual)}
          posY={y(statsRecord.predicted)}
          color={getColor(strengthLevel)}
          maxX={graphWidth}
          maxY={graphHeight}
          render={({x: clampedX, y: clampedY}) => (
            <AccuracyPoint
              data-test-id={`cs-acc-point-${statsRecord.id}`}
              posX={clampedX}
              posY={clampedY}
              outerRadius={12}
              innerColor={getColor(strengthLevel)}
              outerClassName={classes.selectedSample}
            />
          )}
        />
      )}
    </>
  )
}

const CementStrengthAccuracyDetailsGraphContent: React.FC<
  CementStrengthAccuracyDetailsGraphContentProps
> = ({
  materialData,
  materialLevel,
  timeRange,
  graphHeight,
  graphWidth,
  Clipped,
  selectedSample,
  onSelectSample
}) => {
  const {t} = useTranslation()
  const getColor = useCsColorFn()
  const records = useCementStrengthStatsRecords(materialData.samples, timeRange)
  const domain = getStrengthScaleDomain(
    materialLevel.min,
    materialLevel.max,
    CEMENT_STRENGTH_DOMAIN_MARGIN
  )
  const x = d3.scaleLinear().domain(domain).range([0, graphWidth])
  const xTicks = x.ticks(SUGGESTED_TRENDS_TICK_COUNT)
  const y = d3.scaleLinear().domain(domain).range([graphHeight, 0])
  const yTicks = y.ticks(SUGGESTED_TRENDS_TICK_COUNT)

  return (
    <>
      <HorizontalGrid scale={y} color={COLOR_GRID_LINES} width={graphWidth} tickValues={yTicks} />
      <VerticalGrid scale={x} color={COLOR_GRID_LINES} height={graphHeight} tickValues={xTicks} />
      <Clipped>
        <AccuracyTargetLines
          height={graphHeight}
          width={graphWidth}
          rangeOffset={y(0) - y(CEMENT_STRENGTH_ACCURACY_OFFSET)}
          targetPos={{x: x(materialLevel.target), y: y(materialLevel.target)}}
        />
      </Clipped>
      <GraphBorder graphHeight={graphHeight} graphWidth={graphWidth} color={COLOR_TREND_BORDER} />
      <Axis scale={y} position="left" tickValues={yTicks} color={COLOR_TREND_AXIS} />
      <YAxisLabel
        height={graphHeight}
        label={t('chart.axisCementStrengthLabelPredicted', {
          count: getStrengthLevelInDays(materialLevel.name)
        })}
        labelColor={COLOR_TREND_AXIS}
      />
      <Axis
        scale={x}
        position="bottom"
        tickValues={xTicks}
        posY={graphHeight}
        color={COLOR_TREND_AXIS}
      />
      <XAxisLabel
        height={graphHeight}
        width={graphWidth}
        label={t('chart.axisCementStrengthLabelActual', {
          count: getStrengthLevelInDays(materialLevel.name)
        })}
        labelColor={COLOR_TREND_AXIS}
        yOffset={40}
      />

      {records
        .filter((sr) => sr.id !== selectedSample?.id)
        .map((sr) => (
          <ClampedPoint
            data-test-id={`cs-acc-clamped-point-${sr.id}`}
            key={sr.id}
            posX={x(sr.actual)}
            posY={y(sr.predicted)}
            maxX={graphWidth}
            maxY={graphHeight}
            color={getColor(materialLevel.name)}
            render={({x: clampedX, y: clampedY}) => (
              <AccuracyPoint
                data-test-id={`cs-acc-point-${sr.id}`}
                posX={clampedX}
                posY={clampedY}
                outerRadius={8}
                innerColor={getColor(materialLevel.name)}
                onClick={() => {
                  onSelectSample(sr.id)
                }}
              />
            )}
          />
        ))}
      {selectedSample && (
        <SelectedSample
          x={x}
          y={y}
          selectedSample={selectedSample}
          strengthLevel={materialLevel.name}
          graphHeight={graphHeight}
          graphWidth={graphWidth}
        />
      )}
    </>
  )
}

const margin = {left: 56, top: 32, bottom: 64, right: 32} as const

export const CementStrengthAccuracyDetailsGraph: React.FC<MaterialDetailsChartContentProps> = ({
  materialData,
  materialLevel,
  timeRange,
  selectedSample,
  onSelectSample
}) => {
  const render = useCallback(
    ({graphWidth, graphHeight, Clipped}: GraphRenderProps) => (
      <CementStrengthAccuracyDetailsGraphContent
        graphWidth={graphWidth}
        graphHeight={graphHeight}
        Clipped={Clipped}
        timeRange={timeRange}
        materialLevel={materialLevel}
        materialData={materialData}
        onSelectSample={onSelectSample}
        selectedSample={selectedSample}
      />
    ),
    [materialData, materialLevel, onSelectSample, selectedSample, timeRange]
  )

  return (
    <Graph
      margin={margin}
      render={render}
      graphId={`${materialData.materialId}`}
      data-test-id={`cement-strength-details-graph-${materialData.materialId}-accuracy`}
    />
  )
}
