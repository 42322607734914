import {Box, makeStyles, Typography} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatTimeZoneDate} from '../../common/dateUtils'
import {AsyncContainer} from '../../container/AsyncContainer'
import {AggregatedMaterialData, Material, TimeRange} from '../../declarations'
import {useAggregatedCemStrengthData} from '../../hooks/useAggregatedCemStrengthData'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useMaterialLevel} from '../../hooks/useMaterialLevel'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'
import {useSamplesInTimeRangeAndView} from '../../hooks/useSamplesInTimeRangeAndView'
import {ChartInfoText} from '../ChartInfoText'
import {DefaultBox} from '../DefaultBox'
import {ErrorMessage} from '../ErrorMessage'

import {MaterialChartContent} from './MaterialChartContent'

interface MaterialChartProps {
  material: Material
  timeRange: TimeRange
}

interface MaterialChartContentWrapperProps {
  material: Material
  timeRange: TimeRange
  data?: AggregatedMaterialData
  isError: boolean
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  gridHeader: {
    display: 'flex',
    height: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  chartTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: -4
  }
}))

const MaterialChartContentWrapper: React.FC<MaterialChartContentWrapperProps> = ({
  material,
  timeRange,
  data,
  isError,
  isLoading
}) => {
  const {t} = useTranslation()
  const {strength: strengthLevel} = useCsParamsWithDefaults()
  const materialLevel = useMaterialLevel(material.materialId, strengthLevel)

  if (!materialLevel) {
    return <ErrorMessage height="100%">{t('errors.noMaterialLevel')}</ErrorMessage>
  }

  return (
    <AsyncContainer
      isLoading={isLoading}
      isError={isError || !data}
      isSuccess={Boolean(data)}
      errorMessage={<ErrorMessage height="100%">{t('errors.noDataAvailable')}</ErrorMessage>}
    >
      {data && (
        <MaterialChartContent
          material={material}
          materialLevel={materialLevel}
          timeRange={timeRange}
          data={data}
        />
      )}
    </AsyncContainer>
  )
}

const MATERIAL_CHART_BOX_HEIGHT = 340

export const MaterialChart: React.FC<MaterialChartProps> = ({material, timeRange}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const {predictionView} = useCsParamsWithDefaults()
  const isTimeView = predictionView === 'time'
  const {materialId} = material
  const {data, isLoading, isError} = useAggregatedCemStrengthData(materialId)
  const visibleSamples = useSamplesInTimeRangeAndView(data?.samples ?? [])
  const lastSample = visibleSamples[visibleSamples.length - 1]

  return (
    <DefaultBox height={isTimeView ? MATERIAL_CHART_BOX_HEIGHT : undefined}>
      <div className={classes.gridContainer}>
        <Box height={32}>
          <div className={classes.gridHeader}>
            <Typography variant="h6" className={classes.chartTitle}>
              {material.name}
            </Typography>
            {isTimeView && lastSample && (
              <ChartInfoText data-test-id="material-chart-latest-sample-box">
                {t('chart.latestSampleLabel')}
                <br />
                {formatTimeZoneDate(
                  new Date(lastSample.datetime),
                  timeZone,
                  t('chart.latestSampleDate')
                )}
              </ChartInfoText>
            )}
          </div>
        </Box>
        <MaterialChartContentWrapper
          material={material}
          timeRange={timeRange}
          data={data}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
    </DefaultBox>
  )
}
