import {useMemo} from 'react'

import {calcPagination, calcSimpleTableData, getTablePage} from '../common/tablePageUtils'
import {
  PaginatedTableData,
  SensorMetaData,
  TableFilterData,
  TableSort,
  UrlQueryKey
} from '../declarations'

import {useSetQueryParam} from './useSetQueryParam'

export const useSensorSettingsTableData = <T extends SensorMetaData>(
  metaData: T[],
  pageNo: number,
  rowsPerPage: number,
  tablefilters: TableFilterData<keyof T>[],
  tableSort: TableSort<keyof T> | undefined,
  rowsPerPageParamKey: UrlQueryKey,
  pageNoParamKey: UrlQueryKey
): PaginatedTableData<T> => {
  const setRowsPerPage = useSetQueryParam<number>(rowsPerPageParamKey)
  const setPage = useSetQueryParam<number>(pageNoParamKey)

  return useMemo(() => {
    const tableData = calcSimpleTableData<T>(
      metaData,
      tablefilters,
      tableSort ?? {
        field: 'uniformTag',
        order: 'asc'
      }
    )

    const pagination = calcPagination({
      rowCount: tableData.length,
      rowsPerPage,
      page: pageNo,
      setPage,
      setRowsPerPage
    })

    return {
      ...pagination,
      pageData: getTablePage(tableData, pagination.pageNumber, pagination.rowsPerPage)
    }
  }, [metaData, setPage, setRowsPerPage, tablefilters, pageNo, rowsPerPage, tableSort])
}
