import {clamp} from 'lodash'
import React from 'react'

import {calcTransformMatrix} from '../../common/charts/chartUtils'

interface ClampedPos {
  x: number
  y: number
}

interface ClampedPointProps {
  posX: number
  posY: number
  maxX: number
  maxY: number
  color: string
  render: ({x, y}: ClampedPos) => React.ReactNode
  'data-test-id'?: string
}

const calcTransform = ({
  posX,
  posY,
  clampedX,
  clampedY
}: {
  posX: number
  posY: number
  clampedX: number
  clampedY: number
}): {isClamped: boolean; transformMatrix: string} => {
  const dirX = posX - clampedX
  const dirY = posY - clampedY
  const isClamped = dirX * dirX + dirY * dirY > 0
  return {
    isClamped,
    transformMatrix: calcTransformMatrix({posX: clampedX, posY: clampedY, dirX, dirY})
  }
}

export const ClampedPoint: React.FC<ClampedPointProps> = ({
  posX,
  posY,
  maxX,
  maxY,
  render,
  color,
  'data-test-id': dataTestId
}) => {
  const clampedX = clamp(posX, 0, maxX)
  const clampedY = clamp(posY, 0, maxY)
  const {isClamped, transformMatrix} = calcTransform({posX, posY, clampedX, clampedY})

  return (
    <g data-test-id={dataTestId ? `${dataTestId}${isClamped ? '-clamped' : ''}` : undefined}>
      {isClamped ? (
        <polygon transform={transformMatrix} points="11,-4 18,0 11,4" fill={color} />
      ) : null}
      {render({x: clampedX, y: clampedY})}
    </g>
  )
}
