import type {History} from 'history'
import {isArray, isString, toLower} from 'lodash'

import type {
  CsTableSampleFilterData,
  CsTableSampleSort,
  DateTimeParam,
  PredictionView,
  ProcessMetaDataKey,
  SensorMetaData,
  SortOrder,
  TableFilterData,
  TableSort,
  UrlQueryKey,
  UrlQueryParams
} from '../../declarations'
import {getStrengthLevelFromString} from '../cementStrength'
import {SETTINGS_VIEWS} from '../constants'
import {intToParam, toInt} from '../helpers'
import {getValidStorageProvider} from '../storageProvider'

const ARRAY_PARAMETER_SEPARATION_CHAR = ','
const EMPTY_ARRAY_PARAM = 'empty'

export const arrayFromParameter = (urlParamValue?: string): string[] | undefined => {
  if (urlParamValue === EMPTY_ARRAY_PARAM) {
    return []
  }
  if (urlParamValue) {
    return urlParamValue.split(ARRAY_PARAMETER_SEPARATION_CHAR).filter(Boolean)
  }
}

export const arrayToParameter = <T extends string = string>(selected: T[]): string =>
  selected.join(ARRAY_PARAMETER_SEPARATION_CHAR) || EMPTY_ARRAY_PARAM

const getValue = (urlParams: URLSearchParams, key: UrlQueryKey): string | undefined =>
  urlParams.get(key) ?? undefined

const getIntValue = (urlParams: URLSearchParams, key: UrlQueryKey): number | undefined =>
  toInt(getValue(urlParams, key) ?? '')

const getTypeFromString = <T extends string>(types: T[], param?: string): T | undefined => {
  if (param) {
    const strValue = toLower(param)
    return types.find((t) => toLower(t) === strValue)
  }
}

const PREDICTION_VIEWS: PredictionView[] = ['time', 'accuracy', 'table']

const getDateTimeParam = (strVal?: string): DateTimeParam | undefined => {
  if (isString(strVal) && strVal.trim().length > 0) {
    const num = +strVal
    return isNaN(num) ? strVal : num
  }
  return strVal
}

export const objectToParam = <T>(obj: T | undefined): string | undefined =>
  obj && JSON.stringify(obj)

const isTableSort = <T extends string>(value: unknown): value is TableSort<T> => {
  if (value) {
    const sortObj = value as TableSort<T>
    const orders: SortOrder[] = ['asc', 'desc']
    return isString(sortObj.field) && orders.includes(sortObj.order)
  }
  return false
}

const isTableFilters = <T extends string>(value: unknown): value is TableFilterData<T>[] => {
  if (isArray(value)) {
    const arr = value as TableFilterData<T>[]
    return arr.every(
      (filterObj) =>
        Boolean(filterObj) && isString(filterObj.field) && isString(filterObj.filterExpression)
    )
  }
  return false
}

const objFromString = <T>(
  param: string | undefined,
  isT: (value: unknown) => value is T
): T | undefined => {
  if (isString(param)) {
    try {
      const parsed = JSON.parse(param)
      if (isT(parsed)) {
        return parsed
      }
    } catch (e: unknown) {
      return undefined
    }
  }
}

export const searchStringToUrlQueryParams = (search: string): UrlQueryParams => {
  const urlParams = new URLSearchParams(search)
  return {
    strength: getStrengthLevelFromString(getValue(urlParams, 'strength')),
    sampleId: getIntValue(urlParams, 'sampleId'),
    selectedSources: arrayFromParameter(getValue(urlParams, 'selectedSources')),
    predictionView: getTypeFromString(PREDICTION_VIEWS, getValue(urlParams, 'predictionView')),
    csStart: getDateTimeParam(getValue(urlParams, 'csStart')),
    csEnd: getDateTimeParam(getValue(urlParams, 'csEnd')),
    csSelectedTagIds: arrayFromParameter(getValue(urlParams, 'csSelectedTagIds')),
    selectedKilnId: getValue(urlParams, 'selectedKilnId'),
    flStart: getDateTimeParam(getValue(urlParams, 'flStart')),
    flEnd: getDateTimeParam(getValue(urlParams, 'flEnd')),
    flSelectedTagIds: arrayFromParameter(getValue(urlParams, 'flSelectedTagIds')),
    csTableRowsPerPage: getIntValue(urlParams, 'csTableRowsPerPage'),
    csTablePageNo: getIntValue(urlParams, 'csTablePageNo'),
    csTableSort: objFromString<CsTableSampleSort>(getValue(urlParams, 'csTableSort'), isTableSort),
    csTableFilters: objFromString<CsTableSampleFilterData[]>(
      getValue(urlParams, 'csTableFilters'),
      isTableFilters
    ),
    settingsView: getTypeFromString(SETTINGS_VIEWS, getValue(urlParams, 'settingsView')),
    settingsSensorsTablePageNo: getIntValue(urlParams, 'settingsSensorsTablePageNo'),
    settingsSensorsTableRowsPerPage: getIntValue(urlParams, 'settingsSensorsTableRowsPerPage'),
    settingsSensorsTableSort: objFromString<TableSort<ProcessMetaDataKey>>(
      getValue(urlParams, 'settingsSensorsTableSort'),
      isTableSort
    ),
    settingsSensorsTableFilters: objFromString<TableFilterData<ProcessMetaDataKey>[]>(
      getValue(urlParams, 'settingsSensorsTableFilters'),
      isTableFilters
    ),
    adminSensorsTablePageNo: getIntValue(urlParams, 'adminSensorsTablePageNo'),
    adminSensorsTableRowsPerPage: getIntValue(urlParams, 'adminSensorsTableRowsPerPage'),
    adminSensorsTableSort: objFromString<TableSort<keyof SensorMetaData>>(
      getValue(urlParams, 'adminSensorsTableSort'),
      isTableSort
    ),
    adminSensorsTableFilters: objFromString<TableFilterData<keyof SensorMetaData>[]>(
      getValue(urlParams, 'adminSensorsTableFilters'),
      isTableFilters
    ),
    storageProvider: getValidStorageProvider(getValue(urlParams, 'storageProvider')),
    warmStorageProvider: getValidStorageProvider(getValue(urlParams, 'warmStorageProvider')),
    coldStorageProvider: getValidStorageProvider(getValue(urlParams, 'coldStorageProvider'))
  }
}

export const queryParamsToString = (params: UrlQueryParams = {}): string => {
  const toStrObj: Record<keyof UrlQueryParams, string | undefined> = {
    strength: params.strength,
    sampleId: intToParam(params.sampleId),
    selectedSources: params.selectedSources ? arrayToParameter(params.selectedSources) : undefined,
    predictionView: params.predictionView,
    csStart: params.csStart?.toString(),
    csEnd: params.csEnd?.toString(),
    csSelectedTagIds: params.csSelectedTagIds
      ? arrayToParameter(params.csSelectedTagIds)
      : undefined,
    selectedKilnId: params.selectedKilnId,
    flStart: params.flStart?.toString(),
    flEnd: params.flEnd?.toString(),
    flSelectedTagIds: params.flSelectedTagIds
      ? arrayToParameter(params.flSelectedTagIds)
      : undefined,
    csTableRowsPerPage: intToParam(params.csTableRowsPerPage),
    csTablePageNo: intToParam(params.csTablePageNo),
    csTableSort: objectToParam(params.csTableSort),
    csTableFilters: objectToParam(params.csTableFilters),
    settingsView: params.settingsView,
    settingsSensorsTablePageNo: intToParam(params.settingsSensorsTablePageNo),
    settingsSensorsTableRowsPerPage: intToParam(params.settingsSensorsTableRowsPerPage),
    settingsSensorsTableSort: objectToParam(params.settingsSensorsTableSort),
    settingsSensorsTableFilters: objectToParam(params.settingsSensorsTableFilters),
    adminSensorsTablePageNo: intToParam(params.adminSensorsTablePageNo),
    adminSensorsTableRowsPerPage: intToParam(params.adminSensorsTableRowsPerPage),
    adminSensorsTableSort: objectToParam(params.adminSensorsTableSort),
    adminSensorsTableFilters: objectToParam(params.adminSensorsTableFilters),
    storageProvider: params.storageProvider,
    warmStorageProvider: params.warmStorageProvider,
    coldStorageProvider: params.warmStorageProvider
  }
  const urlParams = new URLSearchParams()
  Object.entries(toStrObj)
    .filter(([, value]): boolean => Boolean(value))
    .forEach(([key, value]) => urlParams.set(key, value as string))
  return urlParams.toString()
}

export const setParam = (search: string, key: UrlQueryKey, value?: string): string => {
  const urlParams = new URLSearchParams(search)
  if (value) {
    urlParams.set(key, value)
  } else {
    urlParams.delete(key)
  }
  return urlParams.toString()
}

export const setQueryParam = (history: History, key: UrlQueryKey, value?: string) => {
  history.push({search: setParam(history.location.search, key, value)})
}
