import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuProps,
  Select
} from '@material-ui/core'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {CsSampleType} from '../../declarations'

import type {HasDisabled} from './types'

interface CsSampleTypesSelectProps extends HasDisabled {
  selectable: CsSampleType[]
  onSelected: (values: CsSampleType[]) => void
  selected: CsSampleType[]
}

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 0,
    marginBottom: 8,
    width: 160,
    maxWidth: '100%'
  }
}))

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  PaperProps: {
    id: 'cs-sample-type-select-paper-id'
  }
}

export const CsSampleTypesSelect: React.FC<CsSampleTypesSelectProps> = ({
  selectable,
  onSelected,
  selected,
  disabled = false
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const onSelectionChanged = useCallback(
    (event: React.ChangeEvent<{value: unknown}>) => {
      onSelected(event.target.value as CsSampleType[])
    },
    [onSelected]
  )

  const renderValue = useCallback(
    (selected) => {
      const selectedValues = selected as CsSampleType[]
      if (isEmpty(selectedValues)) {
        return ''
      }
      return selectable
        .filter((sampleType) => selectedValues.includes(sampleType))
        .map((sampleType) => t(`sampleType.${sampleType}`))
        .join(', ')
    },
    [selectable, t]
  )

  return (
    <FormControl className={classes.formContainer} variant="standard">
      <InputLabel id="cs-sample-type-source-select-label">
        {t('csSampleTypeSourcesSelect.label')}
      </InputLabel>
      <Select
        labelId="cs-sample-type-source-select-label"
        id="cs-sample-type-source-select-id"
        data-test-id="cs-sample-type-source-select-test-id"
        multiple
        value={selected}
        onChange={onSelectionChanged}
        renderValue={renderValue}
        MenuProps={menuProps}
        disabled={disabled}
      >
        {selectable.map((sampleType) => (
          <MenuItem key={sampleType} value={sampleType}>
            <Checkbox
              data-test-id={`cs-sample-source-select-cb-${sampleType}`}
              checked={selected.includes(sampleType)}
            />
            <ListItemText primary={t(`sampleType.${sampleType}`)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
