import {AxiosError} from 'axios'

import {ClientConfig} from '../../types'

export const createErrorInterceptor = (clientConfig: Partial<ClientConfig>) => async (
  error: AxiosError
): Promise<AxiosError> => {
  const {onResponseError} = clientConfig

  if (onResponseError) {
    onResponseError(error)
  }

  return Promise.reject(error)
}
