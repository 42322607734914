import type {DownloadPollingStatus, TimeRange} from '../../declarations'
import {wait} from '../wait'

import {
  pollDownloadCementStrengthPredictionsExcel,
  startDownloadCementStrengthPredictionsExcel
} from './excelExport'

const DEFAULT_POLLING_INTERVAL_MS = 500
const DEFAULT_TIMEOUT_LIMIT_MS = 5 * 60 * 1000

export const download = async (
  downloadFunction: () => Promise<string>,
  pollingFunc: (location: string, filename: string) => Promise<DownloadPollingStatus>,
  filename: string,
  pollingIntervalMs = DEFAULT_POLLING_INTERVAL_MS,
  timeoutLimitMs = DEFAULT_TIMEOUT_LIMIT_MS,
  startTimestamp = Date.now()
): Promise<void> => {
  let location = await downloadFunction()
  while (Date.now() - startTimestamp < timeoutLimitMs) {
    const status = await pollingFunc(location, filename)
    if (status.finished) {
      return
    }
    location = status.location
    await wait(pollingIntervalMs)
  }

  throw new Error('Download ran into timeout!')
}

export const downloadCementStrengthExcel = async (
  plantId: string,
  timeRange: TimeRange,
  filename: string,
  startTimestamp = Date.now()
): Promise<void> =>
  download(
    () => startDownloadCementStrengthPredictionsExcel(plantId, timeRange),
    pollDownloadCementStrengthPredictionsExcel,
    filename,
    DEFAULT_POLLING_INTERVAL_MS,
    DEFAULT_TIMEOUT_LIMIT_MS,
    startTimestamp
  )
