import {isNumber} from 'lodash'
import moment from 'moment-timezone'

import {DateTimeParam, TimeRange} from '../declarations'

import {DURATION_PREFIX, NOW_PARAM} from './constants'
import {
  dateTimeParamToAbsDate,
  extractDurationFromRelativeDateTimeParam,
  isDateTimeParam
} from './dateUtils'

export const inTimeRange = (timeRange: TimeRange, timestamp: number): boolean =>
  timeRange.start.getTime() <= timestamp && timestamp <= timeRange.end.getTime()

export const isValidTimeRange = (timeRange: TimeRange): boolean =>
  timeRange.start.getTime() < timeRange.end.getTime()

const calcTimeMarginMs = (timeRange: TimeRange, timeMarginFactor: number): number =>
  Math.floor(timeMarginFactor * (timeRange.end.getTime() - timeRange.start.getTime()))

const calcTimeRangeDuration = (
  timeRange: TimeRange<DateTimeParam>,
  now = new Date()
): moment.Duration => {
  const {start, end} = timeRange
  const startTime = dateTimeParamToAbsDate(start, now).getTime()
  const endTime = dateTimeParamToAbsDate(end, now).getTime()

  return moment.duration(endTime - startTime)
}

export const zoom = (
  timeRange: TimeRange<DateTimeParam>,
  factor: number,
  maxEnd?: DateTimeParam,
  now = new Date()
): TimeRange<DateTimeParam> => {
  // Calculate time margin
  const dur = calcTimeRangeDuration(timeRange, now)
  const zoomSingleEnd = [timeRange.start, timeRange.end].includes(NOW_PARAM)
  const timeMargin = moment.duration(
    dur.asMilliseconds() * (zoomSingleEnd ? 1 : 0.5) * (factor - 1)
  )

  // Calculate new zoomed start
  let zoomedStart: DateTimeParam
  if (isNumber(timeRange.start)) {
    zoomedStart = Math.floor(timeRange.start - timeMargin.asMilliseconds())
  } else {
    // we add in relative DateTimeParam as we are increasing the gap
    zoomedStart =
      timeRange.start === NOW_PARAM
        ? NOW_PARAM
        : `${DURATION_PREFIX}${extractDurationFromRelativeDateTimeParam(timeRange.start)
            .add(timeMargin)
            .toISOString()}`
  }

  // Calculate new zoomed end
  let zoomedEnd: DateTimeParam
  if (isNumber(timeRange.end)) {
    zoomedEnd = Math.ceil(timeRange.end + timeMargin.asMilliseconds())
  } else {
    zoomedEnd =
      timeRange.end === NOW_PARAM
        ? NOW_PARAM
        : `${DURATION_PREFIX}${extractDurationFromRelativeDateTimeParam(timeRange.end)
            .subtract(timeMargin)
            .toISOString()}`
  }

  // Determine MaxEnd DateTimeParam
  // If the user willingly has set the range end after the max end, Choose user range end
  const userMaxEnd = maxEnd ? maxDateTimeParam(timeRange.end, maxEnd, now) : undefined
  // return the max end if the new end is larger
  const limitedEnd = userMaxEnd ? minDateTimeParam(zoomedEnd, userMaxEnd, now) : zoomedEnd

  return {
    start: zoomedStart,
    end: limitedEnd
  }
}

export const padTimeRange = (timeRange: TimeRange, timeMarginFactor: number): TimeRange => {
  const timeMargin = calcTimeMarginMs(timeRange, timeMarginFactor)
  return {
    start: new Date(timeRange.start.getTime() - timeMargin),
    end: new Date(timeRange.end.getTime() + timeMargin)
  }
}

export const timeRangeFromStartEnd = (
  start: DateTimeParam,
  end: DateTimeParam,
  now = new Date()
): TimeRange => ({
  start: dateTimeParamToAbsDate(start, now),
  end: dateTimeParamToAbsDate(end, now)
})

export const timeRangeFromDateTimeParams = (
  {start, end}: TimeRange<DateTimeParam>,
  now = new Date()
): TimeRange => timeRangeFromStartEnd(start, end, now)

export const isValidDateTimeParamsTimeRange = (
  timeRange: TimeRange<DateTimeParam>,
  now = new Date()
): boolean => isValidTimeRange(timeRangeFromDateTimeParams(timeRange, now))

export const minDateTimeParam = (
  a: DateTimeParam,
  b: DateTimeParam,
  now = new Date()
): DateTimeParam => {
  const aDate = dateTimeParamToAbsDate(a, now)
  const bDate = dateTimeParamToAbsDate(b, now)
  return aDate < bDate ? a : b
}

export const maxDateTimeParam = (
  a: DateTimeParam,
  b: DateTimeParam,
  now = new Date()
): DateTimeParam => {
  const aDate = dateTimeParamToAbsDate(a, now)
  const bDate = dateTimeParamToAbsDate(b, now)
  return aDate < bDate ? b : a
}

export const mergeDateTimeParamRanges = (
  timeRangeA: TimeRange<DateTimeParam>,
  timeRangeB: TimeRange<DateTimeParam>,
  now = new Date()
): TimeRange<DateTimeParam> => ({
  start: minDateTimeParam(timeRangeA.start, timeRangeB.start, now),
  end: maxDateTimeParam(timeRangeA.end, timeRangeB.end, now)
})

export const isDateTimeParamRange = (param: unknown): param is TimeRange<DateTimeParam> =>
  Boolean(param) &&
  isDateTimeParam((param as TimeRange<DateTimeParam>).start) &&
  isDateTimeParam((param as TimeRange<DateTimeParam>).end)
