import {Permission} from '@hconnect/apiclient'

import {allApps} from './config/'
import plants from './plants.json'
import {
  AppConfig,
  AppPlusPlant,
  Option,
  PlantId,
  PlantJsonItem,
  PLANT_ID,
  PLANT_NAME,
  PLANT_SHORT_NAME,
  WhiteList
} from './types'

export type Env = 'prod' | 'qa' | 'uat'

export function addToWhiteList(
  env: Env,
  whiteList: WhiteList,
  appConfig: AppConfig,
  plantId: PlantId
) {
  const entry = plants[plantId] as PlantJsonItem | undefined
  const plantName = entry?.name || plantId
  const shortName = entry?.shortName || plantId
  const appName = appConfig.name

  const url = appConfig.url[env]
    .replace(PLANT_ID, plantId)
    .replace(PLANT_NAME, plantName)
    .replace(PLANT_SHORT_NAME, shortName)

  const option: Option = {
    appName,
    plantId,
    plantName,
    url
  }

  if (!whiteList.optionsPerApp[appName]) {
    whiteList.optionsPerApp[appName] = [option]
  } else if (
    !whiteList.optionsPerApp[appName]?.find((current: Option) => current.plantId === plantId)
  ) {
    whiteList.optionsPerApp[appName]?.push(option)
  }

  if (whiteList.optionsPerPlant[plantId] === undefined) {
    whiteList.optionsPerPlant[plantId] = [option]
  } else if (
    !whiteList.optionsPerPlant[plantId].find((current: Option) => current.appName === appName)
  ) {
    whiteList.optionsPerPlant[plantId].push(option)
  }

  whiteList.optionsMap.set(appName + plantId, option)
}

export const whiteListByPermission = (
  env: Env = 'prod',
  usersPermissions: Permission[]
): WhiteList => {
  const whiteList: WhiteList = {
    optionsPerApp: {},
    optionsPerPlant: {},
    optionsMap: new Map<AppPlusPlant, Option>()
  }

  // to maintain the same order of the apps, we iterate over 'allApps' first and then usersPermissions
  allApps.forEach((appConfig) => {
    usersPermissions.forEach((usersPermission) => {
      let dataScope

      if (!appConfig.whiteListedPermissionTypes.includes(usersPermission.permissionType)) {
        return
      }

      try {
        dataScope = dataScope || JSON.parse(usersPermission.dataScope)
      } catch (e) {
        return
      }

      const plantId = dataScope && dataScope['plantId']

      if (!plantId || typeof plantId !== 'string') {
        return
      }

      if (!plants[plantId]) {
        if (!whiteList.missedPlantIds) {
          whiteList.missedPlantIds = [plantId]
        } else if (!whiteList.missedPlantIds.includes(plantId)) {
          whiteList.missedPlantIds.push(plantId)
        }
      }

      if (appConfig.plant?.whiteList && !appConfig.plant?.whiteList.includes(plantId)) {
        return
      }

      if (appConfig.plant?.blackList && appConfig.plant?.blackList.includes(plantId)) {
        return
      }

      addToWhiteList(env, whiteList, appConfig, plantId)
    })
  })

  return whiteList
}

export function getPlantName(plantId: PlantId) {
  return (plants[plantId] as PlantJsonItem)?.name || plantId
}
