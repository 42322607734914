import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'

import type {PlantSpecificProcessMetric, ProcessMetaData} from '../../declarations'
import {
  ProcessData,
  ProcessMetric,
  ProcessMetricsMetadata,
  ProcessSensorData,
  StorageProvider,
  TimeRange
} from '../../declarations'
import {PROCESS_METRICS_MAX_POINTS_RESOLUTION} from '../constants'
import {logger} from '../logger'

import {client} from './client'
import {toTagsParameter} from './parameterUtils'

export const convertProcessData = (processMetrics: ProcessMetric[]): ProcessData[] =>
  processMetrics.map((dto) => ({
    ...mapProcessMetricsMetadata(dto),
    records:
      dto.datapoints?.map(([value, datetime]) => ({
        // convert to milliseconds
        datetime: datetime * 1000,
        value
      })) ?? []
  }))

const mapProcessMetricsMetadata = ({
  plantSpecificTag = '',
  uniformTag = '',
  unit = '',
  description = '',
  displayName = '',
  category = ''
}: ProcessMetricsMetadata): ProcessMetaData => ({
  plantSpecificTag,
  uniformTag,
  unit,
  description: description.trim(),
  displayName: (displayName || description).trim(),
  category: category.trim()
})

export const convertProcessMetricsMetadata = (
  metadataDtos: ProcessMetricsMetadata[]
): ProcessMetaData[] => metadataDtos.map(mapProcessMetricsMetadata)

export const getProcessMetricsMetaData = async (plantId: string): Promise<ProcessMetaData[]> => {
  const response = await client.dfApi.get<ProcessMetricsMetadata[]>(
    `/predict/plants/${plantId}/processMetrics`
  )
  return convertProcessMetricsMetadata(response.data ?? [])
}

export const getProcessMetricsMetadataForFreeLime = async (
  plantId: string
): Promise<ProcessMetaData[]> => {
  const response = await client.dfApi.get<ProcessMetricsMetadata[]>(
    `/predict/plants/${plantId}/processMetrics/view/freelime`
  )
  return convertProcessMetricsMetadata(response.data ?? [])
}

const toParameterIfDefined = (parameterName: string, parameter?: string): string =>
  parameter ? `&${parameterName}=${parameter}` : ''

export const getProcessSensorData = async (
  plantId: string,
  tagIds: string[],
  range: TimeRange,
  storageProvider?: StorageProvider,
  warmStorageProvider?: StorageProvider,
  coldStorageProvider?: StorageProvider
): Promise<ProcessSensorData> => {
  if (isEmpty(tagIds)) {
    return {processData: [], range}
  }
  const response = await client.dfApi.get<ProcessMetric[]>(
    `/predict/plants/${plantId}/processMetrics/data`.concat(
      `?from=${range.start.toJSON()}`,
      `&until=${range.end.toJSON()}`,
      `&${toTagsParameter(tagIds)}`,
      `&maxPoints=${PROCESS_METRICS_MAX_POINTS_RESOLUTION}`,
      toParameterIfDefined('storageProvider', storageProvider),
      toParameterIfDefined('warmStorageProvider', warmStorageProvider),
      toParameterIfDefined('coldStorageProvider', coldStorageProvider)
    )
  )
  return {processData: convertProcessData(response.data ?? []), range}
}

export const updateProcessMetaData = async (
  plantId: string,
  metaData: ProcessMetaData
): Promise<void> => {
  const log = logger.context('updateProcessMetaData', plantId, JSON.stringify(metaData))
  try {
    const payload: PlantSpecificProcessMetric = {
      plantId,
      plantTag: metaData.plantSpecificTag,
      uniformTag: metaData.uniformTag,
      uniformProcessMetric: {
        description: metaData.description,
        unit: metaData.unit,
        uniformTag: metaData.uniformTag,
        displayName: metaData.description
      }
    }
    await client.dfApi.put(
      `predict/plants/${plantId}/processMetrics/plantSpecific/${payload.plantTag}`,
      payload
    )
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}
