import {isNumber} from 'lodash'
import React, {useMemo} from 'react'
import {TFunction, useTranslation} from 'react-i18next'

import {COLOR_FL_ACTUAL_VALUES} from '../../common/charts/chartConfigurations'
import {LSF_CLINKER_ID} from '../../common/constants'
import {timeRangeFromDateTimeParams} from '../../common/timeRange'
import {
  FreeLimeSample,
  GraphData,
  KilnDataSet,
  ProcessSensorData,
  TimeRange
} from '../../declarations'
import {useFlColorFn} from '../../hooks/useColorFn'
import {useFlParamsWithDefaults} from '../../hooks/useFlParamsWithDefaults'
import {useFlTimeRange} from '../../hooks/useFlTimeRange'
import {useFreeLimeConfig} from '../../hooks/useFreeLimeConfig'
import {useKilnGraphData} from '../../hooks/useKilnGraphData'
import {useFlSensorData} from '../../hooks/useSensorData'
import {useSetFlTimeRangeParams} from '../../hooks/useSetTimeRangeParams'
import {DetailsGraph} from '../charts/DetailsGraph'

interface FreeLimeGraphProps {
  dataSet: KilnDataSet
}

const margin = {left: 64, top: 24, bottom: 56, right: 32} as const

const calcOptionalGraphData = (
  sensorData: ProcessSensorData | undefined,
  freeLimeSamples: FreeLimeSample[],
  t: TFunction,
  color: (dataId: string) => string
): GraphData[] => {
  const labData: GraphData = {
    id: LSF_CLINKER_ID,
    actualValues: freeLimeSamples
      .filter(({lsfClinker}) => isNumber(lsfClinker))
      .map(({datetime, lsfClinker}) => ({datetime, value: lsfClinker as number})),
    name: t('sampleData.lsfClinker.label'),
    color: color(LSF_CLINKER_ID)
  }

  const sensorGraphData: GraphData[] =
    sensorData?.processData.map(({displayName, unit, records, uniformTag}) => ({
      id: uniformTag,
      actualValues: records,
      name: displayName,
      unit,
      color: color(uniformTag)
    })) ?? []

  return [labData, ...sensorGraphData]
}

export const FreeLimeGraph: React.FC<FreeLimeGraphProps> = ({dataSet}) => {
  const onTimeRangeChanged = useSetFlTimeRangeParams()
  const {t} = useTranslation()
  const graphDataSet = useKilnGraphData(dataSet)
  const {actualValues, predictions} = graphDataSet
  const timeRange = useFlTimeRange()
  const freeLimeConfig = useFreeLimeConfig()
  const colorFn = useFlColorFn()
  const {flSelectedTagIds} = useFlParamsWithDefaults()
  const {data} = useFlSensorData()
  const mainGraphData: GraphData = useMemo(
    () => ({
      actualValues,
      predictions: predictions,
      id: 'freeLime',
      name: t('sampleData.freeLime.label'),
      unit: t('unit.percent'),
      color: COLOR_FL_ACTUAL_VALUES
    }),
    [actualValues, predictions, t]
  )

  const optionalGraphData: GraphData[] = useMemo(
    () =>
      calcOptionalGraphData(data, actualValues, t, colorFn).filter(({id}) =>
        flSelectedTagIds.includes(id)
      ),
    [colorFn, data, flSelectedTagIds, actualValues, t]
  )

  return (
    <DetailsGraph
      graphId={graphDataSet.kilnId}
      height={440}
      margin={margin}
      data-test-id={`free-lime-graph-${graphDataSet.kilnId}`}
      timeRange={timeRangeFromDateTimeParams(timeRange)}
      mainTargetRange={freeLimeConfig}
      mainAxisRange={{min: freeLimeConfig.axisMin, max: freeLimeConfig.axisMax}}
      mainGraphData={mainGraphData}
      optionalGraphData={optionalGraphData}
      onTimeRangeChanged={(timeRange: TimeRange<number>) => {
        onTimeRangeChanged(timeRange.start, timeRange.end)
      }}
    />
  )
}
