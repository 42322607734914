import {Graph, GraphRenderProps, XAxis, XAxisLabel, YAxis, YAxisLabel} from '@hconnect/uikit'
import * as d3 from 'd3'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {getStrengthLevelInDays} from '../../common/cementStrength'
import {
  CEMENT_STRENGTH_ACCURACY_OFFSET,
  CEMENT_STRENGTH_DOMAIN_MARGIN,
  CEMENT_STRENGTH_OVERVIEW_STEP_SIZE,
  COLOR_GRID_LINES,
  COLOR_OVERVIEW_BORDER
} from '../../common/charts/chartConfigurations'
import {getStrengthTicks} from '../../common/charts/chartUtils'
import {AggregatedMaterialData, MaterialLevel, TimeRange} from '../../declarations'
import {useCementStrengthStatsRecords} from '../../hooks/useCementStrengthStatsRecords'
import {useCsColorFn} from '../../hooks/useColorFn'
import {AccuracyTargetLines} from '../charts/AccuracyTargetLines'
import {ClampedPoint} from '../charts/ClampedPoint'
import {GraphBorder} from '../charts/GraphBorder'
import {SimpleAccuracyPoint} from '../charts/SimpleAccuracyPoint'

interface CementStrengthAccuracyGraphProps {
  materialData: AggregatedMaterialData
  materialLevel: MaterialLevel
  timeRange: TimeRange
}

const margin = {left: 48, top: 24, bottom: 48, right: 24} as const

interface CementStrengthAccuracyGraphContentProps {
  materialData: AggregatedMaterialData
  materialLevel: MaterialLevel
  timeRange: TimeRange
  graphWidth: number
  graphHeight: number
  Clipped: React.FC
}

const CementStrengthAccuracyGraphContent: React.FC<CementStrengthAccuracyGraphContentProps> = ({
  materialData,
  timeRange,
  graphHeight,
  graphWidth,
  materialLevel,
  Clipped
}) => {
  const height = graphHeight
  const width = graphWidth
  const {t} = useTranslation()
  const records = useCementStrengthStatsRecords(materialData.samples, timeRange)
  const getColor = useCsColorFn()

  const ticks = getStrengthTicks({
    min: materialLevel.min,
    max: materialLevel.max,
    margin: CEMENT_STRENGTH_DOMAIN_MARGIN,
    stepSize: CEMENT_STRENGTH_OVERVIEW_STEP_SIZE
  })
  const domain = [ticks[0], ticks[ticks.length - 1]]
  const x = d3.scaleLinear().domain(domain).range([0, width])
  const y = d3.scaleLinear().domain(domain).range([height, 0])

  return (
    <>
      <YAxis
        width={width}
        ticks={ticks}
        y={y}
        textColor={COLOR_OVERVIEW_BORDER}
        color={COLOR_GRID_LINES}
      />
      <YAxisLabel
        height={height}
        label={t('chart.axisCementStrengthLabelPredicted', {
          count: getStrengthLevelInDays(materialLevel.name)
        })}
        labelColor={COLOR_OVERVIEW_BORDER}
      />
      <XAxis
        scale={x}
        height={height}
        tickValues={ticks}
        textColor={COLOR_OVERVIEW_BORDER}
        color={COLOR_GRID_LINES}
      />
      <XAxisLabel
        height={height}
        width={width}
        label={t('chart.axisCementStrengthLabelActual', {
          count: getStrengthLevelInDays(materialLevel.name)
        })}
        labelColor={COLOR_OVERVIEW_BORDER}
        yOffset={40}
      />
      <Clipped>
        <AccuracyTargetLines
          height={height}
          width={width}
          rangeOffset={y(0) - y(CEMENT_STRENGTH_ACCURACY_OFFSET)}
          targetPos={{x: x(materialLevel.target), y: y(materialLevel.target)}}
        />
      </Clipped>
      <GraphBorder
        graphHeight={graphHeight}
        graphWidth={graphWidth}
        color={COLOR_OVERVIEW_BORDER}
      />

      {records.map((sr) => (
        <ClampedPoint
          key={sr.id}
          posX={x(sr.actual)}
          posY={y(sr.predicted)}
          maxX={graphWidth}
          maxY={graphHeight}
          color={getColor(materialLevel.name)}
          render={({x: clampedX, y: clampedY}) => (
            <SimpleAccuracyPoint
              posX={clampedX}
              posY={clampedY}
              color={getColor(materialLevel.name)}
            />
          )}
        />
      ))}
    </>
  )
}

export const CementStrengthAccuracyGraph: React.FC<CementStrengthAccuracyGraphProps> = ({
  materialData,
  materialLevel,
  timeRange
}) => {
  const render = useCallback(
    ({graphWidth, graphHeight, Clipped}: GraphRenderProps) => {
      return (
        <CementStrengthAccuracyGraphContent
          materialData={materialData}
          graphWidth={graphWidth}
          graphHeight={graphHeight}
          materialLevel={materialLevel}
          timeRange={timeRange}
          Clipped={Clipped}
        />
      )
    },
    [materialData, materialLevel, timeRange]
  )

  return <Graph margin={margin} render={render} graphId={`${materialData.materialId}-accuracy`} />
}
