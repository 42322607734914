import equipPulseContactPersonImageSrc from '../assets/ContactPersonImage/equippulse.jpeg'
import {AppConfig, PLANT_SHORT_NAME} from '../types'

export const equipPulse: AppConfig = {
  contact: {
    name: 'Kristina Kokosova',
    mail: 'kristina.kokoskova@heidelbergcement.com',
    profileImage: equipPulseContactPersonImageSrc
  },
  name: 'EquipPulse',
  url: {
    qa: `https://maintain-qa.heidelbergcement.com/plant/${PLANT_SHORT_NAME}`,
    uat: `https://maintain-uat.heidelbergcement.com/plant/${PLANT_SHORT_NAME}`,
    prod: `https://maintain.heidelbergcement.com/plant/${PLANT_SHORT_NAME}`
  },
  whiteListedPermissionTypes: ['VIEW_PLANT_SENSOR_DATA'],
  plant: {
    whiteList: ['0014']
  }
}
