import {Box, MenuItem, TextField, Typography} from '@material-ui/core'
import React from 'react'

import {Plant} from '../declarations'

export interface PlantSelectProps {
  caption?: string
  label: string
  onPlantSelected: (plantId: string) => void
  plants: Plant[]
}

export const PlantSelect: React.FC<PlantSelectProps> = ({
  caption,
  label,
  onPlantSelected,
  plants
}) => {
  return (
    <Box>
      {caption && <Typography>{caption}</Typography>}
      <TextField
        id="predict-select-plant"
        select
        label={label}
        value=""
        fullWidth
        onChange={(event) => {
          onPlantSelected(event.target.value)
        }}
        margin="normal"
      >
        {plants.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.fullName}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}
