import {Button, makeStyles} from '@material-ui/core'
import {Add, Close} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {isChartOption, toCategories} from '../../common/charts/chartOptions'
import {ChartCategory, ChartOption, IdColorMapFn} from '../../declarations'
import {ColorButton} from '../ColorButton'

import {ChartDataDialog} from './ChartDataDialog'

export interface ChartButtonBarProps {
  options: ChartOption[] | ChartCategory[]
  optionColorFn: IdColorMapFn
  selectedOptions: string[]
  onOptionSelected: (optionId: string, selected: boolean) => void
  onSelected: (selectedOptions: string[]) => void
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    margin: 4
  },
  button: {
    '& .MuiButton-label': {
      height: 20
    }
  },
  textLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150
  }
}))

export const ChartButtonBar: React.FC<ChartButtonBarProps> = ({
  options,
  optionColorFn,
  selectedOptions,
  onOptionSelected,
  onSelected
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const categories: ChartCategory[] = toCategories(options)
  const allOptions = categories.flatMap((category) => category.options)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (selected?: string[]) => {
    if (selected) {
      onSelected(selected)
    }
    setOpen(false)
  }

  return (
    <div className={classes.container}>
      {allOptions
        .filter((option) => !option.selectable)
        .map((option) => (
          <div key={option.id} className={classes.item}>
            <ColorButton
              color={optionColorFn(option.id)}
              className={classNames(classes.button)}
              variant="outlined"
              disabled
              data-test-id={`chart-button-bar-btn-disabled-${option.id}`}
              colorizeWhenDisabled
            >
              <span className={classes.textLabel}>{option.label}</span>
            </ColorButton>
          </div>
        ))}
      {selectedOptions
        .map((selectedId) => allOptions.find((opt) => opt.id === selectedId))
        .filter(isChartOption)
        .map((option) => (
          <div key={option.id} className={classes.item}>
            <ColorButton
              color={optionColorFn(option.id)}
              className={classNames(classes.button)}
              variant="outlined"
              endIcon={<Close />}
              onClick={() => onOptionSelected(option.id, false)}
              data-test-id={`chart-button-bar-btn-${option.id}`}
            >
              <span className={classes.textLabel}>{option.label}</span>
            </ColorButton>
          </div>
        ))}
      <div className={classes.item}>
        <Button
          className={classes.button}
          variant="outlined"
          startIcon={<Add />}
          aria-controls="add-menu"
          aria-haspopup="true"
          onClick={handleClick}
          data-test-id="chart-button-bar-btn-add-data"
        >
          {t('chart.addData')}
        </Button>
        <ChartDataDialog
          open={open}
          onClose={handleClose}
          options={options}
          selectedOptions={selectedOptions}
        />
      </div>
    </div>
  )
}
