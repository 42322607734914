import {Box, Button, Paper} from '@material-ui/core'
import React from 'react'

import {LogoHConnectSquareWhite} from '../Assets'
import {Content} from '../Shell'

import Divider from './Divider'
import {OssAttributionLink} from './Footer'
import Grid from './Grid'
import HConnectLogo from './HConnectLogo'
import Logos from './Logos'
import Typography from './Typography'

interface LayoutProps {
  logos: any[]
  onClickButton: () => void
  customLogoStyle?: React.CSSProperties
  loginButtonLabel?: string
}

export const LandingPage: React.FC<LayoutProps> = ({
  logos,
  onClickButton,
  customLogoStyle,
  loginButtonLabel = 'Mit HConnect anmelden'
}) => {
  return (
    <Content>
      <Grid container fullHeight alignItems="flex-end">
        <Grid item xs={1} md={2} lg={3} xl={4} />
        <Grid item xs={10} md={8} lg={6} xl={4}>
          <Paper elevation={4}>
            <Box p={11.25} display="flex" flexDirection="column" alignItems="center">
              <Logos style={{fontSize: '30px'}}>
                {logos.map((logo) => (
                  <img
                    key={logo}
                    src={logo}
                    alt="Logo"
                    style={customLogoStyle}
                    data-test-id="landing-page-logo"
                  />
                ))}
              </Logos>
              <Box minHeight={40} />
              <Button
                color="primary"
                onClick={onClickButton}
                data-test-id="landing-page-button-login-hconnect"
              >
                <img
                  src={LogoHConnectSquareWhite}
                  alt="LogoHConnectSquareWhite"
                  style={{marginRight: 10}}
                />
                {loginButtonLabel}
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid container item xs={12} direction="column">
          <Divider variant="middle" color="light" />

          <Box
            height={80}
            paddingLeft={2}
            paddingRight={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="caption" color="secondary" data-test-id="landing-page-copyright">
                All rights reserved
              </Typography>
            </Box>
            <OssAttributionLink />

            <HConnectLogo logoColor="white" />
          </Box>
        </Grid>
      </Grid>
    </Content>
  )
}
