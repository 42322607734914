import {LogoHProduce, OssAttributionLink, Typography} from '@hconnect/uikit'
import {Box, Grid, makeStyles} from '@material-ui/core'
import React from 'react'

export interface ShellFooterProps {
  label: string
}

const useStyles = makeStyles(() => ({
  ossAttrLinkContainer: {
    textAlign: 'center'
  }
}))

export const ShellFooter: React.FC<ShellFooterProps> = ({label}) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="space-between">
      <Grid item sm={4} />
      <Grid item sm={4} xs={12} className={classes.ossAttrLinkContainer}>
        <OssAttributionLink />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Typography variant="caption" color="secondary">
            {label}
          </Typography>
          <Box display="flex" ml={1}>
            <img src={LogoHProduce} alt="HProduce Logo" width={94} height={18} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
