import {Material, MaterialDto, MaterialLevel} from '../../declarations'
import {compareStrengthLevels, getStrengthLevelFromString} from '../cementStrength'

import {client} from './client'

const compareMaterialLevel = (a: MaterialLevel, b: MaterialLevel) =>
  compareStrengthLevels(a.name, b.name)

export const convertMaterialDto = (dto: MaterialDto): Material => {
  const levels =
    (dto.levels
      ?.map((l) => ({...l, name: getStrengthLevelFromString(l.name)}))
      .filter((l) => Boolean(l.name)) as MaterialLevel[]) ?? []
  levels.sort(compareMaterialLevel)

  return {
    materialId: dto.materialId,
    name: dto.name ?? '',
    levels
  }
}

export const getMaterials = async (plantId: string): Promise<Material[]> => {
  const data = await client.dfApi.get<MaterialDto[]>(
    `/predict/plants/${plantId}/cementStrengths/materials`
  )
  return data.data.map((matDto) => convertMaterialDto(matDto))
}
