import {makeStyles, StyleRulesCallback, Theme, Typography} from '@material-ui/core'
import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatFloat} from '../../common/format'

interface StatsItemProps {
  headerLabel: React.ReactNode
  value?: number
  valueUnit?: string
  valueColor?: string
  valueDigits?: number
  valueSign?: boolean
  'data-test-id'?: string
}

type StyleRuleCb = StyleRulesCallback<Theme, {color?: string}, string>

const useStyles = makeStyles(((theme: Theme) => ({
  container: {
    padding: 0
  },
  header: {
    color: theme.palette.grey[700]
  },
  valueContainer: ({color = theme.palette.grey[700]}) => ({
    fontWeight: theme.typography.fontWeightBold,
    color
  }),
  labelContainer: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 4
  }
})) as StyleRuleCb)

const NumberLabel: React.FC<{
  value?: number
  valueUnit?: string
  valueDigits: number
  valueSign: boolean
}> = ({value, valueUnit, valueDigits, valueSign}) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles({})

  if (!isNumber(value)) {
    return t('common.na')
  }

  return (
    <>
      {formatFloat(value, {
        locale: i18n.language,
        displayPlus: valueSign,
        fractionDigits: valueDigits
      })}
      {valueUnit && (
        <Typography component="span" variant="subtitle2" className={classes.labelContainer}>
          {valueUnit}
        </Typography>
      )}
    </>
  )
}

export const StatsItem: React.FC<StatsItemProps> = ({
  headerLabel,
  valueColor,
  value,
  valueUnit,
  valueDigits = 1,
  valueSign = false,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles({color: valueColor})

  return (
    <div className={classes.container} data-test-id={dataTestId}>
      <Typography variant="caption" className={classes.header}>
        {headerLabel}
      </Typography>
      <Typography variant="h5" className={classes.valueContainer}>
        <NumberLabel
          value={value}
          valueUnit={valueUnit}
          valueDigits={valueDigits}
          valueSign={valueSign}
        />
      </Typography>
    </div>
  )
}
