import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFlParamsWithDefaults} from '../hooks/useFlParamsWithDefaults'
import {useFreeLimeData} from '../hooks/useFreeLimeData'

import {AsyncContainer} from './AsyncContainer'
import {KilnCharts} from './KilnCharts'

export const KilnChartsContainer: React.FC = () => {
  const {t} = useTranslation()
  const {selectedKilnId} = useFlParamsWithDefaults()
  const {data: freeLimeData, isLoading, isSuccess, isError} = useFreeLimeData()
  const kilnData = freeLimeData?.kilnDataSet.find((kilnData) => kilnData.kilnId === selectedKilnId)

  return (
    <AsyncContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError || !kilnData}
      errorMessage={t('errors.freeLimeData')}
    >
      {kilnData && <KilnCharts kilnData={kilnData} />}
    </AsyncContainer>
  )
}
