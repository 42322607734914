import {AxiosError} from 'axios'

import type {ProcessMetaData, SensorMetaData, UniformProcessMetric} from '../../declarations'
import {PlantSpecificProcessMetric} from '../../declarations'
import {logger} from '../logger'

import {client} from './client'

export const getSensorMetaData = async (): Promise<SensorMetaData[]> => {
  const log = logger.context('getSensorMetaData')
  try {
    const {data} = await client.dfApi.get<UniformProcessMetric[]>('predict/processMetrics/uniform')
    return data.map(({uniformTag, unit, description, displayName}) => ({
      uniformTag: uniformTag ?? '',
      unit: unit ?? '',
      displayName: displayName ?? '',
      description: description ?? '',
      category: ''
    }))
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const addSensorMetaData = async (
  metaData: SensorMetaData
): Promise<UniformProcessMetric> => {
  const log = logger.context('addSensorMetaData', JSON.stringify(metaData))
  try {
    const payload: UniformProcessMetric = {
      uniformTag: metaData.uniformTag,
      unit: metaData.unit,
      description: metaData.description,
      displayName: metaData.displayName || metaData.description
    }
    const {data} = await client.dfApi.post<UniformProcessMetric>(
      'predict/processMetrics/uniform',
      payload
    )
    return data
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const updateSensorMetaData = async (metaData: SensorMetaData): Promise<void> => {
  const log = logger.context('updateSensorMetaData', JSON.stringify(metaData))
  try {
    const payload: UniformProcessMetric = {
      uniformTag: metaData.uniformTag,
      unit: metaData.unit,
      description: metaData.description,
      displayName: metaData.displayName || metaData.description
    }
    await client.dfApi.put<void>(`predict/processMetrics/uniform/${metaData.uniformTag}`, payload)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const deleteSensorMetaData = async (uniformTag: string): Promise<void> => {
  const log = logger.context('deleteSensorMetaData', uniformTag)
  try {
    await client.dfApi.delete<void>(`predict/processMetrics/uniform/${uniformTag}`)
    log.info(`UniformTag ${uniformTag} deleted.`)
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const deleteSensorMetaDataList = async (uniformTags: string[]): Promise<void> => {
  await Promise.all(uniformTags.map((tag) => deleteSensorMetaData(tag)))
}

export const getPlantSpecificSensorMetaData = async (
  plantId: string
): Promise<ProcessMetaData[]> => {
  const log = logger.context('getPlantSpecificSensorMetaData', plantId)
  try {
    const sensorMetaData = await getSensorMetaData()
    const {data} = await client.dfApi.get<PlantSpecificProcessMetric[]>(
      `predict/plants/${plantId}/processMetrics/plantSpecific`
    )
    const uniformTag2PlantTagMap: Record<string, string> = {}
    data
      .filter(({uniformTag}) => Boolean(uniformTag))
      .forEach((datum) => {
        uniformTag2PlantTagMap[datum.uniformTag as string] = datum.plantTag ?? ''
      })

    return sensorMetaData.map((sensorMetaDatum) => ({
      plantSpecificTag: uniformTag2PlantTagMap[sensorMetaDatum.uniformTag] ?? '',
      ...sensorMetaDatum
    }))
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}
