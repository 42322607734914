import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {getStrengthLevelInDays} from '../../common/cementStrength'
import type {StrengthLevel} from '../../declarations'
import {usePlantConfig} from '../../hooks/usePlantConfig'

import {StringValueSelect} from './StringValueSelect'

interface PredictionStrengthSelectProps {
  strengthLevel: StrengthLevel
  onStrengthLevelChanged: (strengthLevel: StrengthLevel) => void
  disabled?: boolean
}

export const PredictionStrengthSelect: React.FC<PredictionStrengthSelectProps> = ({
  strengthLevel,
  onStrengthLevelChanged,
  disabled
}) => {
  const {t} = useTranslation()
  const config = usePlantConfig()
  const levels = config.supportedStrengthLevels

  const valueLabel = useCallback(
    (itemValue: StrengthLevel) =>
      t('strengthSelect.strength', {count: getStrengthLevelInDays(itemValue)}),
    [t]
  )

  return (
    <StringValueSelect
      onSelected={onStrengthLevelChanged}
      value={strengthLevel}
      values={levels}
      fieldId="predict-select-strength"
      data-test-id="predict-select-strength"
      valueLabel={valueLabel}
      label={t('pageActionBar.actionPredictionStrengthLabel')}
      disabled={disabled}
    />
  )
}
