import {isNumber} from 'lodash'
import {useQuery} from 'react-query'

import {getCementStrengthByMaterial} from '../common/backend'
import {DEFAULT_TIME_MARGIN_FACTOR} from '../common/charts/chartConfigurations'
import {QueryKeyName} from '../common/constants'
import {padTimeRange, timeRangeFromDateTimeParams} from '../common/timeRange'
import {DateTimeParam, TimeRange} from '../declarations'

import {useCsTimeRange} from './useCsTimeRange'
import {usePlantId} from './usePlantId'

const queryFunction =
  (plantId: string, materialId: number | undefined, timeRange: TimeRange<DateTimeParam>) => () => {
    if (!isNumber(materialId)) {
      return Promise.resolve(undefined)
    }
    const paddedRange = padTimeRange(
      timeRangeFromDateTimeParams(timeRange),
      DEFAULT_TIME_MARGIN_FACTOR
    )
    return getCementStrengthByMaterial(plantId, materialId, paddedRange)
  }

export const useCementStrengthData = (materialId?: number) => {
  const plantId = usePlantId()
  const timeRange = useCsTimeRange()
  return useQuery(
    [QueryKeyName.GetCementStrengthForMaterial, plantId, materialId, timeRange],
    queryFunction(plantId, materialId, timeRange)
  )
}
