import {Box, Link} from '@material-ui/core'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HCTheme} from '../HCTheme'

import HConnectLogo from './HConnectLogo'
import Logos from './Logos'

interface HConnectProps {
  hconnectLinkUrl?: string
  onDark?: boolean
  t: TFunction
}
const HConnectComponent: React.FC<HConnectProps> = ({hconnectLinkUrl, onDark, t}) => {
  if (hconnectLinkUrl) {
    return (
      <Link underline="none" href={hconnectLinkUrl} title={t('HConnect')}>
        <HConnectLogo logoColor={onDark ? 'white' : 'black'} />
      </Link>
    )
  }
  return <HConnectLogo logoColor={onDark ? 'white' : 'black'} />
}

interface Props {
  logos?: string[]
  copyright?: React.ReactNode
  links?: React.ReactNode
  hconnectLinkUrl?: string
  onDark?: boolean
}

const getOssAttributionLink = () => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return 'https://legal.hconnect.digital/ossattribution'
  } else if (location.hostname === 'localhost') {
    return 'http://localhost:3010/ossattribution'
  } else {
    return `https://legal-${process.env.REACT_APP_STAGE}.hconnect.digital/ossattribution`
  }
}

export const OssAttributionLink = () => (
  <Link
    variant="body2"
    color="secondary"
    href={getOssAttributionLink()}
    rel="noopener noreferrer"
    data-test-id={'oss-attribution'}
    target='_blank'
  >
    Open Source Software Attribution
  </Link>
)

export const DefaultFooter: React.FC<Props> = ({
  logos,
  copyright,
  links,
  hconnectLinkUrl,
  onDark = false,
  ...otherProps
}) => {
  const {t} = useTranslation()
  return (
    <Box
      component="footer"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      bgcolor={onDark ? HCTheme.palette.background : HCTheme.palette.common.white}
      flexGrow={1}
      flexWrap="nowrap"
      {...otherProps}
    >
      {!onDark ? (
        <Logos>
          {(logos || []).map((logo, i) => (
            <img style={{maxWidth: 136}} src={logo} key={logo + i} alt="" />
          ))}
        </Logos>
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        {...(onDark ? {flex: 1} : {})}
      >
        <Box p={1}>{copyright}</Box>
        <Box p={1}>
          {links}
          <OssAttributionLink />
        </Box>
        {onDark ? (
          <Box p={1}>
            <HConnectComponent t={t} onDark={onDark} hconnectLinkUrl={hconnectLinkUrl} />
          </Box>
        ) : null}
      </Box>
      {!onDark ? (
        <Box display="flex" justifyContent="flex-end">
          <HConnectComponent t={t} onDark={onDark} hconnectLinkUrl={hconnectLinkUrl} />
        </Box>
      ) : null}
    </Box>
  )
}
