import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {timeHorizonToTimeRangeRounded} from '../../common/dateUtils'
import {CS_TIME_HORIZON_MAX} from '../../common/timeHorizon'
import {useCementStrengthExcelDownload} from '../../hooks/useCementStrengthExcelDownload'
import {usePlantFullName} from '../../hooks/usePlantFullName'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'

import {ActionBarButton} from './ActionBarButton'

export const ExcelExportButton: React.FC = () => {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const plantName = usePlantFullName()
  const {download, isDownloading} = useCementStrengthExcelDownload()

  const onClick = useCallback(() => {
    const now = new Date()
    const timeRange = timeHorizonToTimeRangeRounded(CS_TIME_HORIZON_MAX, timeZone, now)
    download(timeRange, `Predictions-${plantName}-${now.toJSON()}.xlsx`)
  }, [download, plantName, timeZone])

  return (
    <ActionBarButton
      onClick={onClick}
      data-test-id="excel-export-btn"
      startIcon={isDownloading ? undefined : <VerticalAlignBottomIcon />}
      isLoading={isDownloading}
    >
      {t('pageActionBar.actionExport')}
    </ActionBarButton>
  )
}
