import {User} from '@sentry/browser'
import {isEmpty, omitBy} from 'lodash'

import {Severity, LogLevelProcessor, ProcessorFn} from '../../lib'

import {SentryInitializer} from './SentryInitializer'

type BreadcrumbType = 'debug' | 'error' | 'navigation' | 'default'

const breadcrumbTypeMapping: Record<Severity, BreadcrumbType> = {
  [Severity.Info]: 'debug',
  [Severity.Debug]: 'debug',
  [Severity.Log]: 'default',
  [Severity.Warning]: 'error',
  [Severity.Error]: 'error',
  [Severity.Critical]: 'error',
  [Severity.Fatal]: 'error'
}

const addSentryBreadcrumb: ProcessorFn = (level, event, metadata) => {
  const {extras, context, project, tags} = metadata
  const path = (context ?? []).join(' / ')
  const sentry = SentryInitializer.initialize()
  sentry?.addBreadcrumb({
    level,
    category: level,
    type: breadcrumbTypeMapping[level],
    message: event as string,
    data: omitBy(
      {
        project,
        path,
        extras,
        tags
      },
      isEmpty
    )
  })
}

const sendSentryEvent: ProcessorFn = (level, event, metadata) => {
  const {extras, context, project, tags} = metadata
  const path = (context ?? []).join(' / ')
  const sentry = SentryInitializer.initialize()
  sentry?.withScope((scope) => {
    scope.setLevel(level)
    scope.setContext('Context', {project, path})
    if (extras) scope.setExtras(extras)
    if (tags) scope.setTags(tags)
    if (extras?.fingerprint) scope.setFingerprint(extras.fingerprint as string[])
    if (extras?.user) scope.setUser(extras.user as User)

    if (typeof event === 'string') sentry?.captureMessage(event)
    else sentry?.captureException(event)
  })
}

export const SentryProcessor: LogLevelProcessor = {
  [Severity.Debug]: addSentryBreadcrumb,
  [Severity.Info]: addSentryBreadcrumb,
  [Severity.Log]: addSentryBreadcrumb,
  [Severity.Warning]: addSentryBreadcrumb,
  [Severity.Error]: sendSentryEvent,
  [Severity.Critical]: sendSentryEvent,
  [Severity.Fatal]: sendSentryEvent
}
