import {
  Axis,
  Graph,
  GraphInfoBox,
  GraphInfoBoxItem,
  LinearScaleFn,
  SimpleCurve,
  TouchAreas,
  VerticalGrid,
  YAxisLabel
} from '@hconnect/uikit'
import {useTheme} from '@material-ui/core'
import * as d3 from 'd3'
import {isNumber, noop} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  COLOR_GRID_LINES,
  COLOR_TREND_AXIS,
  COLOR_TREND_BORDER,
  hoveredColor,
  PREDICTION_DASHARRAY_SMALL,
  SUGGESTED_TRENDS_TICK_COUNT
} from '../../common/charts/chartConfigurations'
import {calcTimeScale, getTimestampsOfData, timeAxisFormatter} from '../../common/charts/chartUtils'
import {
  calcCombinedSample,
  calcInfoBoxItems,
  calcVerticalMarkerPoint,
  calcYFuncScaleMap,
  labelWithUnit,
  reduceGraphDataPoints
} from '../../common/charts/graphData'
import {formatTimeZoneDate} from '../../common/dateUtils'
import {
  CombinedSample,
  GraphData,
  LinearScaleFnWithTicks,
  NumberRange,
  TargetRange,
  TimeRange
} from '../../declarations'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'

import {GraphBorder} from './GraphBorder'
import {TargetLines} from './TargetLines'
import {TimeZoomWrapper} from './TimeZoomWrapper'
import {VerticalMarker} from './VerticalMarker'

export interface DetailsGraphProps {
  timeRange: TimeRange
  graphId: string
  mainGraphData: GraphData
  optionalGraphData: GraphData[]
  mainTargetRange: TargetRange
  mainAxisRange: NumberRange
  margin: {
    left: number
    top: number
    right: number
    bottom: number
  }
  selected?: CombinedSample
  onSelected?: (datetime: number) => void
  selectedLabel?: string
  onTimeRangeChanged?: (timeRange: TimeRange<number>) => void
  height?: number
  'data-test-id'?: string
}

interface DetailsGraphContentProps {
  graphWidth: number
  graphHeight: number
  Clipped: React.FC
  timeRange: TimeRange
  timeZone: string
  mainTargetRange: TargetRange
  mainAxisRange: NumberRange
  mainGraphData: GraphData
  optionalGraphData: GraphData[]
  marginLeft: number
  selected?: CombinedSample
  onSelected?: (datetime: number) => void
  selectedLabel?: string
  onTimeRangeChanged?: (timeRange: TimeRange<number>) => void
}

const GraphCurve: React.FC<{graphData: GraphData; x: LinearScaleFn; y: LinearScaleFn}> = ({
  graphData,
  x,
  y
}) => (
  <>
    <SimpleCurve
      color={graphData.color}
      records={graphData.actualValues}
      x={x}
      y={y}
      data-test-id={`details-graph-curve-${graphData.id}`}
    />
    {graphData.predictions ? (
      <SimpleCurve
        color={graphData.color}
        records={graphData.predictions}
        x={x}
        y={y}
        strokeDasharray={PREDICTION_DASHARRAY_SMALL}
        data-test-id={`details-graph-curve-${graphData.id}-predicted`}
      />
    ) : null}
  </>
)

const axisLabelBaseOffset = -48

const DetailsGraphContent: React.FC<DetailsGraphContentProps> = ({
  graphWidth,
  graphHeight,
  Clipped,
  timeRange,
  timeZone,
  mainTargetRange,
  mainAxisRange,
  mainGraphData,
  optionalGraphData,
  marginLeft,
  selected,
  onSelected,
  selectedLabel,
  onTimeRangeChanged
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const [hovered, setHovered] = useState<CombinedSample>()
  const allGraphData = useMemo(
    () => [mainGraphData, ...optionalGraphData],
    [mainGraphData, optionalGraphData]
  )
  const onHover = useCallback(
    (timestamp?: number) => {
      if (!isNumber(timestamp)) {
        setHovered(undefined)
      } else {
        setHovered(calcCombinedSample(allGraphData, timestamp))
      }
    },
    [allGraphData]
  )
  const {x, ticks: xTimeTicks} = useMemo(
    () => calcTimeScale({timeRange, graphWidth, timeZone, tickWidth: 64}),
    [graphWidth, timeRange, timeZone]
  )
  const {format, extraFormat} = timeAxisFormatter(xTimeTicks, x, timeZone, t)
  const y = d3.scaleLinear().domain([mainAxisRange.min, mainAxisRange.max]).range([graphHeight, 0])

  const yScaleFnMap: Record<string, LinearScaleFnWithTicks> = useMemo(() => {
    const result: Record<string, LinearScaleFnWithTicks> = calcYFuncScaleMap(
      optionalGraphData,
      graphHeight
    )
    result[mainGraphData.id] = y
    return result
  }, [graphHeight, mainGraphData.id, optionalGraphData, y])

  const renderOptionalDataAxes = useCallback(
    () =>
      optionalGraphData.map(({id, color, name, unit}, index) => {
        const yScaleFn = yScaleFnMap[id]
        const posOffset = (index + 1) * -marginLeft

        return (
          <g key={id}>
            <Axis
              data-test-id={`details-graph-axis-${id}`}
              scale={yScaleFn}
              position="left"
              tickValues={yScaleFn.ticks()}
              posX={posOffset}
              color={color}
            />
            <YAxisLabel
              data-test-id={`details-graph-axis-label-${id}`}
              height={graphHeight}
              label={labelWithUnit(name, unit)}
              labelColor={color}
              xOffset={posOffset + axisLabelBaseOffset}
            />
          </g>
        )
      }),
    [graphHeight, marginLeft, optionalGraphData, yScaleFnMap]
  )

  const timestamps = useMemo(
    () =>
      getTimestampsOfData(
        allGraphData.map(({actualValues, predictions}) => [
          ...actualValues,
          ...(predictions ?? [])
        ]),
        timeRange
      ),
    [allGraphData, timeRange]
  )

  return (
    <>
      <VerticalGrid
        scale={x}
        color={COLOR_GRID_LINES}
        height={graphHeight}
        tickValues={xTimeTicks}
      />
      <TargetLines
        width={graphWidth}
        targetRange={{
          min: y(mainTargetRange.min),
          max: y(mainTargetRange.max),
          target: y(mainTargetRange.target)
        }}
      />
      <GraphBorder graphHeight={graphHeight} graphWidth={graphWidth} color={COLOR_TREND_BORDER} />
      <Axis
        scale={x}
        position="bottom"
        posY={graphHeight}
        format={format}
        extraFormat={extraFormat}
        tickValues={xTimeTicks}
        color={COLOR_TREND_AXIS}
      />
      <Axis
        scale={y}
        position="left"
        tickValues={y.ticks(SUGGESTED_TRENDS_TICK_COUNT)}
        color={COLOR_TREND_AXIS}
        textColor={COLOR_TREND_AXIS}
      />
      <YAxisLabel
        height={graphHeight}
        label={labelWithUnit(mainGraphData.name, mainGraphData.unit)}
        labelColor={COLOR_TREND_AXIS}
        xOffset={axisLabelBaseOffset}
      />
      {renderOptionalDataAxes()}
      <Clipped>
        <GraphCurve graphData={mainGraphData} x={x} y={y} />
        {optionalGraphData.map((graphData) => (
          <GraphCurve
            key={graphData.id}
            graphData={graphData}
            x={x}
            y={yScaleFnMap[graphData.id]}
          />
        ))}
      </Clipped>
      {hovered ? (
        <VerticalMarker
          height={graphHeight}
          color={hoveredColor(theme)}
          posX={x(hovered.datetime)}
          points={calcVerticalMarkerPoint(allGraphData, hovered, yScaleFnMap)}
        />
      ) : null}
      {selected ? (
        <VerticalMarker
          height={graphHeight}
          color={theme.palette.primary.main}
          posX={x(selected.datetime)}
          points={calcVerticalMarkerPoint(allGraphData, selected, yScaleFnMap)}
          label={selectedLabel}
        />
      ) : null}
      {hovered ? (
        <GraphInfoBox
          title={formatTimeZoneDate(hovered.datetime, timeZone, t('sampleDetails.dateFormat'))}
          xPos={x(hovered.datetime)}
          graphWidth={graphWidth}
          graphHeight={graphHeight}
          data={calcInfoBoxItems(allGraphData, hovered, t)}
          itemRender={(props) => <GraphInfoBoxItem {...props} />}
        />
      ) : null}

      <TimeZoomWrapper
        x={x}
        graphHeight={graphHeight}
        graphWidth={graphWidth}
        onTimeRangeChanged={onTimeRangeChanged ?? noop}
      >
        <TouchAreas
          timestamps={timestamps}
          x={x}
          graphHeight={graphHeight}
          graphWidth={graphWidth}
          onHover={onHover}
          onSelect={onSelected}
        />
      </TimeZoomWrapper>
    </>
  )
}

export const DetailsGraph: React.FC<DetailsGraphProps> = ({
  timeRange,
  graphId,
  margin,
  mainTargetRange,
  mainAxisRange,
  mainGraphData,
  optionalGraphData,
  selected,
  onSelected,
  selectedLabel,
  height,
  onTimeRangeChanged,
  'data-test-id': dataTestId
}) => {
  const timeZone = usePlantTimeZone()
  const filteredMainGraphData = useMemo(() => reduceGraphDataPoints(mainGraphData), [mainGraphData])
  const filteredOptionalGraphData = useMemo(
    () => optionalGraphData.map((data) => reduceGraphDataPoints(data)),
    [optionalGraphData]
  )

  const render = useCallback(
    ({graphWidth, graphHeight, Clipped}) => (
      <DetailsGraphContent
        graphWidth={graphWidth}
        graphHeight={graphHeight}
        Clipped={Clipped}
        timeRange={timeRange}
        timeZone={timeZone}
        mainTargetRange={mainTargetRange}
        mainAxisRange={mainAxisRange}
        mainGraphData={filteredMainGraphData}
        optionalGraphData={filteredOptionalGraphData}
        marginLeft={margin.left}
        selected={selected}
        onSelected={onSelected}
        selectedLabel={selectedLabel}
        onTimeRangeChanged={onTimeRangeChanged}
      />
    ),
    [
      filteredMainGraphData,
      filteredOptionalGraphData,
      mainAxisRange,
      mainTargetRange,
      margin.left,
      onSelected,
      onTimeRangeChanged,
      selected,
      selectedLabel,
      timeRange,
      timeZone
    ]
  )

  return (
    <Graph
      height={height}
      margin={
        optionalGraphData.length > 0
          ? {...margin, left: margin.left * (1 + optionalGraphData.length)}
          : margin
      }
      render={render}
      graphId={graphId}
      data-test-id={dataTestId}
    />
  )
}
