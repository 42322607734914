export const formatDate = (d: Date): string =>
  `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${d.getFullYear()}`

export const formatTime = (d: Date): string =>
  `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`

export const getDateObjectFromInput = (
  date: string,
  time: string,
  timeZone = 'Europe/Berlin'
): Date => {
  const {day, month, year} = getDatePartsFromString(date)
  const {minutes, hours} = getTimePartsFromString(time)

  const _date = new Date(
    Date.UTC(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hours, 10),
      parseInt(minutes, 10)
    )
  )
  const utcDate = new Date(_date.toLocaleString('en', {timeZone: 'UTC'}))
  const tzDate = new Date(_date.toLocaleString('en', {timeZone}))
  const offset = utcDate.getTime() - tzDate.getTime()

  _date.setTime(_date.getTime() + offset)
  return _date
}

export const getDateValue = (val: string): string => {
  const value = val.replaceAll('.', '')
  const {day, month, year} = getDatePartsFromString(val)

  if (
    value.length > 10 ||
    (day !== '' && !isDayValid(day)) ||
    (month !== '' && !isMonthValid(month))
  ) {
    return val.slice(0, -1)
  }
  return `${day ?? ''}${month ? `.${month}` : ''}${year ? `.${year}` : ''}`
}

export const getTimeValue = (val: string): string => {
  const value = val.replaceAll(':', '').replace(/\D/g, '')
  const hours = value.substring(0, 2)
  const minutes = value.substring(2, 4)

  if (
    [minutes, hours].some((el) => el && isNaN(parseInt(el, 10))) ||
    value.length > 5 ||
    parseInt(minutes, 10) > 59 ||
    parseInt(hours, 10) > 23
  ) {
    return val.slice(0, -1)
  }
  if (hours.length === 1 && parseInt(hours, 10) > 2) {
    return `0${hours}`
  }
  return `${hours ? hours : ''}${minutes ? `:${minutes}` : ''}`
}

export const getDatePartsFromString = (val: string): {day: string; month: string; year: string} => {
  const value = val.replaceAll('.', '')
  const day = value.substring(0, 2)
  const month = value.substring(2, 4)
  const year = value.substring(4, 8)

  return {
    day: !isNaN(parseInt(day, 10)) ? day : '',
    month: !isNaN(parseInt(month, 10)) ? month.toString() : '',
    year: !isNaN(parseInt(year, 10)) ? year.toString() : ''
  }
}

export const getTimePartsFromString = (val: string): {minutes: string; hours: string} => {
  const [hours = '', minutes = ''] = val.split(':')
  return {minutes, hours}
}

export const isSameDate = (a?: Date | null, b?: Date | null) =>
  a &&
  b &&
  (a === b ||
    (a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()))

export const isBetweenDates = (a?: Date | null, b?: Date, c?: Date | null): boolean =>
  Boolean(a && b && c && a < b && b < c)

export const isAfter = (a?: Date | null, b?: Date | null): boolean => Boolean(a && b && a > b)
export const isDayValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) < 32)
export const isMonthValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) < 13)
export const isYearValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) > 2000)
export const isMinuteValid = (val?: string): boolean =>
  Boolean(val && val.length === 2 && parseInt(val, 10) < 60)
export const isHourValid = (val?: string): boolean =>
  Boolean(val && val.length === 2 && parseInt(val, 10) < 24)

interface DateValidProps {
  date: string
  time: string
  exceptions?: Date[]
  isOutsideRange?: (arg: Date) => boolean
}

export const isDateValid = ({date, time, exceptions, isOutsideRange}: DateValidProps) => {
  const {day, month, year} = getDatePartsFromString(date)
  const {hours, minutes} = getTimePartsFromString(time)

  if (
    !isDayValid(day) ||
    !isMonthValid(month) ||
    !isYearValid(year) ||
    !isMinuteValid(minutes) ||
    !isHourValid(hours)
  ) {
    return false
  }

  const _date = new Date(parseInt(year, 10), parseInt(month) - 1, parseInt(day))

  return (
    !isNaN(_date.getTime()) &&
    !exceptions?.some((d) => isSameDate(_date, d)) &&
    !isOutsideRange?.(_date)
  )
}
