import {useBreakPoints} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Button, makeStyles} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import {NavigateBefore, NavigateNext} from '@material-ui/icons'
import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

import {useAggregatedCemStrengthData} from '../hooks/useAggregatedCemStrengthData'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {useNextSample} from '../hooks/useNextSample'
import {usePlantNavigation} from '../hooks/usePlantNavigation'
import {usePreviousSample} from '../hooks/usePreviousSample'

interface SampleNavigationControlsProps {
  currentSampleId?: number
  materialId?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  space: {
    width: theme.spacing(2)
  },
  button: {
    width: 180,
    [theme.breakpoints.down('xs')]: {
      '& [class*="MuiButton-startIcon"], & [class*="MuiButton-endIcon"]': {
        margin: 0
      }
    }
  }
}))

const getUrl = (navigation, materialId, queryParams, sampleId): string =>
  navigation.getPlantUrl({
    plantPage: 'cementStrengthSample',
    pathParams: {
      materialId,
      sampleId
    },
    queryParams: {...queryParams, sampleId: sampleId}
  })

export const SampleNavigationControls: React.FC<SampleNavigationControlsProps> = ({
  currentSampleId,
  materialId
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const navigation = usePlantNavigation()
  const breakpoint = useBreakPoints()
  const isMobile = breakpoint === 'xs'

  // prev/next behavior
  const queryParams = useCsParamsWithDefaults()
  const {data} = useAggregatedCemStrengthData(materialId)
  const samples = data?.samples ?? []
  const prevSampleId = usePreviousSample(samples, currentSampleId)?.id
  const nextSampleId = useNextSample(samples, currentSampleId)?.id

  return (
    <div className={classes.container}>
      <Button
        component={RouterLink}
        to={getUrl(navigation, materialId, queryParams, prevSampleId)}
        disabled={!isNumber(prevSampleId)}
        className={classes.button}
        startIcon={<NavigateBefore />}
        data-test-id="sample-nav-prev-button"
      >
        {!isMobile ? t('pageActionBar.actionPrevious') : ''}
      </Button>
      <div className={classes.space} />
      <Button
        component={RouterLink}
        to={getUrl(navigation, materialId, queryParams, nextSampleId)}
        disabled={!isNumber(nextSampleId)}
        className={classes.button}
        endIcon={<NavigateNext />}
        data-test-id="sample-nav-next-button"
      >
        {!isMobile ? t('pageActionBar.actionNext') : ''}
      </Button>
    </div>
  )
}
