import React from 'react'
import {useTranslation} from 'react-i18next'

import {CementStrengthActionBar} from '../components/actionBar/CementStrengthActionBar'
import {MaterialChartsContainer} from '../container/MaterialChartsContainer'
import {usePlantFullName} from '../hooks/usePlantFullName'

import {PlantShellPage} from './PlantShellPage'

export const CementStrengthPage: React.FC = () => {
  const {t} = useTranslation()
  const plantName = usePlantFullName()

  return (
    <PlantShellPage>
      <CementStrengthActionBar
        title={t('cementStrengthPredictionPage.title', {plant: plantName})}
      />
      <MaterialChartsContainer />
    </PlantShellPage>
  )
}
