import {AppName, PlantId, WhiteList} from '@hconnect/common/hproduce/types'
import {IconButton, makeStyles, Popover} from '@material-ui/core'
import {Apps as AppsIcon} from '@material-ui/icons'
import React, {useRef, useState, useMemo} from 'react'

import {PlantSelect} from './PlantSelect'
import {ProductSelect} from './ProductSelect'

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    marginTop: theme.spacing(2.5),
    minWidth: '300px'
  }
}))

type AppSelectProps = {
  activeAppName?: AppName | 'HProduce' | undefined
  activePlantId?: PlantId | undefined
  'data-test-id'?: string
  whiteList: WhiteList
}

export const AppSelect: React.FC<AppSelectProps> = ({
  activeAppName,
  activePlantId,
  'data-test-id': dataTestId = 'select',
  whiteList
}) => {
  const {optionsPerPlant} = whiteList

  const allPlants = useMemo(
    () =>
      Object.keys(optionsPerPlant).map((plantId) => ({
        plantId,
        plantName: optionsPerPlant[plantId][0]?.plantName
      })),
    [optionsPerPlant]
  )

  const [plantId, setPlantId] = useState(() => {
    const plantId =
      activePlantId ||
      (activeAppName &&
        activeAppName !== 'HProduce' &&
        whiteList.optionsPerApp[activeAppName]?.[0].plantId) ||
      allPlants[0].plantId

    return plantId
  })

  return (
    <>
      {allPlants.length > 1 && (
        // show the app select only in case there are multiple plants available
        <PlantSelect
          activePlantId={plantId}
          data-test-id={`${dataTestId}-plant`}
          onChange={setPlantId}
          plants={allPlants}
        />
      )}
      <ProductSelect
        data-test-id={`${dataTestId}-product`}
        activeAppName={activeAppName}
        activePlantId={activePlantId}
        options={optionsPerPlant[plantId] ?? []}
      />
    </>
  )
}

type AppSelectPopUpProps = AppSelectProps & {
  'data-test-id'?: string
  iconButtonClassName?: string
}

export const AppSelectPopUp: React.FC<AppSelectPopUpProps> = ({
  'data-test-id': dataTestId = 'grid-menu-icon',
  iconButtonClassName,
  ...rest
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const refContainer = useRef(null)
  const classes = useStyles()

  return (
    <>
      <IconButton
        className={iconButtonClassName}
        data-test-id={dataTestId}
        ref={refContainer}
        onClick={() => setOpen(!isOpen)}
        size="medium"
      >
        <AppsIcon />
      </IconButton>
      <Popover
        anchorEl={refContainer?.current}
        classes={{paper: classes.popoverPaper}}
        data-test-id={`${dataTestId}-popover`}
        onClose={() => setOpen(false)}
        open={isOpen}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <AppSelect {...rest} data-test-id={`${dataTestId}-select`} />
      </Popover>
    </>
  )
}
