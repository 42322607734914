import * as d3 from 'd3'
import {range, sortedUniq} from 'lodash'
import moment from 'moment'

import type {
  AxisFormatFn,
  CementStrengthSample,
  KilnDataSet,
  LinearScaleFn,
  LinearScaleFnWithTicks,
  LinearTimeScaleFn,
  StrengthLevel,
  TimeRange,
  WithDateTime,
  WithValue
} from '../../declarations'
import {getQualityValues, getValuesByLevel, isStrengthLevel} from '../cementStrength'
import {calcTimeOffsetsInMilliSecsOfUtcTimes, formatTimeZoneDate} from '../dateUtils'
import {filterSamplesByTimeRange} from '../filter'
import {inTimeRange} from '../timeRange'

export const average = (x1: number, x2: number): number => {
  return 0.5 * (x1 + x2)
}

export const calcTimeScale = ({
  timeRange,
  timeZone,
  graphWidth,
  tickWidth = 32
}: {
  timeRange: TimeRange
  timeZone: string
  graphWidth: number
  tickWidth?: number
}): {x: LinearTimeScaleFn; ticks: number[]} => {
  // The x-domain is in local time.
  // For example start is Apr 1 2021 0:00:00 GMT+0200 (Central European summer time) which is 2021-03-31T22:00:00.000Z in UTC
  // D3 auto-generates the ticks based on the UTC value,
  // making the first tick at 2021-04-01T00:00:00.000Z which is 2 o'clock
  // at local time. So we need shift back the tick by 2 hours to have the tick
  // at 12am local time with is 10pm UTC.
  const domain = [timeRange.start, timeRange.end]
  const shiftedTimeRange = addTimeZoneOffsetOfUtcTicks(domain, timeZone)
  const xShifted = d3.scaleUtc().domain(shiftedTimeRange).range([0, graphWidth])
  const numTicks = Math.floor(graphWidth / tickWidth)
  const ticks = subtractTimeZoneOffsetOfUtcTicks(xShifted.ticks(numTicks) as Date[], timeZone)

  return {
    x: d3.scaleUtc().domain(domain).range([0, graphWidth]),
    ticks
  }
}

export const addTimeZoneOffsetOfUtcTicks = (utcTimes: Date[], timeZone: string): number[] => {
  return calcTimeOffsetsInMilliSecsOfUtcTimes(utcTimes, timeZone).map(
    (offset, idx) => utcTimes[idx].getTime() + offset
  )
}

export const subtractTimeZoneOffsetOfUtcTicks = (utcTimes: Date[], timeZone: string): number[] => {
  return calcTimeOffsetsInMilliSecsOfUtcTimes(utcTimes, timeZone).map(
    (offset, idx) => utcTimes[idx].getTime() - offset
  )
}

export const dataYRange = (samples: CementStrengthSample[], strengthLevel: StrengthLevel) => {
  return d3.extent(getValuesByLevel(samples, strengthLevel), (d) => d)
}

export const calcFieldYScaleFunc = (
  field: string,
  samples: CementStrengthSample[],
  graphHeight: number,
  suggestedTickCount?: number
): LinearScaleFnWithTicks => {
  const domain = isStrengthLevel(field)
    ? dataYRange(samples, field)
    : d3.extent(getQualityValues(samples, field), (s) => s.value)
  return d3.scaleLinear().domain(domain).nice(suggestedTickCount).range([graphHeight, 0])
}

export const calcYScaleFunc = (
  actualValues: WithValue[],
  graphHeight: number,
  suggestedTickCount: number,
  predictions?: WithValue[]
): LinearScaleFnWithTicks => {
  const domain = d3.extent(
    predictions ? [...actualValues, ...predictions] : actualValues,
    (sample) => sample.value
  )
  return d3.scaleLinear().domain(domain).nice(suggestedTickCount).range([graphHeight, 0])
}

export const getCurveColor = ({
  min,
  max,
  lowerThreshold,
  upperThreshold,
  colorNormal,
  colorError,
  colorGradient
}: {
  min: number
  max: number
  lowerThreshold: number
  upperThreshold: number
  colorNormal: string
  colorError: string
  colorGradient: string
}): string => {
  if (max < lowerThreshold || min > upperThreshold) {
    return colorError
  }

  if (min >= lowerThreshold && max <= upperThreshold) {
    return colorNormal
  }

  return colorGradient
}

export const getStrengthScaleDomain = (
  min: number,
  max: number,
  margin: number,
  stepSize = 1
): [number, number] => [
  Math.max(0, Math.floor((min - margin) / stepSize)) * stepSize,
  Math.ceil((max + margin) / stepSize) * stepSize
]

export const getStrengthTicks = ({
  min,
  max,
  margin,
  stepSize
}: {
  min: number
  max: number
  margin: number
  stepSize: number
}): number[] => {
  const [start, stop] = getStrengthScaleDomain(min, max, margin, stepSize)
  // increase stop to include it in the range
  return range(start, stop + 1, stepSize)
}

const MAX_LABEL_WIDTH = 64
const MIN_SLOT_WIDTH = 8

export const hasLabel = (
  tickIndex: number,
  slotWidth: number,
  maxLabelWidth = MAX_LABEL_WIDTH,
  minSlotWidth = MIN_SLOT_WIDTH
): boolean => {
  if (slotWidth < minSlotWidth) {
    return false
  }

  if (slotWidth > maxLabelWidth) {
    return true
  }

  const slotsPerLabel = Math.ceil(maxLabelWidth / slotWidth)
  const offset = Math.floor(slotsPerLabel / 2)
  return (tickIndex - offset) % slotsPerLabel === 0
}

export const averageWidth = (ticks: number[], x: LinearScaleFn) => {
  if (ticks.length < 2) {
    return 0
  }

  const width = x(ticks[ticks.length - 1]) - x(ticks[0])
  return width / (ticks.length - 1)
}

const axisFormat =
  (xTimeTicks: number[], x: LinearScaleFn, timeZone: string, formatStr: string): AxisFormatFn =>
  (dt, idx) =>
    hasLabel(idx, averageWidth(xTimeTicks, x)) ? formatTimeZoneDate(dt, timeZone, formatStr) : ''

interface DurationBreakpoints {
  days: number
  weeks: number
  months: number
  years: number
}

const getDurationBreakpoints = (): DurationBreakpoints =>
  ({
    days: moment.duration(23, 'hours').asMilliseconds(),
    weeks: moment.duration(6.5, 'days').asMilliseconds(),
    months: moment.duration(27, 'days').asMilliseconds(),
    years: moment.duration(364, 'days').asMilliseconds()
  } as const)

const calcTimeFormatStrings = (
  ticks: number[],
  t: (key: string) => string
): {main: string; sub: string} => {
  if (ticks.length < 2) {
    return {main: '', sub: ''}
  }

  const duration = ticks[ticks.length - 1] - ticks[0]
  const breakpoints = getDurationBreakpoints()

  const avgDurationPerTick = duration / (ticks.length - 1)
  if (avgDurationPerTick > breakpoints.years) {
    return {main: t('chartTimeFormats.years.main'), sub: t('chartTimeFormats.years.sub')}
  }
  if (avgDurationPerTick > breakpoints.months) {
    return {main: t('chartTimeFormats.months.main'), sub: t('chartTimeFormats.months.sub')}
  }
  if (avgDurationPerTick > breakpoints.weeks) {
    return {main: t('chartTimeFormats.weeks.main'), sub: t('chartTimeFormats.weeks.sub')}
  }
  if (avgDurationPerTick > breakpoints.days) {
    return {main: t('chartTimeFormats.days.main'), sub: t('chartTimeFormats.days.sub')}
  }
  return {main: t('chartTimeFormats.hours.main'), sub: t('chartTimeFormats.hours.sub')}
}

export const timeAxisFormatter = (
  ticks: number[],
  x: LinearScaleFn,
  timeZone: string,
  tFunc: (key: string) => string
): {format: AxisFormatFn; extraFormat?: AxisFormatFn} => {
  const {main, sub} = calcTimeFormatStrings(ticks, tFunc)

  return {
    format: axisFormat(ticks, x, timeZone, main),
    extraFormat: sub ? axisFormat(ticks, x, timeZone, sub) : undefined
  }
}

export const getTimestampsOfData = (data: WithDateTime[][], timeRange: TimeRange): number[] =>
  sortedUniq(
    data
      .flat()
      .map(({datetime}) => datetime)
      .filter((timestamp) => inTimeRange(timeRange, timestamp))
      .sort()
  )

export const getTimestampsOfKilnData = (data: KilnDataSet, timeRange: TimeRange): number[] =>
  getTimestampsOfData([data.actualValues, data.predictions], timeRange)

export const getTimestampsOfSamplesInRange = (
  samples: WithDateTime[],
  timeRange: TimeRange
): number[] => sortedUniq(filterSamplesByTimeRange(samples, timeRange).map((rec) => rec.datetime))

type Record5Tuple<T> = [T | undefined, T | undefined, T | undefined, T | undefined, T | undefined]

export const getLast5Records = <T>(samples: T[]): Record5Tuple<T> => {
  const lastIdx = samples.length - 1
  return Array.from({length: 5}, (_, i) => samples[lastIdx - i]) as Record5Tuple<T>
}

export const calcTransformMatrix = ({
  posX,
  posY,
  dirX,
  dirY
}: {
  posX: number
  posY: number
  dirX: number
  dirY: number
}): string => {
  const dirLen = Math.sqrt(dirX * dirX + dirY * dirY)
  const hasDirection = dirLen > 0
  if (!hasDirection) {
    return `matrix(1 0 0 1 ${posX} ${posY})`
  }
  const normX = dirX / dirLen
  const normY = dirY / dirLen
  return `matrix(${normX} ${normY} ${-normY} ${normX} ${posX} ${posY})`
}
