import {useQuery, UseQueryResult} from 'react-query'

import {getUnfilteredCementStrengthData} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {timeRangeFromDateTimeParams} from '../common/timeRange'
import type {RawLabData} from '../declarations'

import {useCsTimeRange} from './useCsTimeRange'
import {usePlantId} from './usePlantId'

export const useAllCementStrengthSamples = (): UseQueryResult<RawLabData[]> => {
  const plantId = usePlantId()
  const timeRange = useCsTimeRange()
  return useQuery([QueryKeyName.GetUnfilteredCsSamples, plantId, timeRange], () =>
    getUnfilteredCementStrengthData(plantId, timeRangeFromDateTimeParams(timeRange))
  )
}
