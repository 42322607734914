import {Button, Grid, makeStyles, Typography} from '@material-ui/core'
import {Add, DeleteOutline} from '@material-ui/icons'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SensorMetaData} from '../../declarations'
import {useAdminParamsWithDefaults} from '../../hooks/useAdminParamsWithDefaults'
import {useSensorMetaData} from '../../hooks/useSensorMetaData'
import {useSensorSettingsTableData} from '../../hooks/useSensorSettingsTableData'
import {useTableFilters} from '../../hooks/useTableFilters'
import {useTableSort} from '../../hooks/useTableSort'
import {DefaultBox} from '../DefaultBox'
import {Spacing} from '../Spacing'

import {SensorMetaDataDeleteDialog} from './SensorMetaDataDeleteDialog'
import {SensorMetaDataDialog} from './SensorMetaDataDialog'
import {SensorSettingsTable} from './SensorSettingsTable'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold
  }
}))

const getSelectedItem = (metaData: SensorMetaData[], itemId?: string) =>
  metaData.find(({uniformTag}) => itemId === uniformTag)

const getSelectedItems = (metaData: SensorMetaData[], itemIds: string[]) =>
  metaData.filter(({uniformTag}) => itemIds.includes(uniformTag))

const getSingleOrMultipleItems = (
  metaData: SensorMetaData[],
  itemIds: string[],
  itemId?: string
) => {
  if (itemId) {
    const item = getSelectedItem(metaData, itemId)
    return item ? [item] : []
  }
  return getSelectedItems(metaData, itemIds)
}

const COLUMN_KEYS: (keyof SensorMetaData)[] = ['uniformTag', 'unit', 'description']

export const SensorSettings: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [singleItemId, setSingleItemId] = useState<string>()
  const metaData = useSensorMetaData()
  const {
    adminSensorsTableSort,
    adminSensorsTableFilters,
    adminSensorsTablePageNo,
    adminSensorsTableRowsPerPage
  } = useAdminParamsWithDefaults()
  const paginatedTableData = useSensorSettingsTableData(
    metaData,
    adminSensorsTablePageNo,
    adminSensorsTableRowsPerPage,
    adminSensorsTableFilters,
    adminSensorsTableSort,
    'adminSensorsTableRowsPerPage',
    'adminSensorsTablePageNo'
  )

  const {sortCriterion, setSortCriterion: onSort} =
    useTableSort<keyof SensorMetaData>('adminSensorsTableSort')
  const {filters, setFilters: onFilter} = useTableFilters<keyof SensorMetaData>(
    'adminSensorsTableFilters'
  )

  const onAddSensor = () => {
    setOpenAddDialog(true)
  }

  const onEditSensor = (item: SensorMetaData) => {
    setSingleItemId(item.uniformTag)
    setOpenEditDialog(true)
  }

  return (
    <DefaultBox width="100%" data-test-id="sensor-settings-form-box">
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm>
          <Typography variant="h6" className={classes.title}>
            {t('sensorSettings.title')}
          </Typography>
        </Grid>
        <>
          <Grid item>
            <Button
              startIcon={<DeleteOutline />}
              data-test-id="sensor-settings-multi-delete-button"
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
              disabled={selectedIds.length === 0}
            >
              {t('sensorSettings.delete', {count: selectedIds.length})}
            </Button>
            <SensorMetaDataDeleteDialog
              items={getSingleOrMultipleItems(metaData, selectedIds, singleItemId)}
              open={openDeleteDialog}
              onClose={() => {
                setSingleItemId(undefined)
                setOpenDeleteDialog(false)
              }}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<Add />}
              onClick={onAddSensor}
              data-test-id="sensor-settings-add-button"
            >
              {t('sensorSettings.add')}
            </Button>
            <SensorMetaDataDialog
              open={openAddDialog}
              onClose={() => {
                setOpenAddDialog(false)
              }}
              title={t('sensorSettings.add')}
            />
          </Grid>
        </>
      </Grid>
      <Spacing height={3} />
      <SensorSettingsTable
        columnKeys={COLUMN_KEYS}
        selectedItemIds={selectedIds}
        onIdsSelected={(selectedIds: string[]) => {
          setSelectedIds(selectedIds)
        }}
        paginatedTableData={paginatedTableData}
        onEditItemClicked={(item) => {
          onEditSensor(item)
        }}
        onDeleteItemClicked={(item) => {
          setSingleItemId(item.uniformTag)
          setOpenDeleteDialog(true)
        }}
        sortCriterion={sortCriterion}
        onSort={onSort}
        filters={filters}
        onFilter={onFilter}
      />
      <SensorMetaDataDialog
        open={openEditDialog}
        onClose={() => {
          setSingleItemId(undefined)
          setOpenEditDialog(false)
        }}
        item={getSelectedItem(metaData, singleItemId)}
        title={t('sensorTableEditButton.title')}
      />
    </DefaultBox>
  )
}
