import {Box} from '@material-ui/core'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {STRENGTH_LEVELS} from '../../common/cementStrength'
import {CEMENT_STRENGTH_DOMAIN_MARGIN} from '../../common/charts/chartConfigurations'
import {getStrengthScaleDomain} from '../../common/charts/chartUtils'
import {
  calcCombinedSample,
  createGraphDataFromCementStrengthSamples,
  createGraphDataFromLabMatData
} from '../../common/charts/graphData'
import {GraphData, NumberRange, TimeRange} from '../../declarations'
import {useCsColorFn} from '../../hooks/useColorFn'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useCementStrengthLabData} from '../../hooks/useLabData'
import {useSetCsTimeRangeParams} from '../../hooks/useSetTimeRangeParams'
import {DetailsGraph} from '../charts/DetailsGraph'

import {MaterialDetailsChartContentProps} from './types'

const baseMargin = {left: 64, top: 16, bottom: 32, right: 24} as const

export const CementStrengthDetailsGraph: React.FC<MaterialDetailsChartContentProps> = ({
  materialData,
  materialLevel,
  timeRange,
  selectedSample,
  onSelectSample,
  height = 480
}) => {
  const onTimeRangeChanged = useSetCsTimeRangeParams()
  const {t} = useTranslation()
  const strengthLevel = materialLevel.name
  const {samples} = materialData
  const getColor = useCsColorFn()
  const mainGraphData: GraphData = useMemo(
    () => createGraphDataFromCementStrengthSamples(samples, strengthLevel, t, getColor),
    [getColor, samples, strengthLevel, t]
  )
  const {data} = useCementStrengthLabData()
  const {csSelectedTagIds} = useCsParamsWithDefaults()
  const optionalGraphData: GraphData[] = useMemo(
    () => [
      ...STRENGTH_LEVELS.filter(
        (strength) => csSelectedTagIds.includes(strength) && strength !== strengthLevel
      ).map((strength) => createGraphDataFromCementStrengthSamples(samples, strength, t, getColor)),
      ...(data
        ? createGraphDataFromLabMatData(
            data.metrics.filter(({uniformTag}) => csSelectedTagIds.includes(uniformTag)),
            getColor
          )
        : [])
    ],
    [getColor, csSelectedTagIds, data, samples, strengthLevel, t]
  )

  const mainAxisRange = useMemo((): NumberRange => {
    const [axisMin, axisMax] = getStrengthScaleDomain(
      materialLevel.min,
      materialLevel.max,
      CEMENT_STRENGTH_DOMAIN_MARGIN
    )
    return {min: axisMin, max: axisMax}
  }, [materialLevel.max, materialLevel.min])

  const onSelected = useCallback(
    (datetime: number) => {
      onSelectSample(samples.find((sample) => sample.datetime === datetime)?.id)
    },
    [onSelectSample, samples]
  )

  const selected = useMemo(() => {
    return selectedSample
      ? calcCombinedSample([mainGraphData, ...optionalGraphData], selectedSample.datetime)
      : undefined
  }, [mainGraphData, optionalGraphData, selectedSample])

  return (
    <Box>
      <DetailsGraph
        graphId={`cement-strength-details-graph-${materialData.materialId}`}
        height={height}
        margin={baseMargin}
        timeRange={timeRange}
        mainTargetRange={materialLevel}
        mainAxisRange={mainAxisRange}
        mainGraphData={mainGraphData}
        optionalGraphData={optionalGraphData}
        selected={selected}
        onSelected={onSelected}
        data-test-id={`cement-strength-details-graph-${materialData.materialId}`}
        onTimeRangeChanged={(timeRange: TimeRange<number>) => {
          onTimeRangeChanged(timeRange.start, timeRange.end)
        }}
      />
    </Box>
  )
}
