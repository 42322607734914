import {Button, makeStyles, TextField, Theme, Typography} from '@material-ui/core'
import {TablePaginationActionsProps} from '@material-ui/core/TablePagination/TablePaginationActions'
import {FirstPage, LastPage, NavigateBefore, NavigateNext} from '@material-ui/icons'
import {clamp} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {stripNonNumbers} from '../../common/helpers'
import {calcPageFromTo, lastPage, pageToString} from '../../common/tablePageUtils'

const useStyles = makeStyles((theme: Theme) => ({
  paginationActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  navigationButton: {
    minWidth: 'auto',
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2
  },
  navigationText: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2
  },
  pageField: {
    width: 64
  },
  pageFieldInput: {
    textAlign: 'center'
  }
}))

export const PaginationActions: React.FC<TablePaginationActionsProps> = (props) => {
  const {count, page, rowsPerPage, onPageChange} = props
  const classes = useStyles()
  const {t} = useTranslation()
  const [pageFromInput, setPageFromInput] = useState(pageToString(page))

  const onFirstPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0)
    },
    [onPageChange]
  )

  const onPrevPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, page - 1))
    },
    [onPageChange, page]
  )

  const onNextPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.min(page + 1, lastPage(count, rowsPerPage)))
    },
    [count, onPageChange, page, rowsPerPage]
  )

  const onLastPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, lastPage(count, rowsPerPage))
    },
    [count, onPageChange, rowsPerPage]
  )

  const onPageNumberEntered = useCallback(() => {
    const pageNumber = +pageFromInput
    const newPage = isNaN(pageNumber)
      ? page
      : clamp(pageNumber - 1, 0, lastPage(count, rowsPerPage))
    setPageFromInput(pageToString(newPage))
    onPageChange(null, newPage)
  }, [count, onPageChange, page, pageFromInput, rowsPerPage])

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageFromInput(stripNonNumbers(event.target.value))
    },
    []
  )

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onPageNumberEntered()
        event.preventDefault()
      }
    },
    [onPageNumberEntered]
  )

  useEffect(() => {
    setPageFromInput(pageToString(page))
  }, [page])

  return (
    <div className={classes.paginationActionsContainer}>
      <Button
        className={classes.navigationButton}
        variant="outlined"
        aria-label={t('paginationActions.firstPage')}
        disabled={page === 0}
        onClick={onFirstPage}
        data-test-id="pagination-actions-btn-first"
      >
        <FirstPage />
      </Button>
      <Button
        className={classes.navigationButton}
        variant="outlined"
        aria-label={t('paginationActions.previousPage')}
        disabled={page === 0}
        onClick={onPrevPage}
        data-test-id="pagination-actions-btn-prev"
      >
        <NavigateBefore />
      </Button>
      <Typography variant="body2" className={classes.navigationText}>
        {t('paginationActions.displayedPage', {
          ...calcPageFromTo(page, rowsPerPage, count),
          total: count
        })}
      </Typography>
      <TextField
        className={classes.pageField}
        value={pageFromInput}
        onChange={onTextChange}
        onKeyUp={onKeyUp}
        onBlur={onPageNumberEntered}
        inputProps={{className: classes.pageFieldInput}}
      />
      <Typography variant="body2" className={classes.navigationText}>
        {t('paginationActions.ofTotal', {total: lastPage(count, rowsPerPage) + 1})}
      </Typography>
      <Button
        className={classes.navigationButton}
        variant="outlined"
        aria-label={t('paginationActions.nextPage')}
        disabled={page === lastPage(count, rowsPerPage)}
        onClick={onNextPage}
        data-test-id="pagination-actions-btn-next"
      >
        <NavigateNext />
      </Button>
      <Button
        className={classes.navigationButton}
        variant="outlined"
        aria-label={t('paginationActions.lastPage')}
        disabled={page === lastPage(count, rowsPerPage)}
        onClick={onLastPage}
        data-test-id="pagination-actions-btn-last"
      >
        <LastPage />
      </Button>
    </div>
  )
}
