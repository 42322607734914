import {makeStyles, Theme} from '@material-ui/core'
import React from 'react'

interface SpacingProps {
  height?: number
  width?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  spacing: ({height, width}: SpacingProps) => ({
    height: theme.spacing(height ?? 0),
    width: theme.spacing(width ?? 0)
  })
}))

export const Spacing: React.FC<SpacingProps> = (props) => {
  const classes = useStyles(props)
  return <div className={classes.spacing} />
}
