import {Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {DefaultBox} from '../components/DefaultBox'
import {usePlantFullName} from '../hooks/usePlantFullName'
import {usePlantNavigation} from '../hooks/usePlantNavigation'

import {PlantShellPage} from './PlantShellPage'

export const PlantHomePage: React.FC = () => {
  const {t} = useTranslation()
  const navigation = usePlantNavigation()
  const plantName = usePlantFullName()

  return (
    <PlantShellPage title={plantName}>
      <DefaultBox>
        <Typography>
          <Link to={navigation.getPlantUrl({plantPage: 'freeLime'})}>
            {t('freeLimePredictionPage.title')}
          </Link>
        </Typography>
        <Typography>
          <Link to={navigation.getPlantUrl({plantPage: 'cementStrength'})}>
            {t('cementStrengthPredictionPage.title')}
          </Link>
        </Typography>
      </DefaultBox>
    </PlantShellPage>
  )
}
