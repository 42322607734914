import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {addMapperFns, calcCsTableData} from '../common/tablePageUtils'
import {CsTableData, RawLabData} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {usePlantTimeZone} from './usePlantTimeZone'

export const useCementStrengthTableData = (
  rawData: RawLabData[],
  materialId?: number
): CsTableData => {
  const {
    csTableSort: sortCriterion,
    csTableFilters,
    strength,
    selectedSources
  } = useCsParamsWithDefaults()
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()

  return useMemo(() => {
    const filters = addMapperFns(csTableFilters, t, timeZone)
    return calcCsTableData({
      rawData,
      materialId,
      strengthLevel: strength,
      filters,
      sortCriterion,
      selectedSources
    })
  }, [csTableFilters, t, timeZone, rawData, materialId, strength, sortCriterion, selectedSources])
}
