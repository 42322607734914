import maintainContactPersonImageSrc from '../assets/ContactPersonImage/maintain.jpeg'
import {AppConfig, PLANT_ID} from '../types'

export const maintain: AppConfig = {
  contact: {
    name: 'Rajendra Reddy',
    mail: 'rajendra.reddy@heidelbergcement.com',
    profileImage: maintainContactPersonImageSrc
  },
  name: 'Maintain',
  url: {
    qa: `https://qa.hproduce.digital/Maintain/${PLANT_ID}`,
    uat: `https://uat.hproduce.digital/Maintain/${PLANT_ID}`,
    prod: `https://www.hproduce.digital/Maintain/${PLANT_ID}`
  },
  whiteListedPermissionTypes: ['CONTRIBUTE_TO_ROOT_CAUSE_FAILURE_ANALYSES']
}
