import {isNaN, isNumber, mean, meanBy, sum} from 'lodash'

import {StatsRecord, StatsValues} from '../../declarations'

const pow2 = (x) => x * x

export const calcMeanAbsoluteError = (records: StatsValues[]): number | undefined => {
  const avg = mean(records.map((r) => Math.abs(r.predicted - r.actual)))
  if (isNaN(avg)) {
    return undefined
  }
  return avg
}

export const calcR2Score = (records: StatsValues[]): number | undefined => {
  if (records.length === 0) {
    return undefined
  }
  const mean = meanBy(records, (r) => r.actual)
  const ssRes = sum(records.map((r) => pow2(r.actual - r.predicted)))
  const ssTot = sum(records.map((r) => pow2(r.actual - mean)))
  if (ssTot > 0) {
    return 1 - ssRes / ssTot
  }
  return undefined
}

export const isStatsRecord = (record: unknown): record is StatsRecord =>
  Boolean(record) &&
  isNumber((record as StatsRecord).actual) &&
  isNumber((record as StatsRecord).predicted)
