import {getMaterialLevel} from '../common/cementStrength'
import {MaterialLevel, StrengthLevel} from '../declarations'

import {useMaterial} from './useMaterial'

export const useMaterialLevel = (
  materialId: number | undefined,
  level: StrengthLevel
): MaterialLevel | undefined => {
  const material = useMaterial(materialId)
  return getMaterialLevel(material, level)
}
