import type {
  DateTimeRecord,
  DatetimeRecordDto,
  FreelimeActual,
  FreeLimeData,
  FreelimeOutput,
  FreeLimeSample,
  KilnDataSet,
  TimeRange
} from '../../declarations'
import {parseDate, parseTimeRange} from '../dateUtils'

import {client} from './client'

const mapFreeLimeActuals = (actuals: FreelimeActual[]): FreeLimeSample[] =>
  actuals.map(({time, value, alite, belite, lsfClinker}) => ({
    datetime: parseDate(time),
    value,
    alite,
    belite,
    lsfClinker
  }))

const mapDatetimeRecords = (records: DatetimeRecordDto[]): DateTimeRecord[] =>
  records.map(({time, value}) => ({datetime: parseDate(time), value}))

export const convertFreelimeOutput = (freelimeData: FreelimeOutput[]): KilnDataSet[] =>
  freelimeData.map((data) => {
    const dataSet: KilnDataSet = {
      kilnId: data.kilnId ?? '',
      actualValues: mapFreeLimeActuals(data.actuals),
      predictions: mapDatetimeRecords(data.predictions),
      range: parseTimeRange(data.range)
    }
    return dataSet
  })

export const getFreeLimeData = async (plantId: string, range: TimeRange): Promise<FreeLimeData> => {
  const response = await client.dfApi.get<FreelimeOutput[]>(
    `/predict/plants/${plantId}/freeLime/predictions?startTime=${range.start.toJSON()}&endTime=${range.end.toJSON()}`
  )

  return {kilnDataSet: convertFreelimeOutput(response.data)}
}
