import {isEmpty, isNumber, isString} from 'lodash'

import type {
  LabMaterialData,
  LabMaterialMetrics,
  LabMetaData,
  LabMetricsMetadata,
  TimeRange
} from '../../declarations'
import {LAB_METRICS_MAX_POINTS_RESOLUTION} from '../constants'

import {client} from './client'
import {toTagsParameter} from './parameterUtils'

const mapLabMetricsMetadata = ({
  plantSpecificTag = '',
  uniformTag = '',
  unit = '',
  description = '',
  displayName = '',
  category = ''
}: LabMetricsMetadata): LabMetaData => ({
  plantSpecificTag,
  uniformTag,
  unit,
  description: description.trim(),
  displayName: (displayName || description).trim(),
  category: category.trim()
})

export const getLabMetaData = async (plantId: string): Promise<LabMetaData[]> => {
  const response = await client.dfApi.get<LabMetricsMetadata[]>(
    `/predict/plants/${plantId}/labMetrics`
  )

  return response.data.filter((dto) => isString(dto.plantSpecificTag)).map(mapLabMetricsMetadata)
}

const convertLabMaterialMetrics = (
  metricsDto: LabMaterialMetrics,
  range: TimeRange
): LabMaterialData => {
  return {
    range,
    plantId: metricsDto.plantId ?? '',
    materialId: metricsDto.materialId as number,
    metrics:
      metricsDto.metrics
        ?.filter((dto) => isString(dto.plantSpecificTag))
        .map((metric) => ({
          ...mapLabMetricsMetadata(metric),
          records:
            metric.datapoints?.map(([value, datetime, sampleId]) => ({
              value,
              // convert to milliseconds
              datetime: datetime * 1000,
              sampleId
            })) ?? []
        })) ?? []
  }
}

export const getLabData = async (
  plantId: string,
  materialId: number,
  tagIds: string[],
  range: TimeRange
): Promise<LabMaterialData> => {
  const emptyLabData = {range, plantId, materialId, metrics: []}
  if (isEmpty(tagIds)) {
    return emptyLabData
  }
  const {data} = await client.dfApi.get<LabMaterialMetrics[]>(
    `/predict/plants/${plantId}/labMetrics/data?from=${range.start.toJSON()}&until=${range.end.toJSON()}&unifiedMaterialId=${materialId}&${toTagsParameter(
      tagIds
    )}&maxPoints=${LAB_METRICS_MAX_POINTS_RESOLUTION}`
  )

  if (data.length < 1) {
    return emptyLabData
  }
  const materialMetricsDto = data[0]
  if (!isNumber(materialMetricsDto.materialId)) {
    throw new Error('material ID not set')
  }
  return convertLabMaterialMetrics(materialMetricsDto, range)
}
