import {Box, makeStyles, Tab, Tabs, Theme} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {userMayWriteSettings} from '../common/backend'
import {DEFAULT_SETTINGS_VIEW, SETTINGS_VIEWS} from '../common/constants'
import {PageTitle} from '../components/PageTitle'
import {CementStrengthTargetSettings} from '../components/settings/CementStrengthTargetSettings'
import {CementStrengthViewSettings} from '../components/settings/CementStrengthViewSettings'
import {FreeLimeTargetSettings} from '../components/settings/FreeLimeTargetSettings'
import {FreeLimeViewSettings} from '../components/settings/FreeLimeViewSettings'
import {ProcessMetaDataSettings} from '../components/settings/ProcessMetaDataSettings'
import {Spacing} from '../components/Spacing'
import {SettingsView} from '../declarations'
import {useSetQueryParam} from '../hooks/useSetQueryParam'
import {useSettingsParamsWithDefaults} from '../hooks/useSettingsParamsWithDefaults'
import {useUser} from '../hooks/useUser'

const settingsViewToTabIndex = (settingsView: SettingsView): number =>
  SETTINGS_VIEWS.findIndex((view) => settingsView === view)

const tabIndexToSettingsView = (index: number): SettingsView =>
  SETTINGS_VIEWS[index] ?? DEFAULT_SETTINGS_VIEW

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  tabContainer: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3)
  },
  tabTitle: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3)
    }
  }
}))

interface TabContentProp {
  disabled?: boolean
}

const TargetLevels: React.FC<TabContentProp> = ({disabled}) => (
  <div data-test-id="settings-container-panel-target-levels">
    <CementStrengthTargetSettings disabled={disabled} />
    <Spacing height={2} />
    <FreeLimeTargetSettings disabled={disabled} />
  </div>
)

const DefaultViews: React.FC<TabContentProp> = ({disabled}) => (
  <div data-test-id="settings-container-panel-default-view">
    <CementStrengthViewSettings disabled={disabled} />
    <Spacing height={2} />
    <FreeLimeViewSettings disabled={disabled} />
  </div>
)

const TAB_CONTENT_MAP: Record<SettingsView, React.FC<TabContentProp>> = {
  targetLevels: TargetLevels,
  defaultViews: DefaultViews,
  sensors: ProcessMetaDataSettings
}

const TabContent: React.FC<{settingsView: SettingsView} & TabContentProp> = ({
  settingsView,
  disabled
}) => TAB_CONTENT_MAP[settingsView]({disabled})

export const SettingsContainer: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {settingsView} = useSettingsParamsWithDefaults()
  const setSettingsView = useSetQueryParam('settingsView')
  const user = useUser()
  const isReadOnly = !userMayWriteSettings(user)

  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setSettingsView(tabIndexToSettingsView(newValue))
  }

  return (
    <div>
      <Box className={classes.header}>
        <PageTitle className={classes.tabTitle}>{t('settingsPage.title')}</PageTitle>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={settingsViewToTabIndex(settingsView)}
          onChange={handleChange}
          className={classes.tabContainer}
          data-test-id="settings-container-tabs"
        >
          {SETTINGS_VIEWS.map((view) => (
            <Tab
              key={view}
              label={t(`settingsContainer.tab.${view}`)}
              data-test-id={`settings-container-tab-${view}`}
            />
          ))}
        </Tabs>
      </Box>
      <div role="tabpanel" data-test-id="settings-container-tab-panel">
        {<TabContent settingsView={settingsView} disabled={isReadOnly} />}
      </div>
    </div>
  )
}
