import {difference, forEach, minBy} from 'lodash'

const CURVE_COLORS = [
  '#9f6133',
  '#8936c2',
  '#6b7a00',
  '#00838a',
  '#5e7a8c',
  '#ba3cae',
  '#0077c7',
  '#009d93',
  '#404000',
  '#004040'
] as const

const isColorUsedTimes = (colorMap: Record<string, string>, color: string): number =>
  Object.values(colorMap).reduce((prev, cur) => (color === cur ? prev + 1 : prev), 0)

export const nextFreeColor = (colorMap: Record<string, string>): string =>
  minBy(
    CURVE_COLORS.map((color) => ({color, count: isColorUsedTimes(colorMap, color)})),
    ({count}) => count
  )?.color as string

export const calcUpdatedColorMap = (
  colorMap: Record<string, string>,
  previousIds: string[],
  currentIds: string[]
): Record<string, string> => {
  const newColorMap = {...colorMap}

  // unassign unused colors
  const unselectedIds = difference(previousIds, currentIds)
  forEach(unselectedIds, (id) => {
    delete newColorMap[id]
  })

  // assign each selected ID a unique color, if not already done
  forEach(currentIds, (id) => {
    if (!newColorMap[id]) {
      newColorMap[id] = nextFreeColor(newColorMap)
    }
  })

  return newColorMap
}
