import {Button, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {dateTimeParamsFromHorizon} from '../../common/dateUtils'
import {DateTimeParam, TimeHorizonId, TimeRange} from '../../declarations'

interface QuickSelectionProps {
  selectableTimeHorizons: TimeHorizonId[]
  onSelect: (params: TimeRange<DateTimeParam>) => void
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}))

export const QuickSelection: React.FC<QuickSelectionProps> = ({
  selectableTimeHorizons,
  onSelect
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" gutterBottom align="center">
        {t('quickSelection.title')}
      </Typography>
      {selectableTimeHorizons.map((horizon) => {
        return (
          <Button
            key={horizon}
            data-test-id={`quick-selection-button-${horizon}`}
            variant="text"
            onClick={() => {
              onSelect(dateTimeParamsFromHorizon(horizon))
            }}
          >
            {t(`timeHorizons.${horizon}`)}
          </Button>
        )
      })}
    </div>
  )
}
