import {Axis, AxisFormatFn, LinearScaleFn} from '@hconnect/uikit'
import React, {useMemo} from 'react'

import {COLOR_GRID_LINES} from '../../common/charts/chartConfigurations'

interface TimeAxisProps {
  x: LinearScaleFn
  height: number
  timeTicks: number[]
  textColor: string
  format?: AxisFormatFn
  extraFormat?: AxisFormatFn
}

export const TimeAxis: React.FC<TimeAxisProps> = ({
  x,
  height,
  timeTicks,
  textColor,
  format,
  extraFormat
}) => {
  const xPositions = useMemo(() => timeTicks.map((xTick) => x(xTick)), [timeTicks, x])

  return (
    <>
      <g>
        {xPositions.map((xPos) => {
          return (
            <g key={xPos}>
              <line
                x1={xPos}
                x2={xPos}
                y1={0}
                y2={height}
                strokeWidth={1}
                stroke={COLOR_GRID_LINES}
                fill="none"
              />
            </g>
          )
        })}
      </g>
      <Axis
        scale={x}
        position="bottom"
        posY={height}
        format={format}
        extraFormat={extraFormat}
        tickValues={timeTicks}
        color="transparent"
        textColor={textColor}
      />
    </>
  )
}
