import {Box, Button, ButtonProps, makeStyles, Theme, Tooltip} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import React, {Ref} from 'react'

import Typography from '../../Typography'

interface Props {
  focus: boolean
  buttonRef: Ref<HTMLButtonElement>
  setFocus: (focus: boolean) => void
  label: string
  isDateChange?: boolean
  isDateChangeLabel?: string
  simpleLayout?: boolean
  customButtonProps?: ButtonProps
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `${theme.spacing(0.125)}px solid ${theme.palette.primary.main}`
  }
}))

export const DatePickerButton: React.FC<Props> = ({
  focus,
  buttonRef,
  setFocus,
  label,
  isDateChange,
  isDateChangeLabel,
  customButtonProps,
  simpleLayout = false
}) => {
  const classes = useStyles()
  const buttonProps: ButtonProps = {
    variant: 'text',
    ref: buttonRef,
    id: 'date-picker-toggle-button',
    onClick: () => {
      setFocus(!focus)
    },
    className: isDateChange ? classes.button : '',
    ...customButtonProps
  }

  return (
    <Tooltip open={isDateChange} title={isDateChangeLabel ?? ''} placement="right">
      <Button {...buttonProps}>
        {simpleLayout ? (
          label
        ) : (
          <Box display="flex" alignItems="center">
            <Typography customVariant="formLabel" color="primary">
              {label}
            </Typography>
            <Box mx={0.25} />
            <DateRangeIcon fontSize="inherit" color="primary" />
          </Box>
        )}
      </Button>
    </Tooltip>
  )
}
