import {isNumber} from 'lodash'

import type {
  CementStrengthSample,
  CementStrengthSampleDetails,
  CsSampleChemicalDetails,
  CsSampleGeneralDetails,
  CsSampleMineralogicalDetails,
  CsSampleParticleSizes,
  CsSamplePhysicalDetails,
  CsSampleSource,
  CsSampleStrengthDetails,
  CsSampleType,
  FormattedQualityParameter
} from '../../declarations'
import {formatTimeZoneDate} from '../dateUtils'
import {formatFloat} from '../format'

export const SAMPLE_TYPES: CsSampleType[] = ['mill', 'silo', 'hourlyProduction', 'dailyAverage']

export const getDefaultSampleSources = (
  sources: CsSampleSource[],
  defaultTypes: CsSampleType[]
): string[] =>
  sources.filter(({sampleType}) => defaultTypes.includes(sampleType)).flatMap(({names}) => names)

type CsSampleNumericalDetailsKey =
  | keyof CsSamplePhysicalDetails
  | keyof CsSampleChemicalDetails
  | keyof CsSampleMineralogicalDetails
  | keyof CsSampleParticleSizes
  | keyof CsSampleStrengthDetails

const formatValue = (value?: number): string | undefined =>
  isNumber(value) ? formatFloat(value) : undefined

const objectToFormattedParameterList = (
  obj: Partial<Record<keyof CementStrengthSampleDetails, string | undefined>>
): FormattedQualityParameter[] =>
  Object.entries<string | undefined>(obj).map(([key, value]) => ({
    key: key as keyof CementStrengthSampleDetails,
    value
  }))

const numberObjectToFormattedParameterList = (
  sample: Partial<Record<CsSampleNumericalDetailsKey, number | undefined>>
): FormattedQualityParameter[] =>
  Object.entries<number | undefined>(sample).map(([key, value]) => ({
    key: key as CsSampleNumericalDetailsKey,
    value: formatValue(value)
  }))

// General
export const getGeneralData = (
  sample: CsSampleGeneralDetails | undefined,
  timeZone: string,
  dateTimeFormat: string
): FormattedQualityParameter[] => {
  const map: Record<keyof CsSampleGeneralDetails, string | undefined> = {
    id: sample ? String(sample.id) : undefined,
    datetime: sample ? formatTimeZoneDate(sample.datetime, timeZone, dateTimeFormat) : undefined,
    unifiedMaterialName: sample?.unifiedMaterialName,
    millName: sample?.millName,
    siloNumber: isNumber(sample?.siloNumber) ? String(sample?.siloNumber) : undefined
  }

  return objectToFormattedParameterList(map)
}

// Physical
export const getPhysicalData = (sample?: CsSamplePhysicalDetails): FormattedQualityParameter[] => {
  const map: Record<keyof CsSamplePhysicalDetails, number | undefined> = {
    loi: sample?.loi,
    waterDemand: sample?.waterDemand,
    settingInitial: sample?.settingInitial,
    settingFinal: sample?.settingFinal,
    expansion: sample?.expansion,
    falseSet: sample?.falseSet,
    airContent: sample?.airContent,
    colorL: sample?.colorL,
    colorA: sample?.colorA,
    colorB: sample?.colorB
  }

  return numberObjectToFormattedParameterList(map)
}

// Chemical (XRF)
export const getChemicalData = (sample?: CsSampleChemicalDetails): FormattedQualityParameter[] => {
  // Copy only the chemical values and ignore the rest.
  const map: Record<keyof CsSampleChemicalDetails, number | undefined> = {
    caO: sample?.caO,
    siO2: sample?.siO2,
    al2O3: sample?.al2O3,
    fe2O3: sample?.fe2O3,
    mgO: sample?.mgO,
    sO3: sample?.sO3,
    k2O: sample?.k2O,
    na2O: sample?.na2O,
    cl: sample?.cl,
    insolubleResidue: sample?.insolubleResidue
  }

  return numberObjectToFormattedParameterList(map)
}

// Mineralogical
export const getMineralogicalData = (
  sample?: CsSampleMineralogicalDetails
): FormattedQualityParameter[] => {
  const map: Record<keyof CsSampleMineralogicalDetails, number | undefined> = {
    alite: sample?.alite,
    aliteM1: sample?.aliteM1,
    aliteM3: sample?.aliteM3,
    belite: sample?.belite,
    beliteAlpha: sample?.beliteAlpha,
    beliteBeta: sample?.beliteBeta,
    aluminate: sample?.aluminate,
    aluminateCubic: sample?.aluminateCubic,
    aluminateOrtho: sample?.aluminateOrtho,
    ferrite: sample?.ferrite,
    gypsum: sample?.gypsum,
    quartz: sample?.quartz,
    calcite: sample?.calcite,
    slag: sample?.slag,
    flyash: sample?.flyash,
    freeLime: sample?.freeLime,
    portlandite: sample?.portlandite,
    hemihydrate: sample?.hemihydrate,
    anhydrite: sample?.anhydrite,
    dolomite: sample?.dolomite,
    periclase: sample?.periclase,
    arcanite: sample?.arcanite,
    aphthitalite: sample?.aphthitalite,
    syngenite: sample?.syngenite,
    langbeinite: sample?.langbeinite
  }

  return numberObjectToFormattedParameterList(map)
}

export const getParticleSizes = (sample?: CsSampleParticleSizes): FormattedQualityParameter[] => {
  const map: Record<keyof CsSampleParticleSizes, number | undefined> = {
    blaine: sample?.blaine,
    psdSieve32um: sample?.psdSieve32um,
    psdSieve45um: sample?.psdSieve45um,
    psdX10: sample?.psdX10,
    psdX50: sample?.psdX50,
    psdX90: sample?.psdX90,
    psdRrLocation: sample?.psdRrLocation,
    psdRrSlope: sample?.psdRrSlope
  }

  return numberObjectToFormattedParameterList(map)
}

export const getCementStrengthSample = (
  sample?: CementStrengthSampleDetails
): CementStrengthSample | undefined => {
  return sample
    ? {
        id: sample.id,
        datetime: sample.datetime,
        predictedStrength1d: sample.predictedStrength1d,
        predictedStrength2d: sample.predictedStrength2d,
        predictedStrength3d: sample.predictedStrength3d,
        predictedStrength7d: sample.predictedStrength7d,
        predictedStrength28d: sample.predictedStrength28d,
        strength1d: sample.strength1d,
        strength2d: sample.strength2d,
        strength3d: sample.strength3d,
        strength7d: sample.strength7d,
        strength28d: sample.strength28d,
        millName: sample.millName,
        siloNumber: sample.siloNumber,
        alite: sample.alite,
        belite: sample.belite,
        blaine: sample.blaine
      }
    : undefined
}
