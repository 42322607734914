import {Box, Hidden, makeStyles} from '@material-ui/core'
import {isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  getCementStrengthSample,
  getChemicalData,
  getGeneralData,
  getMaterialById,
  getMineralogicalData,
  getParticleSizes,
  getPhysicalData
} from '../common/cementStrength'
import {CementStrengthTable} from '../components/CementStrengthTable'
import {DetailsBox} from '../components/DetailsBox'
import {SampleDataBox} from '../components/SampleDataBox'
import {CementStrengthSampleDetails} from '../declarations'
import {useMaterialId} from '../hooks/useMaterialId'
import {usePlantTimeZone} from '../hooks/usePlantTimeZone'
import {useMaterialContext} from '../providers/MaterialProvider'

interface SampleDataContainerProps {
  sample?: CementStrengthSampleDetails
}

const useStyle = makeStyles((theme) => {
  const margin = theme.spacing(3) / 2
  return {
    containerSmall: {
      margin: -margin
    },
    containerLarge: {
      margin: -margin,
      display: 'flex',
      flexDirection: 'row'
    },
    gridItem: {
      padding: margin
    },
    cemStrengthRowCell: {
      fontSize: 16
    },
    cemStrengthValues: {
      fontWeight: theme.typography.fontWeightBold
    }
  }
})

interface DataBoxProps {
  sample?: CementStrengthSampleDetails
}

const GeneralDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()
  const timeZone = usePlantTimeZone()

  return (
    <div className={classes.gridItem}>
      <SampleDataBox
        qualityParameters={getGeneralData(sample, timeZone, t('sampleDetails.dateFormat'))}
        caption={t('sampleData.generalCaption')}
        data-test-id={'sample-data-container-general-box'}
      />
    </div>
  )
}

const CementStrengthDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()
  const materialId = useMaterialId()
  const {materials} = useMaterialContext()
  const material = isNumber(materialId) ? getMaterialById(materials, materialId) : undefined
  const levels = material?.levels ?? []

  return (
    <div className={classes.gridItem}>
      <DetailsBox
        caption={t('sampleData.strengthCaption')}
        data-test-id="sample-data-container-strength-box"
      >
        <CementStrengthTable
          levels={levels}
          sample={getCementStrengthSample(sample)}
          rowCellClassName={classes.cemStrengthRowCell}
          valueCellClassName={classes.cemStrengthValues}
        />
      </DetailsBox>
    </div>
  )
}

const PhysicalDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()

  return (
    <div className={classes.gridItem}>
      <SampleDataBox
        qualityParameters={getPhysicalData(sample)}
        caption={t('sampleData.physicalCaption')}
        data-test-id={'sample-data-container-physical-box'}
      />
    </div>
  )
}

const ChemicalDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()

  return (
    <div className={classes.gridItem}>
      <SampleDataBox
        qualityParameters={getChemicalData(sample)}
        caption={t('sampleData.chemicalCaption')}
        data-test-id={'sample-data-container-chemical-box'}
      />
    </div>
  )
}

const MineralogicalDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()

  return (
    <div className={classes.gridItem}>
      <SampleDataBox
        qualityParameters={getMineralogicalData(sample)}
        caption={t('sampleData.mineralogicalCaption')}
        data-test-id={'sample-data-container-mineralogical-box'}
      />
    </div>
  )
}

const ParticleDataBox: React.FC<DataBoxProps> = ({sample}) => {
  const {t} = useTranslation()
  const classes = useStyle()

  return (
    <div className={classes.gridItem}>
      <SampleDataBox
        qualityParameters={getParticleSizes(sample)}
        caption={t('sampleData.particleSizeCaption')}
        data-test-id={'sample-data-container-particle-box'}
      />
    </div>
  )
}

export const SampleDataContainer: React.FC<SampleDataContainerProps> = ({sample}) => {
  const classes = useStyle()

  return (
    <>
      <Hidden mdUp>
        <div className={classes.containerSmall}>
          <GeneralDataBox sample={sample} />
          <CementStrengthDataBox sample={sample} />
          <PhysicalDataBox sample={sample} />
          <ChemicalDataBox sample={sample} />
          <MineralogicalDataBox sample={sample} />
          <ParticleDataBox sample={sample} />
        </div>
      </Hidden>
      <Hidden smDown lgUp>
        <div className={classes.containerLarge}>
          <Box width="50%">
            <GeneralDataBox sample={sample} />
            <CementStrengthDataBox sample={sample} />
            <PhysicalDataBox sample={sample} />
            <ChemicalDataBox sample={sample} />
          </Box>
          <Box width="50%">
            <MineralogicalDataBox sample={sample} />
            <ParticleDataBox sample={sample} />
          </Box>
        </div>
      </Hidden>
      <Hidden mdDown>
        <div className={classes.containerLarge}>
          <Box width="33.3%">
            <GeneralDataBox sample={sample} />
            <CementStrengthDataBox sample={sample} />
            <PhysicalDataBox sample={sample} />
            <ChemicalDataBox sample={sample} />
          </Box>
          <Box width="33.3%">
            <MineralogicalDataBox sample={sample} />
          </Box>
          <Box width="33.3%">
            <ParticleDataBox sample={sample} />
          </Box>
        </div>
      </Hidden>
    </>
  )
}
