import {Box, CircularProgress} from '@material-ui/core'
import React from 'react'

import {DefaultBox} from './DefaultBox'

export const Loader: React.FC<{height?: string | number}> = ({height}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={height ?? '100%'}
    >
      <DefaultBox display="flex" p={2} m={3}>
        <CircularProgress />
      </DefaultBox>
    </Box>
  )
}
