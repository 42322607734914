import {isEmpty, isNil, isNumber, trim, uniq} from 'lodash'

export const replaceNonWordCharacters = (name: string, replaceValue = '-'): string => {
  return name.replace(/\W/g, replaceValue)
}

export const toInt = (str: string): number | undefined => {
  const parsed = parseInt(str, 10)
  return isNaN(parsed) ? undefined : parsed
}

export const intToParam = (num: number | undefined | null): string | undefined =>
  isNumber(num) ? String(num) : undefined

export const isType = <T>(o: T | null | undefined): o is T => {
  return !isNil(o)
}

export const getFilenameFromPath = (path: string): string => path.split('/').pop() ?? ''

export const stripExtension = (filename: string): string => filename.replace(/\.[^/.]+$/, '')

export const uniqAdd = <T>(arr: T[], item: T): T[] => uniq([...arr, item])

export const addOrRemove = <T extends string>(arr: T[], item: T, add: boolean): T[] =>
  add ? uniqAdd(arr, item) : arr.filter((elem) => elem !== item)

export const stripNonNumbers = (text: string | null | undefined): string =>
  text?.replace(/[^0-9]/g, '') ?? ''

export const isEqualCaseInsensitive = (a: string, b: string): boolean =>
  a.toLowerCase() === b.toLowerCase()

export const isWhiteSpace = (text: string): boolean => isEmpty(trim(text))

export const includesAll = <T, U extends T>(arr: T[], other: U[]): boolean =>
  other.every((o) => arr.includes(o))
