import {useCallback, useMemo} from 'react'
import {useHistory} from 'react-router-dom'

import {getPageUrl, queryParamsToString} from '../common/navigation'
import {NavigatePlantTarget, PathParams, PlantNavigation, UrlQueryParams} from '../declarations'

import {usePlantParameter} from './usePlantParameter'
import {useQueryParamsWithDefaults} from './useQueryParamsWithDefaults'

const mergePathParamsWithDefaultPlant = (
  defaultPlant: string,
  pathParams?: PathParams
): PathParams => {
  return {...pathParams, plant: pathParams?.plant ?? defaultPlant}
}

const mergeQueryParams = (
  currentQueryParams: UrlQueryParams,
  queryParams: UrlQueryParams | undefined,
  reset: boolean
): UrlQueryParams | undefined => (reset ? queryParams : {...currentQueryParams, ...queryParams})

export const usePlantNavigation = (): PlantNavigation => {
  const history = useHistory()
  const currentPlantParam = usePlantParameter()
  const currentQueryParams = useQueryParamsWithDefaults()

  const navigate = useCallback(
    ({plantPage, pathParams, queryParams, resetQueryParams = false}: NavigatePlantTarget) => {
      const search = queryParamsToString(
        mergeQueryParams(currentQueryParams, queryParams, resetQueryParams)
      )
      history.push({
        pathname: getPageUrl(
          plantPage,
          mergePathParamsWithDefaultPlant(currentPlantParam, pathParams)
        ),
        search
      })
    },
    [currentPlantParam, currentQueryParams, history]
  )
  const getPlantUrl = useCallback(
    ({plantPage, pathParams, queryParams, resetQueryParams = false}: NavigatePlantTarget) =>
      getPageUrl(
        plantPage,
        mergePathParamsWithDefaultPlant(currentPlantParam, pathParams),
        mergeQueryParams(currentQueryParams, queryParams, resetQueryParams)
      ),
    [currentPlantParam, currentQueryParams]
  )

  return useMemo(() => ({navigate, getPlantUrl}), [getPlantUrl, navigate])
}
