import type {TFunction} from 'i18next'
import {isNumber} from 'lodash'

import type {
  CementStrengthSample,
  CombinedSample,
  GraphData,
  IdColorMapFn,
  InfoBoxItem,
  LabData,
  LinearScaleFnWithTicks,
  StrengthLevel,
  VerticalMarkerPoint
} from '../../declarations'
import {getActualValues, getPredictedValues} from '../cementStrength'
import {reduceDataPoints} from '../filter'
import {formatFloat} from '../format'

import {
  MAX_GRAPH_DATA_POINTS,
  PREDICTION_DASHARRAY_SMALL,
  SUGGESTED_TRENDS_TICK_COUNT
} from './chartConfigurations'
import {calcYScaleFunc} from './chartUtils'

const PREDICTED_VALUE_POSTFIX = 'Predicted'

export const createGraphDataFromCementStrengthSamples = (
  samples: CementStrengthSample[],
  strengthLevel: StrengthLevel,
  t: TFunction,
  colorFn: IdColorMapFn
): GraphData => ({
  id: strengthLevel,
  name: t(`chart.selectableCsFields.${strengthLevel}.label`),
  color: colorFn(strengthLevel),
  unit: t(`chart.selectableCsFields.${strengthLevel}.unit`),
  actualValues: getActualValues(samples, strengthLevel),
  predictions: getPredictedValues(samples, strengthLevel)
})

export const createGraphDataFromLabMatData = (
  metrics: LabData[],
  colorFn: IdColorMapFn
): GraphData[] =>
  metrics.map(({uniformTag, unit, displayName, records}) => ({
    id: uniformTag,
    name: displayName,
    color: colorFn(uniformTag),
    unit,
    actualValues: records
  }))

export const calcCombinedSample = (graphData: GraphData[], timestamp: number): CombinedSample => {
  const combinedSample: CombinedSample = {
    datetime: timestamp
  }
  graphData.forEach(({id, actualValues, predictions}) => {
    const actualValue = actualValues.find(({datetime}) => datetime === timestamp)?.value
    const predicted = predictions?.find(({datetime}) => datetime === timestamp)?.value
    if (isNumber(actualValue)) {
      combinedSample[id] = actualValue
    }
    if (isNumber(predicted)) {
      combinedSample[`${id}${PREDICTED_VALUE_POSTFIX}`] = predicted
    }
  })
  return combinedSample
}

export const calcYFuncScaleMap = (
  optionalGraphData: GraphData[],
  graphHeight: number
): Record<string, LinearScaleFnWithTicks> => {
  const result: Record<string, LinearScaleFnWithTicks> = {}
  optionalGraphData.forEach(({id, actualValues, predictions}) => {
    result[id] = calcYScaleFunc(actualValues, graphHeight, SUGGESTED_TRENDS_TICK_COUNT, predictions)
  })
  return result
}

const datetimeFilter = ([key]: [string, number]): boolean => key !== 'datetime'

const toGraphDataId = (key: string): string => key.replace(PREDICTED_VALUE_POSTFIX, '')

export const calcVerticalMarkerPoint = (
  graphData: GraphData[],
  sample: CombinedSample,
  yScaleFnMap
): VerticalMarkerPoint[] =>
  Object.entries(sample)
    .filter(datetimeFilter)
    .map(([key, value]) => {
      const graphDataId = toGraphDataId(key)
      const foundData = graphData.find(({id}) => graphDataId === id)
      if (!foundData) {
        return
      }
      return {id: key, posY: yScaleFnMap[graphDataId](value), color: foundData.color}
    })
    .filter(Boolean) as VerticalMarkerPoint[]

export const labelWithUnit = (label: string, unit?: string): string =>
  unit ? `${label} (${unit})` : label

const valueWithUnit = (value: number, unit?: string): string => {
  const options = {fractionDigits: value < 10 ? 2 : 1}
  return unit ? `${formatFloat(value, options)} ${unit}` : formatFloat(value, options)
}

export const calcInfoBoxItems = (
  graphData: GraphData[],
  sample: CombinedSample,
  t: TFunction
): InfoBoxItem[] =>
  Object.entries(sample)
    .filter(datetimeFilter)
    .map(([key, value]) => {
      const graphDataId = toGraphDataId(key)
      const foundData = graphData.find(({id}) => graphDataId === id)
      if (!foundData) {
        return undefined
      }
      const predicted = key.endsWith(PREDICTED_VALUE_POSTFIX)
      return {
        id: key,
        color: foundData.color,
        label: predicted ? `${foundData.name} ${t('chart.predicted')}` : foundData.name,
        value: valueWithUnit(value, foundData.unit),
        strokeDashArray: predicted ? PREDICTION_DASHARRAY_SMALL : undefined
      }
    })
    .filter(Boolean) as InfoBoxItem[]

export const reduceGraphDataPoints = (graphData: GraphData): GraphData => ({
  ...graphData,
  actualValues: reduceDataPoints(graphData.actualValues, MAX_GRAPH_DATA_POINTS),
  predictions: reduceDataPoints(graphData.predictions ?? [], MAX_GRAPH_DATA_POINTS)
})
