import {useContext} from 'react'

import {SnackbarContext} from '../declarations'
import {SbContext} from '../providers/SnackbarProvider'

export const useSnackbar = (): SnackbarContext => {
  const context = useContext(SbContext)
  if (!context) {
    throw new Error('useSnackbarContext used outside of SnackbarProvider')
  }

  return context
}
