import {useMemo} from 'react'

import {aggregateMaterialData} from '../common/cementStrength'
import {filterSamplesByTimeRangeWithMargin} from '../common/filter'
import {timeRangeFromStartEnd} from '../common/timeRange'

import {useCementStrengthData} from './useCementStrengthData'
import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'

export const useAggregatedCemStrengthData = (materialId?: number) => {
  const {selectedSources, strength, csStart, csEnd} = useCsParamsWithDefaults()
  const query = useCementStrengthData(materialId)
  const {data} = query
  const aggregated = useMemo(
    () =>
      data &&
      aggregateMaterialData(data, {
        sourcesToAggregate: selectedSources,
        strengthLevel: strength
      }),
    [data, selectedSources, strength]
  )

  return useMemo(() => {
    if (query.data && aggregated) {
      return {
        ...query,
        data: {
          ...aggregated,
          samples: filterSamplesByTimeRangeWithMargin(
            aggregated.samples,
            timeRangeFromStartEnd(csStart, csEnd)
          )
        }
      }
    }
    return {...query, data: aggregated}
  }, [aggregated, csEnd, csStart, query])
}
