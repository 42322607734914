import {useMemo} from 'react'

import {DEFAULT_TIME_MARGIN_FACTOR} from '../common/charts/chartConfigurations'
import {filterSamplesByTimeRange} from '../common/filter'
import {padTimeRange, timeRangeFromDateTimeParams} from '../common/timeRange'
import {KilnDataSet} from '../declarations'

import {useFlTimeRange} from './useFlTimeRange'

export const useKilnGraphData = (kilnData: KilnDataSet): KilnDataSet => {
  const flTimeRange = useFlTimeRange()

  return useMemo(() => {
    const paddedRange = padTimeRange(
      timeRangeFromDateTimeParams(flTimeRange),
      DEFAULT_TIME_MARGIN_FACTOR
    )
    const actualValues = filterSamplesByTimeRange(kilnData.actualValues, paddedRange)
    const predictions = kilnData.predictions.filter(
      ({datetime}) => datetime >= paddedRange.start.getTime()
    )
    return {...kilnData, actualValues, predictions}
  }, [flTimeRange, kilnData])
}
