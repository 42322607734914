import {makeStyles} from '@material-ui/core'
import Markdown from 'markdown-to-jsx'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'

import {QueryKeyName} from '../common/constants'
import {DefaultBox} from '../components/DefaultBox'
import manualMainDe from '../manuals/de_DE/main.md'
import manualMainEn from '../manuals/en_US/main.md'
import {ManualImage} from '../manuals/ManualImage'

const langToFolderMap = {
  'en-US': manualMainEn,
  'de-DE': manualMainDe
} as const

const langToManualFolder = (language: string): string => {
  return langToFolderMap[language] ?? manualMainEn
}

const useStyles = makeStyles(() => ({
  box: {},
  markdownWrapper: {
    overflow: 'hidden'
  }
}))

const SCROLL_TO_DELAY_MS = 10

export const UserManualContainer: React.FC = () => {
  const {i18n} = useTranslation()
  const {hash} = useLocation()
  const classes = useStyles()
  const manualFile = langToManualFolder(i18n.language)
  const {data} = useQuery([QueryKeyName.GetUserManual, manualFile], async () => {
    const response = await fetch(manualFile, {
      headers: {'Content-Type': 'text/markdown; charset=UTF-8'}
    })
    return response.text()
  })

  useEffect(() => {
    const anchorId = hash.replace('#', '')
    if (anchorId) {
      const getElemAndScroll = () => {
        document.getElementById(anchorId)?.scrollIntoView({behavior: 'smooth'})
      }

      const handle = setTimeout(getElemAndScroll, SCROLL_TO_DELAY_MS)
      return () => clearTimeout(handle)
    }
  }, [hash])

  return data ? (
    <DefaultBox maxWidth={800} className={classes.box} data-test-id="user-manual-container">
      <div className={classes.markdownWrapper}>
        <Markdown
          options={{
            overrides: {
              a: {
                props: {
                  target: '_blank',
                  rel: 'noopener noreferrer'
                }
              },
              img: {
                component: ManualImage
              }
            }
          }}
        >
          {data}
        </Markdown>
      </div>
    </DefaultBox>
  ) : null
}
