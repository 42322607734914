import {Button, ButtonGroup, makeStyles, Theme, Tooltip} from '@material-ui/core'
import {GridOn, MyLocation, ShowChart} from '@material-ui/icons'
import React, {useMemo} from 'react'
import {TFunction, useTranslation} from 'react-i18next'

import {PredictionView} from '../../declarations'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useSetQueryParam} from '../../hooks/useSetQueryParam'

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    marginBottom: theme.spacing(1)
  },
  buttonSelected: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

interface PredictionViewButtonData {
  view: PredictionView
  label: string
  icon: React.ReactNode
}

const getPredictionViewBtnData = (t: TFunction): PredictionViewButtonData[] => [
  {
    view: 'time',
    label: t('pageActionBar.actionPredictionViewTime'),
    icon: <ShowChart />
  },
  {
    view: 'accuracy',
    label: t('pageActionBar.actionPredictionViewAccuracy'),
    icon: <MyLocation />
  },
  {
    view: 'table',
    label: t('pageActionBar.actionPredictionViewTable'),
    icon: <GridOn />
  }
]

export const PredictionViewButtonGroup: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {predictionView: selectedView} = useCsParamsWithDefaults()
  const onPredictionViewSelected = useSetQueryParam<PredictionView>('predictionView')

  const predictionViewBtnData = useMemo(() => getPredictionViewBtnData(t), [t])

  return (
    <ButtonGroup className={classes.buttonGroup} data-test-id="prediction-view-btn-group">
      {predictionViewBtnData.map(({view, label, icon}) => (
        <Tooltip key={view} title={label}>
          <Button
            className={view === selectedView ? classes.buttonSelected : undefined}
            onClick={() => onPredictionViewSelected(view)}
            aria-label={label}
            data-test-id={`prediction-view-btn-${view}`}
          >
            {icon}
          </Button>
        </Tooltip>
      ))}
    </ButtonGroup>
  )
}
