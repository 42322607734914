import {isString} from 'lodash'

import {ProcessMetaData, SensorMetaData} from '../declarations'

import {isWhiteSpace} from './helpers'

export const trimSensorMetaDataFields = (metaData: SensorMetaData): SensorMetaData => ({
  uniformTag: metaData.uniformTag.trim(),
  unit: metaData.unit.trim(),
  displayName: metaData.displayName.trim(),
  category: metaData.category.trim(),
  description: metaData.description.trim()
})

export const trimProcessMetaDataFields = (metaData: ProcessMetaData): ProcessMetaData => ({
  ...trimSensorMetaDataFields(metaData),
  plantSpecificTag: metaData.plantSpecificTag.trim()
})

export const isProcessMetaDataPlantSpecificTagValid = (plantSpecificTag: string) =>
  !isWhiteSpace(plantSpecificTag)
export const isMetaDataUniformTagValid = (uniformTag: string) => !isWhiteSpace(uniformTag)
export const isMetaDataDescriptionValid = (description: string) => !isWhiteSpace(description)

export const isSensorMetaDataValid = ({uniformTag, description}: SensorMetaData): boolean =>
  isMetaDataUniformTagValid(uniformTag) && isMetaDataDescriptionValid(description)

export const isProcessMetaDataValid = (metaData: ProcessMetaData): boolean =>
  isProcessMetaDataPlantSpecificTagValid(metaData.plantSpecificTag) &&
  isSensorMetaDataValid(metaData)

const isSensorMetaData = (metaData: unknown): metaData is SensorMetaData => {
  if (!metaData) {
    return false
  }
  return (
    isString((metaData as SensorMetaData).uniformTag) &&
    isString((metaData as SensorMetaData).unit) &&
    isString((metaData as SensorMetaData).description) &&
    isString((metaData as SensorMetaData).displayName) &&
    isString((metaData as SensorMetaData).category)
  )
}

export const isProcessMetaData = (metaData: unknown): metaData is ProcessMetaData =>
  isSensorMetaData(metaData) && isString((metaData as ProcessMetaData).plantSpecificTag)
