import {isNumber} from 'lodash'
import {useQuery, UseQueryResult} from 'react-query'

import {getLabData} from '../common/backend'
import {
  DEFAULT_TIME_MARGIN_FACTOR,
  FL_FETCH_INTERVAL_MS
} from '../common/charts/chartConfigurations'
import {QueryKeyName} from '../common/constants'
import {isRelativeDateTimeParam} from '../common/dateUtils'
import {padTimeRange, timeRangeFromStartEnd} from '../common/timeRange'
import {DateTimeParam, LabMaterialData} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'
import {useLabMetaData} from './useLabMetaData'
import {useMaterialId} from './useMaterialId'
import {usePlantId} from './usePlantId'
import {usePlantTimeZone} from './usePlantTimeZone'
import {useSelectedMetaIds} from './useSelectedMetaIds'

const useLabData = (
  selectedTagIds: string[],
  start: DateTimeParam,
  end: DateTimeParam,
  refetchInterval?: number
): UseQueryResult<LabMaterialData> => {
  const plantId = usePlantId()
  const timeZone = usePlantTimeZone()
  const materialId = useMaterialId()
  const metaData = useLabMetaData()
  const ids = useSelectedMetaIds(selectedTagIds, metaData, 'uniformTag')

  return useQuery(
    [QueryKeyName.GetLabData, plantId, ids, timeZone, start, end],
    () => {
      if (!isNumber(materialId)) {
        throw new Error('material ID is not set')
      }
      return getLabData(
        plantId,
        materialId,
        ids,
        padTimeRange(timeRangeFromStartEnd(start, end), DEFAULT_TIME_MARGIN_FACTOR)
      )
    },
    {
      refetchInterval: isRelativeDateTimeParam(end) && refetchInterval,
      staleTime: 0
    }
  )
}

export const useFreeLimeLabData = (): UseQueryResult<LabMaterialData> => {
  const {flSelectedTagIds, flStart, flEnd} = useFlParamsWithDefaults()
  return useLabData(flSelectedTagIds, flStart, flEnd, FL_FETCH_INTERVAL_MS)
}

export const useCementStrengthLabData = (): UseQueryResult<LabMaterialData> => {
  const {csSelectedTagIds, csStart, csEnd} = useCsParamsWithDefaults()
  return useLabData(csSelectedTagIds, csStart, csEnd)
}
