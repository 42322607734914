import {isNumber} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {getMaterialById} from '../common/cementStrength'
import {formatTimeZoneDate} from '../common/dateUtils'
import {toInt} from '../common/helpers'
import {ActionBar} from '../components/actionBar/ActionBar'
import {ErrorMessage} from '../components/ErrorMessage'
import {PageBreadcrumbs} from '../components/PageBreadcrumbs'
import {PageTitle} from '../components/PageTitle'
import {SampleNavigationControls} from '../components/SampleNavigationControls'
import {SampleDataContainer} from '../container/SampleDataContainer'
import {PathParamKey} from '../declarations'
import {useCementStrengthSampleDetails} from '../hooks/useCementStrengthSampleDetails'
import {useCsParamsWithDefaults} from '../hooks/useCsParamsWithDefaults'
import {useMaterialId} from '../hooks/useMaterialId'
import {usePlantNavigation} from '../hooks/usePlantNavigation'
import {usePlantTimeZone} from '../hooks/usePlantTimeZone'
import {useMaterialContext} from '../providers/MaterialProvider'

import {PlantShellPage} from './PlantShellPage'

export const CementStrengthSamplePage: React.FC = () => {
  const {t} = useTranslation()
  const timeZone = usePlantTimeZone()
  const materialId = useMaterialId()
  const sampleId = toInt(useParams<Pick<Record<PathParamKey, string>, 'sampleId'>>().sampleId)
  const queryParams = useCsParamsWithDefaults()
  const navigation = usePlantNavigation()
  const {materials} = useMaterialContext()
  const {data, isLoading, isError} = useCementStrengthSampleDetails(sampleId)

  const material = isNumber(materialId) ? getMaterialById(materials, materialId) : undefined
  const getPageTitle = useCallback(() => {
    if (isLoading) {
      return t('cementStrengthSamplePage.sampleLoading')
    }

    if (isError || !data) {
      return t('errors.noSample')
    }

    return t('cementStrengthSamplePage.title', {
      sampleTime: formatTimeZoneDate(data.datetime, timeZone, t('sampleDetails.dateFormat'))
    })
  }, [data, isError, isLoading, t, timeZone])

  return (
    <PlantShellPage>
      <PageBreadcrumbs
        current={t('navigation.cementStrengthSample', {id: sampleId ?? ''})}
        pathEntries={[
          {
            title: t('navigation.cementStrength'),
            url: navigation.getPlantUrl({
              plantPage: 'cementStrength',
              queryParams
            })
          },
          {
            title: material?.name || t('navigation.cementStrengthTrends'),
            url: navigation.getPlantUrl({
              plantPage: 'cementStrengthTrends',
              pathParams: {materialId},
              queryParams
            })
          }
        ]}
      />

      <ActionBar
        title={
          <PageTitle data-test-id="cement-strength-sample-page-title">{getPageTitle()}</PageTitle>
        }
      >
        <SampleNavigationControls currentSampleId={data?.id} materialId={materialId} />
      </ActionBar>
      {isError ? (
        <ErrorMessage>{t('errors.noSample')}</ErrorMessage>
      ) : (
        <SampleDataContainer sample={data} />
      )}
    </PlantShellPage>
  )
}
