import {isNumber} from 'lodash'

import {updateCementStrengthTargetLevel} from '../../common/backend'
import {isStrengthLevel} from '../../common/cementStrength'
import {isValidTargetRange} from '../../common/math/numberRange'
import {StrengthLevel, TargetRange} from '../../declarations'

import {TargetRangeCategory} from './TargetRangeCollapsableTableForm'

export const isValidCategories = (categories: TargetRangeCategory[]): boolean =>
  categories.every(
    (cat) =>
      isNumber(cat.id) &&
      cat.entries.every(
        ({targetRange, id}) => isStrengthLevel(id) && isValidTargetRange(targetRange)
      )
  )

export const saveCategories = (
  categories: TargetRangeCategory[],
  plantId: string
): Promise<void[]> => {
  if (isValidCategories(categories)) {
    return Promise.all(
      categories.flatMap((cat) =>
        cat.entries.map((entry) =>
          updateCementStrengthTargetLevel(
            plantId,
            cat.id as number,
            entry.id as StrengthLevel,
            entry.targetRange as TargetRange
          )
        )
      )
    )
  }
  throw new Error('Target ranges invalid')
}
