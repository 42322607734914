import {isNumber} from 'lodash'
import {useMemo} from 'react'

import {findPrevSample} from '../common/cementStrength'
import {CementStrengthSample} from '../declarations'

export const usePreviousSample = (
  samples: CementStrengthSample[],
  currentSampleId?: number
): CementStrengthSample | undefined => {
  return useMemo(
    () => (isNumber(currentSampleId) ? findPrevSample(samples, currentSampleId) : undefined),
    [currentSampleId, samples]
  )
}
