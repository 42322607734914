import {User} from '@hconnect/apiclient'

import {PredictUser} from '../../declarations'

import {client} from './client'

export const isUserQcSupervisor = (user?: PredictUser): boolean =>
  user?.roles.includes('QC_SUPERVISOR') ?? false

export const isUserAdmin = (user?: PredictUser): boolean =>
  // TODO: replace with the right role
  user?.roles.includes('QC_SUPERVISOR') ?? false

export const userMayWriteSettings = (user?: PredictUser): boolean =>
  isUserQcSupervisor(user) || isUserAdmin(user)

export const isUserLoggedIn = (user?: PredictUser): boolean => {
  return !!user?.userId
}

const loadUser = async (decodedToken, dfApi): Promise<PredictUser | undefined> => {
  const {user_id: userId, email} = decodedToken
  if (!userId || !email) {
    return undefined
  }
  const response = await dfApi.get(`/users/${userId}`)
  const pUser: User | undefined = response?.data
  if (!pUser?.name) {
    return undefined
  }

  return {userId, email, name: pUser.name, roles: pUser.hasRoles ?? []}
}

export const initUser = async (): Promise<PredictUser | undefined> => {
  const {dfApi, dfLoginFlow} = client
  const loginResponse = await dfLoginFlow.getLoginState()

  if (!loginResponse.loggedIn) {
    return undefined
  }

  return loadUser(loginResponse.decodedToken, dfApi)
}

export const startLoginProcess = () => client.dfLoginFlow.startLoginProcess()

export const startLogoutProcess = () => client.dfLoginFlow.startLogoutProcess()
