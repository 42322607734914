import {AxiosRequestConfig} from 'axios'

import {ClientConfig} from '../../types'

const IS_IE11 = typeof window !== 'undefined' && 'ActiveXObject' in window
const BROWSER_CACHES_API_REQUESTS = IS_IE11

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createNoCacheInterceptor = (clientConfig: Partial<ClientConfig>) => async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  if (BROWSER_CACHES_API_REQUESTS) {
    // Reduced precision to seconds which is enough - also keeps size smaller
    return {
      ...config,
      params: {
        ...(config.params || {}),
        refresh: Math.round(Date.now() / 1000)
      }
    }
  }
  return config
}
