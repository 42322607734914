import {useMemo} from 'react'

import {CsSampleSource} from '../declarations'

import {usePlant} from './usePlant'

export const usePlantSampleSources: () => CsSampleSource[] = () => {
  const plant = usePlant()
  return useMemo(() => plant?.sampleSources ?? [], [plant?.sampleSources])
}
