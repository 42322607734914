import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {DEFAULT_ROWS_PER_PAGE} from '../common/constants'
import {searchStringToUrlQueryParams} from '../common/navigation'
import {UrlAdminParamsWithDefaults} from '../declarations'

export const useAdminParamsWithDefaults = (): UrlAdminParamsWithDefaults => {
  const {search} = useLocation()

  return useMemo(() => {
    const params = searchStringToUrlQueryParams(search)
    return {
      adminSensorsTablePageNo: params.adminSensorsTablePageNo ?? 0,
      adminSensorsTableRowsPerPage: params.adminSensorsTableRowsPerPage ?? DEFAULT_ROWS_PER_PAGE,
      adminSensorsTableSort: params.adminSensorsTableSort,
      adminSensorsTableFilters: params.adminSensorsTableFilters ?? []
    }
  }, [search])
}
