import {HCTheme} from '@hconnect/uikit'
import {createTheme, CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {DefaultOptions, QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'

import {AuthProvider} from './AuthProvider'
import {HelmetHead} from './components/helmet/HelmetHead'
import {Main} from './Main'
import {PromptProvider} from './providers/PromptProvider'
import {SnackbarProvider} from './providers/SnackbarProvider'

const customizedOverrides = {...HCTheme.overrides}
// don't use these overrides
delete customizedOverrides?.MuiIconButton
delete customizedOverrides?.MuiOutlinedInput
delete customizedOverrides?.MuiSelect
delete customizedOverrides?.MuiTypography

const theme = createTheme({
  ...HCTheme,
  overrides: {
    ...customizedOverrides,
    MuiButton: {
      ...HCTheme.overrides?.MuiButton,
      root: {
        ...HCTheme.overrides?.MuiButton?.root,
        textTransform: 'none'
      },
      outlined: {
        ...HCTheme.overrides?.MuiButton?.outlined,
        paddingRight: 16,
        paddingLeft: 16
      }
    },
    MuiTab: {
      ...HCTheme.overrides?.MuiTab,
      root: {
        ...HCTheme.overrides?.MuiTab?.root,
        textTransform: 'none'
      }
    }
  }
})

const DEFAULT_QUERY_STALE_TIME_MS = 300_000
const defaultOptions: DefaultOptions = {
  queries: {
    staleTime: DEFAULT_QUERY_STALE_TIME_MS,
    keepPreviousData: true
  }
}
const queryClient = new QueryClient({defaultOptions})

export const App: React.FC = () => {
  const {t} = useTranslation()

  return (
    <HelmetProvider>
      <HelmetHead title={t('appName')} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <PromptProvider>
                <AuthProvider>
                  <Main />
                </AuthProvider>
              </PromptProvider>
            </Router>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
