import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'
import {useFreeLimeData} from './useFreeLimeData'

export const useFreeLimeKilnLastPrediction = () => {
  const {selectedKilnId} = useFlParamsWithDefaults()
  const {data: freeLimeData} = useFreeLimeData()
  const kilnData = freeLimeData?.kilnDataSet.find((kilnData) => kilnData.kilnId === selectedKilnId)

  if (!kilnData) {
    return undefined
  }

  const {predictions} = kilnData
  return kilnData.predictions[predictions.length - 1]?.datetime
}
