import {makeStyles, Theme} from '@material-ui/core'
import React from 'react'

import {ClippedWrapper} from './ClippedWrapper'

interface HasId {
  id: string
}

export interface GraphInfoBoxItemRenderProps<T extends HasId> {
  item: T
  index: number
  itemWidth: number
  itemHeight: number
}

interface GraphInfoBoxProps<T extends HasId> {
  title: string
  graphWidth: number
  graphHeight: number
  itemHeight?: number
  data: T[]
  itemRender: (props: GraphInfoBoxItemRenderProps<T>) => React.ReactNode
  xPos: number
  boxWidth?: number
  textBoxMarginX?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fill: '#ffffff',
    stroke: theme.palette.grey[700]
  },
  caption: {
    ...theme.typography.caption,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold
  }
}))

const boxBaseHeight = 52
const defaultBoxWidth = 320
const marginX = 8
const marginY = 24
const defaultTextBoxMarginX = 16
const textBoxMarginY = 28
const defaultItemHeight = 24

export const GraphInfoBox = <T extends HasId>({
  title,
  graphWidth,
  graphHeight,
  xPos,
  data,
  itemRender,
  itemHeight = defaultItemHeight,
  boxWidth = defaultBoxWidth,
  textBoxMarginX = defaultTextBoxMarginX
}: GraphInfoBoxProps<T>) => {
  const classes = useStyles()
  const left = xPos > 0.5 * graphWidth
  const boxHeight = boxBaseHeight + data.length * itemHeight
  const rectX = left ? xPos - (boxWidth + marginX) : xPos + marginX
  const rectY = graphHeight - (boxHeight + marginY)
  const clipPathId = 'graph-info-box-clip-path-id'
  const entryWidth = boxWidth - 2 * textBoxMarginX

  return (
    <g>
      <rect
        x={rectX}
        y={rectY}
        height={boxHeight}
        width={boxWidth}
        className={classes.container}
        rx={8}
      />
      <g transform={`translate(${rectX + textBoxMarginX}, ${rectY + textBoxMarginY})`}>
        <defs>
          <clipPath id={clipPathId}>
            <rect x={0} y={-textBoxMarginY} height={boxHeight} width={entryWidth} />
          </clipPath>
        </defs>
        <ClippedWrapper clipPathId={clipPathId}>
          <text className={classes.caption}>{title}</text>
          <g transform={`translate(0, ${32})`}>
            {data.map((item, index) => (
              <g key={item.id} transform={`translate(0, ${index * itemHeight})`}>
                {itemRender({item, index, itemWidth: entryWidth, itemHeight: itemHeight})}
              </g>
            ))}
          </g>
        </ClippedWrapper>
      </g>
    </g>
  )
}
