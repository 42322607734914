import {SimpleDatePicker} from '@hconnect/uikit'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from '@material-ui/core'
import {DateRange} from '@material-ui/icons'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {NOW_PARAM} from '../../common/constants'
import {
  dateTimeParamToLocalDate,
  formatDateTimeParam,
  localTimeToAbsoluteTime,
  parseFormattedDateTimeParam
} from '../../common/dateUtils'
import {isValidDateTimeParamsTimeRange} from '../../common/timeRange'
import {DateTimeParam, TimeHorizonId, TimeRange} from '../../declarations'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'
import {DialogCancelSaveActions} from '../DialogCancelSaveActions'

import {QuickSelection} from './QuickSelection'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600
  },
  nowBtn: {
    padding: 0
  },
  pickerBtn: {
    minWidth: 32,
    '& span': {
      margin: 0
    }
  },
  container: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  fieldsContainer: {
    flexGrow: 2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2)
    }
  },
  quickSelectionContainer: {
    flexGrow: 1,
    borderColor: theme.shadows[9],
    borderStyle: 'solid',
    borderWidth: 0,
    borderTopWidth: 1,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      borderTopWidth: 0,
      borderLeftWidth: 1
    }
  }
}))

interface TimeRangePickerDialogProps {
  open: boolean
  onClose: (selectedRange?: TimeRange<DateTimeParam>) => void
  start: DateTimeParam
  end: DateTimeParam
  selectableTimeHorizons: TimeHorizonId[]
}

const emptyFormat = () => ''

export const TimeRangePickerDialog: React.FC<TimeRangePickerDialogProps> = ({
  open,
  onClose,
  start,
  end,
  selectableTimeHorizons
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const timeZone = usePlantTimeZone()
  const [startDateTime, setStartDateTime] = useState(start)
  const [endDateTime, setEndDateTime] = useState(end)

  const getFormattedDateTime = useCallback(
    (date: DateTimeParam) => formatDateTimeParam(date, timeZone, t('dateFormat.datetime')),
    [t, timeZone]
  )

  const [startText, setStartText] = useState(getFormattedDateTime(start))
  const [endText, setEndText] = useState(getFormattedDateTime(end))

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleApply = useCallback(() => {
    onClose({start: startDateTime, end: endDateTime})
  }, [endDateTime, onClose, startDateTime])

  const handleQuickSelect = useCallback(
    (range: TimeRange<DateTimeParam>) => {
      onClose(range)
    },
    [onClose]
  )

  const handleStartChanged = useCallback(
    (date: Date) => {
      const newTimestamp = localTimeToAbsoluteTime(date, timeZone).getTime()
      setStartDateTime(newTimestamp)
      setStartText(getFormattedDateTime(newTimestamp))
    },
    [getFormattedDateTime, timeZone]
  )

  const handleEndChanged = useCallback(
    (date: Date) => {
      const newTimestamp = localTimeToAbsoluteTime(date, timeZone).getTime()
      setEndDateTime(newTimestamp)
      setEndText(getFormattedDateTime(newTimestamp))
    },
    [getFormattedDateTime, timeZone]
  )

  const handleStartTextChanged = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const dateTimeParam = parseFormattedDateTimeParam(
        event.target.value,
        timeZone,
        t('dateFormat.datetime')
      )
      setStartDateTime(dateTimeParam)
      setStartText(getFormattedDateTime(dateTimeParam))
    },
    [getFormattedDateTime, t, timeZone]
  )

  const handleNowBtn = useCallback(() => {
    setEndDateTime(NOW_PARAM)
    setEndText(NOW_PARAM)
  }, [])

  const handleEndTextChanged = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const dateTimeParam = parseFormattedDateTimeParam(
        event.target.value,
        timeZone,
        t('dateFormat.datetime')
      )
      setEndDateTime(dateTimeParam)
      setEndText(getFormattedDateTime(dateTimeParam))
    },
    [getFormattedDateTime, t, timeZone]
  )

  const onEnter = () => {
    setStartDateTime(start)
    setStartText(getFormattedDateTime(start))
    setEndDateTime(end)
    setEndText(getFormattedDateTime(end))
  }

  const placeholder = t('timeRangePickerDialog.placeholder')

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="time-range-picker-dialog-title"
      PaperProps={{className: classes.dialog}}
      TransitionProps={{onEnter}}
    >
      <DialogTitle id="time-range-picker-dialog-title">
        {t('timeRangePickerDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          <Box className={classes.fieldsContainer}>
            <Box
              mb={3}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box flexGrow={1} mr={1}>
                <TextField
                  value={startText}
                  onChange={(event) => {
                    setStartText(event.target.value)
                  }}
                  onBlur={handleStartTextChanged}
                  fullWidth
                  name="startDate"
                  placeholder={placeholder}
                  label={t('datePicker.startDate')}
                />
              </Box>
              <Box>
                <SimpleDatePicker
                  date={dateTimeParamToLocalDate(startDateTime, timeZone)}
                  handleDateChange={handleStartChanged}
                  datePickerButtonWithSimpleLayout
                  showDatePickerButton
                  datePickerButtonProps={{
                    className: classes.pickerBtn,
                    startIcon: <DateRange />,
                    variant: 'outlined'
                  }}
                  textFormatter={emptyFormat}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box flexGrow={1} mr={1}>
                <TextField
                  value={endText}
                  onChange={(event) => {
                    setEndText(event.target.value)
                  }}
                  onBlur={handleEndTextChanged}
                  fullWidth
                  name="endDate"
                  placeholder={placeholder}
                  label={t('datePicker.endDate')}
                  InputProps={{
                    endAdornment: (
                      <Button variant="contained" className={classes.nowBtn} onClick={handleNowBtn}>
                        {t('timeRangePickerDialog.now')}
                      </Button>
                    )
                  }}
                />
              </Box>
              <Box>
                <SimpleDatePicker
                  date={dateTimeParamToLocalDate(endDateTime, timeZone)}
                  handleDateChange={handleEndChanged}
                  datePickerButtonWithSimpleLayout
                  showDatePickerButton
                  datePickerButtonProps={{
                    className: classes.pickerBtn,
                    startIcon: <DateRange />,
                    variant: 'outlined'
                  }}
                  textFormatter={emptyFormat}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.quickSelectionContainer}>
            <QuickSelection
              selectableTimeHorizons={selectableTimeHorizons}
              onSelect={handleQuickSelect}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogCancelSaveActions
        onCancel={handleClose}
        onSave={handleApply}
        saveDisabled={!isValidDateTimeParamsTimeRange({start: startDateTime, end: endDateTime})}
      />
    </Dialog>
  )
}
