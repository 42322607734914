import React from 'react'

import {SettingsContainer} from '../container/SettingsContainer'

import {PlantShellPage} from './PlantShellPage'

export const SettingsPage: React.FC = () => {
  return (
    <PlantShellPage>
      <SettingsContainer />
    </PlantShellPage>
  )
}
