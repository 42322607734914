import i18next from 'i18next'
import {isNumber} from 'lodash'

interface FormatOptions {
  locale?: string
  displayPlus?: boolean
  fractionDigits?: number
}

export const formatFloat = (value: number, options: FormatOptions = {}) => {
  const {locale = i18next.language, displayPlus = false, fractionDigits = 1} = options

  return new Intl.NumberFormat(locale, {
    signDisplay: displayPlus ? 'always' : 'auto',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  }).format(value)
}

export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions,
  locale = i18next.language
) => new Intl.NumberFormat(locale, options).format(value)

export const getDecimalSeparator = (locale = i18next.language): string =>
  new Intl.NumberFormat(locale).formatToParts(1.5).find((part) => part.type === 'decimal')?.value ??
  '.'

export const parseFormattedFloat = (
  value: string,
  locale = i18next.language
): number | undefined => {
  const separator = getDecimalSeparator(locale)
  const expression = `[^0-9\\${separator}]`
  const regex = new RegExp(expression, 'g')
  const numVal = value.replace(regex, '').replace(separator, '.')
  if (numVal.length === 0) {
    return undefined
  }
  const num = +numVal
  return isNaN(num) ? undefined : num
}

export const formatFloatOrNa = (
  value: number | undefined,
  fallbackString: string,
  options: FormatOptions = {}
) => (isNumber(value) ? formatFloat(value, options) : fallbackString)
