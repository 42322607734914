import {Box} from '@material-ui/core'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getMaterialById} from '../../common/cementStrength'
import {getChartCategories} from '../../common/charts/chartOptions'
import {addOrRemove} from '../../common/helpers'
import {arrayToParameter} from '../../common/navigation'
import type {ChartCategory} from '../../declarations'
import {
  MIN_CHART_WIDTH,
  useChartScrollableWrapperStyles
} from '../../hooks/useChartScrollableWrapperStyles'
import {useCsColorFn} from '../../hooks/useColorFn'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'
import {useLabMetaData} from '../../hooks/useLabMetaData'
import {useMaterialId} from '../../hooks/useMaterialId'
import {useSetQueryParamWithConverter} from '../../hooks/useSetQueryParam'
import {useMaterialContext} from '../../providers/MaterialProvider'
import {ChartButtonBar} from '../charts/ChartButtonBar'

import {CementStrengthAccuracyDetailsGraph} from './CementStrengthAccuracyDetailsGraph'
import {CementStrengthDetailsGraph} from './CementStrengthDetailsGraph'
import {MaterialDetailsChartContentProps} from './types'

export const MaterialDetailsChartContent: React.FC<MaterialDetailsChartContentProps> = (props) => {
  const {predictionView, csSelectedTagIds} = useCsParamsWithDefaults()
  const {t} = useTranslation()
  const classes = useChartScrollableWrapperStyles()
  const materialId = useMaterialId()
  const {materials} = useMaterialContext()
  const metaData = useLabMetaData()
  const colorFn = useCsColorFn()
  const options: ChartCategory[] = useMemo(() => {
    if (isNumber(materialId)) {
      const material = getMaterialById(materials, materialId)
      return getChartCategories(props.materialLevel, material?.levels ?? [], metaData, t)
    }
    return []
  }, [materialId, materials, metaData, props.materialLevel, t])

  const onSelectCsFields = useSetQueryParamWithConverter<string[]>(
    'csSelectedTagIds',
    arrayToParameter
  )
  const onSelectedOptions = useCallback(
    (selected: string[]) => {
      onSelectCsFields(selected)
    },
    [onSelectCsFields]
  )

  const onSelectedOption = useCallback(
    (id: string, selected: boolean) => {
      onSelectCsFields(addOrRemove(csSelectedTagIds, id, selected))
    },
    [onSelectCsFields, csSelectedTagIds]
  )

  return predictionView === 'time' ? (
    <>
      <ChartButtonBar
        options={options}
        optionColorFn={colorFn}
        selectedOptions={csSelectedTagIds}
        onSelected={onSelectedOptions}
        onOptionSelected={onSelectedOption}
      />
      <Box className={classes.scrollableWrapper}>
        <Box className={classes.chartWrapper}>
          <CementStrengthDetailsGraph {...props} />
        </Box>
      </Box>
    </>
  ) : (
    <Box className={classes.scrollableWrapper}>
      <Box className={classes.chartWrapper} width={MIN_CHART_WIDTH} height={MIN_CHART_WIDTH}>
        <CementStrengthAccuracyDetailsGraph {...props} />
      </Box>
    </Box>
  )
}
