import {useCallback} from 'react'

import {DateTimeParam} from '../declarations'

import {useSetQueryParam} from './useSetQueryParam'

type OnTimeRangeChanged = (start: DateTimeParam, end: DateTimeParam) => void

const useSetTimeRangeParams = (
  startParam: 'csStart' | 'flStart',
  endParam: 'csEnd' | 'flEnd'
): OnTimeRangeChanged => {
  const onTimeRangeStartChanged = useSetQueryParam<DateTimeParam>(startParam)
  const onTimeRangeEndChanged = useSetQueryParam<DateTimeParam>(endParam)
  return useCallback(
    (start: DateTimeParam, end: DateTimeParam) => {
      onTimeRangeStartChanged(start)
      onTimeRangeEndChanged(end)
    },
    [onTimeRangeEndChanged, onTimeRangeStartChanged]
  )
}

export const useSetCsTimeRangeParams = (): OnTimeRangeChanged =>
  useSetTimeRangeParams('csStart', 'csEnd')

export const useSetFlTimeRangeParams = (): OnTimeRangeChanged =>
  useSetTimeRangeParams('flStart', 'flEnd')
