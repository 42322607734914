import {makeStyles, Theme} from '@material-ui/core'
import React from 'react'

import {ClippedWrapper} from './ClippedWrapper'
import {GraphInfoBoxItemRenderProps} from './GraphInfoBox'
import type {InfoBoxItem} from './types'

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    ...theme.typography.caption,
    fontSize: 14
  }
}))

const lineHeight = -4
const legendWidth = 24
const unitWidth = 80

export const GraphInfoBoxItem: React.FC<GraphInfoBoxItemRenderProps<InfoBoxItem>> = ({
  item,
  itemWidth,
  itemHeight
}) => {
  const classes = useStyles()
  const clipPathId = `graph-info-box-item-clip-id-${item.id}`
  const linearGradientId = `graph-info-box-item-gradient-id-${item.id}`
  const textHeight = (itemHeight * 2) / 3
  const textFadeOutWidth = 24

  return (
    <>
      <line
        x1={0}
        x2={16}
        stroke={item.color}
        strokeWidth={2}
        strokeDasharray={item.strokeDashArray}
        y1={lineHeight}
        y2={lineHeight}
      />
      <g transform={`translate(${legendWidth}, 0)`}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={0}
              y={-textHeight}
              height={itemHeight}
              width={itemWidth - unitWidth - legendWidth}
            />
          </clipPath>
          <linearGradient id={linearGradientId}>
            <stop offset="0%" stopColor="#ffffff00" />
            <stop offset="100%" stopColor="#ffffffff" />
          </linearGradient>
        </defs>
        <ClippedWrapper clipPathId={clipPathId}>
          <text fill={item.color} className={classes.item}>
            {item.label}
          </text>
        </ClippedWrapper>
        <rect
          x={itemWidth - unitWidth - legendWidth - textFadeOutWidth}
          y={-textHeight}
          height={itemHeight}
          width={textFadeOutWidth}
          fill={`url(#${linearGradientId})`}
        />
        <text dx={itemWidth - legendWidth} className={classes.item} textAnchor="end">
          {item.value}
        </text>
      </g>
    </>
  )
}
