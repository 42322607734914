import {Box, makeStyles, Typography} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    color: '#5e7a8c'
  },
  infoIcon: {
    position: 'absolute',
    left: 0
  },
  infoIconWrapper: {
    display: 'inline-block',
    position: 'relative',
    width: 20,
    height: 16,
    marginRight: theme.spacing(1)
  }
}))

interface ErrorMessageProps {
  height?: string | number
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({height, children}) => {
  const classes = useStyles()

  return (
    <Box
      bgcolor="background.paper"
      borderRadius={8}
      p={3}
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="body1" className={classes.message} align="center">
        <span className={classes.infoIconWrapper}>
          <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} />
        </span>
        {children}
      </Typography>
    </Box>
  )
}
