import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {TimeHorizonId} from '../../declarations'

import {StringValueSelect} from './StringValueSelect'

interface TimeHorizonSelectProps {
  selectable: TimeHorizonId[]
  onSelected: (value: TimeHorizonId) => void
  value: TimeHorizonId
  disabled?: boolean
}

export const TimeHorizonSelect: React.FC<TimeHorizonSelectProps> = ({
  selectable,
  onSelected,
  value,
  disabled
}) => {
  const {t} = useTranslation()
  const valueLabel = useCallback((itemValue) => t(`timeHorizons.${itemValue}`), [t])

  return (
    <StringValueSelect
      onSelected={onSelected}
      value={value}
      values={selectable}
      fieldId="predict-select-time"
      data-test-id="predict-select-time"
      valueLabel={valueLabel}
      label={t('pageActionBar.actionTimeHorizonLabel')}
      disabled={disabled}
    />
  )
}
