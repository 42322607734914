import React from 'react'
import {useTranslation} from 'react-i18next'

import {ErrorMessage} from '../components/ErrorMessage'
import {PageContainer} from '../components/PageContainer'
import {PageTitle} from '../components/PageTitle'
import {usePlant} from '../hooks/usePlant'

interface PlantShellPageProps {
  title?: string
  headerActionContent?: React.ReactNode
  pageClassName?: string
}

export const PlantShellPage: React.FC<PlantShellPageProps> = ({title, children}) => {
  const {t} = useTranslation()
  const plant = usePlant()

  return (
    <PageContainer>
      {title && <PageTitle data-test-id="plant-shell-page-title">{title}</PageTitle>}
      {plant ? children : <ErrorMessage>{t('errors.plantNotFoundError')}</ErrorMessage>}
    </PageContainer>
  )
}
