import {PlantId, WhiteList, AppName} from '@hconnect/common/hproduce/types'
import {Box, BoxProps, Divider, IconButton, makeStyles, MenuItem, MenuList} from '@material-ui/core'
import {Close as CloseIcon, Menu as MenuIcon} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useLayoutEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

import {Tab, Tabs} from '../Molecules/Tabs'

import {AppSelect} from './AppSelect'
import {Overlay} from './Overlay'
import {NavItem} from './types'

export const useLinkStyles = makeStyles((theme) => ({
  link: {
    ...theme.typography.h4,
    color: theme.palette.text.primary,
    textDecorationLine: 'none',
    paddingBottom: 4
  },
  verticalLink: {
    paddingTop: 16,
    paddingBottom: 16
  },
  activeLink: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    pointerEvents: 'none'
  },
  divider: {
    margin: theme.spacing(0, 2)
  }
}))

const useStyles = makeStyles((theme) => ({
  activeMenuButton: {
    backgroundColor: theme.palette.grey[50]
  },
  overlayVisibility: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  tab: {
    textTransform: 'capitalize'
  }
}))

enum BurgerMenuTabs {
  menu,
  products,
  settings
}

type BurgerMenuProps = {
  navItems?: NavItem[]
  disableAppSelect?: boolean
  iconButtonClassName?: string
  'data-test-id'?: string

  activeAppName: AppName | 'HProduce'
  activePlantId?: PlantId
  whiteList?: WhiteList
}

interface NavMenuLinksProps extends Pick<BurgerMenuProps, 'navItems' | 'data-test-id'> {
  onSelect?: (selectedItem?: NavItem) => void
}

const MenuLinksBox: React.FC<BoxProps> = (props) => (
  <Box
    bgcolor="background.paper"
    color="text.primary"
    borderRadius={8}
    marginBottom={1}
    {...props}
  />
)

const NavMenuLinks: React.FC<NavMenuLinksProps> = ({
  navItems = [],
  onSelect,
  'data-test-id': dataTestId
}) => {
  const {activeLink, link, verticalLink, divider} = useLinkStyles()
  return (
    <MenuList disablePadding data-test-id={dataTestId}>
      {navItems.map((navItem, index) => (
        <NavLink
          key={`${navItem.label}-${navItem.url}`}
          to={navItem.url}
          activeClassName={activeLink}
          className={classNames(link)}
          data-test-id={
            navItem.dataTestId &&
            (dataTestId ? `${navItem.dataTestId}-${dataTestId}` : navItem.dataTestId)
          }
          onClick={() => onSelect && onSelect(navItem)}
        >
          <MenuItem className={verticalLink}>{navItem.label}</MenuItem>
          {index < navItems.length - 1 && (
            <Divider orientation="horizontal" color="soft" className={divider} />
          )}
        </NavLink>
      ))}
    </MenuList>
  )
}

const getVisibleTabIndices = (
  hasNavItems: boolean,
  disableAppSelect: boolean
): BurgerMenuTabs[] => {
  const result: BurgerMenuTabs[] = []

  // be aware that the order of these checks will define the order of the tabs
  if (hasNavItems) result.push(BurgerMenuTabs.menu)
  if (!disableAppSelect) result.push(BurgerMenuTabs.products)

  return result
}

const getDefaultTabIndex = (hasNavItems: boolean, disableAppSelect: boolean): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect)[0] ?? 0

const validTabIndexOrDefault = (
  tabIndex: number,
  hasNavItems: boolean,
  disableAppSelect: boolean
): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect).includes(tabIndex)
    ? tabIndex
    : getDefaultTabIndex(hasNavItems, disableAppSelect)

export const BurgerMenu: React.FC<BurgerMenuProps> = ({
  navItems = [],
  iconButtonClassName,
  disableAppSelect = false,
  'data-test-id': dataTestId = 'burger-menu',

  activeAppName,
  activePlantId,
  whiteList
}) => {
  const {t} = useTranslation()
  const [isOpen, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const hasNavItems = navItems.length > 0
  const [tabValue, setTabValue] = useState<BurgerMenuTabs>(
    getDefaultTabIndex(hasNavItems, disableAppSelect)
  )

  useLayoutEffect(() => {
    setTabValue(getDefaultTabIndex(hasNavItems, disableAppSelect))
  }, [disableAppSelect, hasNavItems])

  const onClose = () => {
    setOpen(false)
  }

  if (disableAppSelect && !hasNavItems) {
    return null
  }

  const validatedTabValue = validTabIndexOrDefault(tabValue, hasNavItems, disableAppSelect)

  return (
    <>
      <IconButton
        className={classNames(iconButtonClassName, {[classes.activeMenuButton]: isOpen})}
        data-test-id={dataTestId}
        onClick={() => setOpen(!isOpen)}
        size="medium"
        focusRipple={isOpen}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Overlay overlayClassName={classes.overlayVisibility} isVisible={isOpen}>
        <Box height={10} />
        <Tabs
          centered
          indicatorColor="primary"
          textColor="inherit"
          value={validatedTabValue}
          onChange={(e, v: number) => {
            setTabValue(v)
          }}
        >
          {hasNavItems && (
            <Tab value={BurgerMenuTabs.menu} label={t('burgerMenu.menu')} className={classes.tab} />
          )}
          {!disableAppSelect && (
            <Tab
              value={BurgerMenuTabs.products}
              label={t('burgerMenu.products')}
              className={classes.tab}
            />
          )}
        </Tabs>
        <Box height={24} />
        {hasNavItems && validatedTabValue === BurgerMenuTabs.menu && (
          <MenuLinksBox>
            <NavMenuLinks
              navItems={navItems}
              onSelect={onClose}
              data-test-id={`${dataTestId}-list`}
            />
          </MenuLinksBox>
        )}
        {!disableAppSelect && whiteList && validatedTabValue === BurgerMenuTabs.products && (
          <MenuLinksBox>
            <AppSelect
              activeAppName={activeAppName}
              activePlantId={activePlantId}
              data-test-id={dataTestId}
              whiteList={whiteList}
            />
          </MenuLinksBox>
        )}
      </Overlay>
    </>
  )
}
