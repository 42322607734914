import {Shell} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ShellFooter} from '../components/ShellFooter'
import {ShellHeader} from '../components/ShellHeader'

export const DefaultShell: React.FC = ({children}) => {
  const {t} = useTranslation()

  return (
    <Shell
      isResponsive
      boxed={false}
      header={<ShellHeader />}
      footer={<ShellFooter label={t('footer.poweredBy')} />}
    >
      {children}
    </Shell>
  )
}
