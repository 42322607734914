import predictContactPersonImageSrc from '../assets/ContactPersonImage/predict.jpeg'
import {AppConfig, PLANT_SHORT_NAME} from '../types'

export const predict: AppConfig = {
  contact: {
    name: 'Dr. Christoph Deil',
    mail: 'christoph.deil@heidelbergcement.com',
    profileImage: predictContactPersonImageSrc
  },
  name: 'Predict',
  url: {
    qa: `https://predict-qa.heidelbergcement.com/plants/${PLANT_SHORT_NAME}/cement-strength`,
    uat: `https://predict-uat.heidelbergcement.com/plants/${PLANT_SHORT_NAME}/cement-strength`,
    prod: `https://predict.heidelbergcement.com/plants/${PLANT_SHORT_NAME}/cement-strength`
  },
  whiteListedPermissionTypes: ['VIEW_PREDICTIONS'], // ENERGY_MANAGER is missing the VIEW_PREDICTIONS permission
  plant: {
    whiteList: ['0014', 'PL1A', 'U175']
  }
}
