import {Box, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {Content, useShellContext} from '.'

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 66,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    opacity: 0,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    transition: 'opacity, top',
    transitionDuration: '.3s',
    transitionTimingFunction: 'ease'
  },
  overlayVisible: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  stretchedOverlay: {
    top: 50
  },
  contentContainer: {
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: '5%',
    paddingRight: '5%'
  }
}))

type Props = {
  isVisible: boolean
  overlayClassName?: string
  contentClassName?: string
  children: React.ReactNode
  'data-test-id'?: string
}

export const Overlay: React.FC<Props> = ({
  isVisible = false,
  overlayClassName,
  contentClassName,
  children,
  'data-test-id': dataTestId = 'overlay'
}) => {
  const {compact, scrolled} = useShellContext()
  const {overlay, overlayVisible, contentContainer, stretchedOverlay} = useStyles()

  return (
    <Content
      className={classNames(overlayClassName, overlay, {
        [overlayVisible]: isVisible,
        [stretchedOverlay]: compact || scrolled
      })}
      data-test-id={dataTestId}
      data-value={isVisible ? 'open' : 'closed'}
    >
      <Box className={classNames(contentClassName, contentContainer)}>{children}</Box>
    </Content>
  )
}
