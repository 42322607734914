import React from 'react'
import {useTranslation} from 'react-i18next'

import {UserManualContainer} from '../container/UserManualContainer'

import {PlantShellPage} from './PlantShellPage'

export const UserManualPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <PlantShellPage title={t('userManualPage.title')}>
      <UserManualContainer />
    </PlantShellPage>
  )
}
