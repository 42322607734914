import type {HandleBooleanMap} from '../declarations'

export const addHandle = (handle: string) => (map: HandleBooleanMap) => {
  const newMap = {...map}
  newMap[handle] = false
  return newMap
}

export const removeHandle = (handle: string) => (map: HandleBooleanMap) => {
  const newMap = {...map}
  delete newMap[handle]
  return newMap
}

export const enableHandle = (handle: string, enable: boolean) => (map: HandleBooleanMap) => {
  const newMap = {...map}
  newMap[handle] = enable
  return newMap
}

export const isHandleEnabled = (map: HandleBooleanMap) => Object.values(map).some(Boolean)
