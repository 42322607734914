import {makeStyles, TableCell, TableCellProps} from '@material-ui/core'
import {ArrowDownward, ArrowUpward, FilterList} from '@material-ui/icons'
import classNames from 'classnames'
import {noop} from 'lodash'
import React from 'react'

import {isFilteredBy, sortedBy} from '../../common/filter'
import {TableFilterData, TableSort} from '../../declarations'
import {useTableStyles} from '../../hooks/useTableStyles'

interface TableHeaderCellProps<T> extends TableCellProps {
  field: T
  sortCriterion?: TableSort<T>
  filters?: TableFilterData<T>[]
  onHeaderClick?: (
    event:
      | React.MouseEvent<HTMLTableHeaderCellElement>
      | React.MouseEvent<HTMLTableDataCellElement>,
    field: T
  ) => void
  alwaysCellBorder?: boolean
}

const useStyles = makeStyles(() => ({
  headerCellIconWrapper: {
    position: 'absolute',
    top: 0,
    right: 2,
    height: '100%'
  },
  headerCellIconsBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  icon: {
    fontSize: '0.75rem'
  }
}))

export const TableHeaderCell = <T,>(props: TableHeaderCellProps<T>) => {
  const {
    onHeaderClick = noop,
    field,
    filters = [],
    sortCriterion,
    alwaysCellBorder,
    children,
    className,
    ...rest
  } = props
  const classes = useStyles()
  const tableClasses = useTableStyles()
  const filtered = isFilteredBy(filters, field)
  const sortedByOrder = sortedBy(sortCriterion, field)

  return (
    <TableCell
      onClick={(event) => {
        onHeaderClick(event, field)
      }}
      className={classNames(
        tableClasses.tableHeaderCell,
        tableClasses.tableRowCell,
        tableClasses.headerCell,
        tableClasses.cell,
        alwaysCellBorder ? tableClasses.alwaysCellBorder : tableClasses.cellBorder,
        className
      )}
      {...rest}
    >
      {children}
      {Boolean(sortedByOrder) || filtered ? (
        <span className={classes.headerCellIconWrapper}>
          <div className={classes.headerCellIconsBox}>
            {sortedByOrder === 'asc' ? (
              <ArrowUpward className={classes.icon} />
            ) : sortedByOrder === 'desc' ? (
              <ArrowDownward className={classes.icon} />
            ) : null}
            {filtered ? <FilterList className={classes.icon} /> : null}
          </div>
        </span>
      ) : null}
    </TableCell>
  )
}
