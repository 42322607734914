import React from 'react'

import {TargetRange} from '../../declarations'

import {TargetRangeRow} from './TargetRangeRow'
import {TargetRangeTableFormContainer} from './TargetRangeTableFormContainer'
import type {CommonSettingsProps, TargetRangeEntry} from './types'

interface TargetRangeTableFormProps extends CommonSettingsProps {
  headerLabel: string
  unit: string
  targetRangeEntries: TargetRangeEntry[]
  onChange: (entries: TargetRangeEntry[]) => void
}

export const TargetRangeTableForm: React.FC<TargetRangeTableFormProps> = ({
  headerLabel,
  unit,
  targetRangeEntries,
  onChange,
  disabled,
  error
}) => {
  const onNumberChanged = (
    value: number | undefined,
    entry: TargetRangeEntry,
    field: keyof TargetRange
  ) => {
    const newEntries = targetRangeEntries.map((e) =>
      e.id === entry.id
        ? {
            ...entry,
            targetRange: {...entry.targetRange, [field]: value}
          }
        : e
    )
    onChange(newEntries)
  }

  return (
    <TargetRangeTableFormContainer
      headerLabel={headerLabel}
      unit={unit}
      tableRows={targetRangeEntries.map((entry) => (
        <TargetRangeRow
          key={entry.id}
          entry={entry}
          onNumberChanged={onNumberChanged}
          disabled={disabled}
          error={error}
        />
      ))}
    />
  )
}
