import MaterialIconsSvg from '../Assets/fonts/MaterialIcons-Regular.svg'
import Roboto500Svg from '../Assets/fonts/roboto-v20-cyrillic_latin-500.svg'
import Roboto500Ttf from '../Assets/fonts/roboto-v20-cyrillic_latin-500.ttf'
import Roboto500Woff from '../Assets/fonts/roboto-v20-cyrillic_latin-500.woff'
import Roboto500Woff2 from '../Assets/fonts/roboto-v20-cyrillic_latin-500.woff2'
import Roboto700Svg from '../Assets/fonts/roboto-v20-cyrillic_latin-700.svg'
import Roboto700Ttf from '../Assets/fonts/roboto-v20-cyrillic_latin-700.ttf'
import Roboto700Woff from '../Assets/fonts/roboto-v20-cyrillic_latin-700.woff'
import Roboto700Woff2 from '../Assets/fonts/roboto-v20-cyrillic_latin-700.woff2'
import Roboto400Svg from '../Assets/fonts/roboto-v20-cyrillic_latin-regular.svg'
import Roboto400Ttf from '../Assets/fonts/roboto-v20-cyrillic_latin-regular.ttf'
import Roboto400Woff from '../Assets/fonts/roboto-v20-cyrillic_latin-regular.woff'
import Roboto400Woff2 from '../Assets/fonts/roboto-v20-cyrillic_latin-regular.woff2'

const roboto400Woff = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Roboto'),
    url(${Roboto400Woff}) format('woff')
  `
}
const roboto400Woff2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Roboto'),
    url(${Roboto400Woff2}) format('woff2')
  `
}
const roboto400Ttf = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Roboto'),
    url(${Roboto400Ttf}) format('ttf')
  `
}
const roboto400Svg = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Roboto'),
    url(${Roboto400Svg}) format('svg')
  `
}

const roboto500Woff = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Roboto'),
    url(${Roboto500Woff}) format('woff')
  `
}
const roboto500Woff2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Roboto'),
    url(${Roboto500Woff2}) format('woff2')
  `
}
const roboto500Ttf = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Roboto'),
    url(${Roboto500Ttf}) format('ttf')
  `
}
const roboto500Svg = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Roboto'),
    url(${Roboto500Svg}) format('svg')
  `
}
const roboto700Woff = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Roboto'),
    url(${Roboto700Woff}) format('woff')
  `
}
const roboto700Woff2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Roboto'),
    url(${Roboto700Woff2}) format('woff2')
  `
}
const roboto700Ttf = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Roboto'),
    url(${Roboto700Ttf}) format('ttf')
  `
}
const roboto700Svg = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Roboto'),
    url(${Roboto700Svg}) format('svg')
  `
}
const MaterialIconsRegularSvg = {
  fontFamily: 'Material Icons',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Material Icons'),
    url(${MaterialIconsSvg}) format('svg')
  `
}

export const Fonts = {
  roboto400Woff,
  roboto400Woff2,
  roboto400Svg,
  roboto400Ttf,
  roboto500Woff,
  roboto500Woff2,
  roboto500Svg,
  roboto500Ttf,
  roboto700Woff,
  roboto700Woff2,
  roboto700Svg,
  roboto700Ttf,
  MaterialIconsRegularSvg
}
