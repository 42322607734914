/* eslint-disable @typescript-eslint/naming-convention */
import {Project, Severity} from './Constants'
import {getMiddleware} from './Middleware'
import {getProcessors} from './Processors'

export type Event = Error | string

export type Tags = Record<string, string>
export type Context = string[]
export type Extras = Record<string, unknown>

export interface LoggerMetadata {
  project: Project
  tags?: Tags
  context?: Context
  extras?: Extras
}

export type MiddlewareFn = (
  event: Event,
  metadata: LoggerMetadata,
  level: Severity
) => [Event, LoggerMetadata]

export type ProcessorFn = (level: Severity, event: Event, metadata: LoggerMetadata) => void

export type LogLevelProcessor = Record<Severity, ProcessorFn>

export interface LogTransaction extends Record<Severity, (event: Event) => void> {
  _metadata: LoggerMetadata
  _middleware: MiddlewareFn[]
  tags: (tags: Tags) => LogTransaction
  context: (...context: Context) => LogTransaction
  extras: (extras: Extras) => LogTransaction
  middleware: (...middleware: MiddlewareFn[]) => LogTransaction
}
type TransactionData = Pick<LogTransaction, '_metadata' | '_middleware'>
const applyMiddleware = (event: Event, data: TransactionData, level: Severity): Event => {
  let curEvent = event
  const middleware = [...getMiddleware(), ...data._middleware]
  middleware.forEach((cb) => {
    const [processedError, metaData] = cb(event, data._metadata, level)
    data._metadata = metaData
    curEvent = processedError
  })
  return curEvent
}

const processEvent = (level: Severity, event: Event, data: TransactionData): void => {
  const processedEvent = applyMiddleware(event, data, level)
  getProcessors().forEach((processor) => processor[level](level, processedEvent, data._metadata))
}

const transactionFromData = (data: TransactionData): LogTransaction => {
  const runProcess = <T extends Event | string>(severity: Severity) => (param: T) =>
    processEvent(severity, param, data)
  return {
    ...data,
    debug: runProcess<Event>(Severity.Debug),
    info: runProcess<Event>(Severity.Info),
    log: runProcess<Event>(Severity.Log),
    warning: runProcess<Event>(Severity.Warning),
    error: runProcess<Event>(Severity.Error),
    critical: runProcess<Event>(Severity.Critical),
    fatal: runProcess<Event>(Severity.Fatal),
    tags: (tags: Tags) => setTags(tags, data),
    extras: (extras: Extras) => setExtras(extras, data),
    context: (...context: Context) => setContext(data, ...context),
    middleware: (...middleware: MiddlewareFn[]) => setMiddleware(data, ...middleware)
  }
}

const setMiddleware = (data: TransactionData, ...middleware: MiddlewareFn[]): LogTransaction => {
  const newData = {...data}
  newData._middleware = [...data._middleware, ...middleware]
  return transactionFromData(newData)
}

const setTags = (tags: Tags, data: TransactionData): LogTransaction => {
  const newData = {
    ...data,
    _metadata: {...data._metadata, tags: {...tags, ...data._metadata.tags}}
  }
  return transactionFromData(newData)
}

const setContext = (data: TransactionData, ...context: Context): LogTransaction => {
  const newData = {
    ...data,
    _metadata: {...data._metadata, context: [...(data._metadata.context ?? []), ...context]}
  }
  return transactionFromData(newData)
}

const setExtras = (extras: Extras, data: TransactionData): LogTransaction => {
  const newData = {
    ...data,
    _metadata: {...data._metadata, extras: {...data._metadata.extras, ...extras}}
  }
  return transactionFromData(newData)
}

export const createLogger = ({project}: {project: Project}): LogTransaction => {
  return transactionFromData({_metadata: {project}, _middleware: []})
}
