import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core'
import React from 'react'

interface ActionBarButtonProps extends ButtonProps {
  isLoading?: boolean
  width?: number
  toolTipTitle?: string
}

const useStyles = makeStyles<Theme, {width?: number}>({
  button: ({width}) => ({
    width: width ?? 160,
    maxWidth: '100%'
  })
})

export const ActionBarButton: React.FC<ActionBarButtonProps> = ({
  children,
  isLoading = false,
  width,
  toolTipTitle,
  ...rest
}) => {
  const classes = useStyles({width})

  return (
    <Box mb={1}>
      <Tooltip title={toolTipTitle ?? ''}>
        <Button {...rest} disabled={isLoading} className={classes.button}>
          {isLoading ? <CircularProgress size={20} /> : children}
        </Button>
      </Tooltip>
    </Box>
  )
}
