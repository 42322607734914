import {AppName} from '@hconnect/common/hproduce/types'
import type {FC, SVGProps} from 'react'
import React from 'react'

import {ReactComponent as CockpitLogo} from './IconCockpit.svg'
import {ReactComponent as CockpitLogoSmall} from './IconCockpitSmall.svg'
import {ReactComponent as EquipPulseLogo} from './IconEquipPulse.svg'
import {ReactComponent as MaintainLogo} from './IconMaintain.svg'
import {ReactComponent as PlannerLogo} from './IconPlanner.svg'
import {ReactComponent as PredictLogo} from './IconPredict.svg'

type LogoMap = Record<AppName, FC<SVGProps<SVGSVGElement>>>

export const logos: LogoMap = {
  Cockpit: CockpitLogo,
  EquipPulse: EquipPulseLogo,
  Maintain: MaintainLogo,
  Predict: PredictLogo,
  Planner: PlannerLogo
}

interface Props extends SVGProps<SVGSVGElement> {
  name: AppName
  size?: number // will be used as pixel for width and hight,
}

export const AppLogo: FC<Props> = ({name, size = 32, ...props}) => {
  const Logo = logos[name]
  const pixelSize = size + 'px'

  if (!name || !Logo) throw new Error('wrong app name')

  if (size < 32 && name === 'Cockpit') {
    return <CockpitLogoSmall width={pixelSize} height={pixelSize} viewBox="0 0 36 36" {...props} />
  }

  return <Logo width={pixelSize} height={pixelSize} viewBox="0 0 32 32" {...props} />
}
