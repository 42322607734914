import type {TFunction} from 'i18next'
import {isBoolean, isString, sortBy, uniq, upperFirst} from 'lodash'

import type {ChartCategory, ChartOption, LabMetaData, MaterialLevel} from '../../declarations'
import {someMatchAll} from '../filter'

export const isChartOption = (option: unknown): option is ChartOption => {
  if (option) {
    const {id, label, selectable} = option as ChartOption
    return isString(id) && isString(label) && isBoolean(selectable)
  }
  return false
}

export const isOptionSelected = (option: ChartOption, selectedOptions: string[]): boolean =>
  selectedOptions.includes(option.id)

export const isChartCategories = (options: unknown[]): options is ChartCategory[] =>
  options.length === 0 || isString((options[0] as ChartCategory).categoryId)

const DEFAULT_CATEGORY: ChartCategory = {
  categoryId: '',
  categoryLabel: '',
  options: []
}

export const toCategories = (options: ChartOption[] | ChartCategory[]): ChartCategory[] =>
  isChartCategories(options) ? options : [{...DEFAULT_CATEGORY, options}]

export const filterCategories = (categories: ChartCategory[], filter: string[]) =>
  categories
    .map((category) => ({
      ...category,
      options: category.options.filter(
        (option) => Boolean(option.id) && someMatchAll([option.id, option.label], filter)
      )
    }))
    .filter(({options}) => options.length > 0)

export const getChartCategories = (
  currentLevel: MaterialLevel,
  materialLevels: MaterialLevel[],
  labMetaData: LabMetaData[],
  t: TFunction
): ChartCategory[] => {
  const levelCategory: ChartCategory = {
    categoryId: 'materialLevel',
    categoryLabel: t('chart.categories.cementStrength'),
    options: materialLevels.map((level) => ({
      id: level.name,
      label: t(`chart.selectableCsFields.${level.name}.label`),
      selectable: currentLevel.name !== level.name
    }))
  }

  const labCategoryNames: string[] = uniq(labMetaData.map(({category}) => category))
  const labCategories: ChartCategory[] = sortBy(
    labCategoryNames.map((cat) => ({
      categoryId: cat,
      categoryLabel: t(`chart.categories.${cat}`, upperFirst(cat)),
      options: sortBy(
        labMetaData
          .filter(({category}) => category === cat)
          .map(({uniformTag, displayName}) => ({
            id: uniformTag,
            label: displayName,
            selectable: true
          })),
        (o) => o.label
      )
    })),
    (cat) => cat.categoryLabel
  )

  return [levelCategory, ...labCategories]
}
