import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {DEFAULT_ROWS_PER_PAGE, DEFAULT_SETTINGS_VIEW} from '../common/constants'
import {searchStringToUrlQueryParams} from '../common/navigation'
import {UrlSettingsParamsWithDefaults} from '../declarations'

export const useSettingsParamsWithDefaults = (): UrlSettingsParamsWithDefaults => {
  const {search} = useLocation()

  return useMemo(() => {
    const params = searchStringToUrlQueryParams(search)
    return {
      settingsView: params.settingsView ?? DEFAULT_SETTINGS_VIEW,
      settingsSensorsTablePageNo: params.settingsSensorsTablePageNo ?? 0,
      settingsSensorsTableRowsPerPage:
        params.settingsSensorsTableRowsPerPage ?? DEFAULT_ROWS_PER_PAGE,
      settingsSensorsTableSort: params.settingsSensorsTableSort,
      settingsSensorsTableFilters: params.settingsSensorsTableFilters ?? []
    }
  }, [search])
}
