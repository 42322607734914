import type {StrengthLevel} from '../../declarations'

const strengthLevelMap: Record<StrengthLevel, number> = {
  strength1: 1,
  strength2: 2,
  strength3: 3,
  strength7: 7,
  strength28: 28
} as const

export const STRENGTH_LEVELS = Object.keys(strengthLevelMap) as StrengthLevel[]

export const compareStrengthLevels = (a: StrengthLevel, b: StrengthLevel): number =>
  strengthLevelMap[a] - strengthLevelMap[b]

export const isStrengthLevel = (str: unknown): str is StrengthLevel =>
  STRENGTH_LEVELS.includes(str as StrengthLevel)

export const getStrengthLevelFromString = (
  str: string | null | undefined
): StrengthLevel | undefined => {
  if (!str) {
    return undefined
  }

  return STRENGTH_LEVELS.find((sl) => sl.toLowerCase() === str.toLowerCase()) ?? undefined
}

export const getStrengthLevelInDays = (level: StrengthLevel): number => {
  return strengthLevelMap[level]
}
