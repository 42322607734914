import React from 'react'

interface CircleMarkerProps {
  xPos: number
  yPos: number
  color: string
}

export const CircleMarker: React.FC<CircleMarkerProps> = ({xPos, yPos, color}) => {
  return <circle cx={xPos} cy={yPos} r={4} fill="#ffffff" stroke={color} strokeWidth={2} />
}
