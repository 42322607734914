import {makeStyles, Table, TableBody, TableContainer} from '@material-ui/core'
import React from 'react'

import {DetailsBox} from './DetailsBox'
import {QualityParameterRow} from './QualityParameterRow'

interface DataBoxItem {
  key: string
  value?: string
  label: string
}

interface DataBoxProps {
  caption?: string
  data: DataBoxItem[]
  className?: string
  'data-test-id'?: string
}

const useStyles = makeStyles(() => ({
  cell: {
    fontSize: 16,
    paddingRight: 0,
    paddingLeft: 0,
    '&:last-child': {
      paddingRight: 0
    }
  }
}))

export const DataBox: React.FC<DataBoxProps> = ({
  caption,
  data,
  className,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()

  return (
    <DetailsBox data-test-id={dataTestId} caption={caption} className={className}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {data.map((item) => (
              <QualityParameterRow
                key={item.key}
                name={item.label}
                value={item.value}
                data-test-id={`sample-data-box-field-${item.key}`}
                cellClassName={classes.cell}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DetailsBox>
  )
}
