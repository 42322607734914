import {makeStyles} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HCThemeType} from '../../../HCTheme.types'

const useStyle = makeStyles((theme: HCThemeType) => ({
  tabBar: {
    padding: '5px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  tabButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    fontSize: 18,
    '&.selected': {
      color: theme.palette.text.link,
      borderBottom: '2px solid'
    },
    '&:first-child': {
      marginRight: theme.spacing(5)
    }
  }
}))

interface Props {
  setShowCalendar: (show: boolean) => void
  showCalendar: boolean
}

const TabBar: React.FC<Props> = ({setShowCalendar, showCalendar}) => {
  const classes = useStyle()
  const {t} = useTranslation()

  return (
    <div className={classes.tabBar}>
      <button
        className={cx(classes.tabButton, {selected: !showCalendar})}
        onClick={() => setShowCalendar(false)}
        data-test-id="tab-quick-select"
      >
        {t('datePicker.tabQuickSelect')}
      </button>
      <button
        className={cx(classes.tabButton, {selected: showCalendar})}
        onClick={() => setShowCalendar(true)}
        data-test-id="tab-custom"
      >
        {t('datePicker.tabCustom')}
      </button>
    </div>
  )
}

export {TabBar}
