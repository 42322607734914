import {makeStyles, Theme} from '@material-ui/core'

export const usePopoverStyles = makeStyles<Theme, {offset?: number}>((theme: Theme) => ({
  popoverPaper: ({offset = 0}) => ({
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    overflow: 'visible',

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      top: -11,
      left: `calc(50% - ${offset}px)`,
      borderWidth: 8,
      borderColor: 'white',
      borderStyle: 'solid',
      transformOrigin: '0 0',
      transform: 'rotate(45deg)',
      clipPath: 'polygon(-100% -100%, -100% 200%, 200% -100%)',
      boxShadow: '6px 6px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)'
    }
  })
}))
