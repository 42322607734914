import {AxiosRequestConfig} from 'axios'

import {ClientConfig} from '../../types'

export const createOnRequestInterceptor = (clientConfig: Partial<ClientConfig>) => async (
  request: AxiosRequestConfig
) => {
  const {onRequest} = clientConfig
  if (onRequest) {
    await onRequest(request)
  }

  return request
}
