import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HCThemeType} from '../../../HCTheme.types'

import {isSameDate} from './helpers'
import {Dates, QuickSelectionItem} from './types'

const useStyle = makeStyles((theme: HCThemeType) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  selection: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    '&:hover, &:focus': {
      backgroundColor: '#30beff20'
    }
  },
  selected: {
    backgroundColor: '#30beff50'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 18
  },
  value: {
    color: theme.palette.text.secondarySoft
  }
}))

interface QuickSelectionProps {
  handleItemClick: (dates: Dates, item: QuickSelectionItem) => void
  items?: QuickSelectionItem[]
  selectedItem?: QuickSelectionItem
  dateCustomTemplate?: (option: QuickSelectionItem, language?: string) => string
}

const defaultCustomTemplate = (item: QuickSelectionItem, language: string) => {
  return isSameDate(item.dates[0], item.dates[1])
    ? item.dates[0]?.toLocaleDateString(language)
    : item.dates[0] &&
        item.dates[1] &&
        `${item.dates[0].toLocaleDateString(language)} - ${item.dates[1].toLocaleDateString(
          language
        )}`
}

export const QuickSelection: React.FC<QuickSelectionProps> = ({
  handleItemClick,
  items,
  selectedItem,
  dateCustomTemplate
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const classes = useStyle()
  const today = new Date()
  const oneWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
  const previousWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
  const oneMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())
  const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
  const onSelectionItemClick = (item: QuickSelectionItem) => {
    handleItemClick({startDate: item.dates[0], endDate: item.dates[1] || null}, item)
  }

  const selectionItems: QuickSelectionItem[] = items || [
    {
      title: t('datePicker.todaySelection'),
      dates: [today, today]
    },
    {
      title: t('datePicker.weekSelection'),
      dates: [today, oneWeek]
    },
    {
      title: t('datePicker.monthSelection'),
      dates: [today, oneMonth]
    },
    {
      title: t('datePicker.previousWeekSelection'),
      dates: [previousWeek, today]
    },
    {
      title: t('datePicker.previousMonthSelection'),
      dates: [previousMonth, today]
    }
  ]

  const customOptionTemplate = dateCustomTemplate || defaultCustomTemplate

  return (
    <div className={classes.wrapper}>
      {selectionItems.map((item: QuickSelectionItem, i: number) => {
        const isSelected = selectedItem?.id === item.id || selectedItem?.title === item.title
        return (
          <button
            data-test-id={`quick-selection-button-${i}`}
            key={i}
            className={classNames(classes.selection, isSelected && classes.selected)}
            data-state={isSelected ? 'selected' : 'unselected'}
            aria-label={`Date Picker ${item.title} button`}
            onClick={() => onSelectionItemClick(item)}
          >
            <span className={classes.title}>{item.title}</span>
            <span className={classes.value}>{customOptionTemplate(item, language)}</span>
          </button>
        )
      })}
    </div>
  )
}
