import {AxiosRequestConfig} from 'axios'

import {ClientConfig} from '../../types'

export const createBaseUrlInterceptor = (
  clientConfig: Pick<ClientConfig, 'backendSelector'>
) => async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => ({
  ...config,
  baseURL: clientConfig.backendSelector.getSelectedBackend().API_URL
})
