import {useMemo} from 'react'

import {DateTimeParam, TimeRange} from '../declarations'

import {useFlParamsWithDefaults} from './useFlParamsWithDefaults'

export const useFlTimeRange = (): TimeRange<DateTimeParam> => {
  const {flStart: start, flEnd: end} = useFlParamsWithDefaults()
  return useMemo(() => ({start, end}), [end, start])
}
