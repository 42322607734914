import {User} from '@sentry/browser'
import jwtDecode from 'jwt-decode'
import {omitBy, isEmpty} from 'lodash'

import {getQueryString, removeQueryString, sanitizeUrl} from '../../utilities/DataSanitizer'

import {errorKeyFromRequestError} from '@hconnect/adminconsole/src/common/ErrorMap'

const getNetworkErrorEndpoint = (error) =>
  error?.config?.url ? removeQueryString(error.config.url) : undefined

const getNetworkErrorQueryString = (error) =>
  error?.config?.url ? getQueryString(sanitizeUrl(error.config.url)) : undefined

const getNetworkErrorType = (error) => error?.response?.data?.type

const getNetworkErrorKey = (error) => (error.response ? errorKeyFromRequestError(error) : undefined)

const getNetworkErrorRequestId = (error) => error?.config?.headers?.['request-id']

const getNetworkErrorStatus = (error) => error?.response?.status

export const getNetworkErrorFingerprint = (error): string[] | undefined => {
  const {response, message} = error
  const url = error?.config?.url

  if (!url && !response) return undefined

  const cleanedURL = removeQueryString(url ?? '')

  if (!response) {
    return [`errorMessage: ${message}`, `url: ${cleanedURL}`]
  }

  return [`error_key: ${getNetworkErrorKey(error)}`, `url: ${cleanedURL}`]
}

export const getNetworkErrorUser = (error): Partial<User> => {
  try {
    const id = jwtDecode<{user_id?: string}>(
      error?.config?.headers?.Authorization?.replace('Bearer ', '') ?? ''
    )?.user_id
    return {id}
  } catch (error) {
    return {}
  }
}

export const getNetworkErrorTags = (error: Error) =>
  omitBy(
    {
      api_query_string: getNetworkErrorQueryString(error),
      api_endpoint: getNetworkErrorEndpoint(error),
      api_error_type: getNetworkErrorType(error),
      api_error_status: getNetworkErrorStatus(error),
      error_key: getNetworkErrorKey(error),
      request_id: getNetworkErrorRequestId(error)
    },
    isEmpty
  )
