import {Button, makeStyles, TableCell, TableRow, Theme} from '@material-ui/core'
import {ExpandLess, ExpandMore} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useState} from 'react'

import type {TargetRange} from '../../declarations'
import {useTableStyles} from '../../hooks/useTableStyles'

import {TargetRangeRow} from './TargetRangeRow'
import {TargetRangeTableFormContainer} from './TargetRangeTableFormContainer'
import type {CommonSettingsProps, TargetRangeEntry} from './types'

export interface TargetRangeCategory {
  id: string | number
  categoryLabel: string
  entries: TargetRangeEntry[]
}

interface TargetRangeCollapsableTableFormProps extends CommonSettingsProps {
  headerLabel: string
  unit: string
  categories: TargetRangeCategory[]
  onChange: (categories: TargetRangeCategory[]) => void
}

interface CollapsableRowProps extends CommonSettingsProps {
  category: TargetRangeCategory
  onChange: (category: TargetRangeCategory) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0
  },
  cellHidden: {
    border: 0
  },
  collapseToggleButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: theme.typography.fontWeightBold
    }
  },
  collapseToggleButtonOpen: {
    color: theme.palette.primary.main
  }
}))

const CollapsableRow: React.FC<CollapsableRowProps> = ({category, disabled, onChange}) => {
  const [open, setOpen] = useState(false)
  const tableClasses = useTableStyles()
  const bodyCellClassName = classNames(tableClasses.tableRowCell, tableClasses.cell)
  const classes = useStyles()

  const onNumberChanged = (
    value: number | undefined,
    entry: TargetRangeEntry,
    field: keyof TargetRange
  ) => {
    const newEntries = category.entries.map((e) =>
      e.id === entry.id
        ? {
            ...entry,
            targetRange: {...entry.targetRange, [field]: value}
          }
        : e
    )
    onChange({...category, entries: newEntries})
  }

  return (
    <>
      <TableRow>
        <TableCell className={bodyCellClassName} colSpan={5}>
          <Button
            variant="text"
            startIcon={open ? <ExpandLess /> : <ExpandMore />}
            onClick={() => setOpen((o) => !o)}
            className={classNames(classes.collapseToggleButton, {
              [classes.collapseToggleButtonOpen]: open
            })}
            data-test-id={`collapsable-row-expand-btn-${category.id}`}
          >
            {category.categoryLabel}
          </Button>
        </TableCell>
      </TableRow>
      {category.entries.map((entry) => (
        <TargetRangeRow
          key={entry.id}
          entry={entry}
          disabled={disabled}
          withButtonColumn={true}
          onNumberChanged={onNumberChanged}
          collapsable
          open={open}
          tableCellClassName={classNames(classes.cell, {[classes.cellHidden]: !open})}
        />
      ))}
    </>
  )
}

export const TargetRangeCollapsableTableForm: React.FC<TargetRangeCollapsableTableFormProps> = ({
  headerLabel,
  unit,
  categories,
  onChange,
  disabled
}) => {
  const onRowChanged = (category: TargetRangeCategory) => {
    const newCategories = categories.map((cat) => {
      if (cat.id === category.id) {
        return category
      }
      return cat
    })
    onChange(newCategories)
  }

  return (
    <TargetRangeTableFormContainer
      headerLabel={headerLabel}
      unit={unit}
      tableRows={categories.map((cat) => (
        <CollapsableRow key={cat.id} category={cat} onChange={onRowChanged} disabled={disabled} />
      ))}
      withButtonColumn={true}
    />
  )
}
