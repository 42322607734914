import {makeStyles, Theme} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {hoveredColor} from '../../common/charts/chartConfigurations'
import {SimpleAccuracyPoint} from '../charts/SimpleAccuracyPoint'

interface AccuracyPointProps {
  posX: number
  posY: number
  onClick?: () => void
  outerRadius: number
  outerClassName?: string
  innerColor: string
  'data-test-id'?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  sample: {
    pointerEvents: 'none'
  },
  clickableSample: {
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: 8,
    strokeOpacity: 0.5,
    transition: 'stroke 250ms ease-in-out',
    '&:hover': {
      stroke: hoveredColor(theme)
    }
  }
}))

export const AccuracyPoint: React.FC<AccuracyPointProps> = ({
  posX,
  posY,
  onClick,
  outerRadius,
  outerClassName,
  innerColor,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()
  const isClickable = Boolean(onClick)

  return (
    <g data-test-id={dataTestId}>
      <circle
        cx={posX}
        cy={posY}
        r={outerRadius}
        onClick={onClick}
        className={classNames(
          isClickable ? classes.clickableSample : classes.sample,
          outerClassName
        )}
      />
      <SimpleAccuracyPoint posX={posX} posY={posY} color={innerColor} className={classes.sample} />
    </g>
  )
}
