import React from 'react'
import {useTranslation} from 'react-i18next'

import {FormattedQualityParameter} from '../declarations'

import {DataBox} from './DataBox'

interface SampleDataBoxProps {
  caption?: string
  qualityParameters: FormattedQualityParameter[]
  'data-test-id'?: string
}

export const SampleDataBox: React.FC<SampleDataBoxProps> = ({
  caption,
  qualityParameters,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const data = qualityParameters.map((qp) => ({
    key: qp.key,
    value: qp.value && t(`sampleData.${qp.key}.value`, {value: qp.value}),
    label: t(`sampleData.${qp.key}.label`)
  }))

  return <DataBox data={data} caption={caption} data-test-id={dataTestId} />
}
