import React from 'react'

interface SimpleAccuracyPointProps {
  posX: number
  posY: number
  color: string
  className?: string
}

export const SimpleAccuracyPoint: React.FC<SimpleAccuracyPointProps> = ({
  posX,
  posY,
  color,
  className
}) => (
  <circle
    cx={posX}
    cy={posY}
    r={4}
    fill={color}
    fillOpacity={0.5}
    stroke={color}
    className={className}
  />
)
