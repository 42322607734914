import {useBreakPoints} from '@hconnect/uikit/src'
import {Button, makeStyles, Theme, Typography} from '@material-ui/core'
import {Check, Undo} from '@material-ui/icons'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {usePrompt} from '../../hooks/usePrompt'
import {DefaultBox} from '../DefaultBox'

import type {HasDisabled} from './types'

interface FormBoxProps extends HasDisabled {
  title: string
  isFormInvalid?: boolean
  isSaving?: boolean
  onRevert: () => void
  onSave: () => void
  'data-test-id'?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  formHeader: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  title: {
    flex: 1,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginBottom: 0
    }
  },
  buttonsBox: {
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      marginBottom: 0
    }
  },
  button: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      '& [class*="MuiButton-startIcon"]': {
        margin: 0
      }
    }
  }
}))

export const FormBox: React.FC<FormBoxProps> = ({
  title,
  onRevert,
  onSave,
  disabled = false,
  isSaving = false,
  isFormInvalid = false,
  children,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const enablePrompt = usePrompt()

  const breakpoint = useBreakPoints()
  const isMobile = breakpoint === 'xs'

  useEffect(() => {
    enablePrompt(!disabled, {
      message: t('settings.leaveWithoutSaving.message'),
      title: t('settings.leaveWithoutSaving.title'),
      cancelButtonLabel: t('settings.leaveWithoutSaving.cancelButton'),
      confirmButtonLabel: t('settings.leaveWithoutSaving.confirmButton')
    })
  }, [disabled, enablePrompt, t])

  return (
    <DefaultBox width="100%" data-test-id={dataTestId}>
      <div className={classes.formHeader}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.buttonsBox}>
          <Button
            className={classes.button}
            disabled={disabled || isSaving}
            onClick={onRevert}
            startIcon={<Undo />}
            data-test-id="form-box-revert-button"
          >
            {!isMobile ? t('formBox.revertButton') : ''}
          </Button>
          <Button
            className={classes.button}
            disabled={disabled || isFormInvalid || isSaving}
            onClick={onSave}
            color="primary"
            startIcon={<Check />}
            data-test-id="form-box-save-button"
          >
            {!isMobile ? t('formBox.saveButton') : ''}
          </Button>
        </div>
      </div>
      <div>{children}</div>
    </DefaultBox>
  )
}
