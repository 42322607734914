import React from 'react'
import {useTranslation} from 'react-i18next'

import {ErrorMessage} from '../components/ErrorMessage'
import {PageContainer} from '../components/PageContainer'
import {PageTitle} from '../components/PageTitle'

export const ErrorPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <PageContainer>
      <PageTitle>{t('errors.noPageFoundHeader')}</PageTitle>
      <ErrorMessage>{t('errors.noPageFoundDesc')}</ErrorMessage>
    </PageContainer>
  )
}
