import {HCTheme, palette} from '@hconnect/uikit'
import {createTheme, Grid, ThemeProvider, makeStyles} from '@material-ui/core'
import {PaletteOptions} from '@material-ui/core/styles/createPalette'
import React from 'react'

const darkPalette: PaletteOptions = {
  ...palette,
  background: {...palette.background, paper: palette.background.default},
  text: {...palette.text, primary: '#ffffff'},
  type: 'dark'
}

const theme = createTheme({
  ...HCTheme,
  palette: darkPalette,
  overrides: {
    ...HCTheme.overrides,
    MuiInputLabel: {
      ...HCTheme.overrides?.MuiInputLabel,
      root: {}
    },
    MuiButton: {
      ...HCTheme.overrides?.MuiButton,
      root: {
        ...HCTheme.overrides?.MuiButton?.root,
        textTransform: 'none'
      },
      outlined: {
        ...HCTheme.overrides?.MuiButton?.outlined,
        paddingRight: 16,
        paddingLeft: 16
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  actionBarContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  actionBarTitle: {
    alignSelf: 'flex-start',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    }
  },
  actionItemsContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end'
    }
  },
  actionItem: {
    maxWidth: '100%'
  }
}))

export const ActionBar: React.FC<{title: React.ReactNode}> = ({title, children}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.actionBarContainer}
      data-test-id="cement-strength-action-bar"
    >
      <Grid item xs={12} lg={3} xl={4} className={classes.actionBarTitle}>
        {title}
      </Grid>
      <Grid xs={12} lg={9} xl={8} item>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.actionItemsContainer} spacing={2}>
            {React.Children.map(
              children,
              (child) =>
                child && (
                  <Grid item className={classes.actionItem}>
                    {child}
                  </Grid>
                )
            )}
          </Grid>
        </ThemeProvider>
      </Grid>
    </Grid>
  )
}
