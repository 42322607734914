import {Theme} from '@material-ui/core'
import Color from 'color'
import React from 'react'

export const getRelativePosition = (event: React.MouseEvent): {x: number; y: number} => {
  const rect = event.currentTarget.getBoundingClientRect()
  return {x: event.clientX - rect.x, y: event.clientY - rect.y}
}

export const calcPopoverOffset = (
  anchorEl: Element,
  popoverMargin: number,
  popoverWidth: number
) => {
  const headerCellRect = anchorEl.getBoundingClientRect()
  const windowWidth = window.innerWidth
  const cellCenterPosX = headerCellRect.x + 0.5 * headerCellRect.width
  const distanceToRight = windowWidth - cellCenterPosX
  const minDistPopover = popoverMargin + 0.5 * popoverWidth
  if (cellCenterPosX < minDistPopover) {
    return minDistPopover - cellCenterPosX
  }
  if (distanceToRight < minDistPopover) {
    return windowWidth - minDistPopover - cellCenterPosX
  }
  return 0
}

const calcColorPair = (
  color: string,
  theme: Theme
): {textColor: string; backgroundColor: string} => {
  if (!color) {
    throw new Error('color may not be empty!')
  }
  const baseColor = Color(color)
  const textColor = baseColor.isLight() ? theme.palette.text.primary : theme.palette.text.secondary
  return {textColor, backgroundColor: color}
}

export const calcColorStyle = (color: string, theme: Theme, colorizeWhenDisabled = false) => {
  if (color) {
    const {textColor, backgroundColor} = calcColorPair(color, theme)
    const bgColor = Color(backgroundColor)
    return {
      backgroundColor,
      color: textColor,
      '&.Mui-disabled': colorizeWhenDisabled
        ? {color: textColor}
        : {
            color: Color(textColor).mix(Color('#808080'), 0.25).string(),
            backgroundColor: bgColor.grayscale().mix(Color('#808080'), 0.25).string()
          },
      '&:hover': {
        backgroundColor
      }
    }
  }
  return {}
}
