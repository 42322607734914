import {Popover} from '@material-ui/core'
import React, {useLayoutEffect, useState} from 'react'

import {usePopoverStyles} from '../../hooks/usePopoverStyles'
import {calcPopoverOffset} from '../uiUtils'

interface TableHeaderPopoverProps {
  anchorEl: Element | null
  open: boolean
  id?: string
  marginThreshold?: number
  width: number
  onClose: () => void
}

const DEFAULT_POPOVER_MARGIN = 16

export const TableHeaderPopover: React.FC<TableHeaderPopoverProps> = ({
  anchorEl,
  open,
  id,
  onClose,
  marginThreshold = DEFAULT_POPOVER_MARGIN,
  width,
  children
}) => {
  const [popoverOffset, setPopoverOffset] = useState(0)
  const popoverClasses = usePopoverStyles({offset: popoverOffset})

  useLayoutEffect(() => {
    if (anchorEl) {
      setPopoverOffset(calcPopoverOffset(anchorEl, marginThreshold, width))
    }
  }, [anchorEl, marginThreshold, width])

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={{
        className: popoverClasses.popoverPaper
      }}
      marginThreshold={marginThreshold}
    >
      {children}
    </Popover>
  )
}
