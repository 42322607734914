import {Dialog} from '@material-ui/core'
import React, {ImgHTMLAttributes, useState} from 'react'

import {getFilenameFromPath, stripExtension} from '../common/helpers'

import * as images from './images'

const mapNameToSource = (sourceName: string | undefined): string => {
  if (!sourceName) return ''
  const imageMap: Record<string, string> = images
  const name = stripExtension(getFilenameFromPath(sourceName))
  return imageMap[name] ?? ''
}

export const ManualImage: React.FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const {src, alt, style, ...rest} = props
  const imageSrc = mapNameToSource(src)

  if (!imageSrc) {
    return null
  }

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <img
        role="button"
        onClick={toggleDialog}
        tabIndex={0}
        src={imageSrc}
        alt={alt}
        style={{maxWidth: '100%', cursor: 'pointer', ...style}}
        {...rest}
      />
      <Dialog open={isOpen} onClose={toggleDialog}>
        <img
          role="button"
          src={imageSrc}
          alt={alt}
          style={{maxWidth: '100%', ...style}}
          {...rest}
        />
      </Dialog>
    </>
  )
}
