import {Button, DialogActions} from '@material-ui/core'
import {ArrowForward, Close} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useDialogStyles} from '../hooks/useDialogStyles'

import {LoadingButton} from './LoadingButton'

interface DialogCancelSaveActionsProps {
  onCancel: () => void
  onSave: () => void
  cancelBtnLabel?: string
  saveBtnLabel?: string
  saveDisabled?: boolean
  saving?: boolean
}

export const DialogCancelSaveActions: React.FC<DialogCancelSaveActionsProps> = ({
  onCancel,
  onSave,
  cancelBtnLabel,
  saveBtnLabel,
  saveDisabled = false,
  saving = false
}) => {
  const {t} = useTranslation()
  const dialogClasses = useDialogStyles()

  return (
    <DialogActions className={dialogClasses.actionBlock}>
      <Button
        onClick={onCancel}
        color="default"
        startIcon={<Close />}
        data-test-id="dialog-cancel-button"
      >
        {cancelBtnLabel ?? t('button.cancel')}
      </Button>
      <LoadingButton
        onClick={onSave}
        color="primary"
        startIcon={<ArrowForward />}
        data-test-id="dialog-apply-button"
        disabled={saveDisabled}
        loading={saving}
      >
        {saveBtnLabel ?? t('button.apply')}
      </LoadingButton>
    </DialogActions>
  )
}
