import {makeStyles} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

export const BUTTON_SIZE = 44
import {HCThemeType} from '../../../HCTheme.types'

const rangeBGColor = '#30beff20'
const disabledRangeBGColor = '#cccccc90'

const afterStyle = () => ({
  position: 'absolute',
  content: '""',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const useStyle = makeStyles((theme: HCThemeType) => ({
  dayButton: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    height: BUTTON_SIZE,
    width: BUTTON_SIZE,
    fontSize: 16,
    padding: 0,
    cursor: 'pointer',
    color: theme.palette.text.secondarySoft,
    position: 'relative',
    '&.inRange:after': {
      ...afterStyle(),
      backgroundColor: rangeBGColor,
      borderRadius: '0'
    },
    '&.inDisabledRange.inDisabledRange': {
      backgroundColor: 'transparent'
    },
    '&.inDisabledRange:after': {
      ...afterStyle(),
      backgroundColor: disabledRangeBGColor,
      borderRadius: '0'
    },
    '&.isRangeEnd:after': {
      ...afterStyle(),
      border: `10px solid ${rangeBGColor}`,
      borderTopRightRadius: BUTTON_SIZE / 2,
      borderBottomRightRadius: BUTTON_SIZE / 2,
      backgroundColor: 'transparent'
    },
    '&.isDisabledRangeEnd.isDisabledRangeEnd': {
      backgroundColor: 'transparent'
    },
    '&.isDisabledRangeEnd:after': {
      ...afterStyle(),
      borderTopRightRadius: BUTTON_SIZE / 2,
      borderBottomRightRadius: BUTTON_SIZE / 2,
      backgroundColor: disabledRangeBGColor
    },
    '&.isRangeStart:after': {
      ...afterStyle(),
      border: `10px solid ${rangeBGColor}`,
      borderTopLeftRadius: BUTTON_SIZE / 2,
      borderBottomLeftRadius: BUTTON_SIZE / 2,
      backgroundColor: 'transparent'
    },
    '&.isDisabledRangeStart.isDisabledRangeStart': {
      backgroundColor: 'transparent'
    },
    '&.isDisabledRangeStart:after': {
      ...afterStyle(),
      borderTopLeftRadius: BUTTON_SIZE / 2,
      borderBottomLeftRadius: BUTTON_SIZE / 2,
      backgroundColor: disabledRangeBGColor
    },
    '&:hover': {
      backgroundColor: '#30beff95',
      color: theme.palette.common.white,
      borderRadius: '50%'
    },
    '&.isCurrentMonth': {
      color: theme.palette.text.primary
    },
    '&.isDisabled': {
      backgroundColor: disabledRangeBGColor,
      color: 'black',
      cursor: 'not-allowed'
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }
}))

interface Props {
  day: number
  handleHover: () => void
  handleSelect: () => void
  inRange: boolean
  inDisabledRange: boolean
  isDisabled: boolean
  isCurrentMonth: boolean
  isDisabledRangeStart: boolean
  isDisabledRangeEnd: boolean
  isRangeEnd: boolean
  isRangeStart: boolean
  selected: boolean
}

const DateButton: React.FC<Props> = ({
  day,
  handleHover,
  handleSelect,
  inDisabledRange,
  inRange,
  isDisabled,
  isDisabledRangeStart,
  isDisabledRangeEnd,
  isCurrentMonth,
  isRangeEnd,
  isRangeStart,
  selected
}) => {
  const classes = useStyle()

  return (
    <button
      className={cx(classes.dayButton, {
        isCurrentMonth,
        inDisabledRange,
        inRange,
        isDisabled,
        isDisabledRangeStart,
        isDisabledRangeEnd,
        isRangeEnd,
        isRangeStart,
        selected
      })}
      disabled={isDisabled}
      onClick={handleSelect}
      onFocus={handleHover}
      onMouseOver={handleHover}
    >
      {day}
    </button>
  )
}

export {DateButton}
