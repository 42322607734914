import {Option, PlantId, AppName} from '@hconnect/common/hproduce/types'
import {makeStyles, MenuItem, MenuList} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {AppLogo} from '../Assets/HProduceApps'
import Divider from '../Molecules/Divider'

export type ProductSelectProps = {
  activeAppName?: AppName | 'HProduce'
  activePlantId?: PlantId
  'data-test-id'?: string
  options: Option[]
}

const useProductStyles = makeStyles((theme) => ({
  item: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 2),
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  link: {
    textDecoration: 'none'
  },
  active: {
    '& $item': {
      color: theme.palette.primary.main
    }
  },
  divider: {
    margin: theme.spacing(0, 2)
  }
}))

export const ProductSelect: React.FC<ProductSelectProps> = ({
  options,
  activeAppName,
  activePlantId,
  'data-test-id': dataTestId
}) => {
  const classes = useProductStyles()

  return (
    <MenuList disablePadding data-test-id={dataTestId}>
      {options.map((option, i) => {
        const {appName} = option
        const isActive = option.plantId === activePlantId && appName === activeAppName

        return (
          <a
            key={option.appName}
            href={option.url}
            className={classNames(classes.link, isActive && classes.active)}
            data-test-id={dataTestId ? `${appName}-${dataTestId}` : appName}
          >
            <MenuItem className={classes.item}>
              <AppLogo name={appName} size={16} />
              {appName}
            </MenuItem>
            {i < options.length - 1 && (
              <Divider orientation="horizontal" color="soft" className={classes.divider} />
            )}
          </a>
        )
      })}
    </MenuList>
  )
}
