import {AppConfig, AppName} from '../types'

import {cockpit} from './cockpit'
import {equipPulse} from './equipPulse'
import {maintain} from './maintain'
import {planner} from './planner'
import {predict} from './predict'

export const apps: Record<AppName, AppConfig> = {
  Planner: planner,
  Cockpit: cockpit,
  Predict: predict,
  Maintain: maintain,
  EquipPulse: equipPulse
}

export const allApps = [planner, cockpit, predict, maintain, equipPulse]
