import {
  Collapse,
  makeStyles,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {isValidTargetRange} from '../../common/math/numberRange'
import {TargetRange} from '../../declarations'
import {useTableStyles} from '../../hooks/useTableStyles'
import {NumberField} from '../NumberField'

import {CommonSettingsProps, TargetRangeEntry} from './types'

interface TargetRangeRowProps extends CommonSettingsProps {
  entry: TargetRangeEntry
  onNumberChanged: (
    value: number | undefined,
    entry: TargetRangeEntry,
    field: keyof TargetRange
  ) => void
  withButtonColumn?: boolean
  collapsable?: boolean
  open?: boolean
  tableRowProps?: TableRowProps
  tableCellClassName?: string
}

const useStyles = makeStyles(() => ({
  innerCell: {
    paddingTop: 6,
    paddingBottom: 6
  }
}))

const Cell: React.FC<TableCellProps & {collapsable: boolean; open: boolean}> = (props) => {
  const {collapsable, open, children, ...rest} = props
  const classes = useStyles()

  return (
    <TableCell {...rest}>
      {collapsable ? (
        <Collapse in={open}>
          <div className={classes.innerCell}>{children}</div>
        </Collapse>
      ) : (
        children
      )}
    </TableCell>
  )
}

export const TargetRangeRow: React.FC<TargetRangeRowProps> = ({
  entry,
  onNumberChanged,
  disabled,
  withButtonColumn = false,
  collapsable = false,
  open = true,
  tableRowProps,
  tableCellClassName,
  error = false
}) => {
  const tableClasses = useTableStyles()
  const bodyCellClassName = classNames(
    tableClasses.tableRowCell,
    tableClasses.cell,
    tableCellClassName
  )
  const isValid = isValidTargetRange(entry.targetRange)

  return (
    <TableRow data-test-id={`target-range-row-${entry.id}`} {...tableRowProps}>
      {withButtonColumn ? (
        <Cell collapsable={collapsable} open={open} className={bodyCellClassName} />
      ) : null}
      <Cell collapsable={collapsable} open={open} className={bodyCellClassName}>
        {entry.label}
      </Cell>
      <Cell collapsable={collapsable} open={open} className={bodyCellClassName} align="right">
        <NumberField
          value={entry.targetRange.min}
          error={!isValid || error}
          onNumberChange={(value) => {
            onNumberChanged(value, entry, 'min')
          }}
          disabled={disabled}
          data-test-id={`target-range-row-min-field-${entry.id}`}
          inputProps={{
            'data-test-id': `target-range-row-min-input-${entry.id}`
          }}
        />
      </Cell>
      <Cell collapsable={collapsable} open={open} className={bodyCellClassName} align="right">
        <NumberField
          value={entry.targetRange.target}
          error={!isValid || error}
          onNumberChange={(value) => {
            onNumberChanged(value, entry, 'target')
          }}
          disabled={disabled}
          data-test-id={`target-range-row-target-field-${entry.id}`}
          inputProps={{
            'data-test-id': `target-range-row-target-input-${entry.id}`
          }}
        />
      </Cell>
      <Cell collapsable={collapsable} open={open} className={bodyCellClassName} align="right">
        <NumberField
          value={entry.targetRange.max}
          error={!isValid || error}
          onNumberChange={(value) => {
            onNumberChanged(value, entry, 'max')
          }}
          disabled={disabled}
          data-test-id={`target-range-row-max-field-${entry.id}`}
          inputProps={{
            'data-test-id': `target-range-row-max-input-${entry.id}`
          }}
        />
      </Cell>
    </TableRow>
  )
}
