import type {
  FreeLimeConfig,
  FreeLimeSample,
  PlantConfig,
  PredictionView,
  SettingsView
} from '../declarations'

export enum QueryKeyName {
  GetUserManual = 'getUserManual',
  GetUnfilteredCsSamples = 'getUnfilteredCsSamples',
  GetCementStrengthForMaterial = 'getCementStrengthForMaterial',
  GetSampleDetails = 'getSampleDetails',
  GetFreeLime = 'getFreeLime',
  GetLabData = 'getLabData',
  GetLabMetaData = 'getLabMetaData',
  GetProcessMetaData = 'getProcessMetaData',
  GetSensorData = 'getSensorData',
  GetSensorMetaData = 'getSensorMeta',
  GetPlantSpecificSensorMeta = 'GetPlantSpecificSensorMeta',
  GetMaterials = 'getMaterials',
  GetPlants = 'getPlants'
}

// Instead of making a distinction between the different silos,
// all silos are aggregated under this name.
export const AGGREGATED_SILOS_NAME = 'Silo'

export const DEFAULT_PLANT_CONFIG: PlantConfig = {
  defaultStrengthLevel: 'strength28',
  supportedStrengthLevels: ['strength1', 'strength2', 'strength3', 'strength7', 'strength28'],
  defaultTimeDurationCementStrength: 'lastMonth',
  defaultTimeDurationFreeLime: 'last12Hours',
  defaultSampleTypeSources: ['mill']
}

export const DEFAULT_FREE_LIME_CONFIG: FreeLimeConfig = {
  target: 1.0,
  min: 0.5,
  max: 1.5,
  axisMin: 0,
  axisMax: 3
}

export const PROCESS_METRICS_MAX_POINTS_RESOLUTION = 1000
export const LAB_METRICS_MAX_POINTS_RESOLUTION = 1000

export const LSF_CLINKER_ID: keyof FreeLimeSample = 'lsfClinker'

export const DEFAULT_TIME_ZONE = 'UTC'
export const NOW_PARAM = 'now'
export const DURATION_PREFIX = `${NOW_PARAM}-`

export const DEFAULT_ROWS_PER_PAGE = 25
export const DEFAULT_PREDICTION_VIEW: PredictionView = 'time'
export const DEFAULT_SETTINGS_VIEW: SettingsView = 'targetLevels'

export const SETTINGS_VIEWS: SettingsView[] = ['targetLevels', 'defaultViews', 'sensors']

export const DEFAULT_ZOOM_OUT_FACTOR = 2
export const DEFAULT_ZOOM_IN_FACTOR = 0.5
