import React from 'react'

import {LinearScaleFn} from './types'

interface VerticalGridProps {
  scale: LinearScaleFn
  color: string
  height: number
  tickValues: number[]
}

const strokeWidth = 1

export const VerticalGrid: React.FC<VerticalGridProps> = ({scale, color, height, tickValues}) => {
  return (
    <g>
      {tickValues.map((tick) => {
        const x = scale(tick)

        return (
          <line
            key={tick}
            stroke={color}
            strokeWidth={strokeWidth}
            x1={x}
            x2={x}
            y1={0}
            y2={height}
          />
        )
      })}
    </g>
  )
}
