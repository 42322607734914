import {Grid} from '@material-ui/core'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect} from 'react-router-dom'

import {DefaultBox} from '../components/DefaultBox'
import {ErrorMessage} from '../components/ErrorMessage'
import {PageContainer} from '../components/PageContainer'
import {PageTitle} from '../components/PageTitle'
import {PlantSelect} from '../components/PlantSelect'
import {usePlantNavigation} from '../hooks/usePlantNavigation'
import {usePlantsContext} from '../providers/PlantsProvider'

export const PlantsPage: React.FC = () => {
  const {t} = useTranslation()
  const {plants, error} = usePlantsContext()
  const navigation = usePlantNavigation()

  const onPlantSelected = useCallback(
    (plantId: string) => {
      if (!plantId) {
        return
      }
      // navigate to plant view
      const selectedPlant = plants.find((p) => p.id === plantId)
      if (selectedPlant) {
        navigation.navigate({
          plantPage: 'cementStrength',
          pathParams: {plant: selectedPlant.name},
          resetQueryParams: true
        })
      }
    },
    [navigation, plants]
  )

  const showError = error || plants.length === 0

  if (plants.length === 1) {
    return (
      <Redirect
        to={navigation.getPlantUrl({
          plantPage: 'cementStrength',
          pathParams: {plant: plants[0].name},
          resetQueryParams: true
        })}
      />
    )
  }

  return (
    <PageContainer>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <PageTitle align="center">{t('plantsPage.title')}</PageTitle>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <DefaultBox>
            {showError ? (
              <ErrorMessage>{t('errors.plantsError')}</ErrorMessage>
            ) : (
              <PlantSelect
                caption={t('plantsPage.desc')}
                label={t('plantsPage.selectPlant')}
                onPlantSelected={onPlantSelected}
                plants={plants}
              />
            )}
          </DefaultBox>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
