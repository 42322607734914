import React from 'react'

import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'

import {MaterialAccuracyChart} from './MaterialAccuracyChart'
import {MaterialTimeChart} from './MaterialTimeChart'
import {MaterialChartContentProps} from './types'

export const MaterialChartContent: React.FC<MaterialChartContentProps> = (props) => {
  const {predictionView} = useCsParamsWithDefaults()

  return predictionView === 'time' ? (
    <MaterialTimeChart {...props} />
  ) : (
    <MaterialAccuracyChart {...props} />
  )
}
