import {Link, Popover} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {SyntheticEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'

import AccountDropdownLogoutButton from '../Molecules/AccountDropdown.LogoutButton'
import {Action} from '../Molecules/AccountDropdown.types'
import {Locales, LanguageSwitcher} from '../Molecules/LanguageSwitcher'
import Typography from '../Molecules/Typography'

import HProduceAccountButton from './HProduceAccountButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2.5),
    maxWidth: 326,
    background: 'rgb(247, 247, 247)'
  },

  accountButton: {},

  box: {
    padding: `${theme.spacing(2)}px`
  },
  menuButton: {
    border: 'none'
  }
}))

interface Content {
  header?: string | null
  body: any
}

interface Profile {
  name: string
  email: string
}

interface HProduceAccountDropdownProps {
  actions: Action
  logoutButtonText: string
  profile?: Profile
  content?: Content
  locales?: Locales
  userPage?: string
}

const HProduceAccountDropdown: React.FC<HProduceAccountDropdownProps> = ({
  actions,
  logoutButtonText,
  profile,
  content,
  locales,
  userPage
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()
  const {t} = useTranslation()
  const handleClick = (event: SyntheticEvent<EventTarget>) =>
    setAnchorEl(event.currentTarget as HTMLInputElement)

  const handleClose = () => setAnchorEl(null)

  return (
    <div className={classes.accountButton}>
      <HProduceAccountButton
        onClick={(e) => handleClick(e)}
        iconButtonClassName={classes.menuButton}
      />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{paper: classes.paper}}
        data-test-id="account-menu-popover"
      >
        {profile && (
          <div className={classes.box}>
            <Typography
              variant="body2"
              color="textPrimary"
              data-test-id="account-menu-profile-name"
            >
              {profile.name}
            </Typography>
            <Typography
              variant="body1"
              customColor="textPrimarySoft"
              data-test-id="account-menu-profile-email"
            >
              {profile.email}
            </Typography>
            {userPage && <Link href={userPage}>{t('accountDropdown.goToUserPage')}</Link>}
          </div>
        )}
        {content && (
          <div className={classes.box}>
            {content.header && (
              <Typography
                variant="body2"
                color="textPrimary"
                data-test-id="account-menu-profile-accounts"
              >
                {content.header}
              </Typography>
            )}
            {content.body}
          </div>
        )}
        {locales && (
          <LanguageSwitcher
            locales={locales}
            onSelectLanguage={(lang: string) => {
              if (actions.selectLanguage) {
                actions.selectLanguage(lang)
              }
            }}
          />
        )}
        <AccountDropdownLogoutButton text={logoutButtonText} actions={actions} />
      </Popover>
    </div>
  )
}

export default HProduceAccountDropdown
