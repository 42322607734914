import {PermissionType} from '@hconnect/apiclient'

export type {Permission} from '@hconnect/apiclient'

export type AppName = 'Cockpit' | 'EquipPulse' | 'Maintain' | 'Predict' | 'Planner'

export const PLANT_ID = '{plantId}'
export const PLANT_NAME = '{plantName}'
export const PLANT_SHORT_NAME = '{plantShortName}'

export type TemplateUrl = string // including at least one template parameter

export type AppConfig = {
  contact: {
    name: string
    mail: string
    profileImage: string
  }
  name: AppName
  url: {
    qa: TemplateUrl
    uat: TemplateUrl
    prod: TemplateUrl
  }
  whiteListedPermissionTypes: PermissionType[]
  plant?: {
    whiteList?: PlantId[]
    blackList?: PlantId[]
  }
}

export type PlantId = string

// TODO find a better name
export type Option = {
  appName: AppName
  plantId: PlantId
  plantName: string
  url: string
}

export type AppPlusPlant = string // Like cockpitNl11

export type PlantJsonItem = {name: string; shortName?: string}

export type WhiteList = {
  optionsPerApp: Partial<Record<AppName, Option[]>>
  optionsPerPlant: Record<PlantId, Option[]>
  optionsMap: Map<AppPlusPlant, Option>
  missedPlantIds?: PlantId[]
}

export type Env = 'prod' | 'qa' | 'uat'
