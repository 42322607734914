import {Grid} from '@material-ui/core'
import {isEqual} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'
import {usePrevious} from 'react-use'

import {updateCementStrengthSettings} from '../../common/backend'
import {QueryKeyName} from '../../common/constants'
import {CS_TIME_HORIZON_IDS} from '../../common/timeHorizon'
import {CementStrengthViewUpdateSettings, TimeHorizonId} from '../../declarations'
import {usePlantConfig} from '../../hooks/usePlantConfig'
import {usePlantId} from '../../hooks/usePlantId'
import {usePlantSampleTypes} from '../../hooks/usePlantSampleTypes'
import {useSnackbar} from '../../hooks/useSnackbar'
import {PredictionStrengthSelect} from '../actionBar/PredictionStrengthSelect'
import {TimeHorizonSelect} from '../actionBar/TimeHorizonSelect'

import {CsSampleTypesSelect} from './CsSampleTypesSelect'
import {FormBox} from './FormBox'
import type {HasDisabled} from './types'

const useCementStrengthViewSettingsMutation = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const {showSuccess, showError} = useSnackbar()
  const client = useQueryClient()
  return useMutation(
    (mutationArgs: CementStrengthViewUpdateSettings) => {
      return updateCementStrengthSettings(plantId, mutationArgs)
    },
    {
      onSuccess: () => {
        showSuccess(t('settings.settingsSaved'))
        void client.invalidateQueries(QueryKeyName.GetPlants)
      },
      onError: () => {
        showError(t('settings.settingsNotSavedError'))
      }
    }
  )
}

export const CementStrengthViewSettings: React.FC<HasDisabled> = ({disabled}) => {
  const {t} = useTranslation()
  const availableSampleTypes = usePlantSampleTypes()
  const {defaultSampleTypeSources} = usePlantConfig()
  const {defaultStrengthLevel, defaultTimeDurationCementStrength} = usePlantConfig()
  const prevSampleTypes = usePrevious(defaultSampleTypeSources)
  const [selectedSampleTypes, setSelectedSampleTypes] = useState(defaultSampleTypeSources)

  const [selectedTimeHorizon, setSelectedTimeHorizon] = useState<TimeHorizonId>(
    defaultTimeDurationCementStrength
  )

  const [selectedStrengthLevel, setSelectedStrengthLevel] = useState(defaultStrengthLevel)

  const {isLoading, mutate} = useCementStrengthViewSettingsMutation()

  useEffect(() => {
    if (!isEqual(prevSampleTypes, defaultSampleTypeSources)) {
      setSelectedSampleTypes(defaultSampleTypeSources)
    }
  }, [defaultSampleTypeSources, prevSampleTypes])

  useEffect(() => {
    setSelectedTimeHorizon(defaultTimeDurationCementStrength)
  }, [defaultTimeDurationCementStrength])

  useEffect(() => {
    setSelectedStrengthLevel(defaultStrengthLevel)
  }, [defaultStrengthLevel])

  const onRevert = () => {
    setSelectedSampleTypes(defaultSampleTypeSources)
    setSelectedTimeHorizon(defaultTimeDurationCementStrength)
    setSelectedStrengthLevel(defaultStrengthLevel)
  }

  const onSave = () => {
    mutate({
      defaultTimeHorizon: selectedStrengthLevel,
      defaultTimeDurationCementStrength: selectedTimeHorizon,
      defaultSampleTypeSources: selectedSampleTypes
    })
  }

  const isPristine =
    selectedStrengthLevel === defaultStrengthLevel &&
    selectedTimeHorizon === defaultTimeDurationCementStrength &&
    isEqual(selectedSampleTypes, defaultSampleTypeSources)

  return (
    <FormBox
      title={t('cementStrengthViewSettings.title')}
      onSave={onSave}
      onRevert={onRevert}
      disabled={isPristine}
      isSaving={isLoading}
      data-test-id="cement-strength-view-settings-form-box"
    >
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12}>
          <CsSampleTypesSelect
            onSelected={setSelectedSampleTypes}
            selectable={availableSampleTypes}
            selected={selectedSampleTypes}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TimeHorizonSelect
            selectable={CS_TIME_HORIZON_IDS}
            value={selectedTimeHorizon}
            onSelected={setSelectedTimeHorizon}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <PredictionStrengthSelect
            strengthLevel={selectedStrengthLevel}
            onStrengthLevelChanged={setSelectedStrengthLevel}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </FormBox>
  )
}
