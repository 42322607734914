import {makeStyles} from '@material-ui/core'

export const MIN_CHART_WIDTH = 576

export const useChartScrollableWrapperStyles = makeStyles(() => ({
  scrollableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%'
  },
  chartWrapper: {
    margin: 'auto',
    minWidth: MIN_CHART_WIDTH
  }
}))
