import {AppInsightsAdapter} from '@hconnect/common/logging'
import {CircularProgress} from '@material-ui/core'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'

import {App} from './App'
import {initLocalization} from './localization'

initLocalization()
AppInsightsAdapter.init()
// Tracking information and logs will be sent to azure
AppInsightsAdapter.allowTracking()

ReactDOM.render(
  <Suspense fallback={<CircularProgress />}>
    <App />
  </Suspense>,
  document.getElementById('root')
)
