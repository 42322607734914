import React from 'react'

import {latestSample} from '../../common/cementStrength'

import {AccuracyStats} from './AccuracyStats'
import {CementStrengthAccuracyGraph} from './CementStrengthAccuracyGraph'
import {TrendsLink} from './TrendsLink'
import {MaterialChartContentProps} from './types'

export const MaterialAccuracyChart: React.FC<MaterialChartContentProps> = ({
  material,
  materialLevel,
  timeRange,
  data
}) => {
  return (
    <>
      <TrendsLink materialId={material.materialId} sampleId={latestSample(data.samples)?.id}>
        <CementStrengthAccuracyGraph
          materialData={data}
          materialLevel={materialLevel}
          timeRange={timeRange}
        />
      </TrendsLink>
      <AccuracyStats material={material} timeRange={timeRange} data={data} />
    </>
  )
}
