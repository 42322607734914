import {AppBar, Toolbar} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React, {useState} from 'react'

import {LayoutConstants} from '../constants'

import {useShellContext} from './Context'
import {UnsupportedBrowserWarning} from './UnsupportedBrowserWarning'

const useStyles = makeStyles((theme) => ({
  header: {
    '@media print': {
      display: 'none'
    }
  },
  appbar: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  toolbar: {
    flexGrow: 1,
    height: 66,
    minHeight: 50,
    transition: 'height .3s ease',
    width: '100%',
    display: 'flex'
  },

  boxed: {
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  compact: {
    height: 50
  },

  placeholder: {
    background: 'transparent'
  }
}))

export const isIE11 = !!/Trident\/7\./.exec(navigator.userAgent)
interface HeaderProps {
  className?: string
  toolbarProps?: any
  scrolled?: boolean
  boxed?: boolean
  compact?: boolean
}

const Header: React.FC<HeaderProps> = ({
  children,
  className,
  toolbarProps,
  boxed,
  compact,
  scrolled,
  ...props
}) => {
  const {
    boxed: defaultBoxed,
    compact: defaultCompact,
    scrolled: defaultScrolled
  } = useShellContext()
  const [warningOpen, setWarningOpen] = useState(isIE11)
  const classes = useStyles()
  const useBoxedLayout = boxed !== undefined ? boxed : defaultBoxed
  const useCompactLayout = compact !== undefined ? compact : defaultCompact
  const isScrolled = scrolled !== undefined ? scrolled : defaultScrolled

  const addWarningHeight = (height: number) => (warningOpen ? height + 120 : height)

  return (
    <div className={classes.header}>
      <div
        className={classes.placeholder}
        style={{
          height: useCompactLayout || isScrolled ? addWarningHeight(50) : addWarningHeight(66)
        }}
      />
      <AppBar
        color="default"
        elevation={isScrolled ? 4 : 0}
        position="fixed"
        className={classNames(classes.appbar, className)}
        {...props}
      >
        <Collapse in={warningOpen}>
          <UnsupportedBrowserWarning onClose={() => setWarningOpen(false)} />
        </Collapse>

        <Toolbar
          disableGutters
          {...toolbarProps}
          className={classNames(classes.toolbar, {
            [classes.boxed]: useBoxedLayout,
            [classes.compact]: useCompactLayout || isScrolled
          })}
        >
          {children}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
