import i18n, {InitOptions} from 'i18next'
import detector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import backendResolver from './backend'

const detectorOptions = {
  order: ['querystring', 'navigator', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain']
}

export const Localization = (props: InitOptions = {}) => {
  const params: InitOptions = {
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    compatibilityJSON: 'v3',
    detection: detectorOptions,
    saveMissing: process.env.NODE_ENV === 'development',
    backend: {
      loader: (filename: string) => {
        if (process.env.NODE_ENV === 'development') {
          console.warn(
            'You need to setup your Localization-Loader! Please have a look at the @hconnect/uikit readme-file on how to setup Translations.'
          )
          console.info(`Load Translation for uikit [${filename}]`)
        }
        return import(`./${filename}.json`).catch((err) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(`Could not load Translation for uikit [${filename}]`)
          }
          return {err}
        })
      }
    },
    ...props
  }
  return i18n.use(backendResolver).use(detector).use(initReactI18next).init(params)
}

export {backendResolver}

// Bugfix
// if your project uses "import {Localization} from '@hconnect/uikit'"
// and "import {useTranslation} from 'react-i18next'"
// and the version number in the dependency list dose not match 100% in uikit and your project
// webpack will include both versions, the translation will fail and the console will just read:
// "react-i18next:: You will need to pass in an i18next instance by using initReactI18next"
//
// to avoid that use "import {useTranslation} from '@hconnect/uikit'" instead
// and remove "react-i18next" from your dependency list
export {useTranslation} from 'react-i18next'
