import {Button, ButtonGroup, makeStyles, Theme, Tooltip} from '@material-ui/core'
import {ZoomIn, ZoomOut} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DEFAULT_ZOOM_IN_FACTOR, DEFAULT_ZOOM_OUT_FACTOR} from '../../common/constants'
import {zoom} from '../../common/timeRange'
import {DateTimeParam, TimeRange} from '../../declarations'

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    marginBottom: theme.spacing(1)
  }
}))

interface ZoomButtonGroupProps {
  timeRange: TimeRange<DateTimeParam>
  onTimeRangeChanged: (string: DateTimeParam, end: DateTimeParam) => void
  maxEnd?: DateTimeParam
}

export const ZoomButtonGroup: React.FC<ZoomButtonGroupProps> = ({
  timeRange,
  onTimeRangeChanged,
  maxEnd
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const onZoom = (isZoomingIn = false) => {
    const zoomed = zoom(
      timeRange,
      isZoomingIn ? DEFAULT_ZOOM_IN_FACTOR : DEFAULT_ZOOM_OUT_FACTOR,
      maxEnd
    )
    onTimeRangeChanged(zoomed.start, zoomed.end)
  }

  return (
    <ButtonGroup className={classes.buttonGroup} data-test-id="prediction-view-btn-group">
      <Tooltip title={t('pageActionBar.actionZoomIn') ?? ''}>
        <Button onClick={() => onZoom(true)} aria-label={t('pageActionBar.actionZoomIn')}>
          <ZoomIn />
        </Button>
      </Tooltip>

      <Tooltip title={t('pageActionBar.actionZoomOut') ?? ''}>
        <Button onClick={() => onZoom(false)} aria-label={t('pageActionBar.actionZoomOut')}>
          <ZoomOut />
        </Button>
      </Tooltip>
    </ButtonGroup>
  )
}
