import {isString} from 'lodash'

import {StorageProvider} from '../declarations'

export const STORAGE_PROVIDERS: StorageProvider[] = [
  'Auto',
  'InfluxOSS',
  'InfluxCloud',
  'AzureBlob',
  'ADX'
]

export const isStorageProvider = (provider: unknown): provider is StorageProvider =>
  isString(provider) && STORAGE_PROVIDERS.includes(provider as StorageProvider)

export const getValidStorageProvider = (provider: unknown): StorageProvider | undefined =>
  isStorageProvider(provider) ? provider : undefined
