import {Module} from 'i18next'

const defaults = {
  loader: (callback: () => any) => callback(),
  // will replace - with _ in filenames (default: true)
  forceUnderscore: true,
  loadFile: '{{lng}}'
}

class Backend {
  type: Module['type']
  services: any
  options: any
  coreOptions: any
  static type: string

  constructor(services: any, options = {}) {
    this.init(services, options)

    this.type = 'backend'
  }

  init(services: any, options = {}, coreOptions = {}) {
    this.services = services
    this.options = {...defaults, ...options}
    this.coreOptions = coreOptions
  }

  read = async (
    language: string,
    namespace: any,
    callback: (arg0: {error: string} | null, arg1: null) => void
  ) => {
    const {forceUnderscore, loadFile, loader} = this.options
    const lng = forceUnderscore ? language.replace(/-/, '_') : language
    const filename = this.services.interpolator.interpolate(loadFile, {lng, ns: namespace})
    const resource = await loader(filename)
    if (resource && !resource.err) {
      callback(null, resource)
    } else {
      callback(
        {
          error: `could not load translation [${language}]:[${namespace}]!`
        },
        null
      )
    }
  }

  create(languages: any[], namespace: any, key: any) {
    // handle missing translations
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Translation for "${key}" is missing in [${languages.join(', ')}]:[${namespace}]`
      )
    }
  }
}

Backend.type = 'backend'

export default Backend
