import {makeStyles} from '@material-ui/core'
import {Theme} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(3)
  }
}))

export const PageContainer: React.FC = ({children}) => {
  const classes = useStyles()
  return <div className={classes.pageContainer}>{children}</div>
}
