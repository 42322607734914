import {IconButton, IconButtonProps, makeStyles, Tooltip} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  transparentIconButton: {
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))

export const TableIconButton: React.FC<IconButtonProps & {toolTipTitle: string}> = (props) => {
  const classes = useStyles()
  const {toolTipTitle, className, ...rest} = props

  return (
    <Tooltip title={toolTipTitle}>
      <IconButton className={classNames(classes.transparentIconButton, className)} {...rest} />
    </Tooltip>
  )
}
