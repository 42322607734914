import {LoadingButton as HCLoadingButton} from '@hconnect/uikit'
import {ButtonProps, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  progress: {
    bottom: 9
  }
}))

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  btnClassName?: string
  progressClassName?: string
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({...props}) => {
  const {progress} = useStyles()
  return <HCLoadingButton progressClassName={progress} {...props} />
}
