import cockpitContactPersonImageSrc from '../assets/ContactPersonImage/cockpit.jpeg'
import {AppConfig} from '../types'

export const cockpit: AppConfig = {
  contact: {
    name: 'Gesa Hartmann',
    mail: 'gesa.hartmann@heidelbergcement.com',
    profileImage: cockpitContactPersonImageSrc
  },
  name: 'Cockpit',
  url: {
    qa: 'https://cockpit-qa.heidelbergcement.com/',
    uat: 'https://cockpit-uat.heidelbergcement.com/',
    prod: 'https://cockpit.heidelbergcement.com/'
  },

  // 'VIEW_SHIFT_REPORT' and 'VIEW_PLANT_EVENT' is unfortunately also used by RELIABILITY_CONTRIBUTOR
  whiteListedPermissionTypes: ['VIEW_PLANT_TASK']
}
