import React from 'react'

import {latestSample} from '../../common/cementStrength'
import {useCsParamsWithDefaults} from '../../hooks/useCsParamsWithDefaults'

import {CementStrengthGraph} from './CementStrengthGraph'
import {MillStats} from './MillStats'
import {TrendsLink} from './TrendsLink'
import {MaterialChartContentProps} from './types'

export const MaterialTimeChart: React.FC<MaterialChartContentProps> = ({
  material,
  materialLevel,
  timeRange,
  data
}) => {
  const {strength} = useCsParamsWithDefaults()
  const {materialId} = material

  return (
    <>
      <TrendsLink materialId={materialId} sampleId={latestSample(data.samples)?.id}>
        <CementStrengthGraph
          materialData={data}
          materialLevel={materialLevel}
          timeRange={timeRange}
        />
      </TrendsLink>
      <MillStats
        data={data}
        materialId={materialId}
        strengthLevel={strength}
        timeRange={timeRange}
      />
    </>
  )
}
