import {isEqual} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'
import {usePrevious} from 'react-use'

import {getMaterialLevel, getStrengthLevelInDays} from '../../common/cementStrength'
import {QueryKeyName} from '../../common/constants'
import {usePlantConfig} from '../../hooks/usePlantConfig'
import {usePlantId} from '../../hooks/usePlantId'
import {useSnackbar} from '../../hooks/useSnackbar'
import {useMaterialContext} from '../../providers/MaterialProvider'

import {isValidCategories, saveCategories} from './cementStrengthTargetSettingsHelper'
import {FormBox} from './FormBox'
import {
  TargetRangeCategory,
  TargetRangeCollapsableTableForm
} from './TargetRangeCollapsableTableForm'
import type {HasDisabled} from './types'

const useBackendCategories = (): TargetRangeCategory[] => {
  const {t} = useTranslation()
  const {materials} = useMaterialContext()
  const {supportedStrengthLevels} = usePlantConfig()

  return useMemo(
    () =>
      materials.map((mat) => ({
        id: mat.materialId,
        categoryLabel: mat.name,
        entries: supportedStrengthLevels.map((strengthLevel) => ({
          id: strengthLevel,
          label: t('strengthLevel.strength', {count: getStrengthLevelInDays(strengthLevel)}),
          targetRange: getMaterialLevel(mat, strengthLevel) ?? {}
        }))
      })),
    [materials, supportedStrengthLevels, t]
  )
}

const useCsTargetRangeMutation = () => {
  const plantId = usePlantId()
  const {t} = useTranslation()
  const client = useQueryClient()
  const {showSuccess, showError} = useSnackbar()
  return useMutation((categories: TargetRangeCategory[]) => saveCategories(categories, plantId), {
    onSuccess: () => {
      showSuccess(t('settings.settingsSaved'))
      void client.invalidateQueries(QueryKeyName.GetMaterials)
      void client.invalidateQueries(QueryKeyName.GetPlants)
    },
    onError: () => {
      showError(t('settings.settingsNotSavedError'))
    }
  })
}

export const CementStrengthTargetSettings: React.FC<HasDisabled> = ({disabled}) => {
  const {t} = useTranslation()
  const backendCategories = useBackendCategories()
  const prevBeCategories = usePrevious(backendCategories)
  const [targetRangeCategories, setTargetRangeCategories] =
    useState<TargetRangeCategory[]>(backendCategories)
  const {mutate, isLoading} = useCsTargetRangeMutation()

  useEffect(() => {
    if (!isEqual(prevBeCategories, backendCategories)) {
      setTargetRangeCategories(backendCategories)
    }
  }, [backendCategories, prevBeCategories])

  return (
    <FormBox
      title={t('cementStrengthTargetSettings.title')}
      onRevert={() => {
        setTargetRangeCategories(backendCategories)
      }}
      onSave={() => {
        mutate(targetRangeCategories)
      }}
      isFormInvalid={!isValidCategories(targetRangeCategories)}
      disabled={isEqual(backendCategories, targetRangeCategories)}
      isSaving={isLoading}
      data-test-id="cement-strength-target-settings-form-box"
    >
      <TargetRangeCollapsableTableForm
        headerLabel={t('cementStrengthTargetSettings.tableHeaderLabel')}
        unit={t('cementStrengthTargetSettings.tableHeaderUnit')}
        categories={targetRangeCategories}
        onChange={(categories) => {
          setTargetRangeCategories(categories)
        }}
        disabled={disabled}
      />
    </FormBox>
  )
}
