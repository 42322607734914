import {useMemo} from 'react'

import {objectToParam} from '../common/navigation'
import type {SortOrder, TableSort, UrlQueryKey} from '../declarations'

import {useQueryParamsWithDefaults} from './useQueryParamsWithDefaults'
import {useSetQueryParamWithConverter} from './useSetQueryParam'

type UrlSortKey = Extract<
  UrlQueryKey,
  'adminSensorsTableSort' | 'settingsSensorsTableSort' | 'csTableSort'
>

export const useTableSort = <K>(urlSortParam: UrlSortKey) => {
  const urlParams = useQueryParamsWithDefaults()
  const setSortParam = useSetQueryParamWithConverter<TableSort<K>>(urlSortParam, objectToParam)

  return useMemo(() => {
    const sortCriterion = urlParams[urlSortParam] as TableSort<K> | undefined
    const setSortCriterion = (field: K, order: SortOrder) => {
      setSortParam({field, order})
    }
    return {
      sortCriterion,
      setSortCriterion
    }
  }, [setSortParam, urlParams, urlSortParam])
}
