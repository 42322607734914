import React, {Dispatch, SetStateAction, useContext, useState} from 'react'

interface ShellContext {
  boxed: boolean
  setBoxed: Dispatch<SetStateAction<boolean>>
  compact: boolean
  setCompact: Dispatch<SetStateAction<boolean>>
  scrolled: boolean
}

const ShellContext = React.createContext<ShellContext>({
  boxed: false,
  setBoxed: () => {},
  compact: false,
  setCompact: () => {},
  scrolled: false
})

interface ContextProps {
  defaultBoxed: boolean
  defaultCompact: boolean
  scrolled: boolean
}

const Context: React.FC<ContextProps> = ({
  defaultBoxed,
  defaultCompact,
  scrolled = false,
  children
}) => {
  const [boxed, setBoxed] = useState(defaultBoxed)
  const [compact, setCompact] = useState(defaultCompact)
  return (
    <ShellContext.Provider
      value={{
        boxed,
        setBoxed,
        compact,
        setCompact,
        scrolled
      }}
    >
      {children}
    </ShellContext.Provider>
  )
}

export default Context

export const useShellContext = () => useContext(ShellContext)
