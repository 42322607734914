import {Button, makeStyles} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import cx from 'classnames'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {HCThemeType} from '../../../HCTheme.types'

import {
  formatDate,
  formatTime,
  getDateObjectFromInput,
  getDateValue,
  getTimeValue,
  isDateValid
} from './helpers'
import {Dates} from './types'

const useStyle = makeStyles((theme: HCThemeType) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(4)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputs: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  input: {
    width: 146,
    borderRadius: 4,
    height: 48,
    marginBottom: theme.spacing(2),
    background: 'rgba(0,0,0,0.01)',
    boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.50), inset 0 4px 4px 0 rgba(0,0,0,0.05)',
    '& input': {
      padding: '13px 13px 5px'
    },
    '& input:valid + fieldset > legend > span': {
      display: 'none'
    }
  },
  label: {
    transform: 'translate(13px, 16px) scale(1)',
    color: theme.palette.text.primarySoft
  },
  shrink: {
    transform: 'translate(13px, 6px) scale(0.75) !important'
  },
  button: {
    padding: 10,
    textTransform: 'none',
    '&:first-child': {
      marginRight: 20
    }
  },
  delete: {
    color: theme.palette.primary.main
  }
}))

export interface PickerFormProps {
  applyDateRange: (dates: Dates) => void
  dateExceptions?: Date[]
  isOutsideRange?: (arg: Date) => boolean
  isSingleDate?: boolean
  endDate?: Date | null
  startDate: Date | null
  dateFormatter?: (d: Date) => string
  timeFormatter?: (d: Date) => string
}

const PickerForm: React.FC<PickerFormProps> = ({
  applyDateRange,
  endDate: _endDate,
  dateExceptions,
  isOutsideRange,
  isSingleDate,
  startDate: _startDate,
  dateFormatter = formatDate,
  timeFormatter = formatTime
}) => {
  const classes = useStyle()
  const {t} = useTranslation()
  const [startDate, setStartDate] = useState<string>(_startDate ? dateFormatter(_startDate) : '')
  const [endDate, setEndDate] = useState<string>(_endDate ? dateFormatter(_endDate) : '')
  const [startTime, setStartTime] = useState<string>(
    _startDate ? timeFormatter(_startDate) : '00:00'
  )
  const [endTime, setEndTime] = useState<string>(_endDate ? timeFormatter(_endDate) : '00:00')
  const isInputValid = useMemo(
    () =>
      isSingleDate
        ? isDateValid({
            date: startDate,
            time: startTime,
            exceptions: dateExceptions,
            isOutsideRange
          })
        : isDateValid({date: startDate, time: startTime, isOutsideRange}) &&
          isDateValid({date: endDate, time: endTime, isOutsideRange}) &&
          getDateObjectFromInput(endDate, endTime) > getDateObjectFromInput(startDate, startTime),
    [dateExceptions, endDate, endTime, isOutsideRange, isSingleDate, startDate, startTime]
  )

  const handleDateChange = useCallback(
    ({target}) => {
      const value = getDateValue(target.value)
      target.name === 'startDate' ? setStartDate(value) : setEndDate(value)
    },
    [setStartDate]
  )

  const handleTimeChange = useCallback(
    ({target}) => {
      const value = getTimeValue(target.value)
      target.name === 'startTime' ? setStartTime(value) : setEndTime(value)
    },
    [setStartTime]
  )

  const clearInput = useCallback(() => {
    setStartDate('')
    setEndDate('')
    setStartTime('')
    setEndTime('')
  }, [])

  const apply = useCallback(() => {
    applyDateRange({
      startDate: getDateObjectFromInput(startDate, startTime) || null,
      endDate: getDateObjectFromInput(endDate, endTime) || null
    })
  }, [applyDateRange, endDate, endTime, startDate, startTime])

  useEffect(() => {
    setStartDate(_startDate ? dateFormatter(_startDate) : '')
    setEndDate(_endDate ? dateFormatter(_endDate) : '')
  }, [_startDate, _endDate, dateFormatter])

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputs} data-test-id="time-range-inputs">
        <TextField
          variant="outlined"
          name="startDate"
          placeholder="09.01.2021"
          label={t('datePicker.startDate')}
          value={startDate}
          onChange={handleDateChange}
          InputProps={{
            autoComplete: 'off',
            classes: {
              root: classes.input
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.shrink
            }
          }}
        />
        <TextField
          variant="outlined"
          name="startTime"
          placeholder="00:00"
          label={t('datePicker.startTime')}
          value={startTime}
          onChange={handleTimeChange}
          InputProps={{
            autoComplete: 'off',
            classes: {
              root: classes.input
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.shrink
            }
          }}
        />
        {!isSingleDate && (
          <>
            <TextField
              variant="outlined"
              name="endDate"
              placeholder="09.01.2021"
              label={t('datePicker.endDate')}
              value={endDate}
              onChange={handleDateChange}
              InputProps={{
                autoComplete: 'off',
                classes: {
                  root: classes.input
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  shrink: classes.shrink
                }
              }}
            />
            <TextField
              variant="outlined"
              name="endTime"
              placeholder="00:00"
              label={t('datePicker.endTime')}
              value={endTime}
              onChange={handleTimeChange}
              InputProps={{
                autoComplete: 'off',
                classes: {
                  root: classes.input
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  shrink: classes.shrink
                }
              }}
            />
          </>
        )}
      </div>
      <div className={classes.buttons}>
        <Button
          className={cx(classes.button, classes.delete)}
          onClick={clearInput}
          color="secondary"
          variant="outlined"
          data-test-id="range-delete"
        >
          {t('datePicker.delete')}
        </Button>
        <Button
          className={classes.button}
          onClick={apply}
          disabled={!isInputValid}
          color="primary"
          data-test-id="range-apply"
        >
          {t('datePicker.apply')}
        </Button>
      </div>
    </div>
  )
}

export {PickerForm}
