import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {getPlantSpecificSensorMetaData} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {ProcessMetaData} from '../declarations'

import {usePlantId} from './usePlantId'

export const usePlantSpecificSensorMetaData = (): ProcessMetaData[] => {
  const plantId = usePlantId()
  const {data} = useQuery([QueryKeyName.GetPlantSpecificSensorMeta, plantId], async () => {
    const metaData = await getPlantSpecificSensorMetaData(plantId)
    return {metaData}
  })
  return useMemo(() => data?.metaData ?? [], [data])
}
