import React, {createContext, useContext, useMemo} from 'react'
import {useQuery} from 'react-query'

import {getMaterials} from '../common/backend'
import {QueryKeyName} from '../common/constants'
import {MaterialContext} from '../declarations'
import {usePlantId} from '../hooks/usePlantId'

const MatContext = createContext<MaterialContext | undefined>(undefined)

export const useMaterialContext = (): MaterialContext => {
  const context = useContext(MatContext)
  if (!context) {
    throw new Error('useMaterialContext used outside of MaterialProvider')
  }

  return context
}

export const MaterialProvider: React.FC = ({children}) => {
  const plantId = usePlantId()
  const {isLoading, error, data} = useQuery(
    [QueryKeyName.GetMaterials, plantId],
    () => getMaterials(plantId),
    {enabled: Boolean(plantId)}
  )

  const value = useMemo(
    () => ({
      materials: data ?? [],
      isLoading,
      error
    }),
    [data, isLoading, error]
  )

  return <MatContext.Provider value={value}>{children}</MatContext.Provider>
}
