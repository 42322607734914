import {makeStyles, Theme} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DefaultBox} from '../components/DefaultBox'
import {KilnDataChart} from '../components/freeLimeOverview/KilnDataChart'
import {LatestSamplesInfoBox} from '../components/freeLimeOverview/LatestSamplesInfoBox'
import {KilnDataSet} from '../declarations'
import {useChartContainerStyles} from '../hooks/useChartContainerStyles'

interface KilnChartsProps {
  kilnData: KilnDataSet
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    height: theme.spacing(3)
  }
}))

export const KilnCharts: React.FC<KilnChartsProps> = ({kilnData}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const chartClasses = useChartContainerStyles()

  return (
    <div className={chartClasses.container}>
      <div className={chartClasses.itemGraph}>
        <DefaultBox data-test-id={`kiln-data-chart-${kilnData.kilnId}`} minHeight={596}>
          <KilnDataChart data={kilnData} />
        </DefaultBox>
      </div>
      <div className={chartClasses.itemDetails}>
        <LatestSamplesInfoBox
          title={t('latestSamplesInfoBox.title')}
          freeLimeSamples={kilnData.actualValues}
          showQualityParams
          className={chartClasses.itemDetailBox}
          data-test-id="latest-samples-info-box"
        />
        <div className={classes.divider} />
        <LatestSamplesInfoBox
          title={t('latestSamplesInfoBox.titlePredictions')}
          freeLimeSamples={kilnData.predictions}
          className={chartClasses.itemDetailBox}
          data-test-id="latest-predictions-info-box"
        />
      </div>
    </div>
  )
}
