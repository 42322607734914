import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    background:
      'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)',
    color: theme.palette.primary.contrastText,
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface ContentProps {
  className?: string
  style?: React.CSSProperties
}

const Content: React.FC<ContentProps> = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.contentContainer, className)} {...props}>
      {children}
    </div>
  )
}

export default Content
