import React from 'react'

import {LinearScaleFn} from './types'

interface HorizontalGridProps {
  scale: LinearScaleFn
  color: string
  width: number
  tickValues: number[]
}

const strokeWidth = 1

export const HorizontalGrid: React.FC<HorizontalGridProps> = ({
  scale,
  color,
  width,
  tickValues
}) => {
  return (
    <g>
      {tickValues.map((tick) => {
        const y = scale(tick)

        return (
          <line
            key={tick}
            stroke={color}
            strokeWidth={strokeWidth}
            y1={y}
            y2={y}
            x1={0}
            x2={width}
          />
        )
      })}
    </g>
  )
}
