import {Box, makeStyles, Theme, Typography} from '@material-ui/core'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {TFunction, useTranslation} from 'react-i18next'

import {LSF_CLINKER_ID} from '../../common/constants'
import {formatTimeZoneDate} from '../../common/dateUtils'
import {addOrRemove} from '../../common/helpers'
import {arrayToParameter} from '../../common/navigation'
import {ChartCategory, KilnDataSet, ProcessMetaData} from '../../declarations'
import {useChartScrollableWrapperStyles} from '../../hooks/useChartScrollableWrapperStyles'
import {useFlColorFn} from '../../hooks/useColorFn'
import {useFlParamsWithDefaults} from '../../hooks/useFlParamsWithDefaults'
import {usePlantKilnIds} from '../../hooks/usePlantKilnIds'
import {usePlantTimeZone} from '../../hooks/usePlantTimeZone'
import {useProcessMetaData} from '../../hooks/useProcessMetaData'
import {useSetQueryParamWithConverter} from '../../hooks/useSetQueryParam'
import {ChartInfoText} from '../ChartInfoText'
import {ChartButtonBar} from '../charts/ChartButtonBar'

import {FreeLimeGraph} from './FreeLimeGraph'

interface KilnDataChartProps {
  data: KilnDataSet
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  chartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  buttonBar: {
    marginLeft: -4,
    marginTop: theme.spacing(1)
  }
}))

const calcLabDataCategory = (t: TFunction): ChartCategory => ({
  categoryId: 'labData',
  categoryLabel: t('chart.labData'),
  options: [
    {
      id: LSF_CLINKER_ID,
      label: t('sampleData.lsfClinker.label'),
      selectable: true
    }
  ]
})

const calcSensorDataCategory = (metaData: ProcessMetaData[], t: TFunction): ChartCategory => ({
  categoryId: 'sensorData',
  categoryLabel: t('chart.sensorData'),
  options: metaData.map(({uniformTag, displayName}) => ({
    id: uniformTag,
    label: displayName,
    selectable: true
  }))
})

const calcChartCategories = (metaData: ProcessMetaData[], t: TFunction): ChartCategory[] => [
  calcLabDataCategory(t),
  calcSensorDataCategory(metaData, t)
]

export const KilnDataChart: React.FC<KilnDataChartProps> = ({data}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const wrapperClasses = useChartScrollableWrapperStyles()
  const timeZone = usePlantTimeZone()
  const {flSelectedTagIds} = useFlParamsWithDefaults()
  const kilnIds = usePlantKilnIds()
  const {actualValues, predictions} = data
  const lastActual: number | undefined = actualValues[actualValues.length - 1]?.datetime
  const lastPrediction: number | undefined = predictions[predictions.length - 1]?.datetime
  const metaData = useProcessMetaData()
  const colorFn = useFlColorFn()
  const categories = useMemo(() => calcChartCategories(metaData, t), [metaData, t])
  const onTagIdsChanged = useSetQueryParamWithConverter<string[]>(
    'flSelectedTagIds',
    arrayToParameter
  )
  const onTagSelected = useCallback(
    (optionId: string, selected: boolean) => {
      onTagIdsChanged(addOrRemove(flSelectedTagIds, optionId, selected))
    },
    [flSelectedTagIds, onTagIdsChanged]
  )
  const onTagsSelected = useCallback(
    (selected: string[]) => {
      onTagIdsChanged(selected)
    },
    [onTagIdsChanged]
  )
  const showKilnId = kilnIds.length > 1

  return (
    <div className={classes.container}>
      <div className={classes.chartHeader}>
        <ChartInfoText data-test-id="kiln-data-chart-info-box">
          {isNumber(lastActual) && (
            <>
              {t('freeLimeChart.lastClinkerSample', {
                datetime: formatTimeZoneDate(lastActual, timeZone, t('chart.latestSampleDate'))
              })}
              <br />
            </>
          )}
          {isNumber(lastPrediction) &&
            t('freeLimeChart.lastPrediction', {
              datetime: formatTimeZoneDate(lastPrediction, timeZone, t('chart.latestSampleDate'))
            })}
        </ChartInfoText>
        {showKilnId && (
          <Typography variant="h6">{t('freeLimeChart.kiln', {id: data.kilnId})}</Typography>
        )}
      </div>
      <div className={classes.buttonBar}>
        <ChartButtonBar
          options={categories}
          optionColorFn={colorFn}
          selectedOptions={flSelectedTagIds}
          onOptionSelected={onTagSelected}
          onSelected={onTagsSelected}
        />
      </div>

      <Box className={wrapperClasses.scrollableWrapper}>
        <Box className={wrapperClasses.chartWrapper}>
          <FreeLimeGraph dataSet={data} />
        </Box>
      </Box>
    </div>
  )
}
