import {useMemo} from 'react'

import {calcCementStrengthStats} from '../common/cementStrength'
import {CementStrengthSample, PredictionStats, TimeRange} from '../declarations'

import {useCsParamsWithDefaults} from './useCsParamsWithDefaults'
import {useMaterialLevel} from './useMaterialLevel'

export const useCementStrengthStats = (
  samples: CementStrengthSample[],
  timeRange: TimeRange,
  materialId: number
): PredictionStats => {
  const {strength: strengthLevel} = useCsParamsWithDefaults()
  const materialLevel = useMaterialLevel(materialId, strengthLevel)
  const target = materialLevel?.target
  return useMemo(
    () => calcCementStrengthStats(samples, timeRange, strengthLevel, target),
    [samples, strengthLevel, target, timeRange]
  )
}
