import {YAxisLabel} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getStrengthLevelInDays} from '../../common/cementStrength'
import {StrengthLevel} from '../../declarations'

interface CementStrengthAxisLabelProps {
  height: number
  strengthLevel: StrengthLevel
  color: string
  xOffset?: number
}

export const CementStrengthAxisLabel: React.FC<CementStrengthAxisLabelProps> = ({
  height,
  strengthLevel,
  color,
  xOffset = -32
}) => {
  const {t} = useTranslation()

  return (
    <YAxisLabel
      height={height}
      label={t('chart.axisCementStrengthLabel', {count: getStrengthLevelInDays(strengthLevel)})}
      labelColor={color}
      xOffset={xOffset}
    />
  )
}
