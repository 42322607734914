import {LandingPage, LogoPredictLanding} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAuthContext} from './AuthProvider'

export const Login: React.FC = () => {
  const {login} = useAuthContext()
  const {t} = useTranslation()

  return (
    <LandingPage
      logos={[LogoPredictLanding]}
      onClickButton={() => {
        login()
      }}
      loginButtonLabel={t('login.button')}
    />
  )
}
