import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  filterCategories,
  isChartOption,
  isOptionSelected,
  toCategories
} from '../../common/charts/chartOptions'
import {createFilterTerms} from '../../common/filter'
import {addOrRemove, replaceNonWordCharacters} from '../../common/helpers'
import {ChartCategory, ChartOption} from '../../declarations'
import {DialogCancelSaveActions} from '../DialogCancelSaveActions'

interface ChartDataDialogProps {
  open: boolean
  onClose: (selectedOptions?: string[]) => void
  options: ChartOption[] | ChartCategory[]
  selectedOptions: string[]
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: 500
  },
  subHeader: {
    color: theme.palette.text.primary,
    background: theme.palette.grey[50]
  },
  searchBox: {
    marginBottom: theme.spacing(3)
  },
  listWrapper: {
    height: 400,
    paddingTop: 0
  }
}))

const SEARCH_DELAY_MS = 500

export const ChartDataDialog: React.FC<ChartDataDialogProps> = ({
  open,
  onClose,
  options,
  selectedOptions
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [selected, setSelected] = useState(selectedOptions)
  const [filter, setFilter] = useState<string[]>([])
  const filteredCategories = useMemo(
    () => filterCategories(toCategories(options), filter),
    [filter, options]
  )
  const debounced = useRef(
    debounce((searchTerm: string) => {
      setFilter(createFilterTerms(searchTerm))
    }, SEARCH_DELAY_MS)
  )

  useEffect(() => {
    const debouncedFunc = debounced.current
    return () => {
      debouncedFunc.cancel()
    }
  }, [])

  const handleApply = useCallback(() => {
    onClose(selected)
  }, [onClose, selected])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleFilterValueChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const {value} = event.target
      debounced.current(value)
    },
    []
  )

  const onEnter = () => {
    setSelected(selectedOptions)
    setFilter([])
  }

  const onExit = () => {
    debounced.current.cancel()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="chart-dialog-title"
      PaperProps={{className: classes.dialog}}
      TransitionProps={{onEnter, onExit}}
    >
      <DialogTitle id="chart-dialog-title">{t('chart.addData')}</DialogTitle>
      <DialogContent className={classes.searchBox}>
        <TextField
          type="search"
          placeholder={t('chartDataDialog.filterList')}
          onChange={handleFilterValueChange}
          fullWidth
          data-test-id="chart-data-dialog-search-field"
        />
      </DialogContent>

      <DialogContent className={classes.listWrapper}>
        {filteredCategories.length === 0 ? (
          <Typography>{t('chartDataDialog.noEntries')}</Typography>
        ) : (
          <List disablePadding>
            {filteredCategories
              .flatMap(({categoryId, categoryLabel, options}) => [
                {id: categoryId, label: categoryLabel},
                ...options
              ])
              .map((item) =>
                isChartOption(item) ? (
                  <ListItem key={item.id} disabled={!item.selectable}>
                    <Checkbox
                      data-test-id={`chart-data-dialog-item-${replaceNonWordCharacters(item.id)}`}
                      checked={!item.selectable || isOptionSelected(item, selected)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSelected(addOrRemove(selected, item.id, event.target.checked))
                      }
                    />
                    <ListItemText primary={item.label} />
                  </ListItem>
                ) : (
                  <ListSubheader className={classes.subHeader} key={item.id}>
                    {item.label}
                  </ListSubheader>
                )
              )}
          </List>
        )}
      </DialogContent>
      <DialogCancelSaveActions onCancel={handleClose} onSave={handleApply} />
    </Dialog>
  )
}
