import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  MenuProps,
  Select,
  Theme
} from '@material-ui/core'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {replaceNonWordCharacters} from '../../common/helpers'
import {CsSampleSource, CsSampleType} from '../../declarations'
import {usePlantSampleSources} from '../../hooks/usePlantSampleSources'

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: 0,
    marginBottom: 8,
    width: 160,
    maxWidth: '100%'
  },
  subHeader: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main
  }
}))

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  PaperProps: {
    id: 'cs-sample-source-select-paper-id'
  },
  MenuListProps: {
    disablePadding: true
  }
}

interface SampleSourceListItem {
  sampleType: CsSampleType
  name?: string
}

interface CsSampleSourceSelectProps {
  selectedSources: string[]
  onSourceSelected: (value: string[]) => void
  disabled?: boolean
}

const sampleSourcesToList = (sampleSources: CsSampleSource[]): SampleSourceListItem[] =>
  sampleSources
    .filter(({names}) => names.length > 0)
    .flatMap(({sampleType, names}) => [{sampleType}, ...names.map((name) => ({sampleType, name}))])

export const CsSampleSourceSelect: React.FC<CsSampleSourceSelectProps> = ({
  selectedSources,
  onSourceSelected,
  disabled
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const sampleSources = usePlantSampleSources()
  const sourceNames: string[] = sampleSources.flatMap((source) => source.names)

  const onSelectionChanged = useCallback(
    (event: React.ChangeEvent<{value: unknown}>) => {
      onSourceSelected(event.target.value as string[])
    },
    [onSourceSelected]
  )

  const renderValue = useCallback(
    (selected) => {
      const selectedValues = selected as string[]
      if (isEmpty(selectedValues)) {
        return ''
      }
      return sourceNames.filter((pm) => selectedValues.includes(pm)).join(', ')
    },
    [sourceNames]
  )

  return (
    <FormControl className={classes.formContainer} variant="standard">
      <InputLabel id="cs-sample-source-select-label">
        {t('pageActionBar.actionSourcesLabel')}
      </InputLabel>
      <Select
        labelId="cs-sample-source-select-label"
        id="cs-sample-source-select-id"
        data-test-id="cs-sample-source-select-test-id"
        multiple
        value={selectedSources}
        onChange={onSelectionChanged}
        renderValue={renderValue}
        MenuProps={menuProps}
        disabled={disabled}
      >
        {sampleSourcesToList(sampleSources).map(({sampleType, name}) =>
          name ? (
            <MenuItem key={name} value={name}>
              <Checkbox
                data-test-id={`cs-sample-source-select-cb-${replaceNonWordCharacters(name)}`}
                checked={selectedSources.includes(name)}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ) : (
            <ListSubheader className={classes.subHeader} key={sampleType}>
              {t(`sampleType.${sampleType}`)}
            </ListSubheader>
          )
        )}
      </Select>
    </FormControl>
  )
}
