import {AxiosError} from 'axios'

import type {DownloadPollingStatus, TimeRange} from '../../declarations'
import {logger} from '../logger'
import {triggerFileDownload} from '../triggerFileDownload'

import {client} from './client'

export const startDownloadCementStrengthPredictionsExcel = async (
  plantId: string,
  timeRange: TimeRange
): Promise<string> => {
  let response
  try {
    response = await client.dfApi.post(
      `/predict/plants/${plantId}/cementStrengths/export/start?startTime=${timeRange.start.toJSON()}&endTime=${timeRange.end.toJSON()}`
    )
  } catch (e) {
    const error = e as AxiosError
    logger.error(error)
    throw error
  }

  const {headers, status} = response
  if (status !== 202) {
    throw new Error(`Excel download status response is ${status}!`)
  }
  if (!headers.location) {
    throw new Error('Excel download target location is undefined!')
  }
  return headers.location
}

export const pollDownloadCementStrengthPredictionsExcel = async (
  location: string,
  filename: string
): Promise<DownloadPollingStatus> => {
  const response = await client.dfApi.get<Blob>(location, {
    responseType: 'blob',
    headers: {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
  const {headers, status} = response
  if (status === 202 && Boolean(headers.location)) {
    return {location: headers.location, finished: false}
  }

  if (status === 200) {
    triggerFileDownload(response.data, filename)
    return {location: '', finished: true}
  }

  throw new Error('Polling for Excel download failed!')
}
