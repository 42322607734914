import {useMutation, UseMutationOptions} from 'react-query'

import {useSnackbar} from './useSnackbar'

export const useMutationWithSnackbar = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  successMessage: string,
  errorMessage: string,
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
  const {showSuccess, showError} = useSnackbar()
  const optionsWithMessageHooks: UseMutationOptions<TData, TError, TVariables, TContext> = {
    ...options,
    onSuccess: (data, variables, context) => {
      showSuccess(successMessage)
      return options.onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      showError(errorMessage)
      return options.onError?.(error, variables, context)
    }
  }
  return useMutation(optionsWithMessageHooks)
}
