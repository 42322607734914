import {Graph, GraphRenderProps, YAxis} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import * as d3 from 'd3'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CEMENT_STRENGTH_DOMAIN_MARGIN,
  COLOR_GRID_LINES,
  COLOR_OVERVIEW_BORDER
} from '../../common/charts/chartConfigurations'
import {calcTimeScale, timeAxisFormatter} from '../../common/charts/chartUtils'
import {AggregatedMaterialData, MaterialLevel, Plant, TimeRange} from '../../declarations'
import {usePlant} from '../../hooks/usePlant'
import {CementStrengthAxisLabel} from '../charts/CementStrengthAxisLabel'
import {GraphBorder} from '../charts/GraphBorder'
import {MaterialDataCurves} from '../charts/MaterialDataCurves'
import {TargetLines} from '../charts/TargetLines'

import {TimeAxis} from './TimeAxis'

interface CementStrengthGraphProps {
  materialData: AggregatedMaterialData
  materialLevel: MaterialLevel
  timeRange: TimeRange
  height?: number
}

const margin = {left: 48, top: 24, bottom: 32, right: 24} as const

const SUGGESTED_STRENGTH_TICK_COUNT = 6

export const CementStrengthGraph: React.FC<CementStrengthGraphProps> = ({
  materialData,
  materialLevel,
  timeRange,
  height = 200
}) => {
  const {t} = useTranslation()
  const plant = usePlant() as Plant
  const {timeZone} = plant
  const strengthLevel = materialLevel.name

  const render = useCallback(
    ({graphWidth, graphHeight, Clipped}: GraphRenderProps) => {
      const {x, ticks: xTimeTicks} = calcTimeScale({timeRange, graphWidth, timeZone, tickWidth: 64})

      const yDomain = [
        materialLevel.min - CEMENT_STRENGTH_DOMAIN_MARGIN,
        materialLevel.max + CEMENT_STRENGTH_DOMAIN_MARGIN
      ]
      const y = d3.scaleLinear().domain(yDomain).range([graphHeight, 0])

      const {format, extraFormat} = timeAxisFormatter(xTimeTicks, x, timeZone, t)

      return (
        <>
          <TimeAxis
            x={x}
            height={graphHeight}
            format={format}
            extraFormat={extraFormat}
            timeTicks={xTimeTicks}
            textColor={COLOR_OVERVIEW_BORDER}
          />
          <YAxis
            width={0}
            ticks={y.ticks(SUGGESTED_STRENGTH_TICK_COUNT)}
            y={y}
            textColor={COLOR_OVERVIEW_BORDER}
            color={COLOR_GRID_LINES}
          />
          <TargetLines
            width={graphWidth}
            targetRange={{
              min: y(materialLevel.min),
              max: y(materialLevel.max),
              target: y(materialLevel.target)
            }}
          />
          <GraphBorder
            graphHeight={graphHeight}
            graphWidth={graphWidth}
            color={COLOR_OVERVIEW_BORDER}
          />
          <CementStrengthAxisLabel
            height={graphHeight}
            strengthLevel={strengthLevel}
            color={COLOR_OVERVIEW_BORDER}
          />
          <Clipped>
            <MaterialDataCurves materialData={materialData} x={x} y={y} level={strengthLevel} />
          </Clipped>
        </>
      )
    },

    [timeRange, timeZone, materialLevel, t, strengthLevel, materialData]
  )

  return (
    <Box mr={-3}>
      <Graph
        height={height}
        margin={margin}
        render={render}
        graphId={`${materialData.materialId}`}
      />
    </Box>
  )
}
