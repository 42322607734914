import {useMemo} from 'react'

import {objectToParam} from '../common/navigation'
import {calcFilterArray} from '../common/tablePageUtils'
import {TableFilterData, UrlQueryKey} from '../declarations'

import {useQueryParamsWithDefaults} from './useQueryParamsWithDefaults'
import {useSetQueryParamWithConverter} from './useSetQueryParam'

type UrlFiltersKey = Extract<
  UrlQueryKey,
  'adminSensorsTableFilters' | 'settingsSensorsTableFilters' | 'csTableFilters'
>

export const useTableFilters = <K extends string>(urlFiltersKey: UrlFiltersKey) => {
  const setFilterData = useSetQueryParamWithConverter<TableFilterData<K>[]>(
    urlFiltersKey,
    objectToParam
  )
  const urlParams = useQueryParamsWithDefaults()

  return useMemo(() => {
    const filters = urlParams[urlFiltersKey] as TableFilterData<K>[]

    return {
      filters,
      setFilters: (field: K, expression: string) => {
        setFilterData(calcFilterArray(filters, field, expression))
      }
    }
  }, [setFilterData, urlFiltersKey, urlParams])
}
