import {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {usePromise} from 'react-use'

import {downloadCementStrengthExcel} from '../common/backend'
import {TimeRange} from '../declarations'

import {usePlantId} from './usePlantId'
import {useSnackbar} from './useSnackbar'

interface CementStrengthExcelDownload {
  download: (timeRange: TimeRange, filename: string) => void
  isDownloading: boolean
}

export const useCementStrengthExcelDownload = (): CementStrengthExcelDownload => {
  const plantId = usePlantId()
  const {t} = useTranslation()
  const [isDownloading, setIsDownloading] = useState(false)

  const promise = usePromise()
  const {showSnackbar} = useSnackbar()

  const download = useCallback(
    (timeRange: TimeRange, filename: string) => {
      setIsDownloading(true)
      promise(downloadCementStrengthExcel(plantId, timeRange, filename))
        .then(() => {
          setIsDownloading(false)
        })
        .catch(() => {
          setIsDownloading(false)
          showSnackbar(t('errors.downloadFailed'))
        })
    },
    [plantId, promise, showSnackbar, t]
  )

  return useMemo(
    () => ({
      download,
      isDownloading
    }),
    [download, isDownloading]
  )
}
